import { BaseComponent } from "@symbiotejs/symbiote";
import { I18n } from "../../utilities/i18n/i18n";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";
import { GridOrchestrator } from "../../components/grid-orchestrator/grid-orchestrator";

const css = `
    <style>
         @media screen and (orientation:portrait) {
             giveaways-container h1{
                 color: red;
            }
        }
         @media screen and (orientation:landscape) {
             giveaways-container h1{
                 text-align: center;
                 color: green;
            }
        }
    </style>
`

export class GiveawaysContainer extends BaseComponent {

    initCallback() {
        document.head.insertAdjacentHTML("beforeend", css)
        GridOrchestrator.deactivateGameFilters()
    }
}

GiveawaysContainer.template = /*html*/ `
    <div class="col text-center">

  <h1 class="mt-5">Giveaways</h1>
  <p>Giveaways</p>
   <div class="mt-5">
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
        </div>
  </div>
`;

GiveawaysContainer.reg('giveaways-container');
