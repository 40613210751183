import { BaseComponent } from "@symbiotejs/symbiote";
import "../../utilities/bootstrap-assistant/assets/scroll-bar.css";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";

const mlegends = require('./assets/images/mobileLegends 150x150.webp')
const csgo = require('./assets/images/csgologo 150x150.png')
const dota2 = require('./assets/images/dota2logo 150x150.png')
const lol = require('./assets/images/lollogo 150x150.png')
const valorant = require('./assets/images/valorantlogo 150x150.png')
const fifa = require('./assets/images/fifalogo 150x150.png')
const fortnite = require('./assets/images/fortnite 150x150.png')
const hearthstone = require('./assets/images/hearthstone 150x150.png')
const minecraft = require('./assets/images/minecraft 150x150.png')
const overwatch = require('./assets/images/overwatch 150x150.png')
const pubg = require('./assets/images/pubg 150x150.png')
const r6 = require('./assets/images/r6 150x150.webp')
const roblox = require('./assets/images/roblox 150x150.png')
const rocketleague = require('./assets/images/rocketleague 150x150.png')
const wow = require('./assets/images/world of warcraft 150x150.png')
const startcraft = require('./assets/images/starcraft II 150x150.png')
const cod = require('./assets/images/call of duty 150x150.png')
const ea = require('./assets/images/EA 150x150.webp')
const apex = require('./assets/images/ApexLegends 150x150.webp')
const kingofglory = require('./assets/images/KingOfGlory 150x150.webp')

const css = `
<style> 
game-navbar .disable-game-nav-bar{
    cursor:not-allowed;
    opacity:20%;
}

 game-navbar .gameNameActive {
     margin: 0.5rem;
     opacity: 1;
     max-width: 100%;
     transform: translate3d(0, 0, 0);
}
 game-navbar span{
     display: inline-block;
     overflow: hidden;
     max-width: 0;
     opacity: 0;
     transform: translate3d(0.5em, 0, 0);
     transition: opacity 1s, max-width 0s, transform 0.7s;
     transition-timing-function: ease-in-out;
}
 game-navbar .nav-link{
     transition: 0s ease-in-out !important;
}
 game-navbar li{
     display: flex;
     align-items: center;
     padding-right: 10px;
     padding-left: 10px;
}
 game-navbar li:nth-child(even) {
}
 game-navbar .activegamefilter {
     background-color: #ffffff59;
     border-radius: 8px;
     filter: drop-shadow(4px 3px 10px black);
     padding-left: 0.5rem;
     padding-right: 0.5rem;
}
 .gameNavBarItemsShouldBeCentered{
     justify-content: center;
}



/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
game-navbar .img-circle-container {
         width: 1.7rem;
        /* Adjust the width as needed */
         height: 1.7rem;
        /* Ensure the height is the same as the width for a perfect circle */
    }
    
     game-navbar span{
         font-size: 0.6rem;
         font-weight: 800;
    }
    
     game-navbar li{
     margin-right: 0.45rem;
}

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
game-navbar .img-circle-container {
         width: 1.8rem;
        /* Adjust the width as needed */
         height: 1.8rem;
        /* Ensure the height is the same as the width for a perfect circle */
    }   
    
     game-navbar span{
         font-size: 0.65rem;
         font-weight: 800;
    }
      game-navbar li{
     margin-right: 0.5rem;
}
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

game-navbar .img-circle-container {
         width: 1.9rem;
        /* Adjust the width as needed */
         height: 1.9rem;
        /* Ensure the height is the same as the width for a perfect circle */
    }   
    
     game-navbar span{
         font-size: 0.7rem;
         font-weight: 800;
    }
      game-navbar li{
     margin-right: 0.7rem;
}

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
game-navbar .img-circle-container {
         width: 1.9rem;
        /* Adjust the width as needed */
         height: 1.9rem;
        /* Ensure the height is the same as the width for a perfect circle */
    }   
    
     game-navbar span{
         font-size: 0.75rem;
         font-weight: 800;
    }
      game-navbar li{
     margin-right: 1rem;
}


}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
game-navbar li{
     margin-right: 1.5rem;
}
game-navbar .img-circle-container {
     width: 2.6rem;
    /* Adjust the width as needed */
     height: 2.6rem;
    /* Ensure the height is the same as the width for a perfect circle */
}

game-navbar span{
         font-size: 0.8rem;
         font-weight: 800;
    }
}
 </style>
`;

export class GameNavbar extends BaseComponent {
  initCallback() {
    document.head.insertAdjacentHTML("beforeend", css);
    const gameIcons = document.querySelectorAll('game-navbar li a');
    gameIcons.forEach((icon) => {
      icon.addEventListener('click', GameNavbar.changeUrlAccordingToFilters)
    });
    document.querySelector('game-navbar').addEventListener('wheel', GameNavbar.horizontalScrolling)
    window.gameNavBarEventListeners = {}
    window.addEventListener('resize', GameNavbar.performOrientationChangeActions);
    GameNavbar.performOrientationChangeActions();
  }



  static checkUrlToActivateFilters() {
    try { // this also happens at the end, and it is correct , don't change it
      document.querySelector(".activegamefilter").classList.remove("activegamefilter")
      document.querySelector(".gameNameActive").classList.remove("gameNameActive")
    } catch (e) {
      // console.debug("found no active game filter to remove")
    }
    const currentActiveGameFilter = RouterAssistant.getCurrentVariableValue('game')
    if ((currentActiveGameFilter === 'dota2')) {
      document.querySelector('[alt="dota2"]').parentElement.parentElement.classList.add('activegamefilter')
      document.querySelector('[alt="dota2"]').parentElement.parentElement.querySelector("span").classList.add('gameNameActive')

    } else if ((currentActiveGameFilter === 'csgo')) {
      document.querySelector('[alt="csgo"]').parentElement.parentElement.classList.add('activegamefilter')
      document.querySelector('[alt="csgo"]').parentElement.parentElement.querySelector("span").classList.add('gameNameActive')

    } else if ((currentActiveGameFilter === 'easports')) {
      document.querySelector('[alt="easports"]').parentElement.parentElement.classList.add('activegamefilter')
      document.querySelector('[alt="easports"]').parentElement.parentElement.querySelector("span").classList.add('gameNameActive')
    } else if ((currentActiveGameFilter === 'valorant')) {
      document.querySelector('[alt="valorant"]').parentElement.parentElement.classList.add('activegamefilter')
      document.querySelector('[alt="valorant"]').parentElement.parentElement.querySelector("span").classList.add('gameNameActive')
    } else if ((currentActiveGameFilter === 'cod')) {
      document.querySelector('[alt="cod"]').parentElement.parentElement.classList.add('activegamefilter')
      document.querySelector('[alt="cod"]').parentElement.parentElement.querySelector("span").classList.add('gameNameActive')
    } else if ((currentActiveGameFilter === 'overwatch')) {
      document.querySelector('[alt="overwatch"]').parentElement.parentElement.classList.add('activegamefilter')
      document.querySelector('[alt="overwatch"]').parentElement.parentElement.querySelector("span").classList.add('gameNameActive')
    }
    // else if ((currentActiveGameFilter === 'wow')) {
    //   document.querySelector('[alt="wow"]').parentElement.parentElement.classList.add('activegamefilter')
    //   document.querySelector('[alt="wow"]').parentElement.parentElement.querySelector("span").classList.add('gameNameActive')
    // }
    else if ((currentActiveGameFilter === 'fifa')) {
      document.querySelector('[alt="fifa"]').parentElement.parentElement.classList.add('activegamefilter')
      document.querySelector('[alt="fifa"]').parentElement.parentElement.querySelector("span").classList.add('gameNameActive')
    } else if ((currentActiveGameFilter === 'hearthstone')) {
      document.querySelector('[alt="hearthstone"]').parentElement.parentElement.classList.add('activegamefilter')
      document.querySelector('[alt="hearthstone"]').parentElement.parentElement.querySelector("span").classList.add('gameNameActive')
    } else if ((currentActiveGameFilter === 'fortnite')) {
      document.querySelector('[alt="fortnite"]').parentElement.parentElement.classList.add('activegamefilter')
      document.querySelector('[alt="fortnite"]').parentElement.parentElement.querySelector("span").classList.add('gameNameActive')
    } else if ((currentActiveGameFilter === 'pubg')) {
      document.querySelector('[alt="pubg"]').parentElement.parentElement.classList.add('activegamefilter')
      document.querySelector('[alt="pubg"]').parentElement.parentElement.querySelector("span").classList.add('gameNameActive')
    } else if ((currentActiveGameFilter === 'r6')) {
      document.querySelector('[alt="r6"]').parentElement.parentElement.classList.add('activegamefilter')
      document.querySelector('[alt="r6"]').parentElement.parentElement.querySelector("span").classList.add('gameNameActive')
    }
    // else if ((currentActiveGameFilter === 'roblox')) {
    //   document.querySelector('[alt="roblox"]').parentElement.parentElement.classList.add('activegamefilter')
    //   document.querySelector('[alt="roblox"]').parentElement.parentElement.querySelector("span").classList.add('gameNameActive')
    // }
    else if ((currentActiveGameFilter === 'rocketleague')) {
      document.querySelector('[alt="rocketleague"]').parentElement.parentElement.classList.add('activegamefilter')
      document.querySelector('[alt="rocketleague"]').parentElement.parentElement.querySelector("span").classList.add('gameNameActive')
    } else if ((currentActiveGameFilter === 'starcraft')) {
      document.querySelector('[alt="starcraft"]').parentElement.parentElement.classList.add('activegamefilter')
      document.querySelector('[alt="starcraft"]').parentElement.parentElement.querySelector("span").classList.add('gameNameActive')
    }
    // else if ((currentActiveGameFilter === 'minecraft')) {
    //   document.querySelector('[alt="minecraft"]').parentElement.parentElement.classList.add('activegamefilter')
    //   document.querySelector('[alt="minecraft"]').parentElement.parentElement.querySelector("span").classList.add('gameNameActive')
    // }
    else if ((currentActiveGameFilter === 'lol')) {
      document.querySelector('[alt="lol"]').parentElement.parentElement.classList.add('activegamefilter')
      document.querySelector('[alt="lol"]').parentElement.parentElement.querySelector("span").classList.add('gameNameActive')
    }
    else if ((currentActiveGameFilter === 'kingofglory')) {
      document.querySelector('[alt="kingofglory"]').parentElement.parentElement.classList.add('activegamefilter')
      document.querySelector('[alt="kingofglory"]').parentElement.parentElement.querySelector("span").classList.add('gameNameActive')
    }
    else if ((currentActiveGameFilter === 'apex')) {
      document.querySelector('[alt="apex"]').parentElement.parentElement.classList.add('activegamefilter')
      document.querySelector('[alt="apex"]').parentElement.parentElement.querySelector("span").classList.add('gameNameActive')
    }
    else if ((currentActiveGameFilter === 'mlegends')) {
      document.querySelector('[alt="mlegends"]').parentElement.parentElement.classList.add('activegamefilter')
      document.querySelector('[alt="mlegends"]').parentElement.parentElement.querySelector("span").classList.add('gameNameActive')
    } else { // yes this should happen both times one in the beggining to clear the already selected and one here to clear if no variable is present
      try {
        document.querySelector(".activegamefilter").classList.remove("activegamefilter")
        document.querySelector(".gameNameActive").classList.remove("gameNameActive")
      } catch (e) {
        console.debug("found no active game filter to remove")
      }
    }
  }

  static changeUrlAccordingToFilters(event) {
    const gameName = event.target.getAttribute('alt');
    if (window.location.href.includes(`game=${gameName}`)) {
      RouterAssistant.removeCurrentPageVariable("game")
      GameNavbar.checkUrlToActivateFilters()
    } else {
      RouterAssistant.addOrUpdateCurrentPageVariable("game", gameName)
      GameNavbar.checkUrlToActivateFilters()
    }
    GameNavbar.notifyEventListenersForGameFilterChanges()
  }

  static getCurrentlyActivatedGameIfAny() {
    return RouterAssistant.getCurrentVariableValue('game')
  }

  static subscribeToGameFilterChanges(eventListenerName, EventListenerFunction) {
    window.gameNavBarEventListeners[eventListenerName] = EventListenerFunction
  }

  static notifyEventListenersForGameFilterChanges() {
    Object.keys(window.gameNavBarEventListeners).forEach(eventListenerName => {
      window.gameNavBarEventListeners[eventListenerName](GameNavbar.getCurrentlyActivatedGameIfAny())
    })
  }

  static performOrientationChangeActions() {
    const el = document.querySelector('game-navbar ul')
    if ((el.scrollWidth === el.clientWidth) && (window.innerWidth > window.innerHeight)) {
      el.classList.add('gameNavBarItemsShouldBeCentered')
    } else {
      el.classList.remove('gameNavBarItemsShouldBeCentered')
    }
  }

  static horizontalScrolling(event) {
    if (Math.abs(event.wheelDeltaX) < Math.abs(event.wheelDeltaY)) {
      event.preventDefault();
      document.querySelector("game-navbar ul").scrollBy({ left: event.deltaY });
    }
  }
}

GameNavbar.template = /*html*/`
<nav class="navbar rounded mt-0 mt-sm-0 mt-md-0 mt-lg-0 mt-xl-2 mt-xxl-2" style=" background-color: #0D1117">
  <div class="container-fluid">
    <div class="navbar-collapse col-12" id="navbarNav">
      <ul class="navbar-nav flex-row scrollbar" style="overflow: scroll">
       <li class="nav-item col-auto">
         <a class="nav-link w-100 d-flex align-items-center" aria-current="page" >
            <div class=" img-circle-container d-flex justify-content-center flex-column ">
            <img class="img-fluid mx-auto d-block" src="${lol}" alt="lol">
              </div>
            
          <span class=" ">League Of Legends</span></a>
        </li>
        <li class="nav-item col-auto ">
         <a class="nav-link w-100 d-flex align-items-center" aria-current="page" >
        
            <div class=" img-circle-container d-flex justify-content-center flex-column ">
            <img class="img-fluid mx-auto d-block" src="${csgo}" alt="csgo">
             
          </div>
          <span class="  ">Counter Strike 2</span></a>
        </li>
        <li class="nav-item col-auto">
         <a class="nav-link w-100 d-flex align-items-center" aria-current="page" >
            <div class=" img-circle-container d-flex justify-content-center flex-column ">
            <img class="img-fluid mx-auto d-block" src="${dota2}" alt="dota2">
              </div>  
            <span class=" ">Dota 2</span></a> 
        </li>
       <li class="nav-item col-auto">
         <a class="nav-link w-100 d-flex align-items-center" aria-current="page" >
            <div class=" img-circle-container d-flex justify-content-center flex-column ">
            <img class="img-fluid mx-auto d-block" src="${valorant}" alt="valorant">
              </div>
            
          <span class=" ">Valorant</span></a>
        </li>
        <li class="nav-item col-auto">
         <a class="nav-link w-100 d-flex align-items-center" aria-current="page" >
            <div class=" img-circle-container d-flex justify-content-center flex-column ">

            <img class="img-fluid mx-auto d-block" src="${rocketleague}" alt="rocketleague">
              </div>
          <span class=" ">RocketLeague</span></a>
        </li>
        
           <li class="nav-item col-auto">
         <a class="nav-link w-100 d-flex align-items-center" aria-current="page" >
            <div class=" img-circle-container d-flex justify-content-center flex-column ">

            <img class="img-fluid mx-auto d-block" src="${mlegends}" alt="mlegends">
              </div>
          <span class=" ">Mobile Legends</span></a>
        </li>
        
        <li class="nav-item col-auto">
         <a class="nav-link w-100 d-flex align-items-center" aria-current="page" >
            <div class=" img-circle-container d-flex justify-content-center flex-column ">

            <img class="img-fluid mx-auto d-block" src="${startcraft}" alt="starcraft">
              </div>
          <span class=" ">Starcraft</span></a>
        </li>
        <li class="nav-item col-auto">
         <a class="nav-link w-100 d-flex align-items-center" aria-current="page" >
            <div class=" img-circle-container d-flex justify-content-center flex-column ">
            <img class="img-fluid mx-auto d-block" src="${r6}" alt="r6">
              </div>
          <span class=" ">Rainbow 6</span></a>
        </li>
         <li class="nav-item col-auto">
         <a class="nav-link w-100 d-flex align-items-center" aria-current="page" >
            <div class=" img-circle-container d-flex justify-content-center flex-column ">
            <img class="img-fluid mx-auto d-block" src="${cod}" alt="cod">
              </div>
          <span class=" ">Call Of Duty</span></a>
        </li>
        <li class="nav-item col-auto">
         <a class="nav-link w-100 d-flex align-items-center" aria-current="page" >
            <div class=" img-circle-container d-flex justify-content-center flex-column ">
            <img class="img-fluid mx-auto d-block" src="${overwatch}" alt="overwatch">
              </div>  
          <span class=" ">Overwatch</span></a>
        </li>
        <li class="nav-item col-auto">
         <a class="nav-link w-100 d-flex align-items-center" aria-current="page" >
            <div class=" img-circle-container d-flex justify-content-center flex-column ">

            <img class="img-fluid mx-auto d-block" src="${kingofglory}" alt="kingofglory">
              </div>
          <span class=" ">King Of Glory</span></a>
        </li>
        <li class="nav-item col-auto">
         <a class="nav-link w-100 d-flex align-items-center" aria-current="page" >
            <div class=" img-circle-container d-flex justify-content-center flex-column ">

            <img class="img-fluid mx-auto d-block" src="${ea}" alt="easports">
              </div>
          <span class=" ">EA Sports FC</span></a>
        </li>
         <li class="nav-item col-auto disable-game-nav-bar ">
         <a class="nav-link w-100 d-flex align-items-center disabled" aria-current="page" >
            <div class=" img-circle-container d-flex justify-content-center flex-column ">

            <img class="img-fluid mx-auto d-block" src="${apex}" alt="apex">
              </div>
          <span class=" ">Apex Legends</span></a>
        </li>
        <li class="nav-item col-auto disable-game-nav-bar">
         <a class="nav-link w-100 d-flex align-items-center disabled" aria-current="page" >
            <div class=" img-circle-container d-flex justify-content-center flex-column ">

            <img class="img-fluid mx-auto d-block" src="${fortnite}" alt="fortnite">
              </div>
          <span class=" ">Fortnite</span></a>
        </li>
        <li class="nav-item col-auto disable-game-nav-bar">
         <a class="nav-link w-100 d-flex align-items-center disabled" aria-current="page" >
            <div class=" img-circle-container d-flex justify-content-center flex-column ">
            
            <img class="img-fluid mx-auto d-block disabled" src="${pubg}" alt="pubg">
              </div>
          <span class=" ">PUBG</span></a>
        </li>
        <li class="nav-item col-auto disable-game-nav-bar">
         <a class="nav-link w-100 d-flex align-items-center disabled" aria-current="page" >
            <div class=" img-circle-container d-flex justify-content-center flex-column ">
            
            <img class="img-fluid mx-auto d-block" src="${hearthstone}" alt="hearthstone">
              </div>
          <span class=" ">HearthStone</span></a>
        </li>
      
        
            <!-- 
        
        -->
        <!-- 
         <li class="nav-item col-auto">
         <a class="nav-link w-100 d-flex align-items-center" aria-current="page" >
            <div class=" img-circle-container d-flex justify-content-center flex-column ">
            
            <img class="img-fluid mx-auto d-block" src="${roblox}" alt="roblox">
              </div>
          <span class=" ">Roblox</span></a>
        </li>
        <li class="nav-item col-auto">
         <a class="nav-link w-100 d-flex align-items-center" aria-current="page" >
            <div class=" img-circle-container d-flex justify-content-center flex-column ">
            
            <img class="img-fluid mx-auto d-block" src="${minecraft}" alt="minecraft">
              </div>
          <span class=" ">Minecraft</span></a>
        </li>
        <li class="nav-item col-auto">
         <a class="nav-link w-100 d-flex align-items-center" aria-current="page" >
            <div class=" img-circle-container d-flex justify-content-center flex-column ">
            
            <img class="img-fluid mx-auto d-block" src="${wow}" alt="wow">
              </div>
          <span class=" ">World Of Warcraft</span></a>
        </li>
        -->
      </ul>
    </div>
  </div>
</nav>
`;

GameNavbar.reg("game-navbar");
