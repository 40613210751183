import { BaseComponent } from "@symbiotejs/symbiote";
import { I18n } from "../../utilities/i18n/i18n";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";
import { GridOrchestrator } from "../../components/grid-orchestrator/grid-orchestrator";
import { MatchesComponent } from "../matches-component/matches-component";
import { PostBookmakersComponent } from "../postbookmakers-component/postbookmakers-component";
import { FirebaseAssistant } from "../../utilities/firebase-assistant/firebase-assistant";

const streamerImg = require('./assets/slyze.png')
const streamerWidgetImg = require('./assets/att.IYpe-MCWebp1VmkeZr68hZrzZyPELX0Hk6NixZS6QuE.jpg')
const gameImg = require('./assets/csgologo 150x150.png')
const css = `
<style>
      #faqAccordion a {
    color: #007BFF !important;
    text-decoration: none !important;
    transition: color 0.3s ease !important;
      }
  .card-custom {
            background-color: #212529;
            color: white;
        }
      .card-header-custom, .streamer-info-header {
            background-color: #800000; /* Dark red */
            color: white;
            text-align: center;
            
        }
        .streamer-info-header {
            padding: 5px 0;
        }
        .table-custom {
            color: white;
        }
        .table-custom th, .table-custom td {
            border-top: none;
        }
        .table-custom th {
            width: 50%;
            text-align: right;
        }
        .table-custom td {
            width: 50%;
            text-align: left;
        }
     
         .table-custom tr:nth-child(odd) {
            background-color: #1c232c; /* Darker background for odd rows */
        }
        .table-custom tr:nth-child(even) {
            background-color: #161b22; /* Lighter background for even rows */
        }

 .text-resize-button-streamer-info,  .text-resize-button-streamer-chat {
        align-items: center;
        align-content: center;
        display: inline;
        position: relative;
        }

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

streamer-component .text-resize-streamer-name{
font-size: 0.7rem;
font-weight: 800;
}

streamer-component .text-resize-streamer-followers{
font-size: 0.55rem;
font-weight: 600;
}
streamer-component  .text-resize-streamer-status{
display: none;
font-size: 0.5rem;
font-weight:600;
}
streamer-component .text-resize-streamer-live{
font-size: 0.6rem;
font-weight:500;
position: absolute;
bottom: -15px;
left: 5px;
display: none !important;
}
streamer-component .text-resize-streamer-live i{
font-size: 1rem;
font-weight:500;
}
 .desktopversion{
    height: 100px;
}
streamer-component .text-resize-streamer-info-header{
font-size: 0.75rem;
font-weight: 600;
}
    streamer-component .table-custom th {
            font-size: 0.6rem;
            font-weight: 600;
            color:#d5d5d5;
        }
        streamer-component .table-custom tr {
            font-size: 0.6rem;
            font-weight: 400;
            color:#8d8d8d;
        }
        .text-resize-button-streamer-info, .text-resize-button-streamer-chat {
        font-size: 0.7rem;
        font-weight: 700;
        }
        
       streamer-component .img-circle-container-streamer {
width: 35px;
height: 35px;
}

streamer-component .img-circle-container-streamer img {
max-width: 35px;
max-height: 35px;
}

streamer-component .img-circle-container-streamer-widget {
width: 100%;
height: 150px;
}

streamer-component .img-circle-container-streamer-widget img {
object-fit: cover;
}

streamer-component .img-circle-container-game {
width:0.7rem;
height: 0.8rem;
    right: 10px;
    top: 5px;
}

streamer-component .img-circle-container-game img {
max-width: 0.8rem;
max-height: 0.8rem;
}


}


/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

streamer-component .text-resize-streamer-name{
font-size: 0.7rem;
font-weight: 800;
}

streamer-component .text-resize-streamer-followers{
font-size: 0.55rem;
font-weight: 600;
}
streamer-component  .text-resize-streamer-status{
font-size: 0.5rem;
font-weight:600;
}
streamer-component .text-resize-streamer-live{
font-size: 0.6rem;
font-weight:500;
position: absolute;
bottom: -15px;
left: 5px;
}
streamer-component .text-resize-streamer-live i{
font-size: 1rem;
font-weight:500;
}
 .desktopversion{
    height: 155px;
}
streamer-component .text-resize-streamer-info-header{
font-size: 0.75rem;
font-weight: 600;
}
    streamer-component .table-custom th {
            font-size: 0.6rem;
            font-weight: 600;
            color:#d5d5d5;
        }
        streamer-component .table-custom tr {
            font-size: 0.6rem;
            font-weight: 400;
            color:#8d8d8d;
        }
         .text-resize-button-streamer-info,  .text-resize-button-streamer-chat {
        font-size: 0.7rem;
        font-weight: 700;
        }
        
       streamer-component .img-circle-container-streamer {
width: 35px;
height: 35px;
}

streamer-component .img-circle-container-streamer img {
max-width: 35px;
max-height: 35px;
}

streamer-component .img-circle-container-streamer-widget {
width: 100%;
height: 150px;
}

streamer-component .img-circle-container-streamer-widget img {
object-fit: cover;
}

streamer-component .img-circle-container-game {
width:0.7rem;
height: 0.8rem;
    right: 10px;
    top: 5px;
}

streamer-component .img-circle-container-game img {
max-width: 0.8rem;
max-height: 0.8rem;
}


}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

streamer-component .text-resize-streamer-name{
font-size: 0.7rem;
font-weight: 800;
}

streamer-component .text-resize-streamer-followers{
font-size: 0.55rem;
font-weight: 600;
}
streamer-component  .text-resize-streamer-status{
font-size: 0.5rem;
font-weight:600;
}
streamer-component .text-resize-streamer-live{
font-size: 0.6rem;
font-weight:500;
position: absolute;
bottom: 0;
left: 5px;
}
streamer-component .text-resize-streamer-live i{
font-size: 1rem;
font-weight:500;
}
 .desktopversion{
    height: 150px;
}
streamer-component .text-resize-streamer-info-header{
font-size: 0.75rem;
font-weight: 600;
}
   streamer-component .table-custom th {
            font-size: 0.6rem;
            font-weight: 600;
            color:#d5d5d5;
        }
         streamer-component .table-custom tr {
            font-size: 0.6rem;
            font-weight: 400;
            color:#8d8d8d;
        }
         .text-resize-button-streamer-info, .text-resize-button-streamer-chat {
        font-size: 0.7rem;
        font-weight: 700;
        }
        
        streamer-component .img-circle-container-streamer {
width: 35px;
height: 35px;
}

streamer-component .img-circle-container-streamer img {
max-width: 35px;
max-height: 35px;
}

streamer-component .img-circle-container-streamer-widget {
width: 100%;
height: 150px;
}

streamer-component .img-circle-container-streamer-widget img {
object-fit: cover;
}

streamer-component .img-circle-container-game {
width:0.7rem;
height: 0.8rem;
    right: 10px;
    top: 5px;
}

streamer-component .img-circle-container-game img {
max-width: 0.8rem;
max-height: 0.8rem;
}

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
streamer-component .text-resize-streamer-name{
font-size: 0.8rem;
font-weight: 800;
}

streamer-component .text-resize-streamer-followers{
font-size: 0.65rem;
font-weight: 600;
}
streamer-component  .text-resize-streamer-status{
font-size: 0.7rem;
font-weight:400;
}
streamer-component .text-resize-streamer-live{
font-size: 0.7rem;
font-weight:500;
position: absolute;
bottom: 0;
left: 5px;
}
streamer-component .text-resize-streamer-live i{
font-size: 1rem;
font-weight:500;
}
 .desktopversion{
    height: 175px;
}
streamer-component .text-resize-streamer-info-header{
font-size: 0.85rem;
font-weight: 700;
}
    streamer-component .table-custom th {
            font-size: 0.7rem;
            font-weight: 700;
            color:#d5d5d5;
        }
        streamer-component .table-custom tr {
            font-size: 0.7rem;
            font-weight: 500;
            color:#8d8d8d;
        }
        .text-resize-button-streamer-info, .text-resize-button-streamer-chat {
        font-size: 0.8rem;
        font-weight: 700;
        }
        
       streamer-component .img-circle-container-streamer {
width: 30px;
height: 30px;
}

streamer-component .img-circle-container-streamer img {
max-width: 30px;
max-height: 30px;
}

streamer-component .img-circle-container-streamer-widget {
width: 100%;
height: 150px;
}

streamer-component .img-circle-container-streamer-widget img {
object-fit: cover;
}

streamer-component .img-circle-container-game {
width:0.8rem;
height: 0.8rem;
    right: 10px;
    top: 18px;
}

streamer-component .img-circle-container-game img {
max-width: 0.8rem;
max-height: 0.8rem;
}

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
streamer-component .text-resize-streamer-name{
font-size: 1rem;
font-weight: 800;
}

streamer-component .text-resize-streamer-followers{
font-size: 0.9rem;
font-weight: 600;
}
streamer-component  .text-resize-streamer-status{
font-size: 0.8rem;
font-weight:400;
}
streamer-component .text-resize-streamer-live{
font-size: 0.8rem;
font-weight:500;
position: absolute;
bottom: 0;
left: 5px;
}
streamer-component .text-resize-streamer-live i{
font-size: 2rem;
font-weight:500;
}
 .desktopversion{
    height: 200px;
}
 .text-resize-streamer-info-header{
font-size: 1rem;
font-weight: 700;
}
   streamer-component .table-custom th {
            font-size: 0.9rem;
            font-weight: 700;
            color:#d5d5d5;
        }
        streamer-component .table-custom tr {
            font-size: 0.9rem;
            font-weight: 500;
            color:#8d8d8d;
        }
        .text-resize-button-streamer-info, .text-resize-button-streamer-chat {
        font-size: 1rem;
        font-weight: 700;
        }
        
       streamer-component .img-circle-container-streamer {
width: 50px;
height: 50px;
}

streamer-component .img-circle-container-streamer img {
max-width: 60px;
max-height: 60px;
object-fit: scale-down;
}

streamer-component .img-circle-container-streamer-widget {
width: 100%;
height: 250px;
}

streamer-component .img-circle-container-streamer-widget img {
object-fit: cover;
}

streamer-component .img-circle-container-game {
width:1rem;
height: 1rem;
    right: 10px;
    top: 18px;
}

streamer-component .img-circle-container-game img {
max-width: 1rem;
max-height: 1rem;
}

    }
</style>

`

export class StreamerComponent extends BaseComponent {

  initCallback() {


    GridOrchestrator.activateGameFilters()
    RouterAssistant.checkForSingleContainer('streamer-component', StreamerComponent.initiator);
    window.addEventListener('resize', StreamerComponent.resizetoTwitchHeight);
    document.head.insertAdjacentHTML("beforeend", css)


  }

  static accordionTranslations() {
    document.querySelector('.achowTo').innerHTML = I18n.translateString("streamerTranslations.accordionHowTo");
    document.querySelector('.acearn').innerHTML = I18n.translateString("streamerTranslations.accordionEarn");
    document.querySelector('.acgraphics').innerHTML = I18n.translateString("streamerTranslations.accordionGraphics");
    document.querySelector('.achowToStream').innerHTML = I18n.translateString("streamerTranslations.accordionHowToStream");
    document.querySelector('.acviewers').innerHTML = I18n.translateString("streamerTranslations.accordionViewers");
    document.querySelector('.achowToReply').innerHTML = I18n.translateString("streamerTranslations.accordionHowToReply");
    document.querySelector('.acearnReply').innerHTML = I18n.translateString("streamerTranslations.accordionEarnReply");
    document.querySelector('.acgraphicsReply').innerHTML = I18n.translateString("streamerTranslations.accordionGraphicsReply");
    document.querySelector('.achowToStreamReply').innerHTML = I18n.translateString("streamerTranslations.accordionHowToStreamReply");
    document.querySelector('.acviewersReply').innerHTML = I18n.translateString("streamerTranslations.accordionViewersReply");
  }

  static initiator() {
    StreamerComponent.getStreamers(() => {
      if (document.querySelectorAll('#thestreamercomp').length !== 0) {
        console.debug('I am running the STREAMER component call back!')
        StreamerComponent.updateStreamers()
      } else {
        console.debug('The STREAMER component call back is supposed to run but I am not on the correct page!!!')
      }
    });
    StreamerComponent.accordionTranslations();

  }

  static resizetoTwitchHeight() {
    const twitchIframe = document.getElementById('twitch-streamer-iframe');
    if (twitchIframe) {
      const width = twitchIframe.offsetWidth;
      const height = width * (9 / 16);
      twitchIframe.style.height = `${height}px`;
      console.debug('I am running the resizetoTwitchHeight function and the height is', height);
    }
    else {
      console.debug('Twitch iframe element not found');
    }

  }

  static updateStreamers() {
    const fromLiveMatchesClick = RouterAssistant.getCurrentVariableValue('streamerName');
    let streamersInfo = [];
    let userLanguage = I18n.getUsersPreferredLanguage();
    console.debug('User language is', userLanguage);

    for (const [streamerId, streamerData] of Object.entries(window.savedStreamers)) {

      let viewersHtml = '';

      userLanguage = userLanguage.toUpperCase();
      userLanguage = userLanguage.split('-')[0];
      let fullNameProperty = userLanguage + "_fullname";
      let nationalityProperty = userLanguage + "_nationality";



      if (streamerData.status === "Online") {
        viewersHtml = /*html*/ `<p class=" text-danger align-bottom text-start text-resize-streamer-live d-flex justify-content-start align-content-start align-items-center">
          <i class="bi bi-dot viewersTranslation"></i>${streamerData.viewerCount} ${I18n.translateString("streamerTranslations.cardViewers")}
        </p>`;
      } else {
        viewersHtml = /*html*/ `<p class=" text-white-50 align-bottom text-start text-resize-streamer-live d-flex justify-content-start align-content-start align-items-center">
          <i class="bi bi-dot"></i>Offline</p>
     `;
      }

      const embedUrl = `https://player.twitch.tv/?channel=${streamerData.userLogin}&parent=${window.location.hostname}`;
      const embedChatUrl = `https://www.twitch.tv/embed/${streamerData.userLogin}/chat?darkpopout&parent=${window.location.hostname}`;

      const twitchHtml = /*html*/ `<div class="twitch-container p-3 bg-dark text-white rounded mb-3 ratio ratio-16x9">
                                    <div class="col-sm-12 col-sm-12 col-lg-12 col-xl-7 col-xxl-7 d-flex  justify-content-center my-2 ">
                                    <div class="ratio ratio-16x9">
                                      <iframe id="twitch-streamer-iframe"
                                        src="${embedUrl}"
                                        width="100%"
                                        height="100%"
                                        allowfullscreen="true">
                                      </iframe>
                                    </div>
                                  </div>
                                </div>`;

      const twitchChatHtml = /*html*/ `
                                      <div class="twitch-chat-container p-3 bg-dark text-white rounded-bottom mb-3">
                                      <div class="col-12 d-flex  justify-content-center my-2 ">
                                      
                                        <iframe
                                          src="${embedChatUrl}"
                                          width="100%"
                                          height="700px"
                                          allowfullscreen="true">
                                        </iframe>
                                      
                                    </div>
                                  </div>
                                  </div>`;

      const streamerCardHtml = /*html*/ `<div class="col-4 mb-4 ">
      <div class="card text-white color-dark border-0 clickable position-relative streamerCard" id="${streamerData.userLogin}">
        <div class="img-circle-container-game d-flex flex-column justify-content-end align-content-end align-items-end position-absolute ">
        <!-- <img src="${gameImg}" alt="game-logo" class="img-fluid mx-auto d-block"> -->   
        <!-- to be done -->     
        </div>
        <div class="card-body desktopversion">
          <div class=" d-flex mb-1 flex-column justify-content-center align-content-center align-items-center justify-content-md-start align-content-md-start align-items-md-start flex-md-row mb-md-3 ">
            <div class=" img-circle-container-streamer d-flex justify-content-center border border-2 border-dark bg-dark bg-gradient rounded-circle overflow-hidden ">
              <img src="${streamerData.streamerAvatar}" alt="streamer-img" class="img-fluid mx-auto d-block">
            </div>
            <div class="text-center m-0 text-md-start ms-md-3">
              <h5 class="card-title mb-0 mt-1 mt-md-0 mb-md-1 text-resize-streamer-name">${streamerData.streamerName}</h5>
              <p class="card-text text-resize-streamer-followers">${streamerData.followers} ${I18n.translateString("streamerTranslations.cardFollowers")}</p>
            </div>
          </div>
          <p class="card-text text-center text-md-start text-resize-streamer-status">${I18n.translateString("streamerTranslations.cardInfo", { user: streamerData.streamerName, status: streamerData.status })}</p>
          ${viewersHtml}
        </div>
      </div>
    </div>
    `;

      const generateTableRow = (label, value, iconClass = '') => {
        if (value === "N/A") return ''; // Skip rows with "N/A" value
        let iconHtml = iconClass ? `<i class="${iconClass}"></i>` : '';
        let finalHtml = iconHtml ? `<a href="${value}" target="_blank" rel="noopener noreferrer">${iconHtml}</a>` : ``;
        return /*html*/`
                          <tr>
                            <th scope="row text-resize-row-info">${label}</th>
                            <td>${finalHtml !== '' ? finalHtml : `${iconHtml} ${value}`}</td>
                          </tr>
                          `;
      };

      const streamerDetailsHtml = /*html*/ `
  <div class="card card-custom border-0 p-0">
    <div class="card-body text-center p-0">
      <div class="img-circle-container-streamer-widget d-flex justify-content-center flex-column overflow-hidden">
        <img src="${streamerData.realData.streamerImage}" alt="streamer-widget-img" class="img-fluid mx-auto d-block">
      </div>
      <div class="streamer-info-header text-resize-streamer-info-header">${I18n.translateString("streamerTranslations.detailsInformation")}</div>
      <table class="table table-borderless table-custom m-0">
        <tbody class="text-center">
          ${generateTableRow(I18n.translateString("streamerTranslations.detailsName"), streamerData.realData[fullNameProperty])}
          ${generateTableRow(I18n.translateString("streamerTranslations.detailsNationality"), streamerData.realData[nationalityProperty])}
          ${generateTableRow(I18n.translateString("streamerTranslations.detailsAge"), streamerData.realData.age)}
          ${generateTableRow(I18n.translateString("streamerTranslations.detailsGame"), streamerData.games)}
          ${generateTableRow(I18n.translateString("streamerTranslations.detailsRole"), streamerData.realData.role)}
          ${generateTableRow(I18n.translateString("streamerTranslations.detailsTeam"), streamerData.realData.team)}
          ${generateTableRow(I18n.translateString("streamerTranslations.detailsSignature"), streamerData.realData.signature)}
          ${generateTableRow("Facebook", streamerData.socialMedia.facebook, "bi bi-facebook")}
          ${generateTableRow("Instagram", streamerData.socialMedia.instagram, "bi bi-instagram")}
          ${generateTableRow("TikTok", streamerData.socialMedia.tiktok, "bi bi-tiktok")}
          ${generateTableRow("Twitter", streamerData.socialMedia.twitter, "bi bi-twitter")}
          ${generateTableRow("YouTube", streamerData.socialMedia.youtube, "bi bi-youtube")}
        </tbody>
      </table>
    </div>
  </div>`;

      const streamerDetailsHtmlMobile = /*html*/ `
  <div class="card card-custom streamer-info-mobile border-0 mb-3">
    <div class="card-body mobile-version text-center p-0">
      <div class="img-circle-container-streamer-widget d-flex justify-content-center flex-column overflow-hidden">
        <img src="${streamerData.realData.streamerImage}" alt="streamer-widget-img" class="img-fluid mx-auto d-block">
      </div>
      <div class="streamer-info-header text-resize-streamer-info-header">${I18n.translateString("streamerTranslations.detailsInformation")}</div>
      <table class="table table-borderless table-custom m-0">
        <tbody class="text-center">
          ${generateTableRow(I18n.translateString("streamerTranslations.detailsName"), streamerData.realData[fullNameProperty])}
          ${generateTableRow(I18n.translateString("streamerTranslations.detailsNationality"), streamerData.realData[nationalityProperty])}
          ${generateTableRow(I18n.translateString("streamerTranslations.detailsAge"), streamerData.realData.age)}
          ${generateTableRow(I18n.translateString("streamerTranslations.detailsGame"), streamerData.games)}
          ${generateTableRow(I18n.translateString("streamerTranslations.detailsRole"), streamerData.realData.role)}
          ${generateTableRow(I18n.translateString("streamerTranslations.detailsTeam"), streamerData.realData.team)}
          ${generateTableRow(I18n.translateString("streamerTranslations.detailsSignature"), streamerData.realData.signature)}
          ${generateTableRow("Facebook", streamerData.socialMedia.facebook, "bi bi-facebook")}
          ${generateTableRow("Instagram", streamerData.socialMedia.instagram, "bi bi-instagram")}
          ${generateTableRow("TikTok", streamerData.socialMedia.tiktok, "bi bi-tiktok")}
          ${generateTableRow("Twitter", streamerData.socialMedia.twitter, "bi bi-twitter")}
          ${generateTableRow("YouTube", streamerData.socialMedia.youtube, "bi bi-youtube")}
        </tbody>
      </table>
    </div>
  </div>`;

      if (streamerData.showState) {
        streamersInfo.push({ streamerCardHtml, streamerData, embedUrl, twitchHtml, streamerDetailsHtml, streamerDetailsHtmlMobile, twitchChatHtml });
      }

    }



    streamersInfo.sort((a, b) => {
      if (a.streamerData.status === "Online" && b.streamerData.status !== "Online") {
        return -1;
      } else if (a.streamerData.status !== "Online" && b.streamerData.status === "Online") {
        return 1;
      } else if (a.streamerData.status === "Offline" && b.streamerData.status === "Offline") {
        return b.streamerData.followers - a.streamerData.followers;
      } else {
        return b.streamerData.viewerCount - a.streamerData.viewerCount;
      }
    });

    console.debug(streamersInfo);

    let allStreamersHtml = streamersInfo.map(streamer => streamer.streamerCardHtml).join('');
    document.querySelector('#theCardRow').innerHTML = allStreamersHtml;

    document.querySelectorAll('.streamerCard').forEach(card => {
      card.addEventListener('click', function () {
        RouterAssistant.removeCurrentPageVariable("streamerName")
        RouterAssistant.addOrUpdateCurrentPageVariable("streamerName", this.id);
        const userLogin = this.id;
        const streamerInfo = streamersInfo.find(info => info.streamerData.userLogin === userLogin);
        if (streamerInfo) {
          const infoChat = document.getElementById('infoChatRow');
          infoChat.style.display = '';
          const infoVideoRow = document.getElementById('infoVideoRow');
          infoVideoRow.style.display = '';
          const twitchContainer = document.getElementById('dynamicTwitch');
          const streamerDetails = document.getElementById('streamerDetails');


          streamerDetails.innerHTML = streamerInfo.streamerDetailsHtml;
          twitchContainer.innerHTML = streamerInfo.twitchHtml;

          document.getElementById('detailsOption').classList.add('active');
          document.getElementById('chatOption').classList.remove('active');

          StreamerComponent.resizetoTwitchHeight()

          document.querySelectorAll('.detailsOption').forEach(el => {
            el.addEventListener('click', function () {
              streamerDetails.innerHTML = "";
              streamerDetails.innerHTML = streamerInfo.streamerDetailsHtml;

            });

          });

          document.querySelectorAll('.chatOption').forEach(el => {
            el.addEventListener('click', function () {
              streamerDetails.innerHTML = "";
              streamerDetails.innerHTML = streamerInfo.twitchChatHtml;
            });
          });

          document.querySelectorAll('.deskStreamOption').forEach(el => {
            el.addEventListener('click', function () {
              twitchContainer.innerHTML = "";
              twitchContainer.innerHTML = streamerInfo.twitchHtml;
              document.getElementById('deskStreamOption').classList.add('active');
              document.getElementById('deskInfoOption').classList.remove('active');

            });
          });

          document.querySelectorAll('.deskInfoOption').forEach(el => {
            el.addEventListener('click', function () {
              twitchContainer.innerHTML = "";
              twitchContainer.innerHTML = streamerInfo.streamerDetailsHtmlMobile;
              document.getElementById('deskStreamOption').classList.remove('active');
              document.getElementById('deskInfoOption').classList.add('active');
            });
          });
        }
      });
    });

    if (fromLiveMatchesClick !== null) {
      const streamerInfo = streamersInfo.find(info => info.streamerData.userLogin === fromLiveMatchesClick);
      if (streamerInfo) {
        const infoChat = document.getElementById('infoChatRow');
        infoChat.style.display = '';
        const infoVideoRow = document.getElementById('infoVideoRow');
        infoVideoRow.style.display = '';
        const twitchContainer = document.getElementById('dynamicTwitch');
        const streamerDetails = document.getElementById('streamerDetails');

        streamerDetails.innerHTML = streamerInfo.streamerDetailsHtml;

        twitchContainer.innerHTML = streamerInfo.twitchHtml;
        StreamerComponent.resizetoTwitchHeight()

        document.querySelectorAll('.detailsOption').forEach(el => {
          el.addEventListener('click', function () {
            streamerDetails.innerHTML = "";
            streamerDetails.innerHTML = streamerInfo.streamerDetailsHtml;

          });

        });

        document.querySelectorAll('.chatOption').forEach(el => {
          el.addEventListener('click', function () {
            streamerDetails.innerHTML = "";
            // Ensure twitchChatHtml is defined and accessible
            streamerDetails.innerHTML = streamerInfo.twitchChatHtml;
          });
        });

        document.querySelectorAll('.deskStreamOption').forEach(el => {
          el.addEventListener('click', function () {
            twitchContainer.innerHTML = "";
            twitchContainer.innerHTML = streamerInfo.twitchHtml;
            document.getElementById('deskStreamOption').classList.add('active');
            document.getElementById('deskInfoOption').classList.remove('active');
          });
        });

        document.querySelectorAll('.deskInfoOption').forEach(el => {
          el.addEventListener('click', function () {
            twitchContainer.innerHTML = "";
            twitchContainer.innerHTML = streamerInfo.streamerDetailsHtmlMobile;
            document.getElementById('deskStreamOption').classList.remove('active');
            document.getElementById('deskInfoOption').classList.add('active');
          });
        });
      }
    }

  }

  static getStreamers(callback) {
    if (window.savedStreamers === undefined) {
      FirebaseAssistant.getStreamers(StreamersThatCameBack => {
        const tempObject = {}
        StreamersThatCameBack.forEach(NewsEntity => {
          tempObject[NewsEntity.id] = NewsEntity.data();
        });
        window.savedStreamers = tempObject
        callback()
      });
    } else {
      callback()
    }
  }
}

StreamerComponent.template = /*html*/ `


<div class="container" id="thestreamercomp">

                    <div class="row-cols-2 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-0 col-xxl-0 d-flex d-xl-none " id="infoVideoRow"  style="display: none !important ;">
                        <div class="col text-center clickable deskStreamOption active rounded-top" id="deskStreamOption">
                            <h5 class="text-resize-button-streamer-info">Stream</h5>
                        </div>
                        <div class="col text-center clickable deskInfoOption notactive rounded-top" id="deskInfoOption">
                            <h5 class="text-resize-button-streamer-chat">Info</h5>
                        </div>
                        </div>
                    </div>
  <div id="dynamicTwitch">
    <!-- Twitch iframe inserted here dynamically -->
  </div>
  <div class="row p-2 d-flex align-content-center align-items-center" id="theCardRow">
    <!-- Cards inserted here dynamically -->
  </div>



   <!-- QNA Start -->

  <div class="accordion mt-5 rounded" id="faqAccordion">

    <div class="accordion-item border-0 rounded mb-3">
        <h2 class="accordion-header border-0 rounded" id="headingOne">
            <button class="accordion-button collapsed color-dark border-0 rounded achowTo" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
            </button>
        </h2>
        <div id="collapseOne" class="accordion-collapse collapse color-dark border-0 rounded" aria-labelledby="headingOne" data-bs-parent="#faqAccordion">
            <div class="accordion-body rounded achowToReply">
            </div>
        </div>
    </div>

    <div class="accordion-item border-0 mb-3 rounded">
        <h2 class="accordion-header rounded" id="headingTwo">
            <button class="accordion-button collapsed color-dark border-0 rounded acearn" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"> 
            </button>
        </h2>
        <div id="collapseTwo" class="accordion-collapse collapse color-dark border-0 rounded" aria-labelledby="headingTwo" data-bs-parent="#faqAccordion">
            <div class="accordion-body rounded acearnReply">
            </div>
        </div>
    </div>

    <div class="accordion-item border-0 mb-3 rounded">
        <h2 class="accordion-header rounded" id="headingThree">
            <button class="accordion-button collapsed color-dark border-0 rounded acgraphics" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">

            </button>
        </h2>
        <div id="collapseThree" class="accordion-collapse collapse color-dark border-0 rounded" aria-labelledby="headingThree" data-bs-parent="#faqAccordion">
            <div class="accordion-body rounded acgraphicsReply">
            </div>
        </div>
    </div>

    <div class="accordion-item border-0 mb-3 rounded">
        <h2 class="accordion-header rounded" id="headingFour">
            <button class="accordion-button collapsed color-dark border-0 rounded achowToStream" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
            </button>
        </h2>
        <div id="collapseFour" class="accordion-collapse collapse color-dark border-0 rounded" aria-labelledby="headingFour" data-bs-parent="#faqAccordion">
            <div class="accordion-body rounded achowToStreamReply">
            </div>
        </div>
    </div>

 <div class="accordion-item border-0 mb-3 rounded">
        <h2 class="accordion-header border-0 rounded" id="headingFive">
            <button class="accordion-button collapsed color-dark border-0 rounded acviewers" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
            </button>
        </h2>
        <div id="collapseFive" class="accordion-collapse collapse color-dark border-0 rounded" aria-labelledby="headingFive" data-bs-parent="#faqAccordion">
            <div class="accordion-body rounded acviewersReply">
            </div>
        </div>
    </div>
    
</div>
   <!-- QNA End -->

</div>

`;

StreamerComponent.reg('streamer-component');

