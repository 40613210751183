import { BaseComponent } from "@symbiotejs/symbiote";
import { FirebaseAssistant } from "../../utilities/firebase-assistant/firebase-assistant";
import { ScrollToTop } from "../scrolltotop-component/scrolltotop-component";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";




const noImageFoundPlayer = require('./assets/author.webp')
const css = `
    <style>
livestreamer-component .container-livestreamer {
    margin: 0;
    padding: 0; 
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    position: relative;
}

livestreamer-component .circle {
    width: 50px;
    height: 50px;
    background-color: #2ecc71;
    border-radius: 50%;
    border: 3px solid #cc2e2e;
    position: fixed;
    bottom: 5.5rem;
    right: 5rem;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 1057;
    filter: drop-shadow(0 0 0.5rem #101010);
}

livestreamer-component .circle .logo {
    
    transition: all 0.3s ease;
    position: relative;
    z-index: 20;
}

livestreamer-component .circle .content {
    display: none;
    flex-direction: column;
    align-items: center;
    color: #151515;
    text-align: center;
    position: absolute;
    left: 60px; /* Space for the logo when expanded */
    z-index: 15;
    opacity: 0;
    transform: translatey(20px);
    
}

@keyframes slideInFadeIn {
    from {
        opacity: 0;
        transform: translateX(20px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

livestreamer-component .circle:hover {
    width: 350px;
    height: 75px;
    border-radius: 10px;
    justify-content: flex-start;
}

livestreamer-component .circle:hover .logo {
    width: 40px;
    height: 40px;
}

livestreamer-component .circle:hover .content {
    display: flex;
    animation: slideInFadeIn 2s forwards;
}
livestreamer-component  .img-circle-container-streamer{
width: 3rem;
height: 3rem;
 }   
livestreamer-component  .img-circle-container-streamer img{
 max-width: 2.5rem;
 max-height: 2.5rem;
 }   
 
 
 
 /* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    livestreamer-component .circle {
        width: 40px;
        height: 40px;
        bottom: 7rem;
        right: 1rem;
        border-radius: 50%;
    }
    
    
    
    livestreamer-component .circle .content {
        font-size: 0.8rem;
        left: 60px; /* Space for the logo when expanded */
    }
    
    
    livestreamer-component .circle:hover {
        width: 250px;
        height: 45px;
        border-radius: 10px;
    }
    livestreamer-component  .img-circle-container-streamer{
    width: 3rem;
    height: 3rem;
    }   
    livestreamer-component  .img-circle-container-streamer img{
     max-width: 2.5rem;
     max-height: 2.5rem;
    }   
 
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    livestreamer-component .circle {
        width: 40px;
        height: 40px;  
        border-radius: 50%;
        bottom: 7rem;
        right: 1.3rem;
    }
    
    
    
    livestreamer-component .circle .content {
        
        left: 60px; /* Space for the logo when expanded */
    }
    
    
    livestreamer-component .circle:hover {
        width: 350px;
        height: 45px;
        border-radius: 10px;
    }
    
    
    
    livestreamer-component  .img-circle-container-streamer{
    width: 3rem;
    height: 3rem;
    }   
    livestreamer-component  .img-circle-container-streamer img{
     max-width: 2.5rem;
     max-height: 2.5rem;
    }   
 
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    livestreamer-component .circle {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        bottom: 7rem;
        right: 1.3rem;
    }
    
    
    
    livestreamer-component .circle .content {
        left: 60px; /* Space for the logo when expanded */
    }
    
    
    livestreamer-component .circle:hover {
        width: 350px;
        height: 45px;
        border-radius: 10px;
    }
    
    
    
    livestreamer-component  .img-circle-container-streamer{
    width: 3.5rem;
    height: 3.5rem;
    }   
    livestreamer-component  .img-circle-container-streamer img{
     max-width: 2.5rem;
     max-height: 2.5rem;
    }   
     
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    livestreamer-component .circle {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        bottom: 7rem;
        right: 1.3rem;
    }
    
    
    livestreamer-component .circle .content {
        left: 5rem; /* Space for the logo when expanded */
    }
    
    
    livestreamer-component .circle:hover {
        width: 350px;
        height: 50px;
        border-radius: 10px;
    }
    
    livestreamer-component  .img-circle-container-streamer{
    width: 4rem;
    height: 4rem;
    }   
    livestreamer-component  .img-circle-container-streamer img{
     max-width: 3.5rem;
     max-height: 3.5rem;
    }   
 
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

    
    livestreamer-component .circle {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        bottom: 5rem;
        right: 5rem;
    }
    
    
    
    livestreamer-component .circle .content {
        left: 5rem; /* Space for the logo when expanded */
    }
    
    
    livestreamer-component .circle:hover {
        width: 350px;
        height: 50px;
        border-radius: 10px;
    }
    
    livestreamer-component  .img-circle-container-streamer{
    width: 4rem;
    height: 4rem;
    }   
    livestreamer-component  .img-circle-container-streamer img{
     max-width: 3.5rem;
     max-height: 3.5rem;
    }   
 
}
    </style>
`

export class LivestreamerComponent extends BaseComponent {

    initCallback() {
        document.head.insertAdjacentHTML("beforeend", css)
        LivestreamerComponent.translations()
        window.addEventListener('resize', LivestreamerComponent.performOrientationChangeActions);
        LivestreamerComponent.performOrientationChangeActions();
        LivestreamerComponent.getStreamers(() => {
            console.log(window.savedStreamers)
            for (const [streamerId, streamerData] of Object.entries(window.savedStreamers)) {
                console.log(streamerId, streamerData);
                if (streamerData.status === 'Online') {
                    // todo ======== for shadow to start here ========
                    document.getElementById("streamer-container").style.display = ""
                    document.getElementById("streamerImg").setAttribute("src", streamerData.streamerAvatar)
                    document.getElementById("streamerText").innerText = `${streamerData.streamerName} is playing ${streamerData.games}` //todo translations
                    document.getElementById("scrollToTop")

                    document.getElementById("streamer-container").addEventListener('click', () => {
                        RouterAssistant.redirect('streamers',
                            {
                                streamerName: streamerData.userLogin
                            }
                        )
                    })
                    // todo ======= for shadow to end here =========



                    // Function to adjust bottom padding based on scrollToTop visibility


                    // Initial adjustment
                    LivestreamerComponent.adjustStreamerContainerBottom();

                    // Add scroll event listener to adjust dynamically
                    window.addEventListener('scroll', LivestreamerComponent.adjustStreamerContainerBottom, true);

                    // todo ======= for shadow to end here =========
                }

            }
        });
    }

    static adjustStreamerContainerBottom() {
        const scrollToTopElement = document.getElementById("scrollToTop");
        const streamerContainer = document.getElementById("circle-streamer-container");

        if (getComputedStyle(scrollToTopElement).display === "none") {
            console.log('foo')
            streamerContainer.style.bottom = getComputedStyle(scrollToTopElement).bottom;
        } else {
            console.log('bar')
            streamerContainer.style.bottom = ""; // Reset to the value defined in CSS
        }
    }

    static translations() { }

    static performOrientationChangeActions() {
        if (window.location.href.includes('tade-page')) { // make sure the event listener only runs when the component exists in the page
            if (window.innerHeight > window.innerWidth) { // mobile
            } else { // desktop
            }
        }
    }


    static getStreamers(callback) {
        if (window.savedStreamers === undefined) {
            FirebaseAssistant.getStreamers(StreamersThatCameBack => {
                const tempObject = {}
                StreamersThatCameBack.forEach(NewsEntity => {
                    tempObject[NewsEntity.id] = NewsEntity.data();
                });
                window.savedStreamers = tempObject
                callback()
            });
        } else {
            callback()
        }
    }
}

LivestreamerComponent.template = /*html*/`
  
<div class="container-livestreamer" id="streamer-container" style="display: none"> 


    <div class="circle clickable green-animation" id="circle-streamer-container">
        <div class="img-circle-container-streamer d-flex justify-content-center flex-column m-2  ">
                <img id="streamerImg" src="" alt="" class="img-fluid mx-auto d-block" >
                </div>
        <div class="content">
            <span id="streamerText"></span>
        </div>
    </div>
    </a>
    </div>



`;

LivestreamerComponent.reg('livestreamer-component'); // components should always be 2 words seperated with a dash
