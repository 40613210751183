import { BaseComponent } from "@symbiotejs/symbiote";
import { FirebaseAssistant } from "../../utilities/firebase-assistant/firebase-assistant";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";
import { GeofencingGuard } from "../../utilities/geofencing-guard/geofencing-guard";
import { I18n } from "../../utilities/i18n/i18n";
import { doc } from "firebase/firestore";

const stoiximanlogo = require('../bookmakershome-component/assets/Stoiximan home page 2.webp')
const bet365logo = require('../bookmakershome-component/assets/bet365 home page 2.webp')
const betssonlogo = require('../bookmakershome-component/assets/betsson home page 2.webp')
const novibetlogo = require('../bookmakershome-component/assets/novibet home page 2.webp')
const winmasterslogo = require('../bookmakershome-component/assets/winmasters home page 2.webp')
const fonbetlogo = require('../bookmakershome-component/assets/fonbet home page 2.webp')
const netbetlogo = require('../bookmakershome-component/assets/netbet home page 2.webp')

const css = `
    <style>
   
    widget-bookmakers .card:hover{
        cursor:pointer;
    }

 widget-bookmakers a{
 text-decoration: none;
 color: #adadad;
 }
       widget-bookmakers .zoom {
            transition: transform 0.3s ease-in-out;
            /* object-fit: cover; only if we want to crop the image to fit the container and not lose resolution */
        }
        
       widget-bookmakers .zoom:hover {
            transform: scale(1.05);
        }



/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

    widget-bookmakers .text-resize{
 font-size: 0.6rem;
 font-weight: 400;
 color:#ffffff;
 }
   widget-bookmakers .img-circle-container {

    width: 8rem;
    height: 3rem;
    
}
widget-bookmakers .text-resize-title{
 font-size: 0.8rem;
		font-weight: 500;
		color: #b0b0b0;
		margin-left: 0.2rem;

}

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    widget-bookmakers .text-resize{
 font-size: 0.65rem;
 font-weight: 400;
 color:#ffffff;
 }
   widget-bookmakers .img-circle-container {

    width: 10rem;
    height: 4rem;
    
}


}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    widget-bookmakers .text-resize{
 font-size: 0.7rem;
 font-weight: 400;
 color:#ffffff;
 }
   widget-bookmakers .img-circle-container {

    width: 10rem;
    height: 4rem;
    
}
}

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

    widget-bookmakers .text-resize{
 font-size: 0.5rem;
 font-weight: 400;
 color:#ffffff;
 }
   widget-bookmakers .img-circle-container {

    width: 10rem;
    height: auto;
    
}


}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

    widget-bookmakers .text-resize{
 font-size: 0.8rem;
 font-weight: 400;
 color:#ffffff;
 }
  widget-bookmakers .img-circle-container {

    width: auto;
    height: 3rem;
    
}

}


    </style>
`

export class WidgetBookmakers extends BaseComponent {

    initCallback() {
        document.head.insertAdjacentHTML("beforeend", css)
        window.addEventListener('resize', WidgetBookmakers.performOrientationChangeActions);
        RouterAssistant.checkForSingleContainer('widget-bookmakers', WidgetBookmakers.initiator)
        WidgetBookmakers.performOrientationChangeActions();
        WidgetBookmakers.addHoverEffect();
    }

    static performOrientationChangeActions() {
        if (window.location.href.includes('tade-page')) { // make sure the event listener only runs when the component exists in the page
            if (window.innerHeight > window.innerWidth) { // mobile

            } else { // desktop

            }
        }
    }

    static addHoverEffect() {
        // Define the bookmakers and their corresponding colors
        const bookmakers = {
            'stoiximan': '#1365d4',
            'bet365': '#007B5B', // Replace with the actual color you want for bet365
            'betsson': '#FF6600', // Replace with the actual color you want for betsson
            'winmasters': '#ffffff',
            'netbet': '#ffffff',
            'novibet': '#28a8ac',
            'fonbet': '#D42A28'
        };

        // Loop through each bookmaker entry in the object
        Object.entries(bookmakers).forEach(([bookmaker, color]) => {
            const card = document.querySelector(`.${bookmaker}`);
            if (card) {
                const logo = card.querySelector('.card-img'); // Assuming .card-img is the image you want to zoom
                const imgContainer = card.querySelector('.alternatingBackground'); // The container that should change color

                card.addEventListener('mouseenter', () => {
                    if (logo) {
                        logo.classList.remove('unzoom');
                        logo.classList.add('zoom');
                    }
                    if (imgContainer) {
                        imgContainer.style.backgroundColor = color;
                    }
                });

                card.addEventListener('mouseleave', () => {
                    if (logo) {
                        logo.classList.remove('zoom');
                        logo.classList.add('unzoom');
                    }
                    if (imgContainer) {
                        imgContainer.style.backgroundColor = 'transparent';
                    }
                });
            }
        });
    }


    static initiator() {
        FirebaseAssistant.getAllBookmakers((dataReceived) => {
            GeofencingGuard.getUserCountry(function (userCurrentCountry) {
                const userCurrentLanguage = I18n.getUsersPreferredLanguage()

                let html = '';
                const bookmakersData = []; // Array to hold dynamic bookmaker data

                dataReceived.forEach(single => {
                    if (!single.data().location_bookmaker.includes(userCurrentCountry)) return
                    if (single.data().language_bookmaker != userCurrentLanguage) return
                    if (single.data().status == 'draft') return
                    const bookmakerName = single.data().name;
                    const bookmakerAbout = single.data().bookmaker_about;
                    const bookmakerLogo = single.data().bookmaker_logo;
                    const bookmakerRating = single.data().rating;
                    const bookmakersClass = single.data().bookmaker_class;
                    const bookmakerColor = single.data().bookmaker_color;
                    const bookmakerRipos = single.data().bookmaker_ripos;
                    const bookmakerBonus = single.data().bookmaker_hot_bonus;
                    const bookmakerUrl = single.data().bookmaker_bonus_url;
                    bookmakersData.push({
                        bookmakerClass: bookmakersClass,
                        color: bookmakerColor
                    });

                    html += /*html*/ `
      <div class="row mb-2 mb-xl-4 clickable p-0" >
    
    <div class="col-12 ">
      <div class="card card-custom  border-0 ">
      <a class="" href="${bookmakerUrl}" target="_blank" style="text-decoration: none;">
        <div  class="row g-0 rounded d-flex justify-content-center align-items-center" style="background: #161B22;">
         
          <div class="col-3 col-sm-3 col-md-4 col-lg-5 col-xl-4 col-xxl-4 d-flex my-2 my-xl-3 ">
          <div class=" rounded img-circle-container d-flex justify-content-center flex-column mx-2 " style="background-color: ${bookmakerColor}">
            <img src="${bookmakerLogo}" class="img-fluid mx-auto d-block zoom" alt="${bookmakerName} logo">
            </div>
          </div>
              <div class="col-9 col-sm-9 col-md-8 col-lg-7 col-xl-8 col-xxl-8 d-flex  justify-content-start my-2 " >
               
              <span class="text-resize mx-0 mx-xl-2 text-start text-truncate">${bookmakerBonus}</span>
             
              </div>

          <div class="col-12 rounded-bottom p-1 justify-content-center align-content-center text-center align-items-center eeep-text">ΕΕΕΠ | 21+ | ΠΑΙΞΕ ΥΠΕΥΘΥΝΑ </div>
                
          
        </div>
           </a>
      </div>
    </div>
  </div>
 
       
        
        `;
                });
                if (document.querySelector('#widgetbookmakers-container')) {
                    document.querySelector('#widgetbookmakers-container').innerHTML = html;
                    WidgetBookmakers.addHoverEffect(bookmakersData);
                }
            })
        });
    }
}


WidgetBookmakers.template = /*html*/ `
<!-- <div class="container-fluid p-0 my-0  my-xl-5">
 <div class="title mb-3">
        <span class="text-resize-title">BONUS</span>
        </div>
        <div class="row d-flex justify-content-center" id="widgetbookmakers-container"></div>
  
  </div>
 -->
          
`;
WidgetBookmakers.reg('widget-bookmakers'); // components should always be 2 words seperated with a dash
