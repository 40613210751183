import {BaseComponent} from "@symbiotejs/symbiote";
import {RouterAssistant} from "../../utilities/router-assistant/router-assistant.js";



const css = `
    <style>
      user-notifier{
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100dvh;
        z-index: 1055;
        display: none;
      }
    </style>
`

export class UserNotifier extends BaseComponent {

    static initiate(){
        document.body.insertAdjacentHTML('beforeend', `<user-notifier></user-notifier>`)
        const el = document.querySelector('user-notifier');
        el.classList.add('justify-content-center')
        el.classList.add('align-items-center')
        el.classList.add('w-100')
    }

    initCallback() {
        document.head.insertAdjacentHTML("beforeend", css)
        const toast = document.getElementById('userMessage')
        toast.addEventListener('shown.bs.toast', function () {})
        toast.addEventListener('hidden.bs.toast', function () {
            RouterAssistant.deactivateCurtain()
            document.querySelector('user-notifier').classList.remove('d-flex')
            document.getElementById('toastHeader').innerText = ''
            document.getElementById('toastBody').innerText = ''
        })
    }

    static notifyUser (title, message){
        document.querySelector('user-notifier').classList.add('d-flex')
        document.getElementById('toastHeader').innerText = title
        document.getElementById('toastBody').innerText = message
        window.bootstrap.Toast.getOrCreateInstance(document.getElementById('userMessage')).show()
        RouterAssistant.activateCurtain(0.8)
    }
}

UserNotifier.template = /*html*/ `  
  <div id="userMessage" class="toast" role="alert" aria-live="assertive" aria-atomic="true" data-bs-delay="10000">
    <div class="toast-header">
      <svg class="bd-placeholder-img rounded me-2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#007aff"></rect></svg>
      <strong id="toastHeader" class="me-auto"></strong>
      <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
    </div>
    <div id="toastBody" class="toast-body"></div>
  </div>
`;

UserNotifier.reg('user-notifier');
