import { BaseComponent } from "@symbiotejs/symbiote";
import { I18n } from "../../utilities/i18n/i18n";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";
import { GridOrchestrator } from "../../components/grid-orchestrator/grid-orchestrator";
import { PostMatchesComponent } from "../../components/postmatches-component/postmatches-component";
import { MatchesComponent } from "../matches-component/matches-component";



const css = `
    
`

export class PostMatchesContainer extends BaseComponent {

    initCallback() {
        document.head.insertAdjacentHTML("beforeend", css)
        GridOrchestrator.deactivateGameFilters()
    }


}

PostMatchesContainer.template = /*html*/ `
        <div class="main-content col-lg-8 col-md-8 col-sm-8 col-xs-12 g-0 mt-2 ">

         <div class="home-matches home-components row m-2">

        <div class="home-upcoming-matches">
            <postmatches-component></postmatches-component>
        </div>
      </div>

     <div class="home-latest-news home-components row m-2">

            <news-carousel></news-carousel>


    </div>
    <div class="home-tournaments home-components row m-2">

        <div class="home-carousel-slider">
            <hottournaments-component></hottournaments-component>
        </div>

    </div>
<!--     <div class="home-bookmakers home-components row m-2">-->
<!--       -->
<!--        <bookmakershome-component></bookmakershome-component>-->
<!--    </div>-->

</div>





<div class="sidebar col-lg-4 col-md-4 col-sm-4 col-xs-12 mt-2 ">



    <div class="sidebar-predictions home-components row m-2 ">
        <div id="placeholder-chat" class="col-0 col-sm-0 col-md-0 col-lg-0 col-xl-12 col-xxl-12 placeholder-glow rounded w-100 color-dark" >
            <div class="placeholder bg-secondary w-100 rounded my-3" style="height: 700px;">  </div>
        </div>
        <div class="col-0 col-sm-0 col-md-0 col-lg-0 col-xl-12 col-xxl-12 d-none  rounded d-xl-block" id="chatforvideo"></div>
        <matches-component data-mode="homePage"></matches-component>

         <predictions-homecomponent></predictions-homecomponent>
        </div>

    
    <div class="sidebar-discord home-components row m-2 " >
        
            
            <div class="home-discord-template">
            <discord-template></discord-template>
        
        </div>

    </div>
    <div class="sidebar-bonus home-components row m-2 ">
        
           
            <div class="home-widget-bookmakers">
            <widget-bookmakers></widget-bookmakers>
        
        </div>
    </div>
</div>  

`;

PostMatchesContainer.reg('postmatches-container');
