import { BaseComponent } from "@symbiotejs/symbiote";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";

import { I18n } from "../../utilities/i18n/i18n";

const csgo = require("../../components/game-navbar/assets/images/csgologo 150x150.png");
const dota2 = require("../../components/game-navbar/assets/images/dota2logo 150x150.png");
const ea = require("../../components/game-navbar/assets/images/EA 150x150.webp");
const lol = require("../../components/game-navbar/assets/images/lollogo 150x150.png");
const valorant = require("../../components/game-navbar/assets/images/valorantlogo 150x150.png");
const r6 = require("../../components/game-navbar/assets/images/r6 150x150.webp");
const cod = require("../../components/game-navbar/assets/images/call of duty 150x150.png");
const fifa = require('../../components/game-navbar/assets/images/fifalogo 150x150.png')
const fortnite = require('../../components/game-navbar/assets/images/fortnite 150x150.png')
const hearthstone = require('../../components/game-navbar/assets/images/hearthstone 150x150.png')
const minecraft = require('../../components/game-navbar/assets/images/minecraft 150x150.png')
const overwatch = require('../../components/game-navbar/assets/images/overwatch 150x150.png')
const pubg = require('../../components/game-navbar/assets/images/pubg 150x150.png')
const roblox = require('../../components/game-navbar/assets/images/roblox 150x150.png')
const rocketleague = require('../../components/game-navbar/assets/images/rocketleague 150x150.png')
const wow = require('../../components/game-navbar/assets/images/world of warcraft 150x150.png')
const startcraft = require('../../components/game-navbar/assets/images/starcraft II 150x150.png')
const apex = require('../../components/game-navbar/assets/images/ApexLegends 150x150.webp')
const kingofglory = require('../../components/game-navbar/assets/images/KingOfGlory 150x150.webp')
const mlegends = require('../game-navbar/assets/images/mobileLegends 150x150.webp')


const css = `
<style>
.comingsoon{
cursor:not-allowed;
opacity:0.6;
}
predictions-homecomponent .predNavItem {
    border: 1px solid #ff282859; /* Border color */
    border-radius: 5px; /* Rounded corners */
    margin-bottom: 10px; /* Space between items */
    background-color: #161B22; /* Background color */
    
}

predictions-homecomponent .predNavItem .nav-link {
    color: #f8f9fa; /* Link color */
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor:not-allowed;
    padding: 0 !important;
}

predictions-homecomponent .predNavItem .nav-link:hover {
    color: #ff282859; /* Link color on hover */
    text-decoration: none; /* Remove underline on hover */
}

predictions-homecomponent .predCounterBadge {
    background-color: #ff282859; /* Badge background color */
    color: #f8f9fa; /* Text color */
 }

predictions-homecomponent .text-resize-badge {
         display: flex;
         justify-content: center;
         justify-items: center;
         align-items: center;
    }        
        
        @media only screen and (max-width: 600px) {

    .comingsoon {
        cursor: not-allowed;
        opacity: 0.6;
    }

    predictions-homecomponent .text-resize {
         font-size: 0.5rem;
    }
    predictions-homecomponent .text-resize-badge {
         font-size: 0.5rem;
    }

    predictions-homecomponent .img-circle-container {
       width: 1rem;
       height: 1rem;
    }
    
              predictions-homecomponent .text-resize-title{
 font-size: 0.8rem;
    font-weight: 500;
    color:#b0b0b0;
    margin-left: 0.2rem;

}

    /* Other styles.. */
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

    predictions-homecomponent .text-resize {
         font-size: 0.6rem;
    }
    predictions-homecomponent .text-resize-badge {
         font-size: 0.5rem;
    }

    predictions-homecomponent .img-circle-container {
       width: 2rem;
       height: 2rem;
    }

    /* Other styles.. */
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

    predictions-homecomponent .text-resize {
         font-size: 0.7rem;
    }
    predictions-homecomponent .text-resize-badge {
         font-size: 0.6rem;
    }

    predictions-homecomponent .img-circle-container {
       width: 2rem;
       height: 2rem;
    }

    /* Other styles.. */
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

    predictions-homecomponent .text-resize {
         font-size: 0.8rem;
    }
    predictions-homecomponent .text-resize-badge {
         font-size: 0.7rem;
    }

    predictions-homecomponent .img-circle-container {
       width: 2rem;
       height: 2rem;
    }

    /* Other styles.. */
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

    predictions-homecomponent .text-resize {
         font-size: 0.8rem;
    }
    predictions-homecomponent .text-resize-badge {
         font-size: 0.7rem;
    }

    predictions-homecomponent .img-circle-container {
       width: 2rem;
       height: 2rem;
    }

</style>
`

export class PredictionsHomecomponent extends BaseComponent {

    initCallback() {
        document.head.insertAdjacentHTML("beforeend", css);
        RouterAssistant.checkForSingleContainer('predictions-homecomponent', PredictionsHomecomponent.initiator);
    }

    static initiator() {
        let predictionsHtml = /*html*/`
              <div class="title mb-3">
        <span class="text-resize-title predTitle"></span>
        </div>
            <div class="container-fluid p-2 p-xl-2 bg-secondary bg-opacity-10 rounded disabled comingsoon">
             
<!--                    <span class="text-resize-title  predTitle"></span>-->
                    <span class="badge rounded-pill bg-primary primary-animation text-dark text-end text-resize d-flex justify-content-end comingSoonClass" style="
                        float: right;
                        margin-top: -1rem !important;
                        color: #ffffff !important;
                        "></span>
            
            <ul class="nav flex-column nav-pills nav-fill w-100 mt-3">
            <li class="nav-item predNavItem text-resize">
                <a class="nav-link" href="#"> <div class=" img-circle-container d-flex justify-content-center flex-column m-2 ">
                <img src="${lol}" alt="lol" class="logo img-fluid mx-auto d-block"></div>
                League of Legends <span class=" badge predCounterBadge rounded-pill text-resize-badge me-2">0</span></a>
            </li>
            <li class="nav-item predNavItem text-resize">
                <a class="nav-link" href="#"> <div class=" img-circle-container d-flex justify-content-center flex-column m-2 ">
                <img src="${csgo}" alt="csgo" class="logo img-fluid mx-auto d-block"></div>
                CS:GO <span class=" badge predCounterBadge rounded-pill text-resize-badge me-2">0</span></a>
            </li>
            <li class="nav-item predNavItem text-resize">
                <a class="nav-link" href="#"> <div class=" img-circle-container d-flex justify-content-center flex-column m-2 ">
                <img src="${dota2}" alt="dota2" class="logo img-fluid mx-auto d-block"></div>
                Dota2 <span class=" badge predCounterBadge rounded-pill text-resize-badge me-2">0</span></a>
            </li>
            <li class="nav-item predNavItem text-resize">
                <a class="nav-link" href="#"> <div class=" img-circle-container d-flex justify-content-center flex-column m-2 ">
                <img src="${valorant}" alt="valorant" class="logo img-fluid mx-auto d-block"></div>
                Valorant <span class=" badge predCounterBadge rounded-pill text-resize-badge me-2">0</span></a>
            </li>
            <li class="nav-item predNavItem text-resize">
                <a class="nav-link" href="#"> <div class=" img-circle-container d-flex justify-content-center flex-column m-2 ">
                <img src="${rocketleague}" alt="rocketleague" class="logo img-fluid mx-auto d-block"></div>
                Rocket League <span class=" badge predCounterBadge rounded-pill text-resize-badge me-2">0</span></a>
            </li>
            <li class="nav-item predNavItem text-resize">
                <a class="nav-link" href="#"> <div class=" img-circle-container d-flex justify-content-center flex-column m-2 ">
                <img src="${startcraft}" alt="starcraft" class="logo img-fluid mx-auto d-block"></div>
                Starcraft <span class=" badge predCounterBadge rounded-pill text-resize-badge me-2">0</span></a>
            </li>
            <li class="nav-item predNavItem text-resize">
                <a class="nav-link" href="#"> <div class=" img-circle-container d-flex justify-content-center flex-column m-2 ">
                <img src="${r6}" alt="r6" class="logo img-fluid mx-auto d-block"></div>
                Rainbow 6 <span class=" badge predCounterBadge rounded-pill text-resize-badge me-2">0</span></a>
            </li>
            <li class="nav-item predNavItem text-resize">
                <a class="nav-link" href="#"> <div class=" img-circle-container d-flex justify-content-center flex-column m-2 ">
                <img src="${cod}" alt="cod" class="logo img-fluid mx-auto d-block"></div>
                Call Of Duty <span class=" badge predCounterBadge rounded-pill text-resize-badge me-2">0</span></a>
            </li>
            <li class="nav-item predNavItem text-resize">
                <a class="nav-link" href="#"> <div class=" img-circle-container d-flex justify-content-center flex-column m-2 ">
                <img src="${overwatch}" alt="overwatch" class="logo img-fluid mx-auto d-block"></div>
                Overwatch <span class=" badge predCounterBadge rounded-pill text-resize-badge me-2">0</span></a>
            </li>
            <li class="nav-item predNavItem text-resize">
                <a class="nav-link" href="#"> <div class=" img-circle-container d-flex justify-content-center flex-column m-2 ">
                <img src="${kingofglory}" alt="kingofglory" class="logo img-fluid mx-auto d-block"></div>
                King of Glory <span class=" badge predCounterBadge rounded-pill text-resize-badge me-2">0</span></a>
            </li>
            <li class="nav-item predNavItem text-resize">
                <a class="nav-link" href="#"> <div class=" img-circle-container d-flex justify-content-center flex-column m-2 ">
                <img src="${ea}" alt="ea" class="logo img-fluid mx-auto d-block"></div>
                EA Sports FC <span class=" badge predCounterBadge rounded-pill text-resize-badge me-2">0</span></a>
            </li>
            <li class="nav-item predNavItem text-resize">
                <a class="nav-link" href="#"> <div class=" img-circle-container d-flex justify-content-center flex-column m-2 ">
                <img src="${mlegends}" alt="mlegends" class="logo img-fluid mx-auto d-block"></div>
                Mobile Legends <span class=" badge predCounterBadge rounded-pill text-resize-badge me-2">0</span></a>
            </li>   
            </ul>
            </div>
        `
        document.getElementById('predictionsFinalHtml').innerHTML = predictionsHtml;
        // document.querySelectorAll('.predNavItem').forEach((el) => {
        //     let badge = el.querySelector('.predCounterBadge');
        //     if (badge && badge.innerHTML.trim() == '0') {
        //         el.classList.add('d-none');
        //     }
        // });
        PredictionsHomecomponent.translations();
    }

    static translations() {
        document.querySelectorAll('.predTitle').forEach((el) => {
            el.innerHTML = I18n.translateString('predictionsTranslations.predTitle');
        });

        document.querySelectorAll('.comingSoonClass').forEach((el) => {
            el.innerHTML = I18n.translateString('predictionsTranslations.comingSoon');
        });
    }
}
PredictionsHomecomponent.template = /*html*/`
<div id="predictionsFinalHtml"></div>
`;

PredictionsHomecomponent.reg('predictions-homecomponent'); // components should always be 2 words seperated with a dash

