import { BaseComponent } from "@symbiotejs/symbiote";
import { I18n } from "../../utilities/i18n/i18n";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";
import { GridOrchestrator } from "../../components/grid-orchestrator/grid-orchestrator";
// import { MatchesComponent } from "../matches-component/matches-component";
import { TournamentsComponent } from "../tournaments-component/tournaments-component";
import { PostTournamentsComponent } from "../posttournaments-component/posttournaments-component";

const css = `
   
`

export class PostTournamentsContainer extends BaseComponent {

    initCallback() {
        document.head.insertAdjacentHTML("beforeend", css)
        GridOrchestrator.deactivateGameFilters()
    }



}

PostTournamentsContainer.template = /*html*/ `


      <div class="main-content col-lg-8 col-md-8 col-sm-8 col-xs-12 g-0 mt-2 ">

         <div class="home-matches home-components row m-2 p-2">

        <div class="home-matches p-1">
                <posttournaments-component> </posttournaments-component>
        </div>
      </div>
</div>





<div class="sidebar col-lg-4 col-md-4 col-sm-4 col-xs-12 mt-2 ">



    <div class="sidebar-predictions home-components row m-2 ">
       
         <predictions-homecomponent></predictions-homecomponent>
        

    </div>
    <div class="sidebar-discord home-components row m-2 " >
        
            
            <div class="home-discord-template">
            <discord-template></discord-template>
        
        </div>

    </div>
    <div class="sidebar-bonus home-components row m-2 ">
        
           
            <div class="home-widget-bookmakers">
            <widget-bookmakers></widget-bookmakers>
        
        </div>
    </div>
</div>  
`;

PostTournamentsContainer.reg('posttournaments-container');