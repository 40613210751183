import {BaseComponent} from "@symbiotejs/symbiote";
import {FirebaseAssistant} from "../../utilities/firebase-assistant/firebase-assistant";
import {RouterAssistant} from "../../utilities/router-assistant/router-assistant";



const css = `
    <style>
    </style>
`

export class PlaceholderMatchesWidget extends BaseComponent {

    initCallback() {
        document.head.insertAdjacentHTML("beforeend", css)


    }






}


PlaceholderMatchesWidget.template =`
<div id="placeholder-matches-widget">
    <div class="sidebar-matches rounded color-dark d-flex justify-content-center w-100 placeholder-glow mb-3 mt-5" style="height:80px" >
        <!-- Match Item Placeholder -->
        <div class="row d-flex align-items-center align-content-center my-2 w-100">
            <!-- Team 1 Placeholder -->
            <div class="col-4 d-flex justify-content-start text-start align-items-center">
                <div class="placeholder bg-secondary rounded-circle" style="width: 50px; height: 50px;"></div>
                <span class="placeholder bg-secondary rounded ms-2" style="width: 60px; height: 20px;"></span>
            </div>
            <!-- Game Logo and Countdown Placeholder -->
            <div class="col-4 text-center">
                <div class="placeholder bg-danger rounded" style="width: 50px; height: 20px;"></div>
            </div>
            <!-- Team 2 Placeholder -->
            <div class="col-4 d-flex justify-content-end text-end align-items-center">
                <span class="placeholder bg-secondary rounded me-2" style="width: 60px; height: 20px;"></span>
                <div class="placeholder bg-secondary rounded-circle" style="width: 50px; height: 50px;"></div>
            </div>
        </div>
    </div>
    <div class="sidebar-matches rounded color-dark d-flex justify-content-center w-100 placeholder-glow mb-3" style="height:80px" >
        <!-- Match Item Placeholder -->
        <div class="row d-flex align-items-center align-content-center my-2 w-100">
            <!-- Team 1 Placeholder -->
            <div class="col-4 d-flex justify-content-start text-start align-items-center">
                <div class="placeholder bg-secondary rounded-circle" style="width: 50px; height: 50px;"></div>
                <span class="placeholder bg-secondary rounded ms-2" style="width: 60px; height: 20px;"></span>
            </div>
            <!-- Game Logo and Countdown Placeholder -->
            <div class="col-4 text-center">
                <div class="placeholder bg-danger rounded" style="width: 50px; height: 20px;"></div>
            </div>
            <!-- Team 2 Placeholder -->
            <div class="col-4 d-flex justify-content-end text-end align-items-center">
                <span class="placeholder bg-secondary rounded me-2" style="width: 60px; height: 20px;"></span>
                <div class="placeholder bg-secondary rounded-circle" style="width: 50px; height: 50px;"></div>
            </div>
        </div>
    </div>
    <div class="sidebar-matches rounded color-dark d-flex justify-content-center w-100 placeholder-glow mb-3" style="height:80px" >
        <!-- Match Item Placeholder -->
        <div class="row d-flex align-items-center align-content-center my-2 w-100">
            <!-- Team 1 Placeholder -->
            <div class="col-4 d-flex justify-content-start text-start align-items-center">
                <div class="placeholder bg-secondary rounded-circle" style="width: 50px; height: 50px;"></div>
                <span class="placeholder bg-secondary rounded ms-2" style="width: 60px; height: 20px;"></span>
            </div>
            <!-- Game Logo and Countdown Placeholder -->
            <div class="col-4 text-center">
                <div class="placeholder bg-danger rounded" style="width: 50px; height: 20px;"></div>
            </div>
            <!-- Team 2 Placeholder -->
            <div class="col-4 d-flex justify-content-end text-end align-items-center">
                <span class="placeholder bg-secondary rounded me-2" style="width: 60px; height: 20px;"></span>
                <div class="placeholder bg-secondary rounded-circle" style="width: 50px; height: 50px;"></div>
            </div>
        </div>
    </div>
    <div class="sidebar-matches rounded color-dark d-flex justify-content-center w-100 placeholder-glow mb-3" style="height:80px" >
        <!-- Match Item Placeholder -->
        <div class="row d-flex align-items-center align-content-center my-2 w-100">
            <!-- Team 1 Placeholder -->
            <div class="col-4 d-flex justify-content-start text-start align-items-center">
                <div class="placeholder bg-secondary rounded-circle" style="width: 50px; height: 50px;"></div>
                <span class="placeholder bg-secondary rounded ms-2" style="width: 60px; height: 20px;"></span>
            </div>
            <!-- Game Logo and Countdown Placeholder -->
            <div class="col-4 text-center">
                <div class="placeholder bg-danger rounded" style="width: 50px; height: 20px;"></div>
            </div>
            <!-- Team 2 Placeholder -->
            <div class="col-4 d-flex justify-content-end text-end align-items-center">
                <span class="placeholder bg-secondary rounded me-2" style="width: 60px; height: 20px;"></span>
                <div class="placeholder bg-secondary rounded-circle" style="width: 50px; height: 50px;"></div>
            </div>
        </div>
    </div>
    <div class="sidebar-matches rounded color-dark d-flex justify-content-center w-100 placeholder-glow mb-3" style="height:80px" >
        <!-- Match Item Placeholder -->
        <div class="row d-flex align-items-center align-content-center my-2 w-100">
            <!-- Team 1 Placeholder -->
            <div class="col-4 d-flex justify-content-start text-start align-items-center">
                <div class="placeholder bg-secondary rounded-circle" style="width: 50px; height: 50px;"></div>
                <span class="placeholder bg-secondary rounded ms-2" style="width: 60px; height: 20px;"></span>
            </div>
            <!-- Game Logo and Countdown Placeholder -->
            <div class="col-4 text-center">
                <div class="placeholder bg-danger rounded" style="width: 50px; height: 20px;"></div>
            </div>
            <!-- Team 2 Placeholder -->
            <div class="col-4 d-flex justify-content-end text-end align-items-center">
                <span class="placeholder bg-secondary rounded me-2" style="width: 60px; height: 20px;"></span>
                <div class="placeholder bg-secondary rounded-circle" style="width: 50px; height: 50px;"></div>
            </div>
        </div>
    </div>
    <div class="sidebar-matches rounded color-dark d-flex justify-content-center w-100 placeholder-glow mb-3" style="height:80px" >
        <!-- Match Item Placeholder -->
        <div class="row d-flex align-items-center align-content-center my-2 w-100">
            <!-- Team 1 Placeholder -->
            <div class="col-4 d-flex justify-content-start text-start align-items-center">
                <div class="placeholder bg-secondary rounded-circle" style="width: 50px; height: 50px;"></div>
                <span class="placeholder bg-secondary rounded ms-2" style="width: 60px; height: 20px;"></span>
            </div>
            <!-- Game Logo and Countdown Placeholder -->
            <div class="col-4 text-center">
                <div class="placeholder bg-danger rounded" style="width: 50px; height: 20px;"></div>
            </div>
            <!-- Team 2 Placeholder -->
            <div class="col-4 d-flex justify-content-end text-end align-items-center">
                <span class="placeholder bg-secondary rounded me-2" style="width: 60px; height: 20px;"></span>
                <div class="placeholder bg-secondary rounded-circle" style="width: 50px; height: 50px;"></div>
            </div>
        </div>
    </div>
    <div class="sidebar-matches rounded color-dark d-flex justify-content-center w-100 placeholder-glow mb-3" style="height:80px" >
        <!-- Match Item Placeholder -->
        <div class="row d-flex align-items-center align-content-center my-2 w-100">
            <!-- Team 1 Placeholder -->
            <div class="col-4 d-flex justify-content-start text-start align-items-center">
                <div class="placeholder bg-secondary rounded-circle" style="width: 50px; height: 50px;"></div>
                <span class="placeholder bg-secondary rounded ms-2" style="width: 60px; height: 20px;"></span>
            </div>
            <!-- Game Logo and Countdown Placeholder -->
            <div class="col-4 text-center">
                <div class="placeholder bg-danger rounded" style="width: 50px; height: 20px;"></div>
            </div>
            <!-- Team 2 Placeholder -->
            <div class="col-4 d-flex justify-content-end text-end align-items-center">
                <span class="placeholder bg-secondary rounded me-2" style="width: 60px; height: 20px;"></span>
                <div class="placeholder bg-secondary rounded-circle" style="width: 50px; height: 50px;"></div>
            </div>
        </div>
    </div>
    <div class="sidebar-matches rounded color-dark d-flex justify-content-center w-100 placeholder-glow mb-3" style="height:80px" >
        <!-- Match Item Placeholder -->
        <div class="row d-flex align-items-center align-content-center my-2 w-100">
            <!-- Team 1 Placeholder -->
            <div class="col-4 d-flex justify-content-start text-start align-items-center">
                <div class="placeholder bg-secondary rounded-circle" style="width: 50px; height: 50px;"></div>
                <span class="placeholder bg-secondary rounded ms-2" style="width: 60px; height: 20px;"></span>
            </div>
            <!-- Game Logo and Countdown Placeholder -->
            <div class="col-4 text-center">
                <div class="placeholder bg-danger rounded" style="width: 50px; height: 20px;"></div>
            </div>
            <!-- Team 2 Placeholder -->
            <div class="col-4 d-flex justify-content-end text-end align-items-center">
                <span class="placeholder bg-secondary rounded me-2" style="width: 60px; height: 20px;"></span>
                <div class="placeholder bg-secondary rounded-circle" style="width: 50px; height: 50px;"></div>
            </div>
        </div>
    </div>
    <div class="sidebar-matches rounded color-dark d-flex justify-content-center w-100 placeholder-glow mb-3" style="height:80px" >
        <!-- Match Item Placeholder -->
        <div class="row d-flex align-items-center align-content-center my-2 w-100">
            <!-- Team 1 Placeholder -->
            <div class="col-4 d-flex justify-content-start text-start align-items-center">
                <div class="placeholder bg-secondary rounded-circle" style="width: 50px; height: 50px;"></div>
                <span class="placeholder bg-secondary rounded ms-2" style="width: 60px; height: 20px;"></span>
            </div>
            <!-- Game Logo and Countdown Placeholder -->
            <div class="col-4 text-center">
                <div class="placeholder bg-danger rounded" style="width: 50px; height: 20px;"></div>
            </div>
            <!-- Team 2 Placeholder -->
            <div class="col-4 d-flex justify-content-end text-end align-items-center">
                <span class="placeholder bg-secondary rounded me-2" style="width: 60px; height: 20px;"></span>
                <div class="placeholder bg-secondary rounded-circle" style="width: 50px; height: 50px;"></div>
            </div>
        </div>
    </div>
    <div class="sidebar-matches rounded color-dark d-flex justify-content-center w-100 placeholder-glow mb-3" style="height:80px" >
        <!-- Match Item Placeholder -->
        <div class="row d-flex align-items-center align-content-center my-2 w-100">
            <!-- Team 1 Placeholder -->
            <div class="col-4 d-flex justify-content-start text-start align-items-center">
                <div class="placeholder bg-secondary rounded-circle" style="width: 50px; height: 50px;"></div>
                <span class="placeholder bg-secondary rounded ms-2" style="width: 60px; height: 20px;"></span>
            </div>
            <!-- Game Logo and Countdown Placeholder -->
            <div class="col-4 text-center">
                <div class="placeholder bg-danger rounded" style="width: 50px; height: 20px;"></div>
            </div>
            <!-- Team 2 Placeholder -->
            <div class="col-4 d-flex justify-content-end text-end align-items-center">
                <span class="placeholder bg-secondary rounded me-2" style="width: 60px; height: 20px;"></span>
                <div class="placeholder bg-secondary rounded-circle" style="width: 50px; height: 50px;"></div>
            </div>
        </div>
    </div>
</div>
 
          
`;
PlaceholderMatchesWidget.reg('placeholder-matcheswidget'); // components should always be 2 words seperated with a dash




















