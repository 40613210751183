import { BaseComponent } from "@symbiotejs/symbiote";
import { FirebaseAssistant } from "../../utilities/firebase-assistant/firebase-assistant";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";
import { GeofencingGuard } from "../../utilities/geofencing-guard/geofencing-guard";
import { I18n } from "../../utilities/i18n/i18n";

const stoiximanlogo = require('./assets/Stoiximan home page 2.webp')
const bet365logo = require('./assets/bet365 home page 2.webp')
const betssonlogo = require('./assets/betsson home page 2.webp')
const novibetlogo = require('./assets/novibet home page 2.webp')
const winmasterslogo = require('./assets/winmasters home page 2.webp')
const fonbetlogo = require('./assets/fonbet home page 2.webp')
const netbetlogo = require('./assets/netbet home page 2.webp')

const css = `
    <style>
   
    bookmakershome-component .card:hover{
        cursor:pointer;
    }
  
 bookmakershome-component .zoom{
     transform: scale(1.05);
     transition: transform 0.5s ease-in-out;
} 
 bookmakershome-component .unzoom{
     transform: scale(1);
     transition: transform 0.5s ease-in-out;
}
 bookmakershome-component .shadow-right {
     position: relative; 
     overflow: hidden;
}
bookmakershome-component .bet365{
     background: linear-gradient(#161B22, #161B22) padding-box, linear-gradient(to right, #007B5B,#ffffff00,#ffffff00 ,#ffffff00) border-box;
     border-radius: 20px;
     border: 5px solid transparent;
}

bookmakershome-component .bet365::after{
    position: absolute;
    right:-0.3rem;
    top: -0.3rem;
    bottom: -0.3rem;
    left: -0.3rem;
     border-radius: 20px;
     border: 5px solid #007B5B;
     transition: all 0.5s ease-in-out;
     content: '';
     opacity: 0;
}

bookmakershome-component .bet365:hover::after{
     opacity: 1;
}
bookmakershome-component .stoiximan{
     background: linear-gradient(#161B22, #161B22) padding-box, linear-gradient(to right, #1365d4,#ffffff00,#ffffff00 ,#ffffff00) border-box;
     border-radius: 20px;
     border: 5px solid transparent;
}

bookmakershome-component .stoiximan::after{
    position: absolute;
    right:-0.3rem;
    top: -0.3rem;
    bottom: -0.3rem;
    left: -0.3rem;
     border-radius: 20px;
     border: 5px solid #1365d4;
     transition: all 0.5s ease-in-out;
     content: '';
     opacity: 0;
}

bookmakershome-component .stoiximan:hover::after{
     opacity: 1;
}

bookmakershome-component .rivalry{
     background: linear-gradient(#161B22, #161B22) padding-box, linear-gradient(to right, #ffffff,#ffffff00,#ffffff00 ,#ffffff00) border-box;
     border-radius: 20px;
     border: 5px solid transparent;
}

bookmakershome-component .rivalry::after{
    position: absolute;
    right:-0.3rem;
    top: -0.3rem;
    bottom: -0.3rem;
    left: -0.3rem;
     border-radius: 20px;
     border: 5px solid #ffffff;
     transition: all 0.5s ease-in-out;
     content: '';
     opacity: 0;
}

bookmakershome-component .rivalry:hover::after{
     opacity: 1;
}
bookmakershome-component .ggbet{
     background: linear-gradient(#161B22, #161B22) padding-box, linear-gradient(to right, #FF6633,#ffffff00,#ffffff00 ,#ffffff00) border-box;
     border-radius: 20px;
     border: 5px solid transparent;
}

bookmakershome-component .ggbet::after{
    position: absolute;
    right:-0.3rem;
    top: -0.3rem;
    bottom: -0.3rem;
    left: -0.3rem;
     border-radius: 20px;
     border: 5px solid #FF6633;
     transition: all 0.5s ease-in-out;
     content: '';
     opacity: 0;
}

bookmakershome-component .ggbet:hover::after{
     opacity: 1;
}

bookmakershome-component .alternatingBackground{
     transition: all 0.5s ease-in-out;

}
bookmakershome-component .xbet{
     background: linear-gradient(#161B22, #161B22) padding-box, linear-gradient(to right, #195685,#ffffff00,#ffffff00 ,#ffffff00) border-box;
     border-radius: 20px;
     border: 5px solid transparent;
}

bookmakershome-component .xbet::after{
    position: absolute;
    right:-0.3rem;
    top: -0.3rem;
    bottom: -0.3rem;
    left: -0.3rem;
     border-radius: 20px;
     border: 5px solid #195685;
     transition: all 0.5s ease-in-out;
     content: '';
     opacity: 0;
}

bookmakershome-component .xbet:hover::after{
     opacity: 1;
}
bookmakershome-component .betsson{
     background: linear-gradient(#161B22, #161B22) padding-box, linear-gradient(to right, #FF6600,#ffffff00,#ffffff00 ,#ffffff00) border-box;
     border-radius: 20px;
     border: 5px solid transparent;
}

bookmakershome-component .betsson::after{
    position: absolute;
    right:-0.3rem;
    top: -0.3rem;
    bottom: -0.3rem;
    left: -0.3rem;
     border-radius: 20px;
     border: 5px solid #FF6600;
     transition: all 0.5s ease-in-out;
     content: '';
     opacity: 0;
}

bookmakershome-component .betsson:hover::after{
     opacity: 1;
}
bookmakershome-component .novibet{
     background: linear-gradient(#161B22, #161B22) padding-box, linear-gradient(to right, #28a8ac,#ffffff00,#ffffff00 ,#ffffff00) border-box;
     border-radius: 20px;
     border: 5px solid transparent;
}
bookmakershome-component .novibet::after{
    position: absolute;
    right:-0.3rem;
    top: -0.3rem;
    bottom: -0.3rem;
    left: -0.3rem;
     border-radius: 20px;
     border: 5px solid #28a8ac;
     transition: all 0.5s ease-in-out;
     content: '';
     opacity: 0;
}

bookmakershome-component .novibet:hover::after{
     opacity: 1;
}
bookmakershome-component .winmasters{
     background: linear-gradient(#161B22, #161B22) padding-box, linear-gradient(to right, #FFFFFF,#ffffff00,#ffffff00 ,#ffffff00) border-box;
     border-radius: 20px;
     border: 5px solid transparent;
}
bookmakershome-component .winmasters::after{
    position: absolute;
    right:-0.3rem;
    top: -0.3rem;
    bottom: -0.3rem;
    left: -0.3rem;
     border-radius: 20px;
     border: 5px solid #FFFFFF;
     transition: all 0.5s ease-in-out;
     content: '';
     opacity: 0;
}

bookmakershome-component .winmasters:hover::after{
     opacity: 1;
}
bookmakershome-component .netbet{
     background: linear-gradient(#161B22, #161B22) padding-box, linear-gradient(to right, #ffffff,#ffffff00,#ffffff00 ,#ffffff00) border-box;
     border-radius: 20px;
     border: 5px solid transparent;
}
bookmakershome-component .netbet::after{
    position: absolute;
    right:-0.3rem;
    top: -0.3rem;
    bottom: -0.3rem;
    left: -0.3rem;
     border-radius: 20px;
     border: 5px solid #ffffff;
     transition: all 0.5s ease-in-out;
     content: '';
     opacity: 0;
}

bookmakershome-component .netbet:hover::after{
     opacity: 1;
}
bookmakershome-component .fonbet{
     background: linear-gradient(#161B22, #161B22) padding-box, linear-gradient(to right, #D42A28,#ffffff00,#ffffff00 ,#ffffff00) border-box;
     border-radius: 20px;
     border: 5px solid transparent;
}
bookmakershome-component .fonbet::after{
    position: absolute;
    right:-0.3rem;
    top: -0.3rem;
    bottom: -0.3rem;
    left: -0.3rem;
     border-radius: 20px;
     border: 5px solid #D42A28;
     transition: all 0.5s ease-in-out;
     content: '';
     opacity: 0;
}

bookmakershome-component .fonbet:hover::after{
     opacity: 1;
}
 bookmakershome-component .card-custom .card-header {
     background-color: transparent;
     border: none;
     padding-bottom: 0;
}
 bookmakershome-component .card-custom .card-body {
     padding-top: 0.5rem;
}
 bookmakershome-component .card-image-overlay {
     position: absolute;
     top: 0;
     right: 0;
     bottom: 0;
     left: 0;
}
 bookmakershome-component .card-custom .btn {
     background-color: transparent;
     color: #e6e6e6;
     cursor:default;
}
bookmakershome-component #eggrafi {
     background-color: #bb2d3b;
     cursor:pointer;
}
 bookmakershome-component .btn-icon {
     display: inline-flex;
     align-items: center;
}
 bookmakershome-component .btn-icon i {
     margin-right: 0.5rem;
}

bookmakershome-component .rating {
     background-color: #f5c518;
     color: black;
     padding: 0.2rem;
     border-radius: 0.25rem;
     font-size: 0.9rem;
     font-weight: bold;
}
bookmakershome-component .rating .bi {
}
 @media screen and (orientation:portrait) {
 bookmakershome-component .fonbet{
     background: linear-gradient(#161B22, #161B22) padding-box, linear-gradient(to right, #ffffff00,#D42A28,#D42A28 ,#ffffff00) border-box;
    
}
 bookmakershome-component .netbet{
     background: linear-gradient(#161B22, #161B22) padding-box, linear-gradient(to right, #ffffff00,#ffffff,#ffffff ,#ffffff00) border-box;
     
}
bookmakershome-component .winmasters{
     background: linear-gradient(#161B22, #161B22) padding-box, linear-gradient(to right, #FFFFFF00,#ffffff,#ffffff ,#ffffff00) border-box;

}
bookmakershome-component .novibet{
     background: linear-gradient(#161B22, #161B22) padding-box, linear-gradient(to right, #ffffff00,#28a8ac,#28a8ac ,#ffffff00) border-box;

}
bookmakershome-component .betsson{
     background: linear-gradient(#161B22, #161B22) padding-box, linear-gradient(to right, #ffffff00,#FF6600,#FF6600 ,#ffffff00) border-box;
     
}
bookmakershome-component .bet365{
     background: linear-gradient(#161B22, #161B22) padding-box, linear-gradient(to right, #ffffff00,#007B5B,#007B5B ,#ffffff00) border-box;
    
}
bookmakershome-component .stoiximan{
     background: linear-gradient(#161B22, #161B22) padding-box, linear-gradient(to right, #ffffff00,#1365d4,#1365d4 ,#ffffff00) border-box;
     
}
bookmakershome-component .ggbet{
     background: linear-gradient(#161B22, #161B22) padding-box, linear-gradient(to right, #ffffff00,#FF6633,#FF6633 ,#ffffff00) border-box;
     
}
bookmakershome-component .rivalry{
     background: linear-gradient(#161B22, #161B22) padding-box, linear-gradient(to right, #ffffff00,#ffffff,#ffffff ,#ffffff00) border-box;
     
}
bookmakershome-component .xbet{
     background: linear-gradient(#161B22, #161B22) padding-box, linear-gradient(to right, #ffffff00,#195685,#195685 ,#ffffff00) border-box;
     
}

}
 @media screen and (orientation:landscape) {
 
 bookmakershome-component .resize-buttons-text{
font-size: 0.9rem;
}
 
}
 @media only screen and (max-width: 600px) {
  bookmakershome-component .resize-buttons-text {
    font-size: 0.4rem;
    font-weight: 400;
  }


  bookmakershome-component .resize-esports-text {
    font-size: 0.4rem;
    font-weight: 400;
  }

  bookmakershome-component .rating {
    font-size: 0.6rem;
    font-weight: 600;
  }

  bookmakershome-component resize-name {
    font-size: 0.5rem;
    font-weight: 400;
  }
  bookmakershome-component .img-circle-container {
       width: 8rem;
       height: auto;
    }
    
    bookmakershome-component  .resize-bio-text{
    font-size: 0.5rem;
    font-weight: 400;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    }
   
  /* other styles.. */
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

  bookmakershome-component .resize-buttons-text {
    font-size: 0.45rem;
     font-weight: 400;
  }



  bookmakershome-component .resize-esports-text {
    font-size: 0.4rem;
     font-weight: 400;
  }

  bookmakershome-component .rating {
    font-size: 0.5rem;
     font-weight: 800;
  }

  bookmakershome-component resize-name {
    font-size: 0.5rem;
     font-weight: 400;
  }
   bookmakershome-component  .resize-bio-text{
   font-size: 0.6rem;
     font-weight: 400;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    }
     bookmakershome-component .img-circle-container {
       width: 10rem;
       height: auto;
    }
  /* other styles.. */

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

   bookmakershome-component .resize-buttons-text {
    font-size: 0.4rem;
     font-weight: 400;
  }

  

  bookmakershome-component .resize-esports-text {
    font-size: 0.4rem;
    font-weight: 300;
  }

  bookmakershome-component .rating {
    font-size: 0.5rem;
     font-weight: 600;
  }

  bookmakershome-component resize-name {
    font-size: 0.5rem;
    font-weight: 600;
  }
 bookmakershome-component  .resize-bio-text{
 font-size: 0.6rem;
    font-weight: 400;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    }
     bookmakershome-component .img-circle-container {
       width: 11rem;
       height: auto;
    }
  /* other styles.. */
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

   bookmakershome-component .resize-buttons-text {
    font-size: 0.5rem;
    font-weight: 400;
  }


  bookmakershome-component .resize-esports-text {
    font-size: 0.35rem;
     font-weight: 300;
  }

  bookmakershome-component .rating {
    font-size: 0.6rem;
     font-weight: 600;
  }

  bookmakershome-component resize-name {
    font-size: 0.7rem;
     font-weight: 600;
  }
   bookmakershome-component  .resize-bio-text{
   font-size: 0.7rem;
     font-weight: 400;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    }
     bookmakershome-component .img-circle-container {
       width: 12rem;
       height: auto;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

  bookmakershome-component .resize-buttons-text {
    font-size: 0.6rem;
    font-weight: 600;
  }

  bookmakershome-component .resize-esports-text {
    font-size: 0.5rem;
    font-weight: 300;
  }

  bookmakershome-component .rating {
    font-size: 0.65rem;
    font-weight: 800;
  }

  bookmakershome-component resize-name {
    font-size: 0.8rem;
    font-weight:800;
  }

   bookmakershome-component .img-circle-container {
       width: 15rem;
       height: auto;
    }
   bookmakershome-component  .resize-bio-text{
         font-size: 0.7rem;
         font-weight: 400;
         -webkit-line-clamp: 2;
         text-overflow: ellipsis;
         -webkit-box-orient: vertical;
         display: -webkit-box;
         overflow: hidden;
    }
}
    </style>
`

export class BookmakershomeComponent extends BaseComponent {

     initCallback() {
          RouterAssistant.checkForSingleContainer('bookmakershome-component', BookmakershomeComponent.initiator)
          document.head.insertAdjacentHTML("beforeend", css)
     }


     static initiator() {

          BookmakershomeComponent.getBookmakers('fromBookmakersComponent', () => {
               if (document.querySelectorAll('#all-bookmakers').length !== 0) {
                    console.debug('I am running the Bookmakers component call back!')
                    BookmakershomeComponent.updateBookmakers()
               } else {
                    console.debug('The bookmakers component call back is supposed to run but I am not on the correct page!!!')
               }
          });

     }


     static getBookmakers(callbackName, callback) {
          if (window.getBookmakersEventListeners === undefined) {
               window.getBookmakersEventListeners = {}
               window.getBookmakersEventListeners[callbackName] = callback
               FirebaseAssistant.getAllBookmakers((dataReceived) => {
                    console.debug('running firebase getAllBookmakers() query snapshot')
                    console.debug('imrunning', new Date());
                    dataReceived.forEach(single => {
                         const data = single.data();
                         if (window.savedBookMakers === undefined) {
                              window.savedBookMakers = { [single.id]: data }
                         } else {
                              window.savedBookMakers[single.id] = data
                         }
                    });
                    Object.keys(window.getBookmakersEventListeners).forEach(eventListenerName => {
                         window.getBookmakersEventListeners[eventListenerName]()
                    })
               });
          } else if (window.savedBookMakers === undefined) {
               setTimeout(() => {
                    BookmakershomeComponent.getBookmakers(callbackName, callback)
               }, 50)
          } else {
               window.getBookmakersEventListeners[callbackName] = callback
               callback()
          }
     }

     static updateBookmakers() {
          console.debug('running update Bookmakers')
          GeofencingGuard.getUserCountry(function (userCurrentCountry) {
               const userCurrentLanguage = I18n.getUsersPreferredLanguage()

               document.querySelector('#all-bookmakers').innerHTML = ''

               for (let i = 0; i < Object.keys(window.savedBookMakers).length; i++) {
                    const bookmakerData = window.savedBookMakers[Object.keys(window.savedBookMakers)[i]];

                    if (bookmakerData.status == 'draft') {
                         delete window.savedBookMakers[Object.keys(window.savedBookMakers)[i]];
                    }
                    else {
                         console.log(`${bookmakerData.location_bookmaker} ${userCurrentLanguage}`)
                         if (!bookmakerData.location_bookmaker.includes(userCurrentCountry)) continue
                         if (bookmakerData.language_bookmaker != userCurrentLanguage) continue

                         const bookmakerName = bookmakerData.name;
                         const bookmakerAbout = bookmakerData.bookmaker_about;
                         const bookmakerLogo = bookmakerData.bookmaker_logo;
                         const bookmakerRating = bookmakerData.rating;
                         const bookmakersClass = bookmakerData.bookmaker_class;
                         const bookmakerColor = bookmakerData.bookmaker_color;
                         const bookmakerRipos = bookmakerData.bookmaker_ripos;
                         const bookmakerRatingApodoseis = bookmakerData.bookmaker_rating.apodoseis;
                         const bookmakerRatingPlithwra = bookmakerData.bookmaker_rating.plithwra_esports;
                         const bookmakerRatingLiveSupport = bookmakerData.bookmaker_rating.live_support;
                         const bookmakerRatingPlhrwmes = bookmakerData.bookmaker_rating.plhrwmes;
                         const bookmakerRatingLiveSteaming = bookmakerData.bookmaker_rating.live_streaming;
                         const ratingsSum = bookmakerRatingApodoseis + bookmakerRatingPlithwra + bookmakerRatingLiveSupport + bookmakerRatingPlhrwmes + bookmakerRatingLiveSteaming;
                         const overallRating = ratingsSum / 5 / 10;
                         const bookmakersData = [{
                              bookmakerClass: bookmakersClass,
                              color: bookmakerColor
                         }];
                         const bookmakerElementId = `bookmakers-${bookmakerName}`;


                         let html = /*html*/ `
                                        <div id="${bookmakerElementId}"class="row mb-4 p-0">
                              <div class="col-12">
                                   <div class="card card-custom ${bookmakersClass}">
                                   <div class="row g-0">
                                        <div class="col-12 col-md-4 d-flex flex-column align-items-center justify-content-around rounded-start shadow-right alternatingBackground">
                                        <div class="img-circle-container">
                                        <img src="${bookmakerLogo}" class="img-fluid mx-auto d-block " alt="${bookmakerName} logo">
                                        </div>
                                        </div>
                                        <div class="col-md-8 rounded-start">
                                        <div class="card-body text-center text-sm-center text-md-start">
                                        <div class="d-md-flex justify-content-start align-items-center align-content-center ">
                                        <h5 class="card-title resize-name text-white d-none d-md-flex m-0">${bookmakerName}</h5>
                                        <div class="rating d-inline-flex d-md-flex justify-content-center align-items-center mx-0 mx-md-2">
                                             <span>${overallRating}</span>
                                             <i class="bi bi-star-fill"></i>
                                        </div>
                                        <p class="card-text">
                                             <small class="text-muted resize-esports-text ">${bookmakerRipos}</small>
                                        </p>
                                        </div>
                                        
                                        <p class="card-text resize-bio-text bookmaker-about my-2">${bookmakerAbout}</p>
                                        <div class="row d-flex justify-content-center justify-content-md-start text-center" >
                                        <div class="col-auto p-0">
                                        <span class="btn btn-icon resize-buttons-text">
                                             <i class="bi bi-broadcast "></i>Live Betting </span>
                                             </div>
                                             <div class="col-auto p-0">
                                        <span class="btn btn-icon resize-buttons-text">
                                             <i class="bi bi-cast "></i>Live Streaming. </span>
                                             </div>
                                             <div class="col-auto p-0">
                                        <span class="btn btn-icon resize-buttons-text">
                                             <i class="bi bi-phone "></i>Mobile </span>
                                        </div>
                                        <div class="col-auto p-0">
                                        <span id="eggrafi" class="btn btn-icon btn-outline-danger resize-buttons-text">
                                             <i class="bi bi-controller "></i>Read More </span>
                                             </div>
                                        </div>
                                        </div>
                                        <div class="col-12 rounded-bottom p-1 justify-content-center align-content-center text-center align-items-center eeep-text">ΕΕΕΠ | 21+ | ΠΑΙΞΕ ΥΠΕΥΘΥΝΑ </div>
                                   </div>
                                   </div>
                              </div>
                              </div>
                              </div>
 `;
                         document.querySelector('#all-bookmakers').insertAdjacentHTML('beforeend', html);
                         BookmakershomeComponent.addHoverEffect(bookmakersData);
                         BookmakershomeComponent.redirectToBookmakerPage(bookmakerElementId, Object.keys(window.savedBookMakers)[i]);
                    }
               }
          })




     }

     static addHoverEffect(bookmakersData) {
          // Define the bookmakers and their corresponding colors
          bookmakersData.forEach(({ bookmakerClass, color }) => {
               const card = document.querySelector(`.${bookmakerClass}`);
               if (card) {
                    const logo = card.querySelector('.img-circle-container'); // Assuming .card-img is the image you want to zoom
                    const imgContainer = card.querySelector('.alternatingBackground'); // The container that should change color

                    card.addEventListener('mouseenter', () => {
                         if (logo) {
                              logo.classList.remove('unzoom');
                              logo.classList.add('zoom');
                         }
                         if (imgContainer) {
                              imgContainer.style.backgroundColor = color;
                         }
                    });

                    card.addEventListener('mouseleave', () => {
                         if (logo) {
                              logo.classList.remove('zoom');
                              logo.classList.add('unzoom');
                         }
                         if (imgContainer) {
                              imgContainer.style.backgroundColor = 'transparent';
                         }
                    });
               }
          });
     }

     static redirectToBookmakerPage(BoMaID, collectionId) {

          const element = document.getElementById(BoMaID);

          element.addEventListener('click', function (evt) {
               RouterAssistant.redirect('s_bookmaker', {
                    bookmaker: collectionId
               })
          })

     }

}




BookmakershomeComponent.template = /*html*/`

<!--
<div class="container-fluid p-0 mb-5">
 <div class="title">
        <h1>BOOKMAKERS</h1>
        </div>
        <div id="all-bookmakers"></div>
  
  </div>
-->
 

`;
BookmakershomeComponent.reg('bookmakershome-component'); // components should always be 2 words seperated with a dash
