import { BaseComponent } from "@symbiotejs/symbiote";

const discLogo = require('./Discord-logo.png')

const css = `
    <style>
   
  @keyframes discord {
  0% {
    height: 70px;
    width: 100%;
  }
  100% {
    height: 500px;
    width: 100%;
  }
}
@keyframes discord-c {
  0% {
    height: 500px;
    width: 100%;
  }
  100% {
    height: 70px;
    width: 100%;
  }
}
.discord {
  animation-name: discord-c;
  animation-duration: 2s;
  animation-play-state: pause;
  float: left;
  height: 70px;
  width:100%;
}

.discord:hover {
  height: 500px;
  width: 100%;
  float: left;
  animation-name: discord;
  animation-duration: 2s;
  animation-play-state: pause;
}

.card-counter {
  position: relative; /* Makes .card-counter the reference for absolute positioning */
  background-color: #fff;
}

.card-counter {
  background-color: #5865F2;
  color: #FFF;
}

.card-counter .count-numbers {
  
  display: block;
}

.card-counter .count-name {
  font-style: italic;
  text-transform: capitalize;
  opacity: 0.5;
  display: block;
  font-size: 18px;
}
    </style>
`

export class DiscordTemplate extends BaseComponent {

    initCallback() {
        document.head.insertAdjacentHTML("beforeend", css)
    }
}

DiscordTemplate.template = `


<a href="https://discord.com/invite/myesports" target="iframe">
    <div class="container-fluid p-0 mt-1 mt-xl-5 mb-0">
     
<iframe class= "discord rounded clickable" src="https://discord.com/widget?id=1225101748696252497&theme=dark" width="100%" height="70px" allowtransparency="true" frameborder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>
<!--    <div class="row card-counter info rounded text-center mx-1 clickable ">-->
<!--      <a href="https://discord.com/invite/myesports" target="_blank">-->
<!--      <div class="col-12">-->
<!--    <img src="${discLogo}" alt="" class="img-fluid p-3 p-sm-0 p-md-0 p-lg-0 p-xl-3 p-xxl-3 w-50">-->
<!--        </div>-->
<!--        <div class="col-12">-->
<!--        <span class="count-numbers d-inline fs-4">35</span>-->
<!--        <span class="count-name d-inline">Members</span>-->
<!--        <i class="bi bi-people-fill me-2  "></i>-->
<!--        </div>-->
<!--     </a>-->
    </div>
    </a>
  </div>


`

DiscordTemplate.reg('discord-template'); // components should always be 2 words seperated with a dash
