import {BaseComponent} from "@symbiotejs/symbiote";
import {I18n} from "../../utilities/i18n/i18n";

const image = require('./assets/noInternet.png')


const css = `
    <style>
        network-guard {
            z-index:2000000;
        background-color: var(--bs-body-bg);
            position: fixed;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            display: none;
        }
        
        network-guard img {
             width: 20rem;
             position: relative;
             left: 50%;
             transform: translatex(-50%);
             height: auto;
        }
    </style>   
`

export class NetworkGuard extends BaseComponent {

    initCallback() {
        document.head.insertAdjacentHTML("beforeend", css)
        NetworkGuard.translateShit()

        setInterval(async function () {
            if (!window.navigator.onLine) return window.dispatchEvent(new Event('offline'));

            const url = new URL(window.location.origin) // avoid CORS errors with a request to your own origin

            url.searchParams.set('rand', NetworkGuard.getRandomString()) // random value to prevent cached responses

            const controller = new AbortController()
            const timeoutId = setTimeout(() => controller.abort(), 2500)
            fetch(url.toString(), {signal: controller.signal, method: 'HEAD'})
                .then(response => {
                    window.dispatchEvent(new Event('online'));
                })
                .catch(error => {
                    console.debug(error)
                    window.dispatchEvent(new Event('offline'));
                })
        }, 3000); // every 3 seconds

        window.addEventListener('online', () => {
            // console.debug('online')
            document.getElementsByTagName('network-guard')[0].style.display = 'none'
        })
        window.addEventListener('offline', () => {
            // console.debug('offline')
            document.getElementsByTagName('network-guard')[0].style.display = 'unset'
        })
    }

    static translateShit() {
        document.getElementById('networkGuardTitle').innerText = I18n.translateString('networkGuard.title')
        document.getElementById('networkGuardDescription').innerText = I18n.translateString('networkGuard.description')
    }

    static initiate() {
        document.body.insertAdjacentHTML('beforeend', '<network-guard></network-guard>')
    }

    static getRandomString() {
        return Math.random().toString(36).substring(2, 15)
    }


}

NetworkGuard.template = /*html*/ `
<div style="
    width: fit-content;
    position: relative;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -55%);
"><img src="${image}">
<h1 class="text-center mt-5" id="networkGuardTitle">Oh Dear!</h1>
<h4 class="text-center" id="networkGuardDescription">Slow or no internet connection.</h4></div>
`;

NetworkGuard.reg('network-guard');
