import { BaseComponent } from "@symbiotejs/symbiote";
import { FirebaseAssistant } from "../../utilities/firebase-assistant/firebase-assistant";
import "../../utilities/bootstrap-assistant/assets/scroll-bar.css";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";
import { GameNavbar } from "../game-navbar/game-navbar";
import { match } from "assert";
import { TournamentsComponent } from "../tournaments-component/tournaments-component";

import { I18n } from "../../utilities/i18n/i18n";

import tournamentimg2 from "../hottournaments-component/assets/img2.webp"

const noImageFound = require('../matches-component/Assets/no image found.png')

const csgologo = require("../game-navbar/assets/images/csgologo 150x150.png")
const dota2 = require('../game-navbar/assets/images/dota2logo 150x150.png')
const lol = require('../game-navbar/assets/images/lollogo 150x150.png')
const valorant = require('../game-navbar/assets/images/valorantlogo 150x150.png')
const fifa = require('../game-navbar/assets/images/fifalogo 150x150.png')
const fortnite = require('../game-navbar/assets/images/fortnite 150x150.png')
const hearthstone = require('../game-navbar/assets/images/hearthstone 150x150.png')
const minecraft = require('../game-navbar/assets/images/minecraft 150x150.png')
const overwatch = require('../game-navbar/assets/images/overwatch 150x150.png')
const pubg = require('../game-navbar/assets/images/pubg 150x150.png')
const r6 = require('../game-navbar/assets/images/rainbow six 150x150.png')
const roblox = require('../game-navbar/assets/images/roblox 150x150.png')
const rocketleague = require('../game-navbar/assets/images/rocketleague 150x150.png')
const wow = require('../game-navbar/assets/images/world of warcraft 150x150.png')
const startcraft = require('../game-navbar/assets/images/starcraft II 150x150.png')
const cod = require('../game-navbar/assets/images/call of duty 150x150.png')
const ea = require('../game-navbar/assets/images/ea sports 150x150.png')
const apex = require('../game-navbar/assets/images/ApexLegends 150x150.webp')
const kingofglory = require('../game-navbar/assets/images/KingOfGlory 150x150.webp')
const mlegends = require('../game-navbar/assets/images/mobileLegends 150x150.webp')


const css = `
<style>




hottournaments-component .parentHottournament:hover {
	cursor: pointer;
}

     hottournaments-component .ratio {
        position: relative; /* Ensure that this is relative if not already set */
        overflow: hidden; /* This will contain the zoom within the .ratio box */
    }

hottournaments-component .zoom {
	transition: transform 0.3s ease-in-out;
	/* object-fit: cover; only if we want to crop the image to fit the container and not lose resolution */
}

hottournaments-component .zoom {
	transform: scale(1.05);
	transition: all 0.5s ease-in-out;
}

hottournaments-component .unzoom {
	transform: scale(1);
	transition: all 0.5s ease-in-out;

}
 



    
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
hottournaments-component .text-resize-date{
font-size:0.4rem;
font-weight: 400;
}
hottournaments-component .text-resize-tournament-name{
font-size: 0.4rem;
font-weight: 600;
color:#fff;
max-width: 45%;
}
hottournaments-component .img-circle-container-logo-game {
width: 10px;
height: 10px;
}

hottournaments-component .img-circle-container-logo-game img {
max-width: 10px;
max-height: 10px;
}
hottournaments-component .card-body {
height: 55px !important;
}


 hottournaments-component  .text-mobile{
         font-size: 0.35rem;
         font-weight: 400;
    }


  hottournaments-component .img-circle-container {

   width: 2.5rem;  /* Adjust the width as needed */
   height: auto; /* Ensure the height is the same as the width for a perfect circle */
  display:block;
  position:absolute;
  left:0;
  right:0;
  bottom:0;
  margin:auto;
}
hottournaments-component .text-resize-title {
		font-size: 0.8rem;
		font-weight: 500;
		color: #b0b0b0;
		margin-left: 0.2rem;
	}
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
hottournaments-component .text-resize-date{
font-size:0.5rem;
font-weight: 400;
}
hottournaments-component .text-resize-tournament-name{
font-size: 0.6rem;
font-weight: 600;
color:#fff;
max-width: 45%;
}
hottournaments-component .img-circle-container-logo-game {
width: 20px;
height: 20px;
}

hottournaments-component .img-circle-container-logo-game img {
max-width: 20px;
max-height: 20px;
}
hottournaments-component .card-body {
height: 30px !important;
}
 hottournaments-component  .text-mobile{
         font-size: 0.4rem;
         font-weight: 400;
    }

 hottournaments-component .img-circle-container {

   width: 3rem;  /* Adjust the width as needed */
   height: auto; /* Ensure the height is the same as the width for a perfect circle */
  display:block;
  position:absolute;
  left:0;
  right:0;
  bottom:0;
  margin:auto;
}

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
hottournaments-component .text-resize-date{
font-size:0.5rem;
font-weight: 400;
}
hottournaments-component .text-resize-tournament-name{
font-size: 0.6rem;
font-weight: 600;
color:#fff;
max-width: 45%;
}
hottournaments-component .img-circle-container-logo-game {
width: 20px;
height: 20px;
}

hottournaments-component .img-circle-container-logo-game img {
max-width: 20px;
max-height: 20px;
}
hottournaments-component .card-body {
height: 65px !important;
}


 hottournaments-component  .text-mobile{
         font-size: 0.7rem;
         font-weight: 400;
    }

 hottournaments-component .img-circle-container {

   width: 5rem;  /* Adjust the width as needed */
   height: auto; /* Ensure the height is the same as the width for a perfect circle */
  display:block;
  position:absolute;
  left:0;
  right:0;
  bottom:0;
  margin:auto;
}
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
hottournaments-component .text-resize-date{
font-size:0.6rem;
font-weight: 400;
}
hottournaments-component .text-resize-tournament-name{
font-size: 0.8rem;
font-weight: 600;
color:#fff;
max-width: 45%;
}
hottournaments-component .img-circle-container-logo-game {
width: 20px;
height: 20px;
}

hottournaments-component .img-circle-container-logo-game img {
max-width: 20px;
max-height: 20px;
}
hottournaments-component .card-body {
height: 85px !important;
}

 hottournaments-component  .text-mobile{
         font-size: 0.8rem;
         font-weight: 400;
    }

 hottournaments-component .img-circle-container {

   width: 6rem;  /* Adjust the width as needed */
   height: auto; /* Ensure the height is the same as the width for a perfect circle */
  display:block;
  position:absolute;
  left:0;
  right:0;
  bottom:0;
  margin:auto;
}
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
hottournaments-component .text-resize-date{
font-size:0.6rem;
font-weight: 400;
}
hottournaments-component .text-resize-tournament-name{
font-size: 0.9rem;
font-weight: 600;
color:#fff;
max-width: 55%;
}
hottournaments-component .img-circle-container-logo-game {
width: 25px;
height: 25px;
}

hottournaments-component .img-circle-container-logo-game img {
max-width: 25px;
max-height: 25px;
}
hottournaments-component .card-body {
height:85px !important;
}

 hottournaments-component  .text-mobile{
         font-size: 0.7rem;
         font-weight: 400;
    }


 hottournaments-component .img-circle-container {

   width: 7rem;  /* Adjust the width as needed */
   height: auto; /* Ensure the height is the same as the width for a perfect circle */
  display:block;
  position:absolute;
  left:0;
  right:0;
  bottom:0;
  margin:auto;
}

}        
        
        
        
        
        
</style>
`

export class HotTournamentsComponent extends BaseComponent {

    initCallback() {
        RouterAssistant.checkForSingleContainer('hottournaments-component', HotTournamentsComponent.initiator);
        document.head.insertAdjacentHTML("beforeend", css);
        document.querySelectorAll('.hotTournamentsTitle').forEach((el) => {
            el.innerHTML = I18n.translateString('tournamentsTranslations.hotTournamentsTitle');
        });

        const container = document.getElementById('hottournaments-html');

        if (container) {
            const scrollSpeed = 1.5;
            const touchpadThreshold = 60;

            container.addEventListener('wheel', (event) => {
                event.preventDefault();

                if (Math.abs(event.deltaY) < touchpadThreshold) {
                    // console.debug('Scrolling with touchpad');
                    container.style.scrollBehavior = 'auto';
                } else {
                    // console.debug('Scrolling with mouse wheel');
                    container.style.scrollBehavior = 'smooth';
                }

                container.scrollLeft += event.deltaY * scrollSpeed;
            });
        }
    }

    static initiator() {

        TournamentsComponent.getTournaments('fromHotTournamentsComponent', () => {
            if (document.querySelectorAll('#hottournaments-html').length !== 0) {
                console.debug(document.querySelectorAll('#hottournaments-html').length)
                console.debug('I am running the hotTournaments component call back!')
                HotTournamentsComponent.updateTournaments(GameNavbar.getCurrentlyActivatedGameIfAny())
                const tournamentsDiv = document.querySelector('#hottournaments-html');
                if (!window.location.href.includes('tournaments') && !window.location.href.includes('matches')) {
                    if (!tournamentsDiv.innerHTML.trim()) {
                        tournamentsDiv.innerHTML = /*html*/`
               <div class="my-5 msgSMTclass" style="text-align: center; padding: 20px; background-color: #161b22; border-color: #f5c6cb; border-radius: .25rem;" id="noTour-message">
                
                </div>
              `;
                        const showMoreTourButton = '<button id="show-more-tournaments-button" class="btn color-dark text-resize dark-animation buttonSMTclass" style="color#ffffff;font-weight: bold;">View All</button>';
                        const msgSMTelements = document.querySelectorAll('.msgSMTclass');
                        if (document.querySelector('.gameNameActive') && document.querySelector('.gameNameActive') !== null) {
                            msgSMTelements.forEach(element => {
                                element.innerHTML = I18n.translateString('tournamentsTranslations.noHotTournamentsMsg', { hTgame: document.querySelector('.gameNameActive').innerText });
                            });
                        } else {
                            msgSMTelements.forEach(element => {
                                element.innerHTML = I18n.translateString('tournamentsTranslations.noHotTournamentsMsg', { hTgame: '' });
                            });
                        }
                        document.querySelector('#noTour-message').insertAdjacentHTML('beforeend', showMoreTourButton);
                        const buttonSMTelements = document.querySelectorAll('.buttonSMTclass');
                        buttonSMTelements.forEach(element => { element.innerHTML = I18n.translateString("tournamentsTranslations.noHotTournamentsButton") });
                    }
                }
                HotTournamentsComponent.redirectToTournaments();
            } else {
                console.debug('The hottournaments component call back is supposed to run but I am not on the correct page!!!')
            }
        });

        GameNavbar.subscribeToGameFilterChanges('fromHotTournamentsComponent', (eventId) => {
            if (document.querySelectorAll('#hottournaments-html').length !== 0) { // if our component exists on the page
                document.querySelector('#hottournaments-html').innerHTML = '';
            }
            HotTournamentsComponent.updateTournaments(eventId, document.querySelector('hottournaments-component '));
            const tournamentsDiv = document.querySelector('#hottournaments-html');
            if (!window.location.href.includes('tournaments') && !window.location.href.includes('matches')) {
                if (!tournamentsDiv.innerHTML.trim()) {
                    tournamentsDiv.innerHTML = /*html*/`
               <div class="my-5 msgSMTclass" style="text-align: center; padding: 20px; background-color: #161b22; border-color: #f5c6cb; border-radius: .25rem;" id="noTour-message">
                
                </div>
              `;
                    const showMoreTourButton = '<button id="show-more-tournaments-button" class="btn color-dark text-resize dark-animation buttonSMTclass" style="color#ffffff;font-weight: bold;">View All</button>';
                    const msgSMTelements = document.querySelectorAll('.msgSMTclass');
                    if (document.querySelector('.gameNameActive') && document.querySelector('.gameNameActive') !== null) {
                        msgSMTelements.forEach(element => {
                            element.innerHTML = I18n.translateString('tournamentsTranslations.noHotTournamentsMsg', { hTgame: document.querySelector('.gameNameActive').innerText });
                        });
                    } else {
                        msgSMTelements.forEach(element => {
                            element.innerHTML = I18n.translateString('tournamentsTranslations.noHotTournamentsMsg', { hTgame: '' });
                        });
                    }
                    document.querySelector('#noTour-message').insertAdjacentHTML('beforeend', showMoreTourButton);
                    const buttonSMTelements = document.querySelectorAll('.buttonSMTclass');
                    buttonSMTelements.forEach(element => { element.innerHTML = I18n.translateString("tournamentsTranslations.noHotTournamentsButton") });
                }
            }
            HotTournamentsComponent.redirectToTournaments();
        })
    }

    static updateTournaments(gameId) {
        if (!window.location.href.includes('tournaments') && !window.location.href.includes('matches')) {
            console.debug('running update tournaments of hottournaments component')

            const tierOrder = { 's': 1, 'a': 2, 'b': 3, 'c': 4, 'd': 5 };
            let count = 0;
            let imgSources = [tournamentimg2];
            let userLanguage = window.navigator.language;

            // Calculate total prize pool for each tournament
            Object.keys(window.savedTournaments).forEach(id => {

                let totalPrizePool = 0; // in case of no prize pool
                let currency = '';
                let noPrizePoolMsg = '';
                let tournamentsWithoutPrizePool = 0;
                let tier = '';
                if (window.savedTournaments[id] && window.savedTournaments[id].tournaments && window.savedTournaments[id].tournaments[0] && window.savedTournaments[id].tournaments[0].tier) {
                    const data = window.savedTournaments[id];
                    for (let j = 0; j < data.tournaments.length; j++) {
                        const prizePool = data.tournaments[j].prizepool;
                        if (!tier) {
                            tier = data.tournaments[j].tier;
                        }
                        if (prizePool) {
                            const match = prizePool.match(/(\d+)(\D+)/);
                            if (match) {
                                totalPrizePool += Number(match[1]);
                                if (!currency) {
                                    currency = match[2].trim();
                                }
                            }
                        } else {
                            tournamentsWithoutPrizePool++;
                        }
                    }

                    if (tournamentsWithoutPrizePool === data.tournaments.length) {
                        noPrizePoolMsg = `No prize pool available`
                    }


                    // Store the total prize pool and currency in the tournament data
                    data.tier = data.tournaments[0].tier;
                    data.totalPrizePool = totalPrizePool;
                    data.currency = currency;
                    data.noPrizePoolMsg = noPrizePoolMsg;
                    data.prizepoolMessage = noPrizePoolMsg + (totalPrizePool !== 0 ? totalPrizePool : '') + ' ' + currency;
                } else {
                    delete window.savedTournaments[id];
                }
            });

            const allTournamentIds = Object.keys(window.savedTournaments);

            // Sort all tournaments by tier, validity of currency, prize pool, and date
            allTournamentIds.sort((a, b) => {
                const tournamentA = window.savedTournaments[a];
                const tournamentB = window.savedTournaments[b];

                const tierA = tierOrder[tournamentA.tournaments[0].tier] || 6;
                const tierB = tierOrder[tournamentB.tournaments[0].tier] || 6;

                if (tierA !== tierB) {
                    return tierA - tierB;
                }

                const isValidCurrencyA = tournamentA.currency === 'Euro' || tournamentA.currency === 'United States Dollar' ? 1 : 0;
                const isValidCurrencyB = tournamentB.currency === 'Euro' || tournamentB.currency === 'United States Dollar' ? 1 : 0;

                if (isValidCurrencyA !== isValidCurrencyB) {
                    return isValidCurrencyB - isValidCurrencyA; // valid currencies come first
                }

                if (tournamentA.totalPrizePool !== tournamentB.totalPrizePool) {
                    return tournamentB.totalPrizePool - tournamentA.totalPrizePool; // sort in descending order

                }

                const dateA = new Date(tournamentA.begin_at ? tournamentA.begin_at : tournamentA.scheduled_at);
                const dateB = new Date(tournamentB.begin_at ? tournamentB.begin_at : tournamentB.scheduled_at);

                return dateA.getTime() - dateB.getTime();
            });

            // Concatenate the two arrays to get the final order
            const sortedTournamentIds = allTournamentIds;
            for (let i = 0; i < sortedTournamentIds.length; i++) {
                const TournamentCode = sortedTournamentIds[i]
                const tourElementId = `fromhottournamentsId-${TournamentCode}`
                // console.debug('TournamentCode', tourElementId)
                let data = window.savedTournaments[TournamentCode]
                const startTime = data.begin_at ? data.begin_at : data.scheduled_at
                const endTime = data.end_at ? data.end_at : 'NoDateMsg'
                const now = new Date();
                const startTimeDate = new Date(startTime);
                const endTimeDate = endTime !== 'NoDateMsg' ? new Date(endTime) : null;
                const formatedStartTime = startTimeDate.toLocaleString(userLanguage, { year: 'numeric', month: 'short', day: 'numeric' });
                const formatedEndTime = endTimeDate ? endTimeDate.toLocaleString(userLanguage, { year: 'numeric', month: 'short', day: 'numeric' }) : 'NoDateMsg';
                if (startTimeDate < now && (!endTimeDate || endTimeDate > now)) {

                    const currentGameId = data.videogame.id;
                    // Create a mapping of gameIds to their corresponding currentGameId
                    const gameIdMapping = {
                        'csgo': 3,
                        'dota2': 4,
                        'lol': 1,
                        'easports': 25,
                        'valorant': 26,
                        'cod': 23,
                        'overwatch': 14,
                        'pubg': 20,
                        'r6': 24,
                        'rocketleague': 22,
                        'starcraft': [29, 30],
                        'hearthstone': 999,
                        'fortnite': 999,
                        'fifa': 999,
                        'roblox': 999,
                        'minecraft': 999,
                        'wow': 999,
                        'apex': 999,
                        'kingofglory': 27,
                        'mlegends': 34
                    };
                    const gameIdImg = {
                        '3': csgologo,
                        '4': dota2,
                        '1': lol,
                        '25': ea,
                        '26': valorant,
                        '23': cod,
                        '14': overwatch,
                        '20': pubg,
                        '24': r6,
                        '22': rocketleague,
                        '29': startcraft,
                        '30': startcraft,
                        '27': kingofglory,
                        '34': mlegends

                    };
                    // If a gameId is provided and it doesn't match the current game's id, skip this tournament
                    if (gameId && gameIdMapping[gameId] !== undefined) {
                        if (Array.isArray(gameIdMapping[gameId])) {
                            if (!gameIdMapping[gameId].includes(currentGameId)) {
                                continue;
                            }
                        } else {
                            if (gameIdMapping[gameId] !== currentGameId) {
                                continue;
                            }
                        }
                    }

                    console.debug(" LIVE TOURNAMENT!!!")
                    console.debug(data)
                    const tournamentName = data.league.name + ' ' + data.full_name;
                    const gameLogoHTML = gameIdImg[data.videogame.id]
                    const imgSrc = imgSources[count % imgSources.length];

                    data.totalPrizePool = new Intl.NumberFormat('de-DE').format(data.totalPrizePool);
                    data.prizepoolMessage = data.noPrizePoolMsg + (data.totalPrizePool !== "0" ? data.totalPrizePool : '') + ' ' + data.currency;
                    let seriesPrizepool = data.prizepoolMessage;
                    if (data.actualprizepool && data.totalPrizePool === '0') {
                        seriesPrizepool = data.actualprizepool;
                    }
                    const seriesTier = data.tier.toUpperCase();

                    const tournamentLogo = data.league.image_url ? data.league.image_url : noImageFound;
                    const tournamentLogoHTML = tournamentLogo ? `<div style="display: flex; justify-content: center; align-items: center; height: 100%;"><img src="${tournamentLogo}" onerror="this.onerror=null; this.src='';" alt="" class="img-fluid mx-auto d-block" style="width: 25%; height: auto;"></div>` : '';
                    let count2 = 0;
                    if (data.opponents && data.opponents.length !== 0) {
                        count2 = data.opponents.length;
                    } else {
                        const allOpponents = [];
                        for (let g = 0; g < data.tournaments.length; g++) {
                            const tournament = data.tournaments[g];
                            for (let k = 0; k < tournament.matches.length; k++) {
                                const opponentsInMatch = tournament.matches[k].opponents;
                                for (let l = 0; l < opponentsInMatch.length; l++) {
                                    const opponent = opponentsInMatch[l].opponent;
                                    if (!allOpponents.some((o) => o.id === opponent.id)) {
                                        allOpponents.push({
                                            id: opponent.id,
                                            name: opponent.name,
                                        });
                                    }
                                }
                            }
                        }
                        count2 = allOpponents.length;
                    }
                    const playersParticipating = count2;

                    const hotTourhtml = /*html*/ `
        <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8 ps-0 " >
        <div class="card text-white border-0 rounded parentHottournament" id="${tourElementId}">
                              <div class="color-dark rounded-top p-2 d-flex justify-content-start align-content-center align-items-center">
                               <div class="img-circle-container-logo-game d-flex justify-content-center overflow-hidden">
                                        <img class="img-game-logo" src="${gameLogoHTML}" alt="gamelogo">
                               </div>
                               <h5 class="text-resize-tournament-name text-truncate m-0 ms-1">${tournamentName}</h5>
                               
                               <p class="ms-auto mb-0 text-resize-date">${formatedStartTime} - ${formatedEndTime}</p>
                              
                     </div>
        <div class="ratio ratio-16x9">
           <img class="card-img-top unzoom rounded-0" src="${imgSrc}" alt="Card image cap">
           <div class="img-circle-container d-flex justify-content-center align-items-center ">
           <img src="${tournamentLogo}" onerror="this.onerror=null; this.src='';" alt="" class="img-fluid mx-auto d-block unzoom" style="filter: drop-shadow(0 0 1.3rem black);">
           </div>
          
        </div>
        <div class="card-body color-lightdark caption-mobile rounded-bottom p-0 p-lg-2 d-flex justify-content-center align-items-center align-content-center">

          <div class="slider-bottom-info text-center">

            <div class="slider-tags text-center">
             <span class="badge live-animation ms-0 my-1 my-sm-2 p-1 text-mobile text-center ">
             <i class="bi bi-play-btn me-1"></i>
             <span class="ongoing-label"></span>
             </span>
              <span class="badge bg-danger ms-1 my-1 my-sm-2 p-1 text-mobile text-center">
              <span>${playersParticipating}</span>
              <span class="teams-compete-label"></span>
              </span>
               <span class="badge bg-danger ms-1 my-1 my-sm-2 p-1 text-mobile prizepool-label text-center">${seriesPrizepool}</span>
                <span class="badge bg-danger ms-1 my-1 my-sm-2 p-1 text-mobile text-center">
                <span>${seriesTier}</span>
                <span class="tier-label">Tier</span>
                </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    `;
                    document.querySelector('#hottournaments-html').insertAdjacentHTML('beforeend', hotTourhtml);
                    HotTournamentsComponent.translations();
                    const insertedElement = document.querySelector(`[id="${tourElementId}"]`);

                    insertedElement.addEventListener('mouseenter', (evt) => {
                        const images = evt.target.querySelectorAll('img');
                        if (images.length > 1) {
                            // Immediately zoom the second image
                            images[1].classList.add('zoom');
                            images[1].classList.remove('unzoom');
                            images[0].classList.add('zoom');
                            images[0].classList.remove('unzoom');

                        }
                    }, false);

                    insertedElement.addEventListener('mouseleave', (evt) => {
                        const images = evt.target.querySelectorAll('img');
                        if (images.length > 1) {
                            // Remove zoom from the second image immediately
                            images[1].classList.remove('zoom');
                            images[1].classList.add('unzoom');
                            images[0].classList.remove('zoom');
                            images[0].classList.add('unzoom');

                        }
                    }, false);
                    count++; // Increment counter
                    if (count >= 8) {
                        break; // Exit loop after processing three tournaments
                    }
                } else if (!(startTimeDate < now && (!endTimeDate || endTimeDate > now))) {
                    // console.debug("UPCOMING TOURNAMENT OR PAST TOURNAMENT!!! Skipping....")
                }
                HotTournamentsComponent.redirectToTournamentPage(tourElementId);


            }

        }

    }


    static redirectToTournamentPage(tourElementId) {

        const element = document.getElementById(tourElementId);
        const alteredTourElementId = tourElementId.replace('fromhottournamentsId-', '');

        if (element) {
            element.addEventListener('click', function (evt) {
                evt.stopPropagation();
                RouterAssistant.redirect('s_tournament', {
                    tournament: alteredTourElementId
                })
            })
        }
    }

    static redirectToTournaments() {
        const showMoreButton = document.getElementById('show-more-tournaments-button');
        if (showMoreButton) {
            showMoreButton.addEventListener('click', function (evt) {
                RouterAssistant.redirect('tournaments', { game: GameNavbar.getCurrentlyActivatedGameIfAny() })
            })
        }
    }

    static translations() {
        const endElements = document.querySelectorAll('.endDate-label');
        endElements.forEach(element => {
            if (element.innerHTML === 'NoDateMsg') {
                element.innerHTML = I18n.translateString("tournamentsTranslations.noDateMsg");
            }
        });
        const tierElements = document.querySelectorAll('.tier-label');
        const prizepoolElements = document.querySelectorAll('.prizepool-label');
        prizepoolElements.forEach(element => {
            if (element.innerHTML.includes('No prize pool available')) {
                element.innerHTML = I18n.translateString("tournamentsTranslations.noPrizePoolAvailable")
            } else if (element.innerHTML.includes('Euros')) {
                const translatedString = I18n.translateString("tournamentsTranslations.euroPrizePool")
                element.innerHTML = element.innerHTML.replace('Euros', translatedString);
            } else if (element.innerHTML.includes('Euro')) {
                const translatedString = I18n.translateString("tournamentsTranslations.euroPrizePool")
                element.innerHTML = element.innerHTML.replace('Euro', translatedString);
            } else if (element.innerHTML.includes('United States Dollar')) {
                const translatedString = I18n.translateString("tournamentsTranslations.usdPrizePool")
                element.innerHTML = element.innerHTML.replace('United States Dollar', translatedString);
            }
        });
        tierElements.forEach(element => { element.innerHTML = I18n.translateString("tournamentsTranslations.tier") });
        const teamsCompeteElements = document.querySelectorAll('.teams-compete-label');
        teamsCompeteElements.forEach(element => { element.innerHTML = I18n.translateString("tournamentsTranslations.teamsCompete") });
        const ongoingElements = document.querySelectorAll('.ongoing-label');
        ongoingElements.forEach(element => { element.innerHTML = I18n.translateString("tournamentsTranslations.hotTournamentFixedLabel") });
    }





}


HotTournamentsComponent.template = /*html*/ `

<div class="container-fluid p-0 my-0 my-sm-0 my-md-5 my-lg-5 my-xl-5 my-xxl-5">
 <div class="title">
        <span class="text-resize-title hotTournamentsTitle"></span>
        </div>
  <div class="row  flex-nowrap scrollbar overflow-auto mt-0 mt-sm-0 mt-md-1 mt-lg-2 mt-xl-2 mt-xxl-2  mx-xs-1 mx-sm-1 mx-md-1 mx-lg-1 mx-xl-1 mx-xxl-1 mx-1" id="hottournaments-html"></div>
</div>

`;

HotTournamentsComponent.reg('hottournaments-component');
