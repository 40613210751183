import {BaseComponent} from "@symbiotejs/symbiote";
import {FirebaseAssistant} from "../../utilities/firebase-assistant/firebase-assistant";
import {MiscAssistant} from "../../utilities/misc-assistant/misc-assistant";




const css = `
    <style>
scrolltotop-component .scroll-to-top {
   width: 50px;
    height: 50px;
    background-color: #3498db;
    border-radius: 50%;
    position: fixed;
    bottom: 2rem;
    right: 5rem;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 1057;
    color: #ecf0f1;
    font-size: 2rem;
    
 filter: drop-shadow(0 0 0.5rem #101010);
    
}
scrolltotop-component .scroll-to-top i{
 filter: drop-shadow(0 0 0.75rem #101010);
}
scrolltotop-component .scroll-to-top.show {
    display: block;
    opacity: 1;
    transform: translateY(0);
}

scrolltotop-component .scroll-to-top.hide {
    opacity: 0;
    transform: translateY(100px);
}

     
 /* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
 scrolltotop-component .scroll-to-top {
        bottom: 4rem;
        right: 1rem;
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-size: 20px;
        
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
 scrolltotop-component .scroll-to-top {
        bottom: 4rem;
        right: 1.3rem;
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-size: 20px;
        
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
 scrolltotop-component .scroll-to-top {
        bottom: 4rem;
        right: 1.3rem;
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-size: 20px;
        
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
 scrolltotop-component .scroll-to-top {
        bottom: 3.6rem;
        right: 1.3rem;
        width: 50px;
        height: 50px;
        line-height: 40px;
        font-size: 20px;
        
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
 scrolltotop-component .scroll-to-top {
        bottom: 1rem;
        right: 5rem;
        width: 50px;
        height: 50px;
        line-height: 40px;
        font-size: 20px;
        
    }
}
    </style>
`

export class ScrollToTop extends BaseComponent {

    initCallback() {
        document.head.insertAdjacentHTML("beforeend", css)

        window.addEventListener('resize', ScrollToTop.performOrientationChangeActions);
        ScrollToTop.performOrientationChangeActions();
        let lastScrollTop = 0;



        document.getElementById('scrollToTop').addEventListener('click', () => {
            document.getElementById('gameFiltersContainer').scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
        });

        window.addEventListener('scroll', function(){
            // MiscAssistant.debounce(ScrollToTop.checkIfScrollToTopShouldBeEnabledOrDisabled, 250)
            ScrollToTop.checkIfScrollToTopShouldBeEnabledOrDisabled()
        }, true)
        ScrollToTop.checkIfScrollToTopShouldBeEnabledOrDisabled()
    }

    static checkIfScrollToTopShouldBeEnabledOrDisabled(){
        const element = document.querySelector('[data-barba="container"]');
        if (ScrollToTop.isElementTopVisible(element)) {
            document.getElementById('scrollToTop').style.display = 'none'
            document.getElementById('scrollToTop').classList.remove('d-flex')
        } else {
            document.getElementById('scrollToTop').style.display = ''
            document.getElementById('scrollToTop').classList.add('d-flex')
        }
    }

    static isElementTopVisible(element) {
        const rect = element.getBoundingClientRect();
        return rect.top >= 0 && rect.top < window.innerHeight;
    }

    static performOrientationChangeActions() {
        if (window.location.href.includes('myesports')) { // make sure the event listener only runs when the component exists in the page
            if (window.innerHeight > window.innerWidth) { // mobile

            } else { // desktop

            }
        }
    }

}

ScrollToTop.template = `
  
<div class="scroll-to-top d-flex align-content-center align-items-center justify-content-center show" id="scrollToTop"> 
 <i class="bi bi-chevron-up"></i>
</div>



`;

ScrollToTop.reg('scrolltotop-component'); // components should always be 2 words seperated with a dash
