import { BaseComponent } from "@symbiotejs/symbiote";
import { I18n } from "../../utilities/i18n/i18n";

const csgo = require("../../components/game-navbar/assets/images/csgologo 150x150.png");
const dota2 = require("../../components/game-navbar/assets/images/dota2logo 150x150.png");
const fifa = require("../../components/game-navbar/assets/images/fifalogo 150x150.png");
const lol = require("../../components/game-navbar/assets/images/lollogo 150x150.png");
const valorant = require("../../components/game-navbar/assets/images/valorantlogo 150x150.png");

const css = `
<style>
  .sidebar-matches {
           
            color: white;
           
        }
        .sidebar-matches li {
        margin-bottom: 1rem;
        border:none !important;
        }
        .match-item {
            padding: 0.5rem 1rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .team-logo {
            width: 24px; /* Adjust size as needed */
            height: 24px; /* Adjust size as needed */
        }
        .team-name {
            margin: 0 0.5rem;
            display: inline-block;
        }
        .live-badge {
            color: #dc3545;
            border: 1px solid #dc3545;
        }
        .sidebar-header {
            padding: 0.75rem 1rem;
        }
</style>
`

export class WidgetMatches extends BaseComponent {

    initCallback() {
        document.head.insertAdjacentHTML("beforeend", css)

    }

}

WidgetMatches.template = `
<div class="container p-0 my-5">
<div class="sidebar-matches rounded">
       <ul class="list-group list-group-flush">
        <!-- Match Item -->
        <li class="list-group-item match-item color-dark rounded">
            <div class="team">
                <img src="${dota2}" alt="Team Logo" class="team-logo">
                <span class="team-name">Team A</span>
            </div>
            <div class="match-status">
                <span class="badge bg-danger">LIVE</span>
            </div>
            <div class="team">
                <span class="team-name">Team B</span>
                <img src="${csgo}" alt="Team Logo" class="team-logo">
            </div>
        </li>
        <!-- Repeat for other matches -->
               <li class="list-group-item match-item color-dark rounded">
            <div class="team">
                <img src="${dota2}" alt="Team Logo" class="team-logo">
                <span class="team-name">Team A</span>
            </div>
            <div class="match-status">
                <span class="badge bg-secondary">14h 20m</span>
            </div>
            <div class="team">
                <span class="team-name">Team B</span>
                <img src="${csgo}" alt="Team Logo" class="team-logo">
            </div>
        </li>
        <!-- Repeat for other matches -->
               <li class="list-group-item match-item color-dark rounded">
            <div class="team">
                <img src="${dota2}" alt="Team Logo" class="team-logo">
                <span class="team-name">Team A</span>
            </div>
            <div class="match-status">
                <span class="badge bg-secondary">14h 30m</span>
            </div>
            <div class="team">
                <span class="team-name">Team B</span>
                <img src="${csgo}" alt="Team Logo" class="team-logo">
            </div>
        </li>
        <!-- Repeat for other matches -->
               <li class="list-group-item match-item color-dark rounded">
            <div class="team">
                <img src="${dota2}" alt="Team Logo" class="team-logo">
                <span class="team-name">Team A</span>
            </div>
            <div class="match-status">
                <span class="badge bg-secondary">14h 40m</span>
            </div>
            <div class="team">
                <span class="team-name">Team B</span>
                <img src="${csgo}" alt="Team Logo" class="team-logo">
            </div>
        </li>
        <!-- Repeat for other matches -->
               <li class="list-group-item match-item color-dark rounded">
            <div class="team">
                <img src="${dota2}" alt="Team Logo" class="team-logo">
                <span class="team-name">Team A</span>
            </div>
            <div class="match-status">
                <span class="badge bg-secondary">15h</span>
            </div>
            <div class="team">
                <span class="team-name">Team B</span>
                <img src="${csgo}" alt="Team Logo" class="team-logo">
            </div>
        </li>
        <!-- Repeat for other matches -->
               <li class="list-group-item match-item color-dark rounded">
            <div class="team">
                <img src="${dota2}" alt="Team Logo" class="team-logo">
                <span class="team-name">Team A</span>
            </div>
            <div class="match-status">
                <span class="badge bg-secondary">16h</span>
            </div>
            <div class="team">
                <span class="team-name">Team B</span>
                <img src="${csgo}" alt="Team Logo" class="team-logo">
            </div>
        </li>
        <!-- Repeat for other matches -->
               <li class="list-group-item match-item color-dark rounded">
            <div class="team">
                <img src="${dota2}" alt="Team Logo" class="team-logo">
                <span class="team-name">Team A</span>
            </div>
            <div class="match-status">
                <span class="badge bg-secondary">17h</span>
            </div>
            <div class="team">
                <span class="team-name">Team B</span>
                <img src="${csgo}" alt="Team Logo" class="team-logo">
            </div>
        </li>
        <!-- Repeat for other matches -->
               <li class="list-group-item match-item color-dark rounded">
            <div class="team">
                <img src="${dota2}" alt="Team Logo" class="team-logo">
                <span class="team-name">Team A</span>
            </div>
            <div class="match-status">
                <span class="badge bg-secondary">18h</span>
            </div>
            <div class="team">
                <span class="team-name">Team B</span>
                <img src="${csgo}" alt="Team Logo" class="team-logo">
            </div>
        </li>
        <!-- Repeat for other matches -->
               <li class="list-group-item match-item color-dark rounded">
            <div class="team">
                <img src="${dota2}" alt="Team Logo" class="team-logo">
                <span class="team-name">Team A</span>
            </div>
            <div class="match-status">
                <span class="badge bg-secondary">19h</span>
            </div>
            <div class="team">
                <span class="team-name">Team B</span>
                <img src="${csgo}" alt="Team Logo" class="team-logo">
            </div>
        </li>
        <!-- Repeat for other matches -->
               <li class="list-group-item match-item color-dark rounded">
            <div class="team">
                <img src="${dota2}" alt="Team Logo" class="team-logo">
                <span class="team-name">Team A</span>
            </div>
            <div class="match-status">
                <span class="badge bg-secondary">20h</span>
            </div>
            <div class="team">
                <span class="team-name">Team B</span>
                <img src="${csgo}" alt="Team Logo" class="team-logo">
            </div>
        </li>
        <!-- Repeat for other matches -->
               <li class="list-group-item match-item color-dark rounded">
            <div class="team">
                <img src="${dota2}" alt="Team Logo" class="team-logo">
                <span class="team-name">Team A</span>
            </div>
            <div class="match-status">
                <span class="badge bg-secondary">21h</span>
            </div>
            <div class="team">
                <span class="team-name">Team B</span>
                <img src="${csgo}" alt="Team Logo" class="team-logo">
            </div>
        </li>
        <!-- Repeat for other matches -->
        
    </ul>
</div>
</div>
`;

WidgetMatches.reg('widget-matches'); // components should always be 2 words seperated with a dash

