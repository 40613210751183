/*eslint no-undef: "off"*/
import Translator from '@andreasremdt/simple-translator';
import { englishTranslation } from "./translations/en.js";
import { greekTranslation } from "./translations/el.js";


export class I18n {
    static initiate() {
        // The below provided options are default.
        window.i18n = new Translator({
            defaultLanguage: "en",
            detectLanguage: false,
            selector: "[data-i18n]",
            debug: false,
            registerGlobally: "__",
            persist: false,
            persistKey: "preferred_language",
            filesLocation: "./i18n"
        });

        window.i18n.add('en', englishTranslation)
        window.i18n.add('el', greekTranslation)
        window.translateTo = I18n.getUsersPreferredLanguage()
        // I18n.changeMetaDescription()
    }

    static getUsersPreferredLanguage() {
        const userLang = window.navigator.language.split("-")[0]
        return I18n.getCurrentlySupportedLanguages().includes(userLang) ? userLang : window.i18n.defaultLanguage;
    }

    static getCurrentlySupportedLanguages(){
        return Array.from(window.i18n.languages.keys())
    }

    static translateString(key, replacements) {
        // translator.translateForKey('header.title', 'de');
        // window.i18n.translateForKey('timelinePage.timestamps.months', 'el');
        // console.debug(window.i18n)
        let translation = window.i18n.translateForKey(key, window.translateTo);
        if (!translation) {
            console.log(`No translation found for key: ${key}`);
        }
        if (replacements) {
            for (let placeholder in replacements) {
                translation = translation.replace(`{{${placeholder}}}`, replacements[placeholder]);
            }
        }
        return translation;
    }

    static changeMetaDescription() {
        document.querySelector('meta[name="description"]').setAttribute("content", I18n.translateString('metaTags.description'));
    }
}


