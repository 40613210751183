import barba from '@barba/core';
import barbaPrefetch from '@barba/prefetch';
import gsap from "gsap";
import "../../components/user-notifier/user-notifier.js";
import '../../components/loading-splasher/loading-splasher.js'
import '../../components/action-verifier/action-verifier.js'
import '../../components/post-container/post-container.js'
import '../../components/home-container/home-container.js'
import '../../components/news-post/news-post.js'
import '../../components/postmatches-container/postmatches-container.js'
import '../../components/posttournaments-container/posttournaments-container.js'
import '../../components/postbookmakers-container/postbookmakers-container.js'
import '../../components/matches-container/matches-container.js'
import '../../components/streamers-container/streamers-container.js'
import '../../components/authors-container/authors-container.js'
import '../../components/tournaments-container/tournaments-container.js'
import '../../components/casinos-container/casinos-container.js'
import '../../components/giveaways-container/giveaways-container.js'
import { UserNotifier } from "../../components/user-notifier/user-notifier.js";
import { LoadingSplasher } from "../../components/loading-splasher/loading-splasher.js";
import { GameNavbar } from "../../components/game-navbar/game-navbar";
import { MainNavbar } from "../../components/main-navbar/main-navbar";

const homePage = require('../../pages/home.html')
const newsPost = require('../../pages/s_news.html')
const postPage = require('../../pages/s_post.html')
const matchesPage = require('../../pages/matches.html')
const tournamentsPage = require('../../pages/tournaments.html')
const casinosPage = require('../../pages/casinos.html')
const giveawaysPage = require('../../pages/giveaways.html')
const authorsPage = require('../../pages/authors.html')
const matchPost = require('../../pages/s_match.html')
const streamersPage = require('../../pages/streamers.html')
const tournamentPost = require('../../pages/s_tournament.html')
const bookmakersPost = require('../../pages/s_bookmaker.html')

import { titles, descriptions, keywords, links } from "../seo-assistant/seo-assistant";


// this crap (foo) is to handle safari bs
const foo = `<div id="curtain" style="position: fixed; top: 0; left: 0; right: 0; bottom: 0; background: #212529; opacity: 0;display: none"></div>`;

export class RouterAssistant {
    static initiate() {
        this.startBarba();
        UserNotifier.initiate()
        window.afterTransition = function () {
            window.router.history.add(window.location.href.replace(".html", ""), 'barba', 'replace');
        }
        window.beforeRedirect = function () {
            window.router.history.add(window.location.href.replace("?", ".html?"), 'barba', 'replace');
        }
    }

    static startBarba() {
        document.body.setAttribute("data-barba", "wrapper")
        // the below line now exists within the grid-orchestrator!
        // document.body.insertAdjacentHTML("afterbegin", `<main data-barba="container" data-barba-namespace="splash-page"></main>`)
        document.body.insertAdjacentHTML("beforeend", foo)
        document.body.insertAdjacentHTML("beforeend", '<action-verifier></action-verifier>')
        barba.use(barbaPrefetch);
        barba.init({
            prevent: ({ event, href }) => {
                if (href.includes('#')) {
                    event.preventDefault()
                    document.getElementById(href.split('#').pop()).scrollIntoView()
                }
                return true
            }, // is a same page anchor link -> mostly for the legal container
            prefetchIgnore: true,
            preventRunning: true,
            transitions: [{
                name: 'opacity-transition',
                before(data) {
                },
                leave(data) {
                    RouterAssistant.activateCurtain(1) // this crap is to handle safari bs

                    return gsap.to(data.current.container, {
                        opacity: 0,
                        display: 'none'
                    });
                },
                enter(data) {

                    GameNavbar.checkUrlToActivateFilters()
                    RouterAssistant.polishUrl()
                    MainNavbar.checkNavBarState()
                    return gsap.from(data.next.container, {
                        opacity: 0
                    });
                },
                after(data) {
                    document.title = "MyEsports";
                    document.querySelector('[data-barba="container"]').classList.add('row')
                    document.querySelector('[data-barba="container"]').classList.add('g-0')
                    document.getElementById('gameFiltersContainer').scrollIntoView({ behavior: "instant", block: "end", inline: "nearest" });
                    RouterAssistant.deactivateCurtain() // this crap is to handle safari bs
                }
            },
            {
                name: 'self',
                before(data) {
                },
                leave(data) {
                    RouterAssistant.activateCurtain(1) // this crap is to handle safari bs
                    return gsap.to(data.current.container, {
                        opacity: 0,
                        display: 'none'
                    });
                },
                enter(data) {

                    MainNavbar.checkNavBarState()
                    GameNavbar.checkUrlToActivateFilters()
                    RouterAssistant.polishUrl()
                    return gsap.from(data.next.container, {
                        opacity: 0
                    });
                },
                after(data) {
                    document.title = "MyEsports";
                    document.querySelector('[data-barba="container"]').classList.add('row')
                    document.querySelector('[data-barba="container"]').classList.add('g-0')
                    document.getElementById('gameFiltersContainer').scrollIntoView({ behavior: "instant", block: "end", inline: "nearest" });
                    RouterAssistant.deactivateCurtain() // this crap is to handle safari bs
                }
            }]
        });
        window.router = barba
        // barba.history
    }

    static authStateChangedRerouting(loggedInState, userIsRegistered) {
        if (loggedInState) {
            if (userIsRegistered) {
                //todo when users start existing
            } else {
                //todo when users start existing
            }
        } else {
            //todo when users start existing
        }

        const currentLocation = window.location.href
        console.debug(currentLocation)
        console.debug(window.localStorage.getItem('userRequestedPage'))
        window.localStorage.setItem('userRequestedPage', currentLocation)

        let variableDictionary = RouterAssistant.constructVariableDictionary()
        if (variableDictionary[0].includes('s_post')) {
            RouterAssistant.redirect('s_post', variableDictionary[1])
        } else if (variableDictionary[0].includes('s_match')) {
            RouterAssistant.redirect('s_match', variableDictionary[1])
        } else if (variableDictionary[0].includes('streamers')) {
            RouterAssistant.redirect('streamers', variableDictionary[1])
        } else if (variableDictionary[0].includes('s_tournament')) {
            RouterAssistant.redirect('s_tournament', variableDictionary[1])
        } else if (variableDictionary[0].includes('s_bookmaker')) {
            RouterAssistant.redirect('s_bookmaker', variableDictionary[1])
        } else if (variableDictionary[0].includes('home')) {
            RouterAssistant.redirect('home', variableDictionary[1])
        } else if (variableDictionary[0].includes('matches')) {
            RouterAssistant.redirect('matches', variableDictionary[1])
        } else if (variableDictionary[0].includes('tournaments')) {
            RouterAssistant.redirect('tournaments', variableDictionary[1])
        } else if (variableDictionary[0].includes('authors')) {
            RouterAssistant.redirect('authors', variableDictionary[1])
        } else if (variableDictionary[0].includes('casinos') ) {
            RouterAssistant.redirect('casinos', variableDictionary[1])
        } else if (variableDictionary[0].includes('giveaways')) {
            RouterAssistant.redirect('giveaways', variableDictionary[1])
        } else if (variableDictionary[0].includes('s_news')) {
            RouterAssistant.redirect('s_news', variableDictionary[1])
        } else {
            RouterAssistant.redirect('home', {})
        }
    }


    static constructVariableDictionary() {
        if (window.localStorage.getItem('userRequestedPage') === null)
            return ['/', {}]
        const userRequestedPage = window.localStorage.getItem('userRequestedPage')
        const initialString = (userRequestedPage === null) ? window.location.href : userRequestedPage;
        const variables = []
        if (!initialString.includes('?'))
            return [userRequestedPage, {}]
        else {
            initialString.split('?').pop().split('&').forEach(chunk => {
                if (chunk.includes('=')) {
                    variables.push(chunk)
                }
            })
            const dict = {}
            variables.forEach(variable => {
                dict[variable.split('=')[0]] = variable.split('=')[1]
            })
            return [userRequestedPage, dict]
        }
    }

    static redirect(page, variables = {}) {
        window.beforeRedirect()
        // window.history.replaceState({}, '', window.location.href.replace("?", ".html?"));

        window.currentPageVariables = variables
        let variablesString = ''
        let destination;
        for (const [key, value] of Object.entries(variables)) {
            variablesString += `&${key}=${value}`
        }
        try {
            switch (page) {
                case "s_post":
                    destination = postPage
                    break;
                case "s_match":
                    destination = matchPost
                    break;
                case "streamers":
                    destination = streamersPage
                    break;
                case "s_tournament":
                    destination = tournamentPost
                    break;
                case "s_bookmaker":
                    destination = bookmakersPost
                    break;
                case "home":
                    destination = homePage
                    break;
                case "matches":
                    destination = matchesPage
                    break;
                case "tournaments":
                    destination = tournamentsPage
                    break;
                case "authors":
                    destination = authorsPage
                    break;
                case "casinos":
                    destination = casinosPage
                    break;
                case "giveaways":
                    destination = giveawaysPage
                    break;
                case "s_news":
                    destination = newsPost
                    break;
                default:
                    throw "page requested does not exist in router-assistant!"
            }
        } catch (e) {
            console.debug(e)
        }
        barba.go(`${destination}?${variablesString.slice(1)}`).then(r => {
            window.gtag('event', 'navigation', { // todo when this was activated it was working only on localhost and was breaking on production
                'destination': page,
                'arguments': variablesString.slice(1)
            });
            localStorage.removeItem('userRequestedPage')
        })
    }

    static activateCurtain(activationOpacity, zIndexOverride = 1054) {
        LoadingSplasher.activateSplasher('');

        document.getElementById('curtain').style.zIndex = `${zIndexOverride}`;
        gsap.to(document.getElementById('curtain'), {
            opacity: activationOpacity,
            display: ''
        });
    }

    static deactivateCurtain() {
        LoadingSplasher.deactivateSplasher();
        gsap.to(document.getElementById('curtain'), {
            opacity: 0,
            display: 'none'
        });
    }


    static addOrUpdateCurrentPageVariable(key, value) {
        let current = window.location.href.replace(window.location.origin, '')
        if (current.includes(key)) {
            const afterSplit = current.split(key)
            const afterKey = afterSplit[afterSplit.length - 1]
            if (afterKey.includes('&')) {
                const afterKey2 = afterKey.split('&')[0]
                window.history.replaceState(null, null, current.replace(`${key}${afterKey2}`, `${key}=${value}`));
            } else {
                window.history.replaceState(null, null, current.replace(`${key}${afterKey}`, `${key}=${value}`));
            }
        } else {
            window.history.replaceState(null, null, `${current}&${key}=${value}`);
        }
    }

    static removeCurrentPageVariable(key) {
        let current = window.location.href.replace(window.location.origin, '')
        if (current.includes(key)) {
            const afterSplit = current.split(key)
            const afterKey = afterSplit[afterSplit.length - 1]
            if (afterKey.includes('&')) {
                const afterKey2 = afterKey.split('&')[0]
                if (current.includes(`&${key}`)) // if the key is first after the '?', there is no '&' after the reload
                    window.history.replaceState(null, null, current.replace(`&${key}${afterKey2}`, ''));
                else
                    window.history.replaceState(null, null, current.replace(`${key}${afterKey2}`, ''));
            } else {
                if (current.includes(`&${key}`)) // if the key is first after the '?', there is no '&' after the reload
                    window.history.replaceState(null, null, current.replace(`&${key}${afterKey}`, ''));
                else
                    window.history.replaceState(null, null, current.replace(`${key}${afterKey}`, ''));
            }
        }
    }

    static getCurrentVariableValue(key) {
        let current = window.location.href.replace(window.location.origin, '')
        if (current.includes(key)) {
            const afterSplit = current.split(key)
            const afterKey = afterSplit[afterSplit.length - 1]
            if (afterKey.includes('&')) {
                const afterKey2 = afterKey.split('&')[0]
                return afterKey2.replace('=', '')
            } else {
                return afterKey.replace('=', '')
            }
        } else return null
    }

    static checkForSingleContainer(element, callback) {
        console.debug(document.querySelectorAll(element).length)
        if (document.querySelectorAll(element).length === 2) {
            setTimeout(function () {
                RouterAssistant.checkForSingleContainer(element, callback)
            }, 10)
        } else {
            callback()
        }
    }


    static polishUrl() {
        window.afterTransition()
    }
}






