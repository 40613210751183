import { BaseComponent } from "@symbiotejs/symbiote";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";
import { MatchesComponent } from "../matches-component/matches-component";
import { doc, getDoc, setDoc } from "@firebase/firestore";
import { FirebaseAssistant } from "../../utilities/firebase-assistant/firebase-assistant";
import 'flag-icon-css/css/flag-icons.min.css';
import csgologo from "../game-navbar/assets/images/csgologo 150x150.png";
import dota2 from "../game-navbar/assets/images/dota2logo 150x150.png";
import lol from "../game-navbar/assets/images/lollogo 150x150.png";
import ea from "../game-navbar/assets/images/ea sports 150x150.png";
import valorant from "../game-navbar/assets/images/valorantlogo 150x150.png";
import cod from "../game-navbar/assets/images/call of duty 150x150.png";
import overwatch from "../game-navbar/assets/images/overwatch 150x150.png";
import pubg from "../game-navbar/assets/images/pubg 150x150.png";
import r6 from "../game-navbar/assets/images/rainbow six 150x150.png";
import rocketleague from "../game-navbar/assets/images/rocketleague 150x150.png";
import startcraft from "../game-navbar/assets/images/starcraft II 150x150.png";
import apex from "../game-navbar/assets/images/ApexLegends 150x150.webp";
import kingofglory from "../game-navbar/assets/images/KingOfGlory 150x150.webp";
const mlegends = require('../game-navbar/assets/images/mobileLegends 150x150.webp')

import { I18n } from "../../utilities/i18n/i18n";
import showdown from 'showdown';

import { off } from "process";
import { GamerShowcase } from "../gamer-showcase/gamer-showcase";

const ISO6391 = require('iso-639-1');
const noPlayerImg = require('./assets/author.webp');
const noImageFound = require('../matches-component/Assets/no image found.png')
const aiAvatar = require('../matches-component/Assets/ai.jpeg')

const css = `
<style>
postmatches-component .accordion ,postmatches-component .accordion-item ,postmatches-component .accordion-header{
border:none !important;
background-color: transparent !important;
}
postmatches-component .accordion-button:focus {
   outline: 0!important;
   box-shadow: 0 0 0 0;
   
}
postmatches-component .accordion-button{
padding-bottom: 0;
}

postmatches-component .accordion-button::after {
display: none ;
}
postmatches-component .accordion-button.collapsed::after {
display: none;
}

   postmatches-component .img-circle-container-ai {
     /*width: 10rem;*/
    /* Adjust the width as needed */
     height: auto;
    /* Ensure the height is the same as the width for a perfect circle */
}
  postmatches-component .img-circle-container-ai img{
     max-width: 10rem;
     max-height: 10rem;
}





postmatches-component .fixed-size-badge-wld {
    display: flex;
    align-items: center;
    justify-content: center;
}
.analysis-row{
position: relative;
}
.text-ai{
position: absolute;
right: 0;
top: 0;
width: 7rem;
}
#past1-pills-tab, #past1-pills-tab .nav-item{
    display: flex;
    justify-content: center;
    text-align: center;
}

    .style-nav-pills > li > a.active {
        background-color: #ff282859; /* Background color for active state */
        color: #fff; /* Text color for active state */
    }

    .style-nav-pills > li > a:hover {
        background-color: #B22A23; /* Background color for hover state */
        cursor: pointer;
            filter: drop-shadow(0 0 4px #B22A23);
            transition: all ease-in-out 0.4s;
            color: #fff; /* Text color for hover state */
    }



postmatches-component .nav .nav-item , .nav .nav-link {
     background=red !important;
     color:#ffffff !important;
     border-radius: 0 0 8px 8px;
}
 postmatches-component .nav .active{
     background-color: #ff282859 !important;
     color:#ffffff !important;
}

 postmatches-component .winner-left {
     background-color: #52A35E;
     filter: drop-shadow(#0D1117 0rem 0.4rem 4.8px);
     border-radius: 0 15px 15px 0;
    
}
 postmatches-component .winner-right {
     background-color: #52A35E;
     filter: drop-shadow(#0D1117 0rem 0.4rem 4.8px);
     border-radius: 15px 0 0 15px;
    
}
postmatches-component .loser-left {
     background-color: #ff4646;
     filter: drop-shadow(#0D1117 0rem 0.4rem 4.8px);
     border-radius: 0 15px 15px 0;
     
}
 postmatches-component .loser-right {
     background-color:#ff4646;
     filter: drop-shadow(#0D1117 0rem 0.4rem 4.8px);
     border-radius: 15px 0 0 15px;
    
}
 

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
postmatches-component .text-name-ai-resize{
font-size: 0.8rem;
font-weight: 800;
}
postmatches-component .text-ai-resize{
font-size: 0.6rem;
font-weight: 400;
width: 95%;
}

   postmatches-component .fixed-size-badge-wld {
    width: 0.5rem;  /* adjust as needed */
    height: 0.5rem; /* adjust as needed */
    font-size: 0.3rem;
    font-weight: 800;
    margin:0.5%;
    
}
 postmatches-component .text-resize-stream{
 font-size: 0.5rem;
 font-weight: 800;
 }
  postmatches-component .tournament-logo-resize{
    max-width: 0.8rem;
    max-height: 0.8rem;
}
 postmatches-component .text-resize-date{
 font-size: 0.5rem;
 font-weight:400;
 }
 postmatches-component .text-resize-tournament{
 font-size:0.4rem;
 font-weight:500;
 }
 postmatches-component .text-resize-badge {
         font-size: 0.5rem;
         font-weight: 800;
 }
/*postmatches-component i{*/
/*     font-size: 0.5rem;*/
/*}*/
 postmatches-component .text-resize-info{
  font-size: 0.4rem;
  font-weight: 400;
 }
  postmatches-component .text-resize-pos-info{
  font-size: 0.45rem;
  font-weight: 800;
  color: white;
 }

  postmatches-component .team-name-resize{
     font-size:0.7rem;
     font-weight: 800;
     }
     postmatches-component .logo-resize-game {
         max-width: 1rem;
         max-height: 1rem;
    }
     postmatches-component .score-resize {
         font-size: 0.6rem;
         font-weight: 800;
    }
      postmatches-component .player-name-resize{
         font-size: 0.7rem;
         font-weight: 800;
    }
    
    postmatches-component .img-circle-container-player {
     width: 2.5rem;
    /* Adjust the width as needed */
     height: 2.5rem;
    /* Ensure the height is the same as the width for a perfect circle */
}

  postmatches-component .img-circle-container-player img{
     max-width: 2rem;
     max-height: 2rem;
}
postmatches-component .img-circle-container-team-img-main{
width: 2rem;    
height: 2rem;
}
postmatches-component .img-circle-container-team-img-main img{
max-width: 2rem;
max-height: 2rem;
}
postmatches-component .img-circle-container-team-img{
width: 1.7rem;
height: 1.7rem;
}
postmatches-component .img-circle-container-team-img img{
max-width: 1.7rem;
max-height: 1.7rem;
}
postmatches-component .text-resize-match-history{
font-size:0.7rem;
font-weight: 700;
}
 
postmatches-component .h2hNoRecentMsg{
font-size: 0.6rem;
font-weight: 500;
}
 postmatches-component .img-circle-container-game-logo {
 width: 1rem;
 height: 1rem;
 }
 postmatches-component  .img-circle-container-game-logo img{
 max-width: 1rem;
 max-height: 1rem;
 }   
postmatches-component .img-circle-container-tournament-logo {
 width: 1rem;
 height: 1rem;
 }
 postmatches-component  .img-circle-container-tournament-logo img{
 max-width: 1rem;
 max-height: 1rem;
 }
}


/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
postmatches-component .text-name-ai-resize{
font-size: 0.8rem;
font-weight: 800;
}
postmatches-component .text-ai-resize{
font-size: 0.6rem;
font-weight: 400;
width: 95%;
}

 postmatches-component .fixed-size-badge-wld {
    width: 0.5rem;  /* adjust as needed */
    height: 0.5rem; /* adjust as needed */
    font-size: 0.25rem;
    font-weight: 800;
    margin:0.5%;
    
}

postmatches-component .text-resize-stream{
 font-size: 0.5rem;
 font-weight: 800;
 }
  postmatches-component .tournament-logo-resize{
    max-width: 0.8rem;
    max-height: 0.8rem;
}
 postmatches-component .text-resize-date{
 font-size: 0.5rem;
 font-weight:400;
 }
 postmatches-component .text-resize-tournament{
 font-size:0.4rem;
 font-weight:500;
 }
 postmatches-component .text-resize-badge {
         font-size: 0.5rem;
         font-weight: 800;
 }
/*postmatches-component i{*/
/*     font-size: 0.5rem;*/
/*}*/
 postmatches-component .text-resize-info{
  font-size: 0.4rem;
  font-weight: 400;
 }
  postmatches-component .text-resize-pos-info{
  font-size: 0.45rem;
  font-weight: 800;
  color: white;
 }

  postmatches-component .team-name-resize{
     font-size:0.7rem;
     font-weight: 800;
     }
     postmatches-component .logo-resize-game {
         max-width: 1rem;
         max-height: 1rem;
    }
     postmatches-component .score-resize {
         font-size: 0.6rem;
         font-weight: 800;
    }
      postmatches-component .player-name-resize{
         font-size: 0.7rem;
         font-weight: 800;
    }
    postmatches-component .img-circle-container-player {
     width: 2rem;
    /* Adjust the width as needed */
     height: 2rem;
    /* Ensure the height is the same as the width for a perfect circle */
}
  postmatches-component .img-circle-container-player img{
     max-width: 1.7rem;
     max-height: 1.7rem;
}
postmatches-component .img-circle-container-team-img-main{
width: 2rem;    
height: 2rem;
}
postmatches-component .img-circle-container-team-img-main img{
max-width: 2rem;
max-height: 2rem;
}
postmatches-component .img-circle-container-team-img{
width: 1.7rem;
height: 1.7rem;
}
postmatches-component .img-circle-container-team-img img{
max-width: 1.7rem;
max-height: 1.7rem;
}
postmatches-component .text-resize-match-history{
font-size:0.7rem;
font-weight: 700;
}
 postmatches-component .h2hNoRecentMsg{
font-size: 0.7rem;
font-weight: 500;
}
 postmatches-component .img-circle-container-game-logo {
 width: 1rem;
 height: 1rem;
 }
 postmatches-component  .img-circle-container-game-logo img{
 max-width: 1rem;
 max-height: 1rem;
 }   
postmatches-component .img-circle-container-tournament-logo {
 width: 1.1rem;
 height: 1.1rem;
 }
 postmatches-component  .img-circle-container-tournament-logo img{
 max-width: 1rem;
 max-height: 1rem;
 }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
postmatches-component .text-name-ai-resize{
font-size: 0.9rem;
font-weight: 800;
}
postmatches-component .text-ai-resize{
font-size: 0.7rem;
font-weight: 400;
width: 95%;
}

 postmatches-component .fixed-size-badge-wld {
    width: 0.7rem;  /* adjust as needed */
    height: 0.7rem; /* adjust as needed */
    font-size: 0.3rem;
    font-weight: 800;
    margin:0.5%;
    
}

postmatches-component .text-resize-stream{
 font-size: 0.6rem;
 font-weight: 800;
 }
  postmatches-component .tournament-logo-resize{
    max-width: 0.9rem;
    max-height: 0.9rem;
}
 postmatches-component .text-resize-date{
 font-size: 0.6rem;
 font-weight:400;
 }
 postmatches-component .text-resize-tournament{
 font-size:0.4rem;
 font-weight:500;
 }
 postmatches-component .text-resize-badge {
         font-size: 0.5rem;
         font-weight: 800;
 }
/*postmatches-component i{*/
/*     font-size: 0.5rem;*/
/*}*/
 postmatches-component .text-resize-info{
  font-size: 0.4rem;
  font-weight: 400;
 }
  postmatches-component .text-resize-pos-info{
  font-size: 0.45rem;
  font-weight: 800;
  color: white;
 }

  postmatches-component .team-name-resize{
     font-size:0.7rem;
     font-weight: 800;
     }
     postmatches-component .logo-resize-game {
         max-width: 1rem;
         max-height: 1rem;
    }
     postmatches-component .score-resize {
         font-size: 0.6rem;
         font-weight: 800;
    }
      postmatches-component .player-name-resize{
         font-size: 0.7rem;
         font-weight: 800;
    }
    postmatches-component .img-circle-container-player {
     width: 2.5rem;
    /* Adjust the width as needed */
     height: 2.5rem;
    /* Ensure the height is the same as the width for a perfect circle */
}
  postmatches-component .img-circle-container-player img{
     max-width: 1.9rem;
     max-height: 1.9rem;
}
postmatches-component .img-circle-container-team-img-main{
width: 2rem;    
height: 2rem;
}
postmatches-component .img-circle-container-team-img-main img{
max-width: 2rem;
max-height: 2rem;
}
postmatches-component .img-circle-container-team-img{
width: 1.7rem;
height: 1.7rem;
}
postmatches-component .img-circle-container-team-img img{
max-width: 1.7rem;
max-height: 1.7rem;
}
postmatches-component .text-resize-match-history{
font-size:0.7rem;
font-weight: 700;
}
 postmatches-component .h2hNoRecentMsg{
font-size: 0.8rem;
font-weight: 500;
}
 postmatches-component .img-circle-container-game-logo {
 width: 1rem;
 height: 1rem;
 }
 postmatches-component  .img-circle-container-game-logo img{
 max-width: 1rem;
 max-height: 1rem;
 }   
postmatches-component .img-circle-container-tournament-logo {
 width: 1.3rem;
 height: 1.3rem;
 }
 postmatches-component  .img-circle-container-tournament-logo img{
 max-width: 1.2rem;
 max-height: 1.2rem;
 }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
postmatches-component .text-name-ai-resize{
font-size: 1rem;
font-weight: 800;
}
postmatches-component .text-ai-resize{
font-size: 0.8rem;
font-weight: 400;
width: 95%;
}

 postmatches-component .fixed-size-badge-wld {
    width: 0.8rem;  /* adjust as needed */
    height: 0.8rem; /* adjust as needed */
    font-size: 0.4rem;
    font-weight: 800;
    margin:0.5%;
    
}

postmatches-component .text-resize-stream{
 font-size: 0.5rem;
 font-weight: 800;
 }
  postmatches-component .tournament-logo-resize{
    max-width: 0.8rem;
    max-height: 0.8rem;
}
 postmatches-component .text-resize-date{
 font-size: 0.5rem;
 font-weight:400;
 }

 postmatches-component .text-resize-tournament{
 font-size:0.4rem;
 font-weight:500;
 }
 postmatches-component .text-resize-badge {
         font-size: 0.5rem;
         font-weight: 800;
 }
/*postmatches-component i{*/
/*     font-size: 0.5rem;*/
/*}*/
 postmatches-component .text-resize-info{
  font-size: 0.4rem;
  font-weight: 400;
 }
  postmatches-component .text-resize-pos-info{
  font-size: 0.45rem;
  font-weight: 800;
  color: white;
 }

  postmatches-component .team-name-resize{
     font-size:0.7rem;
     font-weight: 800;
     }
     postmatches-component .logo-resize-game {
         max-width: 1rem;
         max-height: 1rem;
    }
     postmatches-component .score-resize {
         font-size: 0.6rem;
         font-weight: 800;
    }
      postmatches-component .player-name-resize{
         font-size: 0.7rem;
         font-weight: 800;
    }
    postmatches-component .img-circle-container-player {
     width: 2.5rem;
    /* Adjust the width as needed */
     height: 2.5rem;
    /* Ensure the height is the same as the width for a perfect circle */
}
  postmatches-component .img-circle-container-player img{
     max-width: 2rem;
     max-height: 2rem;
}
postmatches-component .img-circle-container-team-img-main{
width: 2rem;    
height: 2rem;
}
postmatches-component .img-circle-container-team-img-main img{
max-width: 2rem;
max-height: 2rem;
}
postmatches-component .img-circle-container-team-img{
width: 1.7rem;
height: 1.7rem;
}
postmatches-component .img-circle-container-team-img img{
max-width: 1.7rem;
max-height: 1.7rem;
}
postmatches-component .text-resize-match-history{
font-size:0.7rem;
font-weight: 700;
}
 postmatches-component .h2hNoRecentMsg{
font-size: 0.9rem;
font-weight: 500;
}
 postmatches-component .img-circle-container-game-logo {
 width: 1rem;
 height: 1rem;
 }
 postmatches-component  .img-circle-container-game-logo img{
 max-width: 1rem;
 max-height: 1rem;
 }   
postmatches-component .img-circle-container-tournament-logo {
 width: 1.4rem;
 height: 1.4rem;
 }
 postmatches-component  .img-circle-container-tournament-logo img{
 max-width: 1.3rem;
 max-height: 1.3rem;
 }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
postmatches-component .text-name-ai-resize{
font-size: 1rem;
font-weight: 800;
}
postmatches-component .text-ai-resize{
font-size: 0.8rem;
font-weight: 400;
width: 95%;
}

postmatches-component .text-resize-stream{
 font-size: 0.9rem;
 font-weight: 800;
 }
  postmatches-component .tournament-logo-resize{
    max-width: 1.2rem;
    max-height: 1.2rem;
}
 postmatches-component .text-resize-date{
 font-size: 0.6rem;
 font-weight:400;
 }
 postmatches-component .text-resize-tournament{
 font-size:0.65rem;
 font-weight:500;
 }
 postmatches-component .text-resize-badge {
         font-size: 0.8rem;
         font-weight: 800;
 }
/*postmatches-component i{*/
/*     font-size: 0.5rem;*/
/*}*/
 postmatches-component .text-resize-info{
  font-size: 0.6rem;
  font-weight: 400;
 }
  postmatches-component .text-resize-pos-info{
  font-size: 0.65rem;
  font-weight: 800;
  color: white;
 }

  postmatches-component .team-name-resize{
     font-size:1rem;
     font-weight: 800;
     }
     postmatches-component .logo-resize-game {
         max-width: 1.5rem;
         max-height: 1.5rem;
    }
     postmatches-component .score-resize {
         font-size: 1rem;
         font-weight: 800;
    }
      postmatches-component .player-name-resize{
         font-size: 1rem;
         font-weight: 800;
    }
    postmatches-component .img-circle-container-player {
     width: 4rem;
    /* Adjust the width as needed */
     height: 4rem;
    /* Ensure the height is the same as the width for a perfect circle */
    
}
   postmatches-component .img-circle-container-player img{
     max-width: 3.3rem;
     max-height: 3.3rem;
}
postmatches-component .img-circle-container-team-img-main{
width: 4rem;    
height: 4rem;
}
postmatches-component .img-circle-container-team-img-main img{
max-width: 4rem;
max-height: 4rem;
}
postmatches-component .img-circle-container-team-img{
width: 2rem;    
height: 2rem;
}
postmatches-component .img-circle-container-team-img img{
max-width: 2rem;
max-height: 2rem;
}
postmatches-component .text-resize-match-history{
font-size:0.9rem;
font-weight: 700;
}
 postmatches-component .img-circle-container-game-logo {
 width: 1.4rem;
 height: 1.4rem;
 }
 postmatches-component  .img-circle-container-game-logo img{
 max-width: 1.3rem;
 max-height: 1.3rem;
 }   
 postmatches-component .img-circle-container-tournament-logo {
 width: 1.5rem;
 height: 1.5rem;
 }
 postmatches-component  .img-circle-container-tournament-logo img{
 max-width: 1.4rem;
 max-height: 1.4rem;
 }
 postmatches-component .h2hNoRecentMsg{
font-size: 1rem;
font-weight: 500;
}
postmatches-component .fixed-size-badge-wld {
    width: 1rem;  /* adjust as needed */
    height: 1rem; /* adjust as needed */
    font-size: 0.5rem;
    font-weight: 800;
    display: flex;
    align-items: center;
    margin:0.5%;
    justify-content: center;
}

}

@media (orientation: portrait) {
    #clickBaitSpan, #mainAiTitle {
        max-width: 80%;
    }
}
</style>
`

export class PostMatchesComponent extends BaseComponent {

    initCallback() {
        document.head.insertAdjacentHTML("beforeend", css);
        RouterAssistant.checkForSingleContainer('postmatches-component', PostMatchesComponent.initiator)
        window.addEventListener('resize', PostMatchesComponent.resizetoTwitchHeight);

    }

    static postGlobalDate = new Date();

    static resizetoTwitchHeight() {
        const twitchIframe = document.querySelector('[id^="twitch-iframe"]');
        if (twitchIframe) {
            const width = twitchIframe.offsetWidth;
            const height = width * (9 / 16);
            twitchIframe.style.height = `${height}px`;
            console.debug('I am running the resizetoTwitchHeight function and the height is', height);
        }
        else {
            console.debug('Twitch iframe element not found');
        }

    }



    static initiator() {
        MatchesComponent.getMatches('fromPostMatchesComponent', async () => {
            if (window.location.href.includes('s_match')) {
                console.debug(window.tadeCallback)
                if (window.tadeCallback === undefined) {
                    window.tadeCallback = true;
                    console.debug('I am running the post matches component callback!!!')
                    const matchcodeid = RouterAssistant.getCurrentVariableValue('match')
                    let data = null;
                    if (window.savedMatches[matchcodeid] !== undefined) {
                        console.debug('The match is found in the saved matches');
                        data = window.savedMatches[matchcodeid];
                    } else if (window.savedMatchesFromTournaments && window.savedMatchesFromTournaments[matchcodeid] !== undefined) {
                        console.debug('The match is found in the saved matches from tournaments');
                        data = window.savedMatchesFromTournaments[matchcodeid];
                    } else if (window.savedPastMatches) {
                        for (let key in window.savedPastMatches) {
                            if (window.savedPastMatches[key] !== undefined) {
                                for (let i = 0; i < window.savedPastMatches[key].length; i++) {
                                    if (window.savedPastMatches[key][i] && window.savedPastMatches[key][i].id && window.savedPastMatches[key][i].id.toString() === matchcodeid) {
                                        console.debug('The match is found in the saved past matches');
                                        data = window.savedPastMatches[key][i];
                                        break;
                                    }
                                }
                            }
                        }
                    }

                    if (!data) {
                        console.debug('The match is not found in the saved matches of either, so we are going to fetch the data again');
                        await new Promise((resolve, reject) => {
                            FirebaseAssistant.getSingleMatch(matchcodeid, querySnapshot => {
                                console.debug('getting the single match from firestore')
                                if (!querySnapshot.empty) {
                                    const single = querySnapshot.docs[0];
                                    data = single.data();
                                    window.savedMatches[data.id] = data;
                                    resolve();
                                } else {
                                    document.querySelector('#matchpost-html').innerHTML = '';
                                    document.querySelector('#matchpost-html').insertAdjacentHTML('beforeend',
                                       /*html*/ `
                                        <br><br><br><br>
                                    <h1 class="text-center text-white">No data for this match, yet.</h1>
                                    <h2 class="text-center text-white">Please check back later</h2>
                                        <br><br><br><br>
                                    `); // Shadow edw paizeis mpala
                                    document.getElementById('placeholder-stream').style.display = "none";
                                    document.getElementById('placeholder-chat').style.display = "none";
                                    window.tadeCallback === undefined;
                                }
                            });
                        });
                    }
                    let userLanguage = I18n.getUsersPreferredLanguage();
                    let analysisString = '';
                    if (data.status !== 'finished') {
                        await new Promise((resolve, reject) => {
                            FirebaseAssistant.getAnalysis(matchcodeid + '-' + userLanguage, querySnapshot => {
                                console.debug('getting the analysis from firestore')
                                if (querySnapshot && !querySnapshot.empty && querySnapshot.status.state === 'COMPLETED') {
                                    console.debug('Analysis found for this match', querySnapshot);
                                    analysisString = querySnapshot.output;
                                } else if (data.status === 'finished') {
                                    analysisString = ``;
                                } else {
                                    analysisString = I18n.translateString("postMatchTranslations.analysis");
                                }
                                resolve();
                            });
                        });
                    }
                    const converter = new showdown.Converter();
                    const clickBaitString = PostMatchesComponent.extractFirstTextBlock(analysisString)
                    const htmlAnalysisContent = converter.makeHtml(analysisString); // to htmlAnalysisContent einai pou benei stin html pio kato

                    console.debug('DATAAAAAAAAAAA', data)


                    if (!data.opponents || data.opponents.length < 2) {
                        console.debug('No opponents found for this match');
                        document.querySelector('#matchpost-html').innerHTML = '';
                        document.querySelector('#matchpost-html').insertAdjacentHTML('beforeend',
                                       /*html*/ `
                                        <br><br><br><br>
                                    <h1 class="text-center text-white">No data for this match, yet.</h1>
                                    <h2 class="text-center text-white">Please check back later</h2>
                                        <br><br><br><br>
                                    `); // Shadow edw paizeis mpala
                        document.getElementById('placeholder-stream').style.display = "none";
                        document.getElementById('placeholder-chat').style.display = "none";
                        window.tadeCallback === undefined;
                        return;
                    } // if no opponents due to tourney format ongoing, skip the match
                    const team1id = data.opponents[0].opponent.id;
                    const team2id = data.opponents[1].opponent.id;
                    if (window.savedPastMatches && window.savedPastMatches[team1id] && window.savedPastMatches[team2id] && window.savedPastMatches[team1id + '_' + team2id] && window.savedPastMatches[team2id + '_' + team1id]) {
                        console.debug('Past matches already saved for these teams');
                    } else {
                        let counterHistory = 0;
                        let counterH2hHistory = 0;
                        let promise1 = new Promise((resolve, reject) => {
                            FirebaseAssistant.getPastMatches(team1id, team2id, (querySnapshot1, querySnapshot2) => {
                                console.debug('getting the past matches from firestore')
                                if (!querySnapshot1.empty || !querySnapshot2.empty) {
                                    if (!querySnapshot1.empty) {
                                        for (let doc of querySnapshot1.docs) {
                                            if (counterHistory >= 10) break;
                                            let pastData = doc.data();
                                            if (((pastData.opponents[0].opponent.id === team1id && pastData.opponents[1].opponent.id === team2id) || (pastData.opponents[0].opponent.id === team2id && pastData.opponents[1].opponent.id === team1id)) && ((pastData.id !== data.id) && pastData.status === 'finished')) {
                                                console.debug('data h2h matches for opponent1', pastData.name);
                                                if (window.savedPastMatches === undefined) {
                                                    window.savedPastMatches = { [team1id + '_' + team2id]: [pastData] }
                                                } else if (window.savedPastMatches[team1id + '_' + team2id] === undefined) {
                                                    window.savedPastMatches[team1id + '_' + team2id] = [pastData];
                                                } else if (!window.savedPastMatches[team1id + '_' + team2id].some(data => data.id === pastData.id)) {
                                                    window.savedPastMatches[team1id + '_' + team2id].push(pastData);
                                                }
                                                if (window.savedPastMatches[team1id] === undefined) {
                                                    window.savedPastMatches[team1id] = [pastData];
                                                    console.debug('pushing data to team1id', pastData.name)
                                                } else if (!window.savedPastMatches[team1id].some(data => data.id === pastData.id)) {
                                                    window.savedPastMatches[team1id].push(pastData);
                                                    console.debug('pushing data to team1id', pastData.name)
                                                }
                                                counterHistory++;
                                            } else if ((pastData.id !== data.id) && pastData.status === 'finished') {
                                                if (window.savedPastMatches === undefined) {
                                                    window.savedPastMatches = { [team1id]: [pastData] }
                                                } else if (window.savedPastMatches[team1id] === undefined) {
                                                    window.savedPastMatches[team1id] = [pastData];
                                                } else if (!window.savedPastMatches[team1id].some(data => data.id === pastData.id)) {
                                                    window.savedPastMatches[team1id].push(pastData);
                                                }
                                                counterHistory++;
                                            }
                                        }
                                    }
                                    counterHistory = 0;
                                    if (window.savedPastMatches === undefined) {
                                        window.savedPastMatches = { [team1id]: [''] }
                                    } else if (window.savedPastMatches[team1id] === undefined) {
                                        window.savedPastMatches[team1id] = [''];
                                    }
                                    if (!querySnapshot2.empty) {
                                        for (let doc of querySnapshot2.docs) {
                                            if (counterHistory >= 10) break;
                                            let pastData = doc.data();
                                            if (((pastData.opponents[0].opponent.id === team1id && pastData.opponents[1].opponent.id === team2id) || (pastData.opponents[0].opponent.id === team2id && pastData.opponents[1].opponent.id === team1id)) && ((pastData.id !== data.id) && pastData.status === 'finished')) {
                                                console.debug('data h2h matches for opponent2', pastData.name);
                                                if (window.savedPastMatches === undefined) {
                                                    window.savedPastMatches = { [team2id + '_' + team1id]: [pastData] }
                                                } else if (window.savedPastMatches[team2id + '_' + team1id] === undefined) {
                                                    window.savedPastMatches[team2id + '_' + team1id] = [pastData];
                                                } else if (!window.savedPastMatches[team2id + '_' + team1id].some(data => data.id === pastData.id)) {
                                                    window.savedPastMatches[team2id + '_' + team1id].push(pastData);
                                                }
                                                if (window.savedPastMatches[team2id] === undefined) {
                                                    window.savedPastMatches[team2id] = [pastData];
                                                    console.debug('pushing data to team2id', pastData.name)
                                                } else if (!window.savedPastMatches[team2id].some(data => data.id === pastData.id)) {
                                                    window.savedPastMatches[team2id].push(pastData);
                                                    console.debug('pushing data to team2id', pastData.name)
                                                }
                                                counterHistory++;
                                            } else if ((pastData.id !== data.id) && pastData.status === 'finished') {
                                                if (window.savedPastMatches === undefined) {
                                                    window.savedPastMatches = { [team2id]: [pastData] }
                                                } else if (window.savedPastMatches[team2id] === undefined) {
                                                    window.savedPastMatches[team2id] = [pastData];
                                                } else if (!window.savedPastMatches[team2id].some(data => data.id === pastData.id)) {
                                                    window.savedPastMatches[team2id].push(pastData);
                                                }
                                                counterHistory++;
                                            }
                                        }
                                    }
                                    if (window.savedPastMatches === undefined) {
                                        window.savedPastMatches = { [team2id]: [''] }
                                    } else if (window.savedPastMatches[team2id] === undefined) {
                                        window.savedPastMatches[team2id] = [''];
                                    }
                                    // Process the snapshots here
                                    resolve();
                                } else {
                                    console.debug('No past matches found for these teams');
                                    window.savedPastMatches = window.savedPastMatches || {}; // Initialize if it doesn't exist
                                    window.savedPastMatches[team1id] = [''];
                                    window.savedPastMatches[team2id] = [''];
                                    resolve();
                                }

                            });
                        });
                        let promise2 = new Promise((resolve, reject) => {
                            FirebaseAssistant.getH2hPastMatches(team1id, team2id, (docs) => {
                                if (docs.length > 0) {
                                    for (let doc of docs) {
                                        if (counterH2hHistory >= 10) break;
                                        let pastData = doc.data();
                                        if (((pastData.opponents[0].opponent.id === team1id && pastData.opponents[1].opponent.id === team2id) || (pastData.opponents[0].opponent.id === team2id && pastData.opponents[1].opponent.id === team1id)) && ((pastData.id !== data.id) && pastData.status === 'finished')) {
                                            console.debug('data h2h matches for opponent1', pastData.name);
                                            if (window.savedPastMatches === undefined) {
                                                window.savedPastMatches = { [team1id + '_' + team2id]: [pastData] }
                                            } else if (window.savedPastMatches[team1id + '_' + team2id] === undefined) {
                                                window.savedPastMatches[team1id + '_' + team2id] = [pastData];
                                            } else if (!window.savedPastMatches[team1id + '_' + team2id].some(data => data.id === pastData.id)) {
                                                window.savedPastMatches[team1id + '_' + team2id].push(pastData);
                                            }
                                            counterH2hHistory++;
                                        }
                                    }
                                    resolve();
                                } else {
                                    console.debug('No h2h matches found for these teams');
                                    window.savedPastMatches = window.savedPastMatches || {}; // Initialize if it doesn't exist
                                    window.savedPastMatches[team1id + '_' + team2id] = [''];
                                    window.savedPastMatches[team1id + '_' + team2id] = [''];
                                    resolve();
                                }
                            });
                        });
                        let results = await Promise.allSettled([promise1, promise2]);
                        console.debug(results)
                    }

                    const matchId = data.id;
                    const promoStreamUserLogin = PostMatchesComponent.PromoVideo(false, matchId);
                    console.debug('Promo Streamer login:', promoStreamUserLogin);


                    const gameStatus = data.status;
                    const idOfScore = `matchScore${matchcodeid}`
                    const dateRaw = new Date(data.begin_at ? data.begin_at : data.scheduled_at);
                    const endDateRaw = data.end_at ? new Date(data.end_at) : null;
                    let startDif = PostMatchesComponent.postGlobalDate.getTime() - dateRaw.getTime(); // calculate the difference between the current time and the start time of the match
                    let endDif = null;
                    let videoLinks = Array(6).fill('No video available');
                    let chatLinks = Array(6).fill('No chat available');
                    let htmlTags = Array(6).fill('No tag available');
                    let consoleIcons = Array(6).fill('No icon available');

                    let index = 0;

                    while (index < videoLinks.length) {
                        if (videoLinks[index] !== undefined) {
                            let bestMatch = null;
                            for (let i = 0; i < data.streams_list.length; i++) {
                                const stream = data.streams_list[i];
                                let rawUrl = null;
                                let channelName = null;
                                if (stream.embed_url && videoLinks[index] === 'No video available') {
                                    let tempVideoLink = null;
                                    if (stream.embed_url.includes('twitch')) {
                                        rawUrl = stream.raw_url;
                                        channelName = rawUrl ? rawUrl.split('/').pop() : null;
                                        tempVideoLink = `${stream.embed_url}&parent=${window.location.hostname}`;
                                        if (!videoLinks.includes(tempVideoLink)) {
                                            if (stream.language === 'el' || (stream.official && stream.language === 'en')) {
                                                bestMatch = {
                                                    videoLink: tempVideoLink,
                                                    chatLink: `https://www.twitch.tv/embed/${channelName}/chat?darkpopout&parent=${window.location.hostname}`,
                                                    htmlTag: `${stream.raw_url}---${stream.language}`,
                                                    consoleIcon: 'twitch'
                                                };
                                                break;
                                            } else if (stream.language === 'en' && (!bestMatch || !bestMatch.official)) {
                                                bestMatch = {
                                                    videoLink: tempVideoLink,
                                                    chatLink: `https://www.twitch.tv/embed/${channelName}/chat?darkpopout&parent=${window.location.hostname}`,
                                                    htmlTag: `${stream.raw_url}---${stream.language}`,
                                                    consoleIcon: 'twitch',
                                                    official: stream.official
                                                };
                                            } else if (!bestMatch) {
                                                bestMatch = {
                                                    videoLink: tempVideoLink,
                                                    chatLink: `https://www.twitch.tv/embed/${channelName}/chat?darkpopout&parent=${window.location.hostname}`,
                                                    htmlTag: `${stream.raw_url}---${stream.language}`,
                                                    consoleIcon: 'twitch'
                                                };
                                            }
                                        }
                                    }
                                }
                            }
                            if (bestMatch) {
                                videoLinks[index] = bestMatch.videoLink;
                                chatLinks[index] = bestMatch.chatLink;
                                htmlTags[index] = bestMatch.htmlTag;
                                consoleIcons[index] = bestMatch.consoleIcon;
                            }
                        }
                        index++;
                    }

                    // If no Twitch streams were found, check for YouTube and AfreecaTV streams
                    index = 0;
                    while (index < videoLinks.length) {
                        if (videoLinks[index] !== undefined && videoLinks[index] === 'No video available') {
                            for (let i = 0; i < data.streams_list.length; i++) {
                                const stream = data.streams_list[i];
                                let tempVideoLink = null; // Temporary variable to hold the new video link
                                let rawUrl = null;
                                let channelName = null;
                                if (stream.embed_url) {
                                    if (stream.embed_url.includes('youtube')) {
                                        tempVideoLink = stream.embed_url;
                                        if (!videoLinks.includes(tempVideoLink)) { // If the new video link does not match the old ones
                                            videoLinks[index] = tempVideoLink;
                                            rawUrl = stream.raw_url;
                                            channelName = rawUrl ? rawUrl.split('?').pop() : null;
                                            console.debug('channelName', channelName);
                                            chatLinks[index] = `https://www.youtube.com/live_chat?${channelName}&embed_domain=${window.location.hostname}`;
                                            htmlTags[index] = `${stream.raw_url}---${stream.language}`;
                                            consoleIcons[index] = 'youtube';
                                        }
                                    } else if (stream.embed_url.includes('play.afreecatv')) {
                                        tempVideoLink = stream.embed_url;
                                        if (!videoLinks.includes(tempVideoLink)) { // If the new video link does not match the old ones
                                            videoLinks[index] = tempVideoLink;
                                            chatLinks[index] = 'No chat available';
                                            htmlTags[index] = `${stream.raw_url}---${stream.language}`;
                                            consoleIcons[index] = 'cast';
                                        }
                                    }
                                }
                                // else if (stream.raw_url && stream.raw_url.includes('youtube')) {
                                //     rawUrl = stream.raw_url;
                                //     if (rawUrl.includes('/channel/')) {
                                //         channelName = rawUrl.split('/channel/').pop();
                                //     } else if (rawUrl.includes('/user/')) {
                                //         channelName = rawUrl.split('/user/').pop();
                                //     } else if (rawUrl.includes('@')) {
                                //         channelName = rawUrl.match(/\/(@[^\/]*)/)[1];
                                //         console.debug('channelName', channelName);
                                //     }
                                //     tempVideoLink = `https://www.youtube.com/embed/${channelName}`
                                //     if (!videoLinks.includes(tempVideoLink)) { // If the new video link does not match the old ones
                                //         videoLinks[index] = tempVideoLink;;
                                //         chatLinks[index] = `https://www.youtube.com/live_chat?${channelName}&embed_domain=${window.location.hostname}`;
                                //         htmlTags[index] = `${stream.raw_url}---${stream.language}`;
                                //         consoleIcons[index] = 'youtube';
                                //     }
                                // }

                                // WILL WORK ON THIS AT SOME POINT, need youtube api see notes.txt
                            }
                        }
                        index++;
                    }

                    console.debug(videoLinks);
                    console.debug(chatLinks);
                    console.debug(htmlTags);

                    if (promoStreamUserLogin !== undefined) {
                        videoLinks.unshift(`https://player.twitch.tv/?channel=${promoStreamUserLogin}&parent=${window.location.hostname}`);
                        chatLinks.unshift(`https://www.twitch.tv/embed/${promoStreamUserLogin}/chat?darkpopout&parent=${window.location.hostname}`);
                        htmlTags.unshift(`https://www.twitch.tv/${promoStreamUserLogin}---promo`);
                        consoleIcons.unshift('twitch');
                    }

                    let videoPlayers = videoLinks.map((videoLink, index) => {
                        return `<div class="twitch-container p-3 color-dark rounded ">
                            ${videoLink !== 'No video available'
                                ? `<div id="videosid">
                            <iframe id="twitch-iframe-${index}"
                                src="${videoLink}" 
                                height="100%" 
                                width="100%" 
                                frameborder="0" 
                                scrolling="no" 
                                allowfullscreen="true">
                            </iframe>
                        </div>`
                                : '<span class="d-flex justify-content-center noVideoMessage">No video available<span>'}
                        </div>`;
                    });

                    let chats = chatLinks.map((chatLink, index) => {
                        return `
                            ${chatLink !== 'No chat available'
                                ? `<div id="chats">
                                <iframe id="twitch-chat-${index}"
                                            src="${chatLink}"
                                            height="700px"
                                            width="100%"
                                            frameborder="0"
                                            scrolling="no"
                                            allowfullscreen="true">
                                        </iframe>
                                        </div>`
                                : ''}
                        </div>`;

                    })

                    let navPills = videoPlayers.map((videoPlayer, index) => {
                        if (videoPlayer.includes('No video available')) {
                            return '';
                        } else {
                            let userLanguage = window.navigator.language;
                            let languageCode = htmlTags[index].split('---')[1];
                            let activeClass = index === 0 ? 'active' : '';
                            if (languageCode === 'promo') {
                                return `<li class="nav-item text-resize-stream">
                                    <a class="nav-link ${activeClass}" id="${htmlTags[index]}" data-toggle="pill"><i class="me-2 bi bi-${consoleIcons[index]}"></i>MyEsports</a>
                                </li>`;
                            } else {
                                let languageName = new Intl.DisplayNames([userLanguage], { type: 'language' }).of(languageCode);
                                return `<li class="nav-item text-resize-stream">
                                    <a class="nav-link ${activeClass}" id="${htmlTags[index]}" data-toggle="pill"><i class="me-2 bi bi-${consoleIcons[index]}"></i>${languageName}</a>
                                </li>`;
                            }
                        }
                    }).join('');

                    const countdownElementId = `countdown-${data.id}`;
                    const matchElementId = `${data.id}`;

                    if (document.querySelectorAll('[id^="postmatch"]').length !== 0) {
                        console.debug('an addition arrived from snapshot, so we are not going to fetch the data again, but we are going to update the page!!');

                        console.debug(`${data.results[0].score.toString()} - ${data.results[1].score.toString()}`);


                        document.querySelector(`#${idOfScore}`).innerText = `${data.results[0].score.toString()} - ${data.results[1].score.toString()}`;

                        const element = document.querySelector('#chatforvideo');
                        if (gameStatus === 'finished') {
                            endDif = PostMatchesComponent.postGlobalDate.getTime() - endDateRaw.getTime(); // calculate the difference between the current time and the end time of the match
                        }
                        if (element && element.innerHTML.trim() === '' && gameStatus !== 'finished' && startDif >= - 20 * 60 * 1000) {
                            element.insertAdjacentHTML('beforeend', chats[0]);
                        } else if (element && element.innerHTML.trim() === '' && endDif !== null && endDif <= 20 * 60 * 1000) {
                            element.insertAdjacentHTML('beforeend', chats[0]);
                        } else if (element && element.innerHTML.trim() === '' && gameStatus === 'running') {
                            element.insertAdjacentHTML('beforeend', chats[0]);
                        } else {
                            console.debug('The match is not live or the element is not empty, so we are not going to show the chat')
                        }

                        if (document.querySelector(`.thePlayerContainer`).innerHTML.trim() === '' || document.querySelector('.thePlayerContainer .noVideoMessage')) {
                            console.debug('The player container is empty or it has no video yet, so we are going to add the video player');
                            document.querySelector(`.thePlayerContainer`).innerHTML = `
                        ${(() => {
                                    if (gameStatus === 'finished') {
                                        endDif = PostMatchesComponent.postGlobalDate.getTime() - endDateRaw.getTime(); // calculate the difference between the current time and the end time of the match
                                    }
                                    if (gameStatus !== 'finished' && startDif >= - 20 * 60 * 1000) { // if the start difference is less than 20 minutes, show the video player
                                        return `${videoPlayers[0]}
                                        <ul class="nav nav-pills style-nav-pills ">
                                            ${navPills}
                                        </ul>
                                    </div>`;
                                    } else if (endDif !== null && endDif <= 20 * 60 * 1000) { // if the end difference is less than 20 minutes, show the video player
                                        return `${videoPlayers[0]}
                                        <ul class="nav nav-pills style-nav-pills ">
                                            ${navPills}
                                        </ul>
                                    </div>`;
                                    } else if (gameStatus === 'running') { // if the game is running, show the video player
                                        return `${videoPlayers[0]}
                                        <ul class="nav nav-pills style-nav-pills ">
                                            ${navPills}
                                        </ul>
                                    </div>`;
                                    } else { // if none of the above, don't show the video player
                                        console.debug('The match is not live, so we are not going to show the video player')
                                        return '';
                                    }
                                })()
                                } `;

                            htmlTags.forEach((tag, index) => {
                                let pill = document.getElementById(tag);
                                if (pill) {
                                    pill.addEventListener('click', () => {
                                        console.debug('Pill clicked:', pill.id);
                                        // Remove 'active' class from all pills
                                        htmlTags.forEach((tag) => {
                                            let p = document.getElementById(tag);
                                            if (p) p.classList.remove('active');
                                        });

                                        // Add 'active' class to the clicked pill
                                        pill.classList.add('active');

                                        // Replace the current video player with the one associated with the clicked pill
                                        let videoContainer = document.querySelector('#videosid iframe');
                                        if (videoContainer) {
                                            videoContainer.src = videoLinks[index]; // Use videoLinks instead of videoPlayers
                                        }

                                        // Replace the current chat with the one associated with the clicked pill
                                        let chatIframe = document.querySelector('#chats iframe');
                                        if (chatIframe) {
                                            chatIframe.src = chatLinks[index];
                                        }
                                    });
                                }
                            });
                        } else {
                            console.debug('The player container is not empty, so we are not going to add the video player again');
                        }

                        PostMatchesComponent.startCountdown(dateRaw, countdownElementId, matchElementId, gameStatus);
                        PostMatchesComponent.resizetoTwitchHeight()


                        if (data.winner_id) {
                            document.querySelector(`#team-${data.winner_id}`).classList.add('winner')
                        }
                        window.tadeCallback = undefined;
                    } else {
                        console.debug(RouterAssistant.getCurrentVariableValue('match'));



                        const tournamentId = data.tournament_id || data.tournament.id;
                        const serieId = data.serie_id;
                        const serieIdElement = `seriefrompostmatch-${serieId}`;
                        console.debug('serieIdElement', serieIdElement);
                        let matchIdsArrayForListener = [];
                        const team1name = data.opponents[0].opponent.name;
                        const team1img = data.opponents[0].opponent.image_url ? data.opponents[0].opponent.image_url : noImageFound
                        const team1idF = data.opponents[0].opponent.id;
                        const team2name = data.opponents[1].opponent.name;
                        const team2img = data.opponents[1].opponent.image_url ? data.opponents[1].opponent.image_url : noImageFound
                        const team2idF = data.opponents[1].opponent.id;
                        const winnerId = data.winner_id;
                        const currentGameId = data.videogame.id;
                        const gameIdImg = {
                            '3': csgologo,
                            '4': dota2,
                            '1': lol,
                            '25': ea,
                            '26': valorant,
                            '23': cod,
                            '14': overwatch,
                            '20': pubg,
                            '24': r6,
                            '22': rocketleague,
                            '29': startcraft,
                            '30': startcraft,
                            '27': kingofglory,
                            '34': mlegends

                        };
                        const tournamentName = `${data.league.name} ${data.serie.full_name}`;
                        const tournamentLogo = data.league.image_url;
                        const tournamentLogoHTML = tournamentLogo ? `<img src="${tournamentLogo}" alt="tournament logo" class="img-fluid">` : '';
                        const gameLogoHTML = `<img src="${gameIdImg[data.videogame.id]}" alt="game logo" class="img-fluid mx-auto d-block">`;
                        const matchTypeRaw = data.match_type;
                        const numberOfGames = data.number_of_games;
                        let matchTypeFinal = ``;
                        if (matchTypeRaw === 'best_of' && (numberOfGames !== 2 && numberOfGames !== 1)) {
                            matchTypeFinal = 'best_of_X';
                        } else if (matchTypeRaw === 'best_of' && numberOfGames === 1) {
                            matchTypeFinal = 'single_game';
                        } else if (matchTypeRaw === 'best_of' && numberOfGames === 2) {
                            matchTypeFinal = 'best_of_2';
                        } else if (matchTypeRaw === 'all_games_played') {
                            matchTypeFinal = 'all_games_played';
                        } else if (matchTypeRaw === 'first_to') {
                            matchTypeFinal = 'first_to_X';
                        } else {
                            matchTypeFinal = 'general_drop_to';
                        }
                        const matchType = matchTypeRaw.charAt(0).toUpperCase() + matchTypeRaw.slice(1).replace('_', ' ');
                        const matchTypefull = `${matchType} ${data.number_of_games.toString()}`;
                        const score = `${data.results[0].score.toString()} - ${data.results[1].score.toString()}`;
                        const date = `${dateRaw.toLocaleDateString(userLanguage, {
                            weekday: 'short', // "short" for abbreviated weekday name (e.g., Mon)
                            day: 'numeric',   // numeric day of the month
                            month: 'short'    // "short" for abbreviated month name (e.g., Feb)
                        })} ${dateRaw.toLocaleTimeString(userLanguage, {
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: false
                        })}`;
                        let countdown = '';


                        countdown = `${countdownElementId}`;

                        let teamData1Html = ``
                        let teamData2Html = ``
                        let result1Circles = [];
                        let result2Circles = [];
                        if (data.opponents[0].type === 'Team') {
                            console.debug('I am running the team data function');
                            // Fetch team data here, inside the callback function getRostersData(tournamentId, team1Id, team2Id)
                            await PostMatchesComponent.getRostersData(tournamentId.toString(), team1id, team2id).then((response) => {

                                const teamData1 = response.rosterId1;
                                const teamData2 = response.rosterId2;
                                console.debug('teamData1', teamData1);
                                console.debug('teamData2', teamData2);

                                const rolesOrder = ['top', 'jun', 'mid', 'adc', 'sup'];
                                const rolesOrderDota = ['carry/mid', 'carry', 'mid', 'offlane', 'support'];

                                function sortPlayersByRole(players) {
                                    return players.sort((a, b) => {
                                        const roleA = a.role ? rolesOrder.indexOf(a.role.toLowerCase()) : rolesOrder.length;
                                        const roleB = b.role ? rolesOrder.indexOf(b.role.toLowerCase()) : rolesOrder.length;
                                        return roleA - roleB;
                                    });
                                }
                                function sortPlayersByDotaRole(players) {
                                    return players.sort((a, b) => {
                                        const roleA = a.role ? rolesOrderDota.indexOf(a.role.toLowerCase()) : rolesOrderDota.length;
                                        const roleB = b.role ? rolesOrderDota.indexOf(b.role.toLowerCase()) : rolesOrderDota.length;
                                        const indexA = roleA === -1 ? rolesOrderDota.length : roleA;
                                        const indexB = roleB === -1 ? rolesOrderDota.length : roleB;
                                        return indexA - indexB;
                                    });
                                }
                                if (currentGameId === 1) {
                                    teamData1.players = sortPlayersByRole(teamData1.players);
                                    teamData2.players = sortPlayersByRole(teamData2.players);
                                }
                                if (currentGameId === 4) {
                                    teamData1.players = sortPlayersByDotaRole(teamData1.players);
                                    teamData2.players = sortPlayersByDotaRole(teamData2.players);
                                }

                                let team1Count = 0;
                                for (let i = 0; i < 19; i++) { // setting this up to grab as many players as possible
                                    if (teamData1.players[i]) {
                                        console.debug(teamData1.players[i])
                                        let countryCode = teamData1.players[i].nationality ? teamData1.players[i].nationality.toLowerCase() : false;
                                        teamData1Html += /*html*/`
                                <div class="card mb-3 border-0 color-dark ">   
                                <div class="row g-0 color-dark rounded  d-flex justify-content-start">
                                    <div class="col-auto d-flex justify-content-center p-0">
                                        <div class=" img-circle-container-player d-flex justify-content-center flex-column  m-2 color-dark rounded ">
                                            <img src="${teamData1.players[i].image_url ? teamData1.players[i].image_url : noPlayerImg}" alt="" class="img-fluid mx-auto d-block" >
                                        </div>
                                        </div>
                                    <div class="col-auto d-flex justify-content-center ">
                                        <div class="card-body d-flex justify-content-center p-0 m-0 flex-column mt-1 text-start ">
                                         <span class=" text-white card-title player-name-resize d-flex p-0">${teamData1.players[i].name}  <i class="ms-2 flag-icon flag-icon-${countryCode ? countryCode : ''}"></i></span>
                                           <span class="text-resize card-text p-0">
                                                ${teamData1.players[i].role ? `<span class="position-label text-resize-info">Position:</span>
                                                <span class="position-value text-resize-pos-info">${teamData1.players[i].role}</span>` : ''
                                            } 
                                                <span class="age-label ms-2 text-resize-info">Age:</span>
                                                <span class="age-value text-resize-pos-info">${teamData1.players[i].age ? teamData1.players[i].age : 'N/A'}</span>
                                            </span>                                               
                                         </div>
                                         </div>
                                         </div>
                                         </div>
                                    
                                `;
                                        team1Count++;
                                    }
                                    while (team1Count < 5 && teamData1.players[i] === undefined && currentGameId !== 22) {
                                        teamData1Html += `
                            <div class="card mb-3 border-0 color-dark " >
                                <div class="row g-0 color-dark rounded d-flex justify-content-start">
                                    <div class="col-auto d-flex justify-content-center p-0">
                                        <div class=" img-circle-container-player d-flex justify-content-center flex-column m-2 color-dark rounded ">
                                            <img src="${noPlayerImg}" alt="" class="img-fluid mx-auto d-block" >
                                        </div>
                                        </div>
                                    <div class="col-auto d-flex justify-content-center">
                                        <div class="card-body d-flex justify-content-center p-0 m-0 flex-column mt-1 text-start">
                                         <span class=" text-white card-title d-flex p-0">N/A</span>   
                                         </div>
                                         </div>
                                         </div>
                                         </div>
                            `;
                                        team1Count++;
                                    }
                                }



                                let team2Count = 0;
                                for (let i = 0; i < 19; i++) { // setting this up to grab as many players as possible
                                    if (teamData2.players[i]) {
                                        let countryCode = teamData2.players[i].nationality ? teamData2.players[i].nationality.toLowerCase() : false;
                                        teamData2Html += /*html*/`
                                <div class="card mb-3 border-0 color-dark">   
                                <div class="row g-0 color-dark rounded d-flex justify-content-end  ">
                                   
                                    <div class="col-auto  d-flex justify-content-center">
                                        <div class="card-body d-flex justify-content-center align-items-end p-0 m-0 flex-column mt-1 text-end">
                                         <span class=" text-white d-flex card-title player-name-resize  p-0"> <i class="me-2 flag-icon flag-icon-${countryCode ? countryCode : ''}"></i> ${teamData2.players[i].name} </span>
                                                <span class="text-resize card-text p-0">
                                                    ${teamData2.players[i].role ? `<span class="position-label text-resize-info">Position:</span>
                                                    <span class="position-value text-resize-pos-info">${teamData2.players[i].role}</span>` : ''
                                            } 
                                                    <span class="age-label ms-2 text-resize-info">Age:</span>
                                                    <span class="age-value text-resize-pos-info">${teamData2.players[i].age ? teamData2.players[i].age : 'N/A'}</span>
                                                </span>                                         
                                         </div>
                                         </div>
                                          <div class="col-auto d-flex justify-content-center p-0">
                                        <div class=" img-circle-container-player d-flex flex-column justify-content-center m-2 color-dark rounded ">
                                            <img src="${teamData2.players[i].image_url ? teamData2.players[i].image_url : noPlayerImg}" alt="" class="img-fluid mx-auto d-block" >
                                        </div>
                                        </div>
                                         </div>
                                         </div>`;
                                        team2Count++;
                                    }
                                    while (team2Count < 5 && teamData2.players[i] === undefined && currentGameId !== 22) {
                                        teamData2Html += `
                            <div class="card mb-3 border-0 color-dark " >
                                <div class="row g-0 color-dark rounded d-flex justify-content-end">
                                    <div class="col-auto d-flex justify-content-center">
                                        <div class="card-body d-flex justify-content-center align-items-end p-0 m-0 flex-column mt-1 text-end">
                                         <span class=" text-white card-title d-flex p-0">N/A</span>
                                         </div>
                                        </div>
                                         <div class="col-auto d-flex justify-conent-center p-0">
                                        <div class=" img-circle-container-player d-flex justify-content-center flex-column m-2 color-dark rounded ">
                                            <img src="${noPlayerImg}" alt="" class="img-fluid mx-auto d-block" >
                                        </div>
                                         </div>
                                         </div>
                                         </div>
                            `;
                                        team2Count++;
                                    }
                                }

                            })
                        }


                        let h2hMatchesforTeam1 = `<div class="tab-pane fade show active" id="pills-h2h-${team1idF}" role="tabpanel" aria-labelledby="pills-h2h-${team1idF}-tab">`;
                        let pastMatchesforTeam1 = `<div class="tab-pane fade show" id="pills-gen-${team1idF}" role="tabpanel" aria-labelledby="pills-${team1idF}-tab">`;
                        let pastMatchesforTeam2 = `<div class="tab-pane fade show" id="pills-gen-${team2idF}" role="tabpanel" aria-labelledby="pills-${team2idF}-tab">`;
                        let pastPills1 = /*html*/ `
                         <li class="nav-item" role="presentation">
                            <button class="nav-link" id="generalpast1-pills-tab" data-bs-toggle="pill" data-bs-target="#pills-gen-${team1idF}" type="button" role="tab" aria-controls="pills-gen-${team1idF}" aria-selected="false">${team1name}</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active h2hpillClass" id="generalpast1-pills-tab" data-bs-toggle="pill" data-bs-target="#pills-h2h-${team1idF}" type="button" role="tab" aria-controls="pills-h2h-${team1idF}" aria-selected="false">Head to head</button>
                        </li>                      
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="generalpast2-pills-tab" data-bs-toggle="pill" data-bs-target="#pills-gen-${team2idF}" type="button" role="tab" aria-controls="pills-gen-${team2idF}" aria-selected="false">${team2name}</button>
                        </li>
                                `;

                        if (window.savedPastMatches[team1idF][0] !== '') {
                            for (let i = 0; i < window.savedPastMatches[team1idF].length; i++) {
                                const pastMatchData = window.savedPastMatches[team1idF][i];

                                let matchId = pastMatchData.matchId;
                                const matchPastId = `m${team1idF}-pastMatchFromPostmatch-${pastMatchData.id}`;
                                console.debug('matchPastId', matchPastId);
                                matchIdsArrayForListener.push(matchPastId);

                                const team1name = pastMatchData.opponents[0].opponent.name;
                                const team1id = pastMatchData.opponents[0].opponent.id;
                                const team2name = pastMatchData.opponents[1].opponent.name;
                                const team2id = pastMatchData.opponents[1].opponent.id;
                                let team1img, team2img;
                                if (pastMatchData.opponents[1].type === 'Player') {
                                    team1img = `<img src="${pastMatchData.opponents[0].opponent.image_url ? pastMatchData.opponents[0].opponent.image_url : noPlayerImg}" onerror="this.onerror=null; this.src='${noPlayerImg}';" alt="" class="img-fluid mx-auto d-block" >`
                                    team2img = `<img src="${pastMatchData.opponents[1].opponent.image_url ? pastMatchData.opponents[1].opponent.image_url : noPlayerImg}" onerror="this.onerror=null; this.src='${noPlayerImg}';" alt="" class="img-fluid mx-auto d-block" >`
                                } else if (pastMatchData.opponents[1].type === 'Team') {
                                    const team1imginitial = pastMatchData.opponents[0].opponent.image_url ? pastMatchData.opponents[0].opponent.image_url : noImageFound
                                    const team2imginitial = pastMatchData.opponents[1].opponent.image_url ? pastMatchData.opponents[1].opponent.image_url : noImageFound
                                    team1img = `<img src="${team1imginitial}" alt="" class="img-fluid mx-auto d-block" >`
                                    team2img = `<img src="${team2imginitial}" alt="" class="img-fluid mx-auto d-block" >`
                                }

                                const tournamentName = `${pastMatchData.league.name} ${pastMatchData.serie.full_name}`;
                                const tournamentLogo = pastMatchData.league.image_url;
                                const tournamentLogoHTML = tournamentLogo ? `<img src="${tournamentLogo}" alt="tournament logo" class="img-fluid mx-auto d-block " >` : '';
                                const matchTypeRaw = pastMatchData.match_type;
                                const matchType = matchTypeRaw.charAt(0).toUpperCase() + matchTypeRaw.slice(1).replace('_', ' ');
                                const matchTypefull = `${matchType} ${pastMatchData.number_of_games.toString()}`;

                                const score = `${pastMatchData.results[0].score.toString()} - ${pastMatchData.results[1].score.toString()}`;
                                const winnerId = pastMatchData.winner && pastMatchData.winner.id ? pastMatchData.winner.id : pastMatchData.winner_id;
                                if (winnerId) {
                                    if (winnerId === team1idF) {
                                        if (result1Circles.length < 5) {
                                            result1Circles.unshift('<span class="badge bg-success rounded-circle fixed-size-badge-wld">W</span>');
                                        }
                                    } else {
                                        if (result1Circles.length < 5) {
                                            result1Circles.unshift('<span class="badge bg-danger rounded-circle fixed-size-badge-wld">L</span>');
                                        }
                                    }
                                } else {
                                    if (result1Circles.length < 5) {
                                        result1Circles.unshift('<span class="badge bg-warning rounded-circle fixed-size-badge-wld">D</span>');
                                    }
                                }
                                const dateRaw = new Date(pastMatchData.begin_at ? pastMatchData.begin_at : pastMatchData.scheduled_at);
                                const date = `${dateRaw.toLocaleDateString(userLanguage, {
                                    weekday: 'short', // "short" for abbreviated weekday name (e.g., Mon)
                                    day: 'numeric',   // numeric day of the month
                                    month: 'short',   // "short" for abbreviated month name (e.g., Feb)
                                    year: 'numeric'   // numeric year
                                })} ${dateRaw.toLocaleTimeString(userLanguage, {
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    hour12: false
                                })}`;

                                pastMatchesforTeam1 += /*html*/ `
                                            <div id="${matchPastId}" class="row  mt-3 rounded text-white color-lightdark p-0 clickable">
                                            <div class="col-12 p-0 d-flex justify-content-between rounded-top mb-2 color-dark">
                                            <div class="text-start d-flex p-1 " id="">
                                            <span class="m-0 mx-2 d-flex align-items-center text-resize-tournament">${tournamentName}</span>
                                            <div class="  img-circle-container-tournament-logo d-flex align-items-center">
                                            ${tournamentLogoHTML}
                                            </div>
                                            </div>
                                            <div class="text-end text-white text-resize-date mt-1 me-1 d-flex justify-content-center align-content-center align-items-center">
                                            <i class="bi bi-clock"></i><span class="ms-1"> ${date}</span>
                                            </div>
                                            </div>
                                            
                                            <div class="col-5 mt-2 d-flex justify-content-end align-items-center ${winnerId === team1id && winnerId === team1idF ? 'winner-left' : (winnerId === team2id && team1id === team1idF ? 'loser-left' : '')}" id="pastMatch-${matchId}-team-${team1id}">
                                            <span class="team-name-resize mx-2 my-0 ">${team1name}</span>
                                                <div class=" img-circle-container-team-img d-flex flex-column justify-content-center  m-2 ">
                                                    ${team1img}
                                                </div>
                                            </div>
                                            <div class="col-2 mt-2 gay-bg d-flex flex-column align-items-center justify-content-around">
                                            <p class="score-resize m-0">${score}</p>
                                            </div>
                                            <div class="col-5 mt-2 d-flex justify-content-start align-items-center ${winnerId === team2id && winnerId === team1idF ? 'winner-right' : (winnerId === team1id && team2id === team1idF ? 'loser-right' : '')}" id="pastMatch-${matchId}-team-${team2id}">
                                                <div class=" img-circle-container-team-img flex-column d-flex justify-content-center m-2 ">
                                                    ${team2img}
                                                </div>
                                            <span class="team-name-resize mx-2 my-0 ">${team2name}</span>
                                            </div>
                                        <div class="row mt-4"> </div>
                                        </div>
                                    `
                            }
                        }
                        if (window.savedPastMatches[team2idF][0] !== '') {
                            for (let i = 0; i < window.savedPastMatches[team2idF].length; i++) {
                                const pastMatchData = window.savedPastMatches[team2idF][i];

                                let matchId = pastMatchData.matchId;
                                const matchPastId = `m${team2idF}-pastMatchFromPostmatch-${pastMatchData.id}`;
                                matchIdsArrayForListener.push(matchPastId);

                                const team1name = pastMatchData.opponents[0].opponent.name;
                                const team1id = pastMatchData.opponents[0].opponent.id;
                                const team2name = pastMatchData.opponents[1].opponent.name;
                                const team2id = pastMatchData.opponents[1].opponent.id;
                                let team1img, team2img;
                                if (pastMatchData.opponents[1].type === 'Player') {
                                    team1img = `<img src="${pastMatchData.opponents[0].opponent.image_url ? pastMatchData.opponents[0].opponent.image_url : noPlayerImg}" onerror="this.onerror=null; this.src='${noPlayerImg}';" alt="" class="img-fluid mx-auto d-block" >`
                                    team2img = `<img src="${pastMatchData.opponents[1].opponent.image_url ? pastMatchData.opponents[1].opponent.image_url : noPlayerImg}" onerror="this.onerror=null; this.src='${noPlayerImg}';" alt="" class="img-fluid mx-auto d-block" >`
                                } else if (pastMatchData.opponents[1].type === 'Team') {
                                    const team1imginitial = pastMatchData.opponents[0].opponent.image_url ? pastMatchData.opponents[0].opponent.image_url : noImageFound
                                    const team2imginitial = pastMatchData.opponents[1].opponent.image_url ? pastMatchData.opponents[1].opponent.image_url : noImageFound
                                    team1img = `<img src="${team1imginitial}" alt="" class="img-fluid mx-auto d-block" >`
                                    team2img = `<img src="${team2imginitial}" alt="" class="img-fluid mx-auto d-block" >`
                                }

                                const tournamentName = `${pastMatchData.league.name} ${pastMatchData.serie.full_name}`;
                                const tournamentLogo = pastMatchData.league.image_url;
                                const tournamentLogoHTML = tournamentLogo ? `<img src="${tournamentLogo}" alt="tournament logo" class="img-fluid mx-auto d-block" >` : '';

                                const matchTypeRaw = pastMatchData.match_type;
                                const matchType = matchTypeRaw.charAt(0).toUpperCase() + matchTypeRaw.slice(1).replace('_', ' ');
                                const matchTypefull = `${matchType} ${pastMatchData.number_of_games.toString()}`;

                                const score = `${pastMatchData.results[0].score.toString()} - ${pastMatchData.results[1].score.toString()}`;
                                const winnerId = pastMatchData.winner && pastMatchData.winner.id ? pastMatchData.winner.id : pastMatchData.winner_id;
                                if (winnerId) {
                                    if (winnerId === team2idF) {
                                        if (result2Circles.length < 5) {
                                            result2Circles.push('<span class="badge bg-success rounded-circle fixed-size-badge-wld">W</span>');
                                        }
                                    } else {
                                        if (result2Circles.length < 5) {
                                            result2Circles.push('<span class="badge bg-danger rounded-circle fixed-size-badge-wld">L</span>');
                                        }
                                    }
                                } else {
                                    if (result2Circles.length < 5) {
                                        result2Circles.push('<span class="badge bg-warning rounded-circle fixed-size-badge-wld">D</span>');
                                    }
                                }
                                const dateRaw = new Date(pastMatchData.begin_at ? pastMatchData.begin_at : pastMatchData.scheduled_at);
                                const date = `${dateRaw.toLocaleDateString(userLanguage, {
                                    weekday: 'short', // "short" for abbreviated weekday name (e.g., Mon)
                                    day: 'numeric',   // numeric day of the month
                                    month: 'short',   // "short" for abbreviated month name (e.g., Feb)
                                    year: 'numeric'   // numeric year
                                })} ${dateRaw.toLocaleTimeString(userLanguage, {
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    hour12: false
                                })}`;

                                pastMatchesforTeam2 += /*html*/ `
                                            
                                            <div id="${matchPastId}" class="row  mt-3 rounded text-white color-lightdark p-0 clickable">
                                            <div class="col-12 p-0 d-flex justify-content-between rounded-top color-dark mb-2 ">
                                            <div class="text-start d-flex p-1"  id="">
                                            <span class="m-0 mx-2 d-flex align-items-center text-resize-tournament">${tournamentName}</span>
                                            <div class="  img-circle-container-tournament-logo d-flex align-items-center">
                                            ${tournamentLogoHTML}
                                            </div>
                                            </div>
                                            <div class="text-end text-white text-resize-date mt-1 me-1 d-flex justify-content-center align-items-center align-content-center">
                                            <i class="bi bi-clock"></i><span class="ms-1"> ${date}</span>
                                            </div>
                                            </div>
                                            <div class="col-5 mt-2 d-flex justify-content-end align-items-center ${winnerId === team1id && winnerId === team2idF ? 'winner-left' : (winnerId === team2id && team1id === team2idF ? 'loser-left' : '')}" id="pastMatch-${matchId}-team-${team1id}">
                                            <span class="team-name-resize mx-2 my-0 ">${team1name}</span>
                                                <div class=" img-circle-container-team-img flex-column d-flex justify-content-center  m-2 ">
                                                     ${team1img}
                                                </div>
                                            </div>
                                            <div class="col-2 mt-2 d-flex flex-column align-items-center justify-content-around">
                                            <p class="score-resize m-0">${score}</p>
                                            </div>
                                            <div class="col-5 mt-2 d-flex justify-content-start align-items-center ${winnerId === team2id && winnerId === team2idF ? 'winner-right' : (winnerId === team1id && team2id === team2idF ? 'loser-right' : '')}" id="pastMatch-${matchId}-team-${team2id}">
                                                <div class=" img-circle-container-team-img flex-column d-flex justify-content-center m-2 ">
                                                    ${team2img} 
                                                </div>
                                            <span class="team-name-resize mx-2 my-0 ">${team2name}</span>
                                            </div>
                                             <div class="row mt-4"> </div>
                                        </div>

                                    `
                            }
                        }
                        if (window.savedPastMatches[team1idF + '_' + team2idF] && window.savedPastMatches[team1idF + '_' + team2idF][0] !== '') {
                            for (let i = 0; i < window.savedPastMatches[team1idF + '_' + team2idF].length; i++) {
                                const pastMatchData = window.savedPastMatches[team1idF + '_' + team2idF][i];

                                let matchId = pastMatchData.matchId;
                                const matchPastId = `m${team1idF + '_' + team2idF}-pastMatchFromPostmatch-${pastMatchData.id}`;
                                matchIdsArrayForListener.push(matchPastId);

                                const team1name = pastMatchData.opponents[0].opponent.name;
                                const team1id = pastMatchData.opponents[0].opponent.id;
                                const team2name = pastMatchData.opponents[1].opponent.name;
                                const team2id = pastMatchData.opponents[1].opponent.id;
                                let team1img, team2img;
                                if (pastMatchData.opponents[1].type === 'Player') {
                                    team1img = `<img src="${pastMatchData.opponents[0].opponent.image_url ? pastMatchData.opponents[0].opponent.image_url : noPlayerImg}" onerror="this.onerror=null; this.src='${noPlayerImg}';" alt="" class="img-fluid mx-auto d-block" >`
                                    team2img = `<img src="${pastMatchData.opponents[1].opponent.image_url ? pastMatchData.opponents[1].opponent.image_url : noPlayerImg}" onerror="this.onerror=null; this.src='${noPlayerImg}';" alt="" class="img-fluid mx-auto d-block" >`
                                } else if (pastMatchData.opponents[1].type === 'Team') {
                                    const team1imginitial = pastMatchData.opponents[0].opponent.image_url ? pastMatchData.opponents[0].opponent.image_url : noImageFound
                                    const team2imginitial = pastMatchData.opponents[1].opponent.image_url ? pastMatchData.opponents[1].opponent.image_url : noImageFound
                                    team1img = `<img src="${team1imginitial}" alt="" class="img-fluid mx-auto d-block" >`
                                    team2img = `<img src="${team2imginitial}" alt="" class="img-fluid mx-auto d-block" >`
                                }
                                const tournamentName = `${pastMatchData.league.name} ${pastMatchData.serie.full_name}`;
                                const tournamentLogo = pastMatchData.league.image_url;
                                const tournamentLogoHTML = tournamentLogo ? `<img src="${tournamentLogo}" alt="tournament logo" class="img-fluid mx-auto d-block " >` : '';

                                const matchTypeRaw = pastMatchData.match_type;
                                const matchType = matchTypeRaw.charAt(0).toUpperCase() + matchTypeRaw.slice(1).replace('_', ' ');
                                const matchTypefull = `${matchType} ${pastMatchData.number_of_games.toString()}`;

                                const score = `${pastMatchData.results[0].score.toString()} - ${pastMatchData.results[1].score.toString()}`;
                                const winnerId = pastMatchData.winner && pastMatchData.winner.id ? pastMatchData.winner.id : pastMatchData.winner_id;

                                const dateRaw = new Date(pastMatchData.begin_at ? pastMatchData.begin_at : pastMatchData.scheduled_at);
                                const date = `${dateRaw.toLocaleDateString(userLanguage, {
                                    weekday: 'short', // "short" for abbreviated weekday name (e.g., Mon)
                                    day: 'numeric',   // numeric day of the month
                                    month: 'short',   // "short" for abbreviated month name (e.g., Feb)
                                    year: 'numeric'   // numeric year
                                })} ${dateRaw.toLocaleTimeString(userLanguage, {
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    hour12: false
                                })}`;

                                h2hMatchesforTeam1 += /*html*/ `
                                            
                                            <div id="${matchPastId}" class="row  mt-3 rounded text-white color-lightdark p-0 clickable">
                                            <div class="col-12 p-0 d-flex justify-content-between rounded-top color-dark mb-2 ">
                                            <div class="text-start d-flex p-1" id="">
                                            <span class="m-0 mx-2 d-flex align-items-center text-resize-tournament">${tournamentName}</span>
                                              <div class="  img-circle-container-tournament-logo d-flex align-items-center">
                                            ${tournamentLogoHTML}
                                            </div>
                                            </div>
                                            <div class="text-end text-white text-resize-date mt-1 me-1 d-flex justify-content-center align-content-center align-items-center">
                                            <i class="bi bi-clock"></i><span class="ms-1"> ${date}</span>
                                            </div>
                                            </div>
                                            <div class="col-5 mt-2 d-flex justify-content-end align-items-center ${winnerId === team1id ? 'winner-left' : ''}" id="pastMatch-${matchId}-team-${team1id}">
                                            <span class="team-name-resize mx-2 my-0 ">${team1name}</span>
                                                <div class=" img-circle-container-team-img flex-column d-flex justify-content-center  m-2 ">
                                                     ${team1img}
                                                </div>
                                            </div>
                                            <div class="col-2 mt-2  d-flex flex-column align-items-center justify-content-around">
                                            <p class="score-resize m-0">${score}</p>
                                            </div>
                                            <div class="col-5 mt-2 d-flex justify-content-start align-items-center ${winnerId === team2id ? 'winner-right' : ''}" id="pastMatch-${matchId}-team-${team2id}">
                                                <div class=" img-circle-container-team-img flex-column d-flex justify-content-center  m-2 ">
                                                    ${team2img} 
                                                </div>
                                            <span class="team-name-resize mx-2 my-0 ">${team2name}</span>
                                            </div>
                                             <div class="row mt-4"> </div>
                                        </div>

                                    `
                            }
                        }
                        pastMatchesforTeam1 += `</div>`;
                        pastMatchesforTeam2 += `</div>`;
                        h2hMatchesforTeam1 += `</div>`;


                        const element = document.querySelector('#chatforvideo');
                        if (gameStatus === 'finished') {
                            endDif = PostMatchesComponent.postGlobalDate.getTime() - endDateRaw.getTime(); // calculate the difference between the current time and the end time of the match
                        }
                        if (element && element.innerHTML.trim() === '' && gameStatus !== 'finished' && startDif >= - 20 * 60 * 1000) {
                            element.insertAdjacentHTML('beforeend', chats[0]);
                            document.getElementById('placeholder-chat').style.display = "none";
                        } else if (element && element.innerHTML.trim() === '' && endDif !== null && endDif <= 20 * 60 * 1000) {
                            element.insertAdjacentHTML('beforeend', chats[0]);
                            document.getElementById('placeholder-chat').style.display = "none";
                        } else if (element && element.innerHTML.trim() === '' && gameStatus === 'running') {
                            element.insertAdjacentHTML('beforeend', chats[0]);
                            document.getElementById('placeholder-chat').style.display = "none";
                        } else {
                            document.getElementById('placeholder-chat').style.display = "none";
                        }

                        while (result1Circles.length < 5) {
                            result1Circles.unshift('<span class="badge bg-secondary rounded-circle fixed-size-badge-wld">-</span>');
                        }

                        while (result2Circles.length < 5) {
                            result2Circles.push('<span class="badge bg-secondary rounded-circle fixed-size-badge-wld">-</span>');
                        }
                        const resultCircles1HTML = result1Circles.join(' ');
                        const resultCircles2HTML = result2Circles.join(' ');
                        const matchHtml = /*html*/ `
                            <div class="row  mb-3 rounded thePlayerContainer">
                                ${(() => {
                                if (gameStatus === 'finished') {
                                    endDif = PostMatchesComponent.postGlobalDate.getTime() - endDateRaw.getTime(); // calculate the difference between the current time and the end time of the match
                                }
                                if (gameStatus !== 'finished' && startDif >= - 20 * 60 * 1000) { // if the start difference is less than 20 minutes, show the video player
                                    return `${videoPlayers[0]}
                                        <ul class="nav nav-pills style-nav-pills ">
                                            ${navPills}
                                        </ul>
                                    </div>`;
                                } else if (endDif !== null && endDif <= 20 * 60 * 1000) { // if the end difference is less than 20 minutes, show the video player
                                    return `${videoPlayers[0]}
                                        <ul class="nav nav-pills style-nav-pills ">
                                            ${navPills}
                                        </ul>
                                    </div>`;
                                } else if (gameStatus === 'running') { // if the game is running, show the video player
                                    return `${videoPlayers[0]}
                                        <ul class="nav nav-pills style-nav-pills ">
                                            ${navPills}
                                        </ul>
                                    </div>`;
                                } else { // if none of the above, don't show the video player
                                    console.debug('the match is not live, so no video player')
                                    return '';
                                }
                            })()
                            }   
                            </div>

            <div id="postmatch-${matchElementId}" class="row  mt-3 rounded text-white color-lightdark " >
                
                <div class="col-12 p-0 d-flex justify-content-between rounded-top color-dark">
                <div class="text-start d-flex p-1 clickable d-flex justify-content-center align-content-center align-items-center " id="${serieIdElement}"> 
                <div class="img-circle-container-game-logo d-flex align-items-center">
                ${gameLogoHTML} 
                </div>
                <span class="m-0 mx-2 d-flex align-items-center text-resize-tournament">${tournamentName}</span>
                <div class="  img-circle-container-tournament-logo d-flex align-items-center">
                ${tournamentLogoHTML}
                </div>
                </div>
                <div class="text-end text-white text-resize-date d-flex justify-content-center align-items-center align-content-center">
                <i class="me-1 bi bi-clock"></i><span class="ms-1 me-2"> ${date}</span>
                </div>

                </div>
  
   
                <div class="col-5 mt-2 d-flex  justify-content-end align-items-center ${winnerId === team1id ? 'winner-left' : ''}" id="team-${team1id}">
                <span class="team-name-resize mx-2 my-0">${team1name}</span>
                <div class=" img-circle-container-team-img-main flex-column d-flex justify-content-center  m-2 ">
                <img src="${team1img}" alt="" class="img-fluid mx-auto d-block " >
                </div>
                </div>
    
                <div class="col-2 mt-2 gay-bg d-flex  flex-column align-items-center justify-content-around">
                <p class="score-resize m-0" id="${idOfScore}">${score}</p>
                
                </div>
    
                <div class="col-5 mt-2 d-flex justify-content-start align-items-center  ${winnerId === team2id ? 'winner-right' : ''}" id="team-${team2id}">
                 <div class=" img-circle-container-team-img-main flex-column d-flex justify-content-center  m-2 ">
                <img src="${team2img}" alt="" class="img-fluid mx-auto d-block " >
                 </div>
                <span class="team-name-resize mx-2 my-0 ">${team2name}</span>
                </div>

                    <div class="col-5 mt-2 d-flex justify-content-end align-items-center">
                       ${resultCircles1HTML}
                    </div>
                    <div class="col-2 mt-2"></div>
                    <div class="col-5 mt-2 d-flex justify-content-start align-items-center ">
                        ${resultCircles2HTML}
                    </div>
                
                    <div class="col-4 p-0 m-0 mt-2">
                    </div>
                    <div class="col-4 text-center  p-0 m-0 mt-2">
                     <span class="my-2 badge bg-secondary text-resize-badge matchTypeFromPostMatch">${matchTypeFinal}</span>
                    </div>
                    <div class="col-4 text-end  p-0 m-0 pe-2 mt-2">
                    <div id="${countdown}"> </div>
                    </div>
                 
                
                </div>
                </div>

                </div>
            
                        <div class="row my-3 p-0 rounded">
                            <div class="col-6 ">
                            ${teamData1Html}
                            </div>
            
                          
            
                            <div class="col-6 ">
                            ${teamData2Html}
                            </div>
                            </div>
                                <div class="accordion accordion-flush my-3 row ${data.status === 'finished' ? 'd-none' : ''}" id="accordionFlushExample">                              <div class="accordion-item ">
                                <div class="accordion-header" id="flush-headingOne">
                                  <button class="accordion-button collapsed rounded-top color-lightdark clickable" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                    
                                    
                                    <div class="col-auto mb-3">
                                   <div class=" img-circle-container-ai d-flex justify-content-center flex-column">
                                            <img src="${aiAvatar}" alt="" class="img-fluid mx-auto d-block" style="border-radius: 25px">
                                        </div>
                                    </div>
                                     <div class="col-8 col-md-9 col-lg-10 d-inline-flex flex-column justify-content-center align-content-start ms-3">
                                     <span id="mainAiTitle" class="text-name-ai-resize text-white">${I18n.translateString("postMatchTranslations.whatDoesAiThink")}</span>
                                      <span id="clickBaitSpan" class="text-ai-resize mt-3 text-truncate-3 text-white"><em>"${clickBaitString}</em></span>
                                    </div>
                                     <span class="my-2 me-2 badge bg-success text-resize-badge text-ai">${I18n.translateString("postMatchTranslations.aiAnalysis")}</span>
                                    
                                  </button>
                                </div>
                                <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                  <div class="accordion-body color-dark rounded-bottom">
                                   <div id="analysisFullBodySpan"  class="">${htmlAnalysisContent}</div>
                                   </div>
                                </div>
                              </div>
 
                              </div>
                           
                        
                        <div class="row my-3 p-0 rounded">
                        <h3 class="matchHistory" style="justify-content: center; text-align: center;"></h3>
                        <ul class="nav nav-pills mb-1 text-resize-match-history" id="past1-pills-tab" role="tablist">
                            ${pastPills1}
                            </ul>
                        <div class="col-12">  
                            <div class="tab-content" id="past1-pills-tabContent">
                            ${h2hMatchesforTeam1}
                            ${pastMatchesforTeam1}
                            ${pastMatchesforTeam2}                         
                            </div>
                            </div>
                        `;
                        document.querySelector('#matchpost-html').insertAdjacentHTML('beforeend', matchHtml);
                        console.log(analysisString.length)
                        const myCollapsible = document.getElementById('flush-collapseOne')
                        myCollapsible.addEventListener('hidden.bs.collapse', event => {
                            document.querySelector('#clickBaitSpan').style.display = ''
                        })
                        myCollapsible.addEventListener('show.bs.collapse', event => {
                            document.querySelector('#clickBaitSpan').style.display = 'none'
                        })
                        if (analysisString.length < 200) {
                            document.querySelector('#flush-collapseOne').remove()
                            document.querySelector('#clickBaitSpan').style.display = 'none';
                            document.querySelector('#mainAiTitle').innerText = analysisString
                        }
                        document.getElementById('placeholder-stream').style.display = "none"; //VGAZW TO PLACEHOLDER
                        const matchTypeElement = document.querySelector(`.matchTypeFromPostMatch`);
                        if (matchTypeElement.innerHTML === 'all_games_played') {
                            matchTypeElement.innerHTML = I18n.translateString("matchTranslations.allGamesPlayed", { gamesNumber: numberOfGames });
                        } else if (matchTypeElement.innerHTML === 'best_of_X') {
                            let gamesNumber;
                            if (window.translateTo === 'el') {
                                gamesNumber = Math.floor(numberOfGames / 2) + 1;
                            } else {
                                gamesNumber = numberOfGames;
                            }
                            matchTypeElement.innerHTML = I18n.translateString("matchTranslations.bestofX", { gamesNumber: gamesNumber });
                        } else if (matchTypeElement.innerHTML === 'best_of_2') {
                            matchTypeElement.innerHTML = I18n.translateString("matchTranslations.bestof2");
                        } else if (matchTypeElement.innerHTML === 'first_to_X') {
                            matchTypeElement.innerHTML = I18n.translateString("matchTranslations.firstToX", { gamesNumber: numberOfGames });
                        } else if (matchTypeElement.innerHTML === 'single_game') {
                            matchTypeElement.innerHTML = I18n.translateString("matchTranslations.bestof1");
                        } else {
                            matchTypeElement.innerHTML = I18n.translateString("matchTranslations.generalDrop", { matchType: matchTypefull });
                        }
                        document.querySelectorAll('.fixed-size-badge-wld').forEach(function (el) {
                            if (el.innerHTML === 'W') {
                                el.innerHTML = I18n.translateString("postMatchTranslations.badgeWon");
                            } else if (el.innerHTML === 'L') {
                                el.innerHTML = I18n.translateString("postMatchTranslations.badgeLost");
                            } else if (el.innerHTML === 'D') {
                                el.innerHTML = I18n.translateString("postMatchTranslations.badgeDraw");
                            }
                        });
                        document.querySelectorAll('.age-label').forEach(el => el.innerHTML = I18n.translateString("postMatchTranslations.ageLabel"));
                        document.querySelectorAll('.position-label').forEach(el => el.innerHTML = I18n.translateString("postMatchTranslations.positionLabel"));
                        document.querySelectorAll('.matchHistory').forEach(el => el.innerHTML = I18n.translateString("postMatchTranslations.matchHistory"));
                        document.querySelectorAll('.h2hpillClass').forEach(el => el.innerHTML = I18n.translateString("postMatchTranslations.h2hPill"));
                        document.querySelectorAll('.noVideoMessage').forEach(el => el.innerHTML = I18n.translateString("postMatchTranslations.noVideoMessage"));
                        htmlTags.forEach((tag, index) => {
                            let pill = document.getElementById(tag);
                            if (pill) {
                                pill.addEventListener('click', () => {
                                    console.debug('Pill clicked:', pill.id);
                                    // Remove 'active' class from all pills
                                    htmlTags.forEach((tag) => {
                                        let p = document.getElementById(tag);
                                        if (p) p.classList.remove('active');
                                    });

                                    // Add 'active' class to the clicked pill
                                    pill.classList.add('active');

                                    // Replace the current video player with the one associated with the clicked pill
                                    let videoContainer = document.querySelector('#videosid iframe');
                                    if (videoContainer) {
                                        videoContainer.src = videoLinks[index]; // Use videoLinks instead of videoPlayers
                                    }

                                    // Replace the current chat with the one associated with the clicked pill
                                    let chatIframe = document.querySelector('#chats iframe');
                                    if (chatIframe) {
                                        chatIframe.src = chatLinks[index];
                                    }
                                });
                            }
                        });

                        let pillElements = document.querySelectorAll('#past1-pills-tab .nav-link');
                        for (let i = 0; i < pillElements.length; i++) {
                            new bootstrap.Tab(pillElements[i]);
                        }

                        const tabPanes = document.querySelectorAll('#past1-pills-tabContent .tab-pane');
                        for (let i = 0; i < tabPanes.length; i++) {
                            if (tabPanes[i].innerHTML.trim() === '') {
                                tabPanes[i].innerHTML = /*html*/ `
                                                                            <div class="row rounded text-white color-dark">
                                <span class="mt-4 mb-4 h2hNoRecentMsg" style="justify-content: center; text-align: center;"></span>
                                </div>
                                `;
                            }
                        }
                        document.querySelectorAll('.h2hNoRecentMsg').forEach(el => el.innerHTML = I18n.translateString("postMatchTranslations.h2hNoRecentMsg"));
                        PostMatchesComponent.redirectToMatchFromPastMatches(matchIdsArrayForListener);
                        PostMatchesComponent.redirectToSeriePage(serieIdElement);
                        PostMatchesComponent.startCountdown(dateRaw, countdownElementId, matchElementId, gameStatus);
                        // Fetch team data here, inside the callback function
                        PostMatchesComponent.resizetoTwitchHeight()
                        document.querySelectorAll('postmatches-component .card').forEach(el => el.addEventListener('click', PostMatchesComponent.showPlayerData))
                        window.tadeCallback = undefined;

                    }
                }
            } else {
                console.debug('The post matches component callback is supposed to run but we are not on the right page!!!!')
            }
            window.tadeCallback = undefined;
        })

    }

    static showPlayerData(evt) {
        // GamerShowcase.showGamer(evt.target.closest('.card').getAttribute('data-lookupid'))
        console.debug('I am supposedly opening the player modal here')
    }

    static async getTeamsData(team1idss, team2idss) {
        return new Promise(async (resolve, reject) => {
            FirebaseAssistant.callHelloWorldFunction({ team1: team1idss, team2: team2idss }, (result) => {
                console.debug(result)
                resolve(result)
            })
        })
    }

    static async getRostersData(tournamentId, team1Id, team2Id) {
        return new Promise(async (resolve, reject) => {
            FirebaseAssistant.callGetRostersFunctions({ tournamentId: tournamentId, rosterId1: team1Id, rosterId2: team2Id }, (result) => {
                console.debug(result)
                resolve(result)
            })
        })
    }

    static redirectToSeriePage(serieElementId) {

        const element = document.getElementById(serieElementId);
        const alteredSerieElementId = serieElementId.replace(/.*seriefrompostmatch-/, '');
        if (window.location.href.includes('s_match') && element && !element.listenerAdded) {
            element.addEventListener('click', function (evt) {
                evt.stopPropagation();
                RouterAssistant.redirect('s_tournament', {
                    tournament: alteredSerieElementId
                })
            })
            element.listenerAdded = true;
        }

    }

    static redirectToMatchFromPastMatches(matchIdsArrayForListener) {
        matchIdsArrayForListener.forEach(pastmatchId => {
            const element = document.getElementById(pastmatchId);
            const alteredpastmatchId = pastmatchId.replace(/.*pastMatchFromPostmatch-/, '');
            if (window.location.href.includes('s_match') && element && !element.listenerAdded) {
                element.addEventListener('click', function (evt) {
                    evt.stopPropagation();
                    RouterAssistant.redirect('s_match', {
                        match: alteredpastmatchId
                    })
                })
                element.listenerAdded = true;
            }
        });
    }

    static extractFirstTextBlock(markup) {
        try {
            // Split the string into lines
            const lines = markup.split('\n');

            // Initialize an array to hold the text block lines
            let textBlock = [];
            let blockStarted = false;

            // Iterate over each line
            for (let line of lines) {
                // Check if the line does not start with special characters and is not empty
                if (!line.startsWith('##') && !line.startsWith('#') && !line.startsWith('**') && !line.startsWith('* **') && !line.startsWith('*') && !line.startsWith('-') && line.trim() !== '') {
                    textBlock.push(line.trim());
                    blockStarted = true;
                } else if (blockStarted) {
                    // If the block has started and we encounter a line with special characters or an empty line, break the loop
                    break;
                }
            }
            const textToBeReturned = textBlock.join(' ').replace('<p>', "").replace('</p>', "");
            return (textToBeReturned.length === 0) ? "You won't believe it" : textToBeReturned;
        } catch (e) {
            console.log('ERROR')
            return ''
        }

    }

    static startCountdown(gameStartTime, elementId, matchElementId, gameStatus) {
        const now = new Date();
        const diffInMilliseconds = gameStartTime - now;
        const diffInMinutes = Math.round(diffInMilliseconds / 1000 / 60);

        const element = document.getElementById(elementId);
        const matchElement = document.getElementById(`postmatch-${matchElementId}`);

        if (element && diffInMinutes <= 60 && diffInMinutes > 0) {
            element.innerHTML = I18n.translateString("matchTranslations.matchMatchUpcoming", { minutes: diffInMinutes });
        } else if (element && (diffInMinutes <= 0 && gameStatus === 'running')) {
            element.innerHTML = I18n.translateString("matchTranslations.matchMatchLive");
        } else if (element && (diffInMinutes <= 0 && diffInMinutes >= -180 && gameStatus === 'not_started')) {
            element.innerHTML = I18n.translateString("matchTranslations.matchMatchAboutTo");
        } else if (element && (gameStatus === 'finished')) {
            element.innerHTML = I18n.translateString("matchTranslations.matchMatchFinished");
        } else if (element && (gameStatus === 'postponed')) {
            element.innerHTML = I18n.translateString("matchTranslations.matchMatchPostponed");
        } else if (element && (gameStatus === 'canceled')) {
            element.innerHTML = I18n.translateString("matchTranslations.matchMatchCanceled");
        }
    }

    static PromoVideo(fromHotmatch, promomatchid) {
        if (window.savedStreamers) {
            for (const [key, streamer] of Object.entries(window.savedStreamers)) {
                console.debug("here", key, streamer);

                if (streamer.promo && streamer.promo.check && streamer.promo.promoMatchId && streamer.promo.check === true && streamer.promo.promoMatchId === promomatchid && streamer.status === 'Online' && fromHotmatch === false) {
                    console.debug('Promo streamer found with corresponding matchid:', streamer);
                    return streamer.userLogin;
                } else if (streamer.promo && streamer.promo.check && streamer.promo.promoMatchId && streamer.promo.check === true && streamer.status === 'Online' && fromHotmatch === true) {
                    console.debug('Promo streamer found but for the hotmatches:', streamer);
                    return {
                        promoMatchId: streamer.promo.promoMatchId,
                        userLogin: streamer.userLogin,
                        promoViewers: streamer.viewerCount
                    };
                } else {
                    console.debug('Promo streamer not found:', streamer);
                }
            }
        } else {
            console.debug('window.savedStreamers is not defined');
        }
    }


}

PostMatchesComponent.template = /*html*/ `
<div class="twitch-container p-3 bg-dark text-white rounded placeholder-glow" id="placeholder-stream">
<div class="ratio ratio-16x9 placeholder">
</div>
  <div class="placeholder-glow">
    <span class="placeholder col-12"></span>
       </div>
</div>


<div id="matchpost-html"></div>
<gamer-showcase></gamer-showcase>
`;

PostMatchesComponent.reg('postmatches-component');
