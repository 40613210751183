import { BaseComponent } from "@symbiotejs/symbiote";
import { I18n } from "../../utilities/i18n/i18n";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";
import { GridOrchestrator } from "../grid-orchestrator/grid-orchestrator";
import { AuthorsComponent } from "../author-component/author-component";

const css = `
    <style>
         @media screen and (orientation:portrait) {
             
        }
         @media screen and (orientation:landscape) {
             
        }
    </style>
`

export class AuthorsContainer extends BaseComponent {

    initCallback() {
        document.head.insertAdjacentHTML("beforeend", css)
        GridOrchestrator.deactivateGameFilters();
    }
}

AuthorsContainer.template = /*html*/ `
   <authors-component></authors-component>

`;

AuthorsContainer.reg('authors-container');
