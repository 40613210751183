import { BaseComponent } from "@symbiotejs/symbiote";
import "../../utilities/bootstrap-assistant/assets/scroll-bar.css";
import { MiscAssistant } from "../../utilities/misc-assistant/misc-assistant";
import { FirebaseAssistant } from "../../utilities/firebase-assistant/firebase-assistant";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";
import { AuthorsComponent } from "../author-component/author-component";
import { I18n } from "../../utilities/i18n/i18n";
import { GameNavbar } from "../game-navbar/game-navbar";

const csgologo = require("../game-navbar/assets/images/csgologo 150x150.png")
const dota2 = require('../game-navbar/assets/images/dota2logo 150x150.png')
const lol = require('../game-navbar/assets/images/lollogo 150x150.png')
const valorant = require('../game-navbar/assets/images/valorantlogo 150x150.png')
const fortnite = require('../game-navbar/assets/images/fortnite 150x150.png')
const hearthstone = require('../game-navbar/assets/images/hearthstone 150x150.png')
const minecraft = require('../game-navbar/assets/images/minecraft 150x150.png')
const overwatch = require('../game-navbar/assets/images/overwatch 150x150.png')
const pubg = require('../game-navbar/assets/images/pubg 150x150.png')
const r6 = require('../game-navbar/assets/images/rainbow six 150x150.png')
const rocketleague = require('../game-navbar/assets/images/rocketleague 150x150.png')
const startcraft = require('../game-navbar/assets/images/starcraft II 150x150.png')
const cod = require('../game-navbar/assets/images/call of duty 150x150.png')
const ea = require('../game-navbar/assets/images/ea sports 150x150.png')
const apex = require('../game-navbar/assets/images/ApexLegends 150x150.webp')
const mlegends = require('../game-navbar/assets/images/mobileLegends 150x150.webp')
const test = require('./assets/test.png')


const css = `
<style>


news-carousel .newsItemSingle:hover {
	cursor: pointer;
}

news-carousel .ratio {
	position: relative;
	/* Ensure that this is relative if not already set */
	overflow: hidden;
	/* This will contain the zoom within the .ratio box */
}

news-carousel .zoom {
	transition: transform 0.3s ease-in-out;
	/* object-fit: cover; only if we want to crop the image to fit the container and not lose resolution */
}

news-carousel .zoom {
	transform: scale(1.05);
	transition: all 0.5s ease-in-out;
}

news-carousel .unzoom {
	transform: scale(1);
	transition: all 0.5s ease-in-out;

}

news-carousel .img-info {
	position: relative;
	margin: 0;
	border-radius: 0;
	padding: 0;
}

news-carousel .img-container-info {
	pointer-events: none;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: space-between;
	margin: 0;
	padding: 0;
	overflow: hidden;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
news-carousel .text-resize-date{
font-size:0.4rem;
font-weight: 400;
}
news-carousel .text-resize-streamer-name{
font-size: 0.6rem;
font-weight: 600;
color:#fff
}
news-carousel .img-circle-container-streamer {
width: 25px;
height: 25px;
}

news-carousel .img-circle-container-streamer img {
max-width: 25px;
max-height: 25px;
}
news-carousel .text-resize {
font-size: 0.7rem;
font-weight: 500;
color:#d5d5d5;
}
news-carousel .card-body {
height: 40px !important;
}
	news-carousel .text-resize-title {
		font-size: 0.8rem;
		font-weight: 500;
		color: #b0b0b0;
		margin-left: 0.2rem;
	}



	news-carousel .img-game-logo {
		max-width: 1rem;
		position: absolute;
		padding: 0;
	}

	news-carousel .text-cut {
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;

	}

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

news-carousel .text-resize-date{
font-size:0.5rem;
font-weight: 400;
}
news-carousel .text-resize-streamer-name{
font-size: 0.6rem;
font-weight: 600;
color:#fff
}
news-carousel .img-circle-container-streamer {
width: 25px;
height: 25px;
}

news-carousel .img-circle-container-streamer img {
max-width: 25px;
max-height: 25px;
}
news-carousel .text-resize {
font-size: 0.7rem;
font-weight: 500;
color:#d5d5d5;
}
news-carousel .card-body {
height: 42px !important;
}
	news-carousel .img-game-logo {
		max-width: 1.5rem;
		position: absolute;
		padding: 0;
	}



	news-carousel .text-cut {
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;

	}

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
news-carousel .text-resize-date{
font-size:0.5rem;
font-weight: 400;
}
news-carousel .text-resize-streamer-name{
font-size: 0.7rem;
font-weight: 600;
color:#fff
}
news-carousel .img-circle-container-streamer {
width: 30px;
height: 30px;
}

news-carousel .img-circle-container-streamer img {
max-width: 30px;
max-height: 30px;
}
news-carousel .text-resize {
font-size: 0.8rem;
font-weight: 500;
color:#d5d5d5;
}
news-carousel .card-body {
height: 50px !important;
}
	news-carousel .img-game-logo {
		max-width: 1.5rem;
		position: absolute;
		padding: 0;
	}


	news-carousel .text-cut {
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;

	}


}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
news-carousel .text-resize-date{
font-size:0.5rem;
font-weight: 400;
}
news-carousel .text-resize-streamer-name{
font-size: 0.8rem;
font-weight: 600;
color:#fff
}
news-carousel .img-circle-container-streamer {
width: 30px;
height: 30px;
}

news-carousel .img-circle-container-streamer img {
max-width: 30px;
max-height: 30px;
}
news-carousel .text-resize {
font-size: 0.9rem;
font-weight: 500;
color:#d5d5d5;
}
news-carousel .card-body {
height: 60px !important;
}

	news-carousel .img-game-logo {
		max-width: 1.5rem;
		position: absolute;
		padding: 0;
	}



	news-carousel .text-cut {
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;

	}

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
news-carousel .text-resize-date{
font-size:0.7rem;
font-weight: 400;
}
news-carousel .text-resize-streamer-name{
font-size: 1rem;
font-weight: 600;
color:#fff
}
news-carousel .img-circle-container-streamer {
width: 40px;
height: 40px;
}

news-carousel .img-circle-container-streamer img {
max-width: 40px;
max-height: 40px;
}
news-carousel .text-resize {
font-size: 1rem;
font-weight: 500;
color:#d5d5d5;
}
news-carousel .card-body {
height: 65px !important;
}
	news-carousel .img-game-logo {
		max-width: 1.5rem;
		position: absolute;
		padding: 0;
	}

	news-carousel .img-info p {
		margin: .5rem;
	}


	news-carousel .text-cut {
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;

	}
}

</style>
`

export class NewsCarousel extends BaseComponent {

	static gameImageMapping = {
		'lol': lol,
		'valorant': valorant,
		'fortnite': fortnite,
		'csgo': csgologo,
		'dota2': dota2,
		'r6': r6,
		'apex': apex,
		'cod': cod,
		'rocketleague': rocketleague,
		'overwatch': overwatch,
		'easports': ea,
		'pubg': pubg,
		'starcraft': startcraft,
		'hearthstone': hearthstone,
		'mlegends': mlegends,
	};

	initCallback() {
		RouterAssistant.checkForSingleContainer('news-carousel', NewsCarousel.initiator);
		document.querySelectorAll('.newsTitle').forEach((el) => {
			el.innerHTML = I18n.translateString('newsTranslations.newsTitle');
		});

		// Get the container
		const container = document.getElementById('NewsCarouselItemsContainer');

		if (container) {
			const scrollSpeed = 1.5;
			const touchpadThreshold = 60;

			container.addEventListener('wheel', (event) => {
				event.preventDefault();

				if (Math.abs(event.deltaY) < touchpadThreshold) {
					// console.debug('Scrolling with touchpad');
					container.style.scrollBehavior = 'auto';
				} else {
					// console.debug('Scrolling with mouse wheel');
					container.style.scrollBehavior = 'smooth';
				}

				container.scrollLeft += event.deltaY * scrollSpeed;
			});
		}
	}

	static initiator() {
		document.head.insertAdjacentHTML("beforeend", css)
		AuthorsComponent.getAuthors('fromNewsCarousel', () => {
			console.debug('Authors loaded from news carousel')
		});
		NewsCarousel.getNews(() => {
			NewsCarousel.populateNewsCarousel(GameNavbar.getCurrentlyActivatedGameIfAny())
			if (document.querySelector('.gameNameActive') && document.querySelector('.gameNameActive') !== null) { //todo this is wrong , the gameNavBar needs to be called to ask whether a game filter is currently active!!!!
				document.getElementById('noNewsPlaceholder').innerHTML = I18n.translateString('newsTranslations.noRecentNewsMsg', { newsGame: document.querySelector('.gameNameActive').innerText });
			} else {
				document.getElementById('noNewsPlaceholder').innerHTML = I18n.translateString('newsTranslations.noRecentNewsMsg', { newsGame: '' });
			}
		});
		GameNavbar.subscribeToGameFilterChanges('fromNewsCarousel', (activeGameFilterId) => {
			if (document.querySelectorAll('news-carousel').length !== 0) {// if our component exists on the page
				NewsCarousel.populateNewsCarousel(activeGameFilterId);
				if (document.querySelector('.gameNameActive') && document.querySelector('.gameNameActive') !== null) {
					document.getElementById('noNewsPlaceholder').innerHTML = I18n.translateString('newsTranslations.noRecentNewsMsg', { newsGame: document.querySelector('.gameNameActive').innerText });
				} else {
					document.getElementById('noNewsPlaceholder').innerHTML = I18n.translateString('newsTranslations.noRecentNewsMsg', { newsGame: '' });
				}
			}
		})

	}

	static redirectToNewsPost(postId) {
		RouterAssistant.redirect('s_news', {
			newsPostId: postId
		})
	}

	static populateNewsCarousel(activeGameFilterId) {
		console.debug(activeGameFilterId)
		if (document.querySelectorAll('news-carousel').length !== 0) {
			document.getElementById('noNewsPlaceholder').style.display = 'none'
			const container = document.getElementById('NewsCarouselItemsContainer')
			document.getElementById('placeholder-news-carousel').style.display = "none"; //vgazw to placeholder news

			container.innerHTML = ''
			for (const [NewsPostId, NewsPostData] of Object.entries(window.savedNews)) {
				if (activeGameFilterId !== null && activeGameFilterId !== 'null' && NewsPostData.game_tag !== activeGameFilterId) continue
				if (NewsPostData.public_draft !== 'Public' || NewsPostData.is_page) continue

				const greeklishUrl = NewsPostData.public_url_greeklish;
				const writtenAtTimestamp = NewsPostData.writtenAt;
				const writtenAtDate = new Date(writtenAtTimestamp.seconds * 1000 + writtenAtTimestamp.nanoseconds / 1000000);
				const formattedDate = writtenAtDate.toLocaleString(I18n.getUsersPreferredLanguage(), {
					year: 'numeric',
					month: 'numeric',
					day: 'numeric',
					hour: '2-digit',
					minute: '2-digit'
				});
				Object.entries(window.savedAuthors).forEach(([authorName, authorData]) => {
					if (authorName.toLowerCase() === NewsPostData.linkedAuthor.toLowerCase()) {
						NewsPostData.tempAuthor = authorName;
						NewsPostData.tempAuthorPhoto = authorData.photo;
					}
				});

				container.insertAdjacentHTML('beforeend', /*html*/`
 						<div class="col-xxl-5 col-xl-5 col-md-6 col-sm-5 col-6 ps-0">
           					<a href="${window.location.origin}/s_news?article=${greeklishUrl}" class="newsItemSingle card color-lightdarkdark border-0 rounded" data-newsid="${NewsPostId}" data-newsgameid="${NewsPostData.game_tag}">
                     			<div class="color-dark rounded-top p-2 d-flex justify-content-start align-content-center align-items-center">
                               		<div class="img-circle-container-streamer d-flex justify-content-center border border-2 border-danger bg-dark bg-gradient rounded-circle overflow-hidden">
							<img src="${NewsPostData.tempAuthorPhoto ? NewsPostData.tempAuthorPhoto : test}" alt="streamer-img" class="img-fluid mx-auto d-block unzoom">                               </div>
							<h5 class="m-0 ms-2 text-resize-streamer-name">${NewsPostData.tempAuthor ? NewsPostData.tempAuthor : 'Unknown'}</h5>                               
							<p class="ms-auto mb-0 text-resize-date">${formattedDate}</p>
                     	</div>
                     		<div class="ratio  ratio-16x9">
                               <img class="card-img-top unzoom img-fluid rounded-0" src="${NewsPostData.article_photo}" alt="Card image cap">
                               <div class="img-container-info ">
                                         <div class="img-info  row card-img-overlay " style="background: linear-gradient(-360deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5354516806722689) 100%);">
                                                   <img class="img-game-logo" src="${NewsCarousel.gameImageMapping[NewsPostData.game_tag]}" alt="gamelogo" style="    margin-left: 0.2rem;margin-top: 0.2rem;">
                                         </div>
                               </div>
                     </div>
                     <div class="card-body color-lightdark rounded-bottom caption-mobile p-1 p-sm-1 p-md-2 p-lg-2">
                               <p class="card-text text-start text-cut text-resize">${NewsPostData.titles[I18n.getUsersPreferredLanguage()]}</p>
                     </div>
           				</a>
						 </div>
                `)
				const insertedElement = document.querySelector(`[data-newsid="${NewsPostId}"]`)
				const articleImage = insertedElement.querySelector('.card-img-top'); // Target the specific image
				insertedElement.addEventListener('click', MiscAssistant.MyEsportsClick(function (evt) {
					RouterAssistant.redirect('s_news', {
						article: greeklishUrl
					})
				}))
				insertedElement.addEventListener('mouseenter', (evt) => {
					articleImage.classList.add('zoom')
					articleImage.classList.remove('unzoom')
				}, false)
				insertedElement.addEventListener('mouseleave', (evt) => {
					articleImage.classList.remove('zoom')
					articleImage.classList.add('unzoom')
				}, false)
			}
			if (document.querySelectorAll('.newsItemSingle').length === 0)
				document.getElementById('noNewsPlaceholder').style.display = ''
		}
		// console.log(window.savedNews)

	}

	static getNews(callback) {
		if (window.savedNews === undefined) {
			FirebaseAssistant.getNews(NewsThatCameBack => {
				const tempObject = {}
				NewsThatCameBack.forEach(NewsEntity => {
					tempObject[NewsEntity.id] = NewsEntity.data();
				});
				window.savedNews = tempObject
				callback()
			});
		} else {
			callback()
		}
	}
}


NewsCarousel.template = /*html*/`

     

<div class="container-fluid p-0 my-0 my-sm-0 my-md-5 my-lg-5 my-xl-5 my-xxl-5">
 <div class="title">
        <span class="text-resize-title newsTitle"></span>
        </div>
        <!-- placeholder Start -->
<div id="placeholder-news-carousel">
   <div class="row flex-nowrap overflow-hidden mt-0 mt-sm-0 mt-md-1 mt-lg-2 mt-xl-2 mt-xxl-2   mx-1 mx-sm-1 mx-md-1 mx-lg-1 mx-xl-1 mx-xxl-1 mx-1 w-100" style="height: 15rem;" >
                      <div class="col-xxl-5 col-xl-5 col-md-6 col-sm-5 col-5  placeholder-wave color-dark rounded d-flex align-items-end">
                      <span class="placeholder col-12 rounded mb-2"></span>
                       </div>
                        <div class="col-xxl-5 col-xl-5 col-md-6 col-sm-5 col-5 mx-2 placeholder-wave color-dark rounded d-flex align-items-end">
                        <span class="placeholder col-12 rounded mb-2"></span>
                         </div>
                 			 <div class="col-xxl-5 col-xl-5 col-md-6 col-sm-5 col-5  placeholder-wave color-dark rounded d-flex align-items-end">
                 			 <span class="placeholder col-12 rounded mb-2"></span>
                 			  </div>
                  </div>
</div>
      <!-- placeholder end -->       
    <div id="NewsCarouselItemsContainer" class="row flex-nowrap scrollbar overflow-auto mt-0 mt-sm-0 mt-md-1 mt-lg-2 mt-xl-2 mt-xxl-2   mx-1 mx-sm-1 mx-md-1 mx-lg-1 mx-xl-1 mx-xxl-1 mx-1   ">
    </div>
    <div id="noNewsPlaceholder" class="my-5" style="display:none; text-align: center; padding: 20px; background-color: #161b22; border-color: #f5c6cb; border-radius: .25rem;">
      </div>
 

</div>
`;

NewsCarousel.reg('news-carousel'); // components should always be 2 words seperated with a dash
