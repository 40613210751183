import { BaseComponent } from "@symbiotejs/symbiote";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";
import { MatchesComponent } from "../matches-component/matches-component";
import { doc, getDoc, setDoc } from "@firebase/firestore";
import { FirebaseAssistant } from "../../utilities/firebase-assistant/firebase-assistant";
import 'flag-icon-css/css/flag-icons.min.css';
import csgologo from "../game-navbar/assets/images/csgologo 150x150.png";
import dota2 from "../game-navbar/assets/images/dota2logo 150x150.png";
import lol from "../game-navbar/assets/images/lollogo 150x150.png";
import ea from "../game-navbar/assets/images/ea sports 150x150.png";
import valorant from "../game-navbar/assets/images/valorantlogo 150x150.png";
import cod from "../game-navbar/assets/images/call of duty 150x150.png";
import overwatch from "../game-navbar/assets/images/overwatch 150x150.png";
import pubg from "../game-navbar/assets/images/pubg 150x150.png";
import r6 from "../game-navbar/assets/images/rainbow six 150x150.png";
import rocketleague from "../game-navbar/assets/images/rocketleague 150x150.png";
import startcraft from "../game-navbar/assets/images/starcraft II 150x150.png";
import apex from "../game-navbar/assets/images/ApexLegends 150x150.webp";
import kingofglory from "../game-navbar/assets/images/KingOfGlory 150x150.webp";
const mlegends = require('../game-navbar/assets/images/mobileLegends 150x150.webp')

import { off } from "process";
import { GamerShowcase } from "../gamer-showcase/gamer-showcase";
import { TournamentsComponent } from "../tournaments-component/tournaments-component";
import { DateTime } from 'luxon';

import { createBracket } from 'bracketry';
import { I18n } from "../../utilities/i18n/i18n";
import tournamentimg2 from "../hottournaments-component/assets/img2.webp"
import { isMatchForfeitCompleted } from "brackets-manager/dist/helpers";


const css = `
<style> 
   .team-card {
            position: relative;
            width: 100%;
            padding-top: 100%;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            border: 1px solid #000;
            overflow: hidden;
            transition: all 0.3s ease-in-out;
            cursor: pointer;
        }
        .team-card:hover .team-info {
            opacity: 1;
        }
        .team-info {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.8);
            color: #fff;
            opacity: 0;
            transition: opacity 0.3s ease-in-out;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }
        .team-info h5 {
            margin-bottom: 10px;
        }
        .team-info ul {
            list-style: none;
            padding: 0;
            margin: 0;
        }
        .team-info ul li {
            margin-bottom: 5px;
        }
        .team-name {
            color:#fff;
            text-align: center;
            margin-top: 10px;
            font-weight: bold;
        }
        .team-card.active .team-info {
            opacity: 1;
        }
posttournaments-component .list-group-item {
background: transparent;
border:none;
cursor: default !important;
}
posttournaments-component td, posttournaments-component th {
align-content: center;
}

 posttournaments-component .nav .nav-item , .nav .nav-link {
background=red !important;
color:#ffffff !important;
}
posttournaments-component .nav .styleTheLiveWeek {
font-weight: bold; /* Makes the text bold */
    border: 2px solid #ffffff; /* Adds a border around the pill */
    box-shadow: 0 0 10px #000000; /* Adds a shadow to make the pill stand out */
}
 posttournaments-component .nav .active{
background-color: #ff282859 !important;
color:#ffffff !important;
}
 posttournaments-component .nav .disabled{
color:#707070 !important;
}
 posttournaments-component .winner-left {
    background-color: #005307;
    filter: drop-shadow(#0D1117 0rem 0.4rem 4.8px);
    border-radius: 0 15px 15px 0;
}
 posttournaments-component .winner-right {
    background-color: #005307;
    filter: drop-shadow(#0D1117 0rem 0.4rem 4.8px);
    border-radius: 15px 0 0 15px;
}


.bracket-root .round-title {
    display: none !important;
}

.bracket-root{
background-color:#0D1117 !important;
border:none !important;
}
.round-titles-grid-item, .navigation-button{
border:none !important;
}
.nationality{
    background: #1B222A !important;
    border-bottom: 2px solid #B22A23 !important;
    width: 3rem !important;
    height: 3rem !important;
    display: flex !important; 
    justify-content: center !important;
    overflow: hidden !important;
}
.match-body {
background-color: #161B22;
border-radius:8px !important;
border:none !important;
width: 12rem !important;    
}
.match-body .match-top .match-title {
font-size: 0.8rem !important;
text-align: center !important;
}
.side-wrapper{
padding-top: 0 !important;
padding-left: 10px !important;
padding-bottom: 0 !important;
}
.table-striped{
    text-align: center;
}





/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) { 
   posttournaments-component .team-name {
                font-size: 0.6rem;
            }
           posttournaments-component .team-info ul {
                font-size: 0.5rem;
            }
      posttournaments-component .team-info-mobile {
      font-size: 0.6rem;
      }
posttournaments-component .text-resize-tournament-name{
 font-size: 0.5rem;
 }

 posttournaments-component .text-resize-tournament-info-left{
 font-size: 0.4rem;
 }
  posttournaments-component .text-resize-tournament-info-right{
 font-size: 0.4rem;
 }
   posttournaments-component .img-circle-container-tournament-logo {
        
        width: 5rem;  /* Adjust the width as needed */
   height: auto; /* Ensure the height is the same as the width for a perfect circle */
  display:block;
  position:absolute;
  left:0;
  right:0;
  bottom:0;
  margin:auto;
    }
     posttournaments-component .img-circle-container-tournament-logo img {
        max-width: 3rem;
        max-height: 3rem;
    }
    
    
    posttournaments-component .img-circle-container-team-logo-table {
        width: 1rem;
        height: 1rem;
    }
     posttournaments-component .img-circle-container-team-logo-table img {
        max-width: 1rem;
        max-height: 1rem;
    }
    
    posttournaments-component .img-circle-container-team-logo {
        width: 1.5rem;
        height: 1.5rem;
    }
     posttournaments-component .img-circle-container-team-logo img {
       max-width: 1.5rem;
        max-height: 1.5rem;
    }
   
     posttournaments-component .text-resize-team-name-table {
        font-size: 0.35rem;
        font-weight: 800;
    }
   posttournaments-component th{
    font-size: 0.4rem;
    }
     posttournaments-component .text-resize-table-info {
        font-size: 0.5rem;
    }
      posttournaments-component .text-resize-team-name {
        font-size: 0.35rem;
        font-weight: 600;
    }
        posttournaments-component .text-resize-date {
        font-size: 0.3rem;
    }
    posttournaments-component .text-resize-score{
        font-size:0.35rem;
        font-weight: 800;
   }
      posttournaments-component .text-resize-badge {
        font-size: 0.35rem;
        font-weight: 600;
        
    }
    posttournaments-component .text-resize-nav-pills{
    font-size: 0.4rem;
    font-weight: 700;
    }
    }

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
posttournaments-component .team-name {
                font-size: 0.6rem;
            }
           posttournaments-component .team-info ul {
                font-size: 0.5rem;
            }
           posttournaments-component .team-info-mobile {
      font-size: 0.7rem;
      }
posttournaments-component .text-resize-tournament-name{
 font-size: 0.5rem;
 }

 posttournaments-component .text-resize-tournament-info-left{
 font-size: 0.4rem;
 }
  posttournaments-component .text-resize-tournament-info-right{
 font-size: 0.4rem;
 }
   posttournaments-component .img-circle-container-tournament-logo {
        
        width: 5rem;  /* Adjust the width as needed */
   height: auto; /* Ensure the height is the same as the width for a perfect circle */
  display:block;
  position:absolute;
  left:0;
  right:0;
  bottom:0;
  margin:auto;
    }
     posttournaments-component .img-circle-container-tournament-logo img {
        max-width: 3rem;
         max-height: 3rem;
    }
    
    
    posttournaments-component .img-circle-container-team-logo-table {
        width: 1.3rem;
        height: 1.3rem;
    }
     posttournaments-component .img-circle-container-team-logo-table img {
        max-width: 1.5rem;
         max-height: 1.5rem;
    }
    
    posttournaments-component .img-circle-container-team-logo {
        width: 1.5rem;
        height: 1.5rem;
    }
     posttournaments-component .img-circle-container-team-logo img {
       max-width: 1.5rem;
        max-height: 1.5rem;
    }
   
     posttournaments-component .text-resize-team-name-table {
        font-size: 0.45rem;
        font-weight: 800;
    }
   posttournaments-component th{
    font-size: 0.4rem;
    
    }
     posttournaments-component .text-resize-table-info {
        font-size: 0.4rem;
    }
      posttournaments-component .text-resize-team-name {
        font-size: 0.5rem;
        font-weight: 800;
    }
        posttournaments-component .text-resize-date {
        font-size: 0.35rem;
    }
    posttournaments-component .text-resize-score{
        font-size:0.45rem;
        font-weight: 800;
   }
      posttournaments-component .text-resize-badge {
        font-size: 0.4rem;
        font-weight: 600;
        
    }
    posttournaments-component .text-resize-nav-pills{
    font-size: 0.45rem;
    font-weight: 600;
    }

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  posttournaments-component .team-name {
                font-size: 0.6rem;
            }
           posttournaments-component .team-info ul {
                font-size: 0.7rem;
            }
            posttournaments-component .team-info-mobile {
      font-size: 0.8rem;
      }

posttournaments-component .text-resize-tournament-name{
 font-size: 0.5rem;
 }

 posttournaments-component .text-resize-tournament-info-left{
 font-size: 0.4rem;
 }
  posttournaments-component .text-resize-tournament-info-right{
 font-size: 0.4rem;
 }
   posttournaments-component .img-circle-container-tournament-logo {
        
        width: 5rem;  /* Adjust the width as needed */
   height: auto; /* Ensure the height is the same as the width for a perfect circle */
  display:block;
  position:absolute;
  left:0;
  right:0;
  bottom:0;
  margin:auto;
    }
     posttournaments-component .img-circle-container-tournament-logo img {
        max-width: 3rem;
        max-height: 3rem;
    }
    
    
    posttournaments-component .img-circle-container-team-logo-table {
        width: 1.5rem;
        height: 1.5rem;
    }
     posttournaments-component .img-circle-container-team-logo-table img {
        max-width: 1.5rem;
         max-height: 1.5rem;
    }
    
    posttournaments-component .img-circle-container-team-logo {
        width: 2rem;
        height: 2rem;
    }
     posttournaments-component .img-circle-container-team-logo img {
       max-width: 2rem;
        max-height: 2rem;
    }
   
     posttournaments-component .text-resize-team-name-table {
        font-size: 0.6rem;
        font-weight: 800;
    }
   posttournaments-component th{
    font-size: 0.5rem;
    }
     posttournaments-component .text-resize-table-info {
        font-size: 0.6rem;
    }
      posttournaments-component .text-resize-team-name {
        font-size: 0.6rem;
        font-weight: 800;
    }
        posttournaments-component .text-resize-date {
        font-size: 0.4rem;
    }
    posttournaments-component .text-resize-score{
        font-size:0.5rem;
        font-weight: 800;
   }
      posttournaments-component .text-resize-badge {
        font-size: 0.4rem;
        font-weight: 600;
        
    }
    posttournaments-component .text-resize-nav-pills{
    font-size: 0.5rem;
    font-weight: 700;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
posttournaments-component .team-name {
                font-size: 0.7rem;
            }
           posttournaments-component .team-info ul {
                font-size: 0.6rem;
            }
          
posttournaments-component .text-resize-tournament-name{
 font-size: 0.7rem;
 }

 posttournaments-component .text-resize-tournament-info-left{
 font-size: 0.6rem;
 }
  posttournaments-component .text-resize-tournament-info-right{
 font-size: 0.6rem;
 }
   posttournaments-component .img-circle-container-tournament-logo {
        
        width: 5rem;  /* Adjust the width as needed */
   height: auto; /* Ensure the height is the same as the width for a perfect circle */
  display:block;
  position:absolute;
  left:0;
  right:0;
  bottom:0;
  margin:auto;
    }
     posttournaments-component .img-circle-container-tournament-logo img {
        max-width: 3rem;
         max-height: 3rem;
    }
    
    
    posttournaments-component .img-circle-container-team-logo-table {
        width: 1.5rem;
        height: 1.5rem;
    }
     posttournaments-component .img-circle-container-team-logo-table img {
        max-width: 1.5rem;
         max-height: 1.5rem;
    }
    
    posttournaments-component .img-circle-container-team-logo {
        width: 2rem;
        height: 2rem;
    }
     posttournaments-component .img-circle-container-team-logo img {
       max-width: 2rem;
        max-height: 2rem;
    }
   
     posttournaments-component .text-resize-team-name-table {
        font-size: 0.7rem;
        font-weight: 800;
    }
   posttournaments-component th{
    font-size: 0.6rem;
    }
     posttournaments-component .text-resize-table-info {
        font-size: 0.6rem;
    }
      posttournaments-component .text-resize-team-name {
        font-size: 0.7rem;
        font-weight: 800;
    }
        posttournaments-component .text-resize-date {
        font-size: 0.5rem;
    }
    posttournaments-component .text-resize-score{
        font-size:0.6rem;
        font-weight: 800;
   }
      posttournaments-component .text-resize-badge {
        font-size: 0.5rem;
        font-weight: 600;
        
    }
    posttournaments-component .text-resize-nav-pills{
    font-size: 0.6rem;
    font-weight: 700;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  posttournaments-component .team-name {
                font-size: 0.8rem;
            }
           posttournaments-component .team-info ul {
                font-size: 0.7rem;
            }
          
 posttournaments-component .text-resize-tournament-name{
 font-size: 1rem;
 font-weight: 800;
 }

 posttournaments-component .text-resize-tournament-info-left{
 font-size: 0.8rem;
 font-weight: 500;
 
 }
  posttournaments-component .text-resize-tournament-info-right{
 font-size: 0.8rem;
 font-weight: 500;
 }
   posttournaments-component .img-circle-container-tournament-logo {
        
        width: 5rem;  /* Adjust the width as needed */
   height: auto; /* Ensure the height is the same as the width for a perfect circle */
  display:block;
  position:absolute;
  left:0;
  right:0;
  bottom:0;
  margin:auto;
    }
     posttournaments-component .img-circle-container-tournament-logo img {
        max-width: 3rem;
         max-height: 3rem;
    }
    
    
    posttournaments-component .img-circle-container-team-logo-table {
        width: 2rem;
        height: 2rem;
    }
     posttournaments-component .img-circle-container-team-logo-table img {
        max-width: 2rem;
         max-height: 2rem;
    }
    
    posttournaments-component .img-circle-container-team-logo {
        width: 2.5rem;
        height: 2.5rem;
    }
     posttournaments-component .img-circle-container-team-logo img {
       max-width: 2.5rem;
        max-height: 2.5rem;
    }
   
     posttournaments-component .text-resize-team-name-table {
        font-size: 0.8rem;
        font-weight: 800;
    }
   posttournaments-component th{
    font-size: 0.7rem;
    }
     posttournaments-component .text-resize-table-info {
        font-size: 0.7rem;
    }
      posttournaments-component .text-resize-team-name {
        font-size: 0.8rem;
        font-weight: 700;
    }
        posttournaments-component .text-resize-date {
        font-size: 0.6rem;
    }
    posttournaments-component .text-resize-score{
        font-size:0.7rem;
        font-weight: 800;
   }
      posttournaments-component .text-resize-badge {
        font-size: 0.7rem;
        font-weight: 600;
        
    }
    posttournaments-component .text-resize-nav-pills{
    font-size: 0.7rem;
    font-weight: 700;
    }
}
@media only screen and (min-width: 1920px) {
posttournaments-component .team-name {
                font-size: 1rem;
            }
           posttournaments-component .team-info ul {
                font-size: 1rem;
            }
}
}

}

</style>
`

export class PostTournamentsComponent extends BaseComponent {

    initCallback() {
        document.head.insertAdjacentHTML("beforeend", css);
        RouterAssistant.checkForSingleContainer('posttournaments-component', PostTournamentsComponent.initiator)
        document.querySelectorAll('.team-card').forEach(card => {
            card.addEventListener('click', function () {
                // Toggle the 'active' class on the clicked card
                this.classList.toggle('active');
            });
        });
    }


    static userLanguage = window.navigator.language;

    static translations(data, seriesTeamsCount) {
        //general info translation
        const teCoEl = document.querySelector('.teams-compete-labelP');
        const tiEleme = document.querySelector('.tier-labelP');
        const prizEle = document.querySelector('.prizepool-labelP');
        const startEle = document.querySelector('.start-labelP');
        const endEle = document.querySelector('.end-labelP');
        const checkEle = document.querySelector('.check-nodate-labelP');

        let winEle = '';
        if (document.querySelector('.winner-labelP')) {
            winEle = document.querySelector('.winner-labelP');
        }
        if ((data.tournaments[0].teams.length !== 0) || (data.tournaments[0].matches[0] && data.tournaments[0].matches[0].opponents[0] && data.tournaments[0].matches[0].opponents[0].type === 'Team') || (data.tournaments[0].winner_type && data.tournaments[0].winner_type === 'Team')) {
            teCoEl.innerHTML = I18n.translateString("postTournamentTranslations.teamsCompetePost", { teams: seriesTeamsCount });
        } else {
            teCoEl.innerHTML = I18n.translateString("postTournamentTranslations.playersCompetePost", { teams: seriesTeamsCount });
        }

        if (prizEle.innerHTML.includes('No prize pool available')) {
            prizEle.innerHTML = I18n.translateString("postTournamentTranslations.noPrizePoolAvailablePost")
        } else if (prizEle.innerHTML.includes('Euro')) {
            prizEle.innerHTML = I18n.translateString("postTournamentTranslations.euroPrizePoolPost", { money: data.totalPrizePool })
        } else if (prizEle.innerHTML.includes('United States Dollar')) {
            prizEle.innerHTML = I18n.translateString("postTournamentTranslations.usdPrizePoolPost", { money: data.totalPrizePool })
        }

        tiEleme.innerHTML = I18n.translateString("postTournamentTranslations.tierPost");

        if (winEle && winEle.innerHTML.includes('Winner')) {
            winEle.innerHTML = I18n.translateString("postTournamentTranslations.winnerPost");
        }

        if (checkEle.innerHTML.includes('NoDateMsg')) {
            checkEle.innerHTML = I18n.translateString("postTournamentTranslations.noDateMsgPost");
        }

        startEle.innerHTML = I18n.translateString("postTournamentTranslations.startPost");
        endEle.innerHTML = I18n.translateString("postTournamentTranslations.endPost");

        // ladder translation
        if (document.querySelector('.winsClass')) {
            document.querySelectorAll('.winsClass').forEach(element => {
                element.innerHTML = I18n.translateString("postTournamentTranslations.ladderWins");
            });
        }
        if (document.querySelector('.tiesClass')) {
            document.querySelectorAll('.tiesClass').forEach(element => {
                element.innerHTML = I18n.translateString("postTournamentTranslations.ladderTies");
            });
        }
        if (document.querySelector('.lossesClass')) {
            document.querySelectorAll('.lossesClass').forEach(element => {
                element.innerHTML = I18n.translateString("postTournamentTranslations.ladderLosses");
            });
        }
        if (document.querySelector('.gWinsClass')) {
            document.querySelectorAll('.gWinsClass').forEach(element => {
                element.innerHTML = I18n.translateString("postTournamentTranslations.ladderGamesWon");
            });
        }
        if (document.querySelector('.gLossesClass')) {
            document.querySelectorAll('.gLossesClass').forEach(element => {
                element.innerHTML = I18n.translateString("postTournamentTranslations.ladderGamesLost");
            });
        }
        if (document.querySelector('.tieBreakerClass')) {
            document.querySelectorAll('.tieBreakerClass').forEach(element => {
                element.innerHTML = I18n.translateString("postTournamentTranslations.ladderTieBreaker");
            });
        }
        if (document.querySelector('.eachLaddersMatches')) {
            document.querySelectorAll('.eachLaddersMatches').forEach(element => {
                element.innerHTML = I18n.translateString("postTournamentTranslations.laddersMatchesTitle");
            });
        }
        // match type translation
        document.querySelectorAll('.matchTypeFromPostTournaments').forEach(matchTypeElement => {
            if (matchTypeElement.innerHTML === 'all_games_played') {
                matchTypeElement.innerHTML = I18n.translateString("matchTranslations.allGamesPlayed", { gamesNumber: numberOfGames });
            } else if (matchTypeElement.innerHTML.startsWith('best_of_')) {
                let gamesNumber = parseInt(matchTypeElement.innerHTML.split('_')[2]);
                if (window.translateTo === 'el') {
                    gamesNumber = Math.floor(gamesNumber / 2) + 1;
                }
                matchTypeElement.innerHTML = I18n.translateString("matchTranslations.bestofX", { gamesNumber: gamesNumber });
            } else if (matchTypeElement.innerHTML === 'bestof_2') {
                matchTypeElement.innerHTML = I18n.translateString("matchTranslations.bestof2");
            } else if (matchTypeElement.innerHTML === 'first_to_X') {
                matchTypeElement.innerHTML = I18n.translateString("matchTranslations.firstToX", { gamesNumber: numberOfGames });
            } else if (matchTypeElement.innerHTML === 'single_game') {
                matchTypeElement.innerHTML = I18n.translateString("matchTranslations.bestof1");
            } else {
                matchTypeElement.innerHTML = I18n.translateString("matchTranslations.generalDrop", { matchType: matchTypefull });
            }
        });
        // week buttons translation
        const weekElements = document.querySelectorAll('.weekButton');
        weekElements.forEach(element => {
            let translatedString = ``
            if (element.innerHTML.toLowerCase().includes('week'.toLowerCase())) {
                translatedString = I18n.translateString("postTournamentTranslations.week")
                element.innerHTML = element.innerHTML.replace(/week/gi, translatedString);
            }
        });
        const liveWeekElements = document.querySelectorAll('.styleTheLiveWeek');
        liveWeekElements.forEach(element => {
            let translatedString = ``
            if (element.innerHTML.toLowerCase().includes('live'.toLowerCase())) {
                translatedString = I18n.translateString("postTournamentTranslations.liveWeek")
                element.innerHTML = element.innerHTML.replace(/live/gi, translatedString);
            }
        });
    }

    static initiator() {
        TournamentsComponent.getTournaments('fromPostTournamentsComponent', async () => {
            if (window.location.href.includes('s_tournament')) {
                const tournamentcodeid = RouterAssistant.getCurrentVariableValue('tournament')
                console.debug('I am running the post tournament component callback!!!')
                let data = null;
                if (window.savedTournaments[tournamentcodeid] && window.savedTournaments[tournamentcodeid].modified_at) {
                    console.debug('DATA IS LESS, NOT UPDATING')
                } else {
                    console.debug('Fetching the single tournament from firestore for the complete showcase');
                    await new Promise((resolve, reject) => {
                        FirebaseAssistant.getSingleTournament(tournamentcodeid, querySnapshot => {
                            console.debug('getting the single tournament from firestore')
                            if (!querySnapshot.empty) {
                                const single = querySnapshot.docs[0];
                                data = single.data();
                                window.savedTournaments[data.id] = data;

                                if (Array.isArray(data.tournaments)) {
                                    data.tournaments.forEach(tournament => {
                                        if (Array.isArray(tournament.matches)) {
                                            tournament.matches.forEach(match => {
                                                console.debug(match)
                                                if (window.savedMatchesFromTournaments === undefined) {
                                                    window.savedMatchesFromTournaments = {};
                                                }
                                                if (window.savedMatchesFromTournaments[match.id] === undefined) {
                                                    console.debug('saving match to window.savedMatchesFromTournaments')
                                                    window.savedMatchesFromTournaments[match.id] = match;
                                                }
                                            });
                                        }
                                    });
                                }

                                resolve();
                            } else {
                                reject('QuerySnapshot is empty');
                            }
                        });
                    });
                }
                data = window.savedTournaments[tournamentcodeid];
                let totalPrizePool = 0; // in case of no prize pool
                let currency = '';
                let noPrizePoolMsg = '';
                let tournamentsWithoutPrizePool = 0;
                let tier = '';

                for (let j = 0; j < data.tournaments.length; j++) {
                    const prizePool = data.tournaments[j].prizepool;
                    if (!tier) {
                        tier = data.tournaments[j].tier;
                    }
                    if (prizePool) {
                        const match = prizePool.match(/(\d+)(\D+)/);
                        if (match) {
                            totalPrizePool += Number(match[1]);
                            if (!currency) {
                                currency = match[2].trim();
                            }
                        }
                    } else {
                        tournamentsWithoutPrizePool++;
                    }
                }

                if (tournamentsWithoutPrizePool === data.tournaments.length) {
                    noPrizePoolMsg = `No prize pool available`
                }


                // Store the total prize pool and currency in the tournament data
                data.tier = data.tournaments[0].tier;
                data.totalPrizePool = totalPrizePool;
                data.currency = currency;
                data.noPrizePoolMsg = noPrizePoolMsg;
                data.prizepoolMessage = noPrizePoolMsg + (totalPrizePool !== 0 ? totalPrizePool : '') + ' ' + currency;
                console.debug(data);
                PostTournamentsComponent.finalShowcase(data);

                document.querySelectorAll('.match-wrapper').forEach(function (wrapper) {
                    var matchBody = wrapper.querySelector('.match-body');
                    if (!matchBody) {
                        wrapper.style.setProperty('--connectionLinesColor', '#131416');
                    }
                });
                const matchTitleElements = document.querySelectorAll('.match-title');
                matchTitleElements.forEach(element => {
                    let translatedString = ``
                    if (element.innerHTML.toLowerCase().includes('upper bracket'.toLowerCase())) {
                        translatedString = I18n.translateString("postTournamentTranslations.upperBracket")
                        element.innerHTML = element.innerHTML.replace(/upper bracket/gi, translatedString);
                    }
                    if (element.innerHTML.toLowerCase().includes('lower bracket'.toLowerCase())) {
                        translatedString = I18n.translateString("postTournamentTranslations.lowerBracket")
                        element.innerHTML = element.innerHTML.replace(/lower bracket/gi, translatedString);
                    }
                    if (element.innerHTML.toLowerCase().includes('grand final'.toLowerCase())) {
                        translatedString = I18n.translateString("postTournamentTranslations.grandFinal")
                        element.innerHTML = element.innerHTML.replace(/grand final/gi, translatedString);
                    }
                    if (!element.innerHTML.toLowerCase().includes('semifinal'.toLowerCase()) && !element.innerHTML.toLowerCase().includes('quarterfinal'.toLowerCase()) && !element.innerHTML.toLowerCase().includes('grand final'.toLowerCase()) && element.innerHTML.toLowerCase().includes('final'.toLowerCase())) {
                        translatedString = I18n.translateString("postTournamentTranslations.grandFinal")
                        element.innerHTML = element.innerHTML.replace(/final/gi, translatedString);
                    }
                    if (element.innerHTML.toLowerCase().includes('semifinal'.toLowerCase())) {
                        translatedString = I18n.translateString("postTournamentTranslations.semiFinal")
                        element.innerHTML = element.innerHTML.replace(/semifinal/gi, translatedString);
                    }
                    if (element.innerHTML.toLowerCase().includes('quarterfinal'.toLowerCase())) {
                        translatedString = I18n.translateString("postTournamentTranslations.quarterFinal")
                        element.innerHTML = element.innerHTML.replace(/quarterfinal/gi, translatedString);
                    }
                    if (element.innerHTML.toLowerCase().includes('round'.toLowerCase())) {
                        translatedString = I18n.translateString("postTournamentTranslations.roundBrackets")
                        element.innerHTML = element.innerHTML.replace(/round/gi, translatedString);
                    }
                    if (element.innerHTML.toLowerCase().includes('match'.toLowerCase())) {
                        translatedString = I18n.translateString("postTournamentTranslations.matchBrackets")
                        element.innerHTML = element.innerHTML.replace(/match/gi, translatedString);
                    }
                });

            } else {
                console.debug('The post tournaments component callback is supposed to run but we are not on the right page!!!!')
            }
        })
    }





    static async finalShowcase(data) {
        const binaryTrees = data.tournaments
            .filter(tournament => tournament.has_bracket)
            .map(tournament => {
                const sortedMatches = PostTournamentsComponent.sortInReverseChronologicalOrder(
                    tournament.tournamentvisual.map(match => {
                        const correspondingMatch = tournament.matches.find(m => m.id === match.id);
                        return {
                            ...match,
                            results: correspondingMatch ? correspondingMatch.results : null
                        };
                    })
                );
                // console.debug(sortedMatches);
                return PostTournamentsComponent.formatAsTree(sortedMatches);
            });
        console.debug(binaryTrees);

        // Initialize an empty object to store the ladders
        let ladders = {};
        let bracketTournamentNames = [];
        let bracketTournamentNamesNoSpaces = []; // Declare this at the beginning of your function
        let binaryTreeIndex = 0;
        let globalIndex = 0;
        let parentElements = [];
        let winnerShow = '';
        let seriesTeamsCount = 0;
        let seriesTeams = {};

        // Iterate over each tournament
        for (let i = 0; i < data.tournaments.length; i++) {
            let tournament = data.tournaments[i];

            // Initialize an empty object to store the team names
            let teamNames = {};

            // Map team IDs to their names
            if (tournament.teams.length === 0 && tournament.winner_type === 'Player') {
                console.debug('this is a player tournament');
                if (tournament.matches && tournament.matches.length > 0) {
                    for (let i = 0; i < tournament.matches.length; i++) {
                        let match = tournament.matches[i];
                        for (let j = 0; j < match.opponents.length; j++) {
                            let opponent = match.opponents[j].opponent;
                            if (!teamNames[opponent.id]) {
                                teamNames[opponent.id] = { name: opponent.name };
                            }
                        }
                    }
                }
            } else {
                console.debug('this is a team tournament');
                for (let k = 0; k < tournament.teams.length; k++) {
                    let team = tournament.teams[k];
                    if (!teamNames[team.id]) {
                        teamNames[team.id] = { name: team.name };
                    }
                }
            }

            if (!data.tournaments[i].has_bracket) {
                // Initialize an empty object to store the ladder for this tournament
                let ladder = {};

                // Initialize each team's win count to 0
                for (let teamId in teamNames) {
                    ladder[teamNames[teamId].name] = { points: 0, wins: 0, draws: 0, losses: 0, gamesW: 0, gamesL: 0, tiebrakersW: 0, tiebrakersL: 0, matches: [], teamlogo: '' };
                }

                // Iterate over each match in the tournament
                for (let j = 0; j < tournament.matches.length; j++) {
                    let match = tournament.matches[j];
                    if (match.opponents.length > 1 && !(match.status === 'canceled' && match.winner_id === null)) {
                        let teamName1 = teamNames[match.opponents[0].opponent.id].name;
                        let teamName2 = teamNames[match.opponents[1].opponent.id].name;
                        ladder[teamName1].matches.push(match.id);
                        ladder[teamName2].matches.push(match.id);
                        ladder[teamName1].teamlogo = match.opponents[0].opponent.image_url ? match.opponents[0].opponent.image_url : '';
                        ladder[teamName2].teamlogo = match.opponents[1].opponent.image_url ? match.opponents[1].opponent.image_url : '';
                        if (!((match.status === 'not_started' || match.status === 'running' || match.status === 'postponed') || (match.status === 'canceled' && match.winner_id === null))) {

                            if ((match.winner_id !== null) && (match.name.includes("Tiebreaker"))) {
                                let winnerName = teamNames[match.winner_id].name;
                                ladder[winnerName].tiebrakersW += 1;
                                if (match.opponents[0].opponent.id === match.winner_id) {
                                    let loserName = teamNames[match.opponents[1].opponent.id].name;
                                    ladder[loserName].tiebrakersL += 1;
                                } else {
                                    let loserName = teamNames[match.opponents[0].opponent.id].name;
                                    ladder[loserName].tiebrakersL += 1;
                                }
                            } else if (match.winner_id === null) {
                                let drawName1 = teamNames[match.opponents[0].opponent.id].name;
                                let drawName2 = teamNames[match.opponents[1].opponent.id].name;
                                ladder[drawName1].draws++;
                                ladder[drawName2].draws++;
                                ladder[drawName1].gamesW++;
                                ladder[drawName1].gamesL++;
                                ladder[drawName2].gamesW++;
                                ladder[drawName2].gamesL++;
                                ladder[drawName1].points++;
                                ladder[drawName2].points++;
                            } else if (match.winner_id !== null) {
                                for (let k = 0; k < match.games.length; k++) {
                                    if (match.games[k].winner.id !== null) {
                                        let gameWinnerName = teamNames[match.games[k].winner.id].name;
                                        ladder[gameWinnerName].gamesW++;
                                        if (match.opponents[0].opponent.id === match.games[k].winner.id) {
                                            let gameLoserName = teamNames[match.opponents[1].opponent.id].name;
                                            ladder[gameLoserName].gamesL++;
                                        } else {
                                            let gameLoserName = teamNames[match.opponents[0].opponent.id].name;
                                            ladder[gameLoserName].gamesL++;
                                        }
                                    }
                                }
                                let WinnerName = teamNames[match.winner_id].name;
                                ladder[WinnerName].wins++;
                                ladder[WinnerName].points += 2;
                                if (match.opponents[0].opponent.id === match.winner_id) {
                                    let loserName = teamNames[match.opponents[1].opponent.id].name;
                                    ladder[loserName].losses++;
                                } else {
                                    let loserName = teamNames[match.opponents[0].opponent.id].name;
                                    ladder[loserName].losses++;
                                }
                            }
                        }
                    }
                }
                // Convert the ladder into an array of objects and sort it
                let sortedLadder = Object.entries(ladder)
                    .map(([team, stats]) => ({ team, ...stats }))
                    .sort((a, b) => b.points - a.points || b.tiebrakersW - a.tiebrakersW || b.wins - a.wins || a.losses - b.losses || b.gamesW - a.gamesW || a.gamesL - b.gamesL);
                // Store the sorted ladder
                ladders[tournament.name] = sortedLadder;
                console.debug(ladders);
            } else {
                let bracketTournamentName = data.tournaments[i].name;
                let bracketTournamentNameNoSpaces = bracketTournamentName.replace(/\s/g, '');
                bracketTournamentNamesNoSpaces.push(bracketTournamentNameNoSpaces);
                bracketTournamentNames.push(bracketTournamentName);

                let parentElement = document.querySelector('#ladderContainer');// Replace with the id of the parent element
                let wrapper = document.createElement('div');
                wrapper.id = 'theWrapper';
                parentElement.appendChild(wrapper);

                /**
                 * @typedef {Object} Data
                 * @property {Round[]} rounds
                 * @property {Match[]} [matches]
                 * @property {Object.<string, Contestant>} [contestants]
                 */
                const sampleOptions = {
                    connectionLinesWidth: Math.round(2 * 0.35), // Adjust as needed
                    navButtonArrowSize: Math.round(60 * 0.35), // Adjust as needed
                    scrollButtonArrowSize: Math.round(60 * 0.35), // Adjust as needed
                    distanceBetweenScorePairs: Math.round(8 * 0.35), // Adjust as needed
                    mainVerticalPadding: Math.round(10 * 0.35), // Adjust as needed
                    matchAxisMargin: Math.round(15 * 0.35), // Adjust as needed
                    matchFontSize: Math.round(33 * 0.35), // Adjust as needed
                    matchMinVerticalGap: Math.round(50 * 0.35), // Adjust as needed
                    matchHorMargin: Math.round(50 * 0.35), // Adjust as needed
                    scrollbarWidth: Math.round(12 * 0.35), // Adjust as needed
                    connectionLinesColor: "#B22A23",
                    highlightedConnectionLinesColor: "orangered",
                    highlightedPlayerTitleColor: "black",
                    matchTextColor: "white",
                    matchStatusBgColor: "#ff282859",
                    navButtonSvgColor: "#a8ff75",
                    playerTitleFontFamily: "Inter, sans-serif",
                    rootBgColor: "#212529",
                    highlightedPlayerTitleColor: "#de3128",
                    rootBorderColor: "#c54343",
                    scoreFontFamily: "serif",
                    navButtonsPosition: 'overMatches',
                    scrollbarColor: "#ffc107",
                    getMatchTopHTML: (match) => {
                        return `<div class="match-title" style="font-size : 0.6rem !important;">${match.title}</div>`;
                    },
                    onMatchClick: match => {
                        const matchId = match.id;
                        const matchName = match.url_name ? `${match.url_name.replace(/\s/g, "_").replace(/match/gi, "")}` : 'unknown';
                        RouterAssistant.redirect('s_match', {
                            match: matchId,
                            name: matchName
                        })
                    },
                    getNationalityHTML: player => {
                        return `<img style="width: 25px;" src="${player.teamimg}">`
                    }
                }

                // console.debug(binaryTrees[binaryTreeIndex]);
                function processArray(array) {
                    return array.map((item, index) => {
                        let wrapper = document.createElement('div');
                        wrapper.id = 'theWrapper' + globalIndex;
                        parentElement.appendChild(wrapper);
                        globalIndex++;
                        return wrapper;
                    });
                }

                const currentBinaryTree = binaryTrees[binaryTreeIndex];
                const wrappers = processArray(currentBinaryTree);
                binaryTreeIndex++;

                // After the wrappers have been added to the document's DOM, call createBracket for each wrapper
                wrappers.forEach((wrapper, index) => {
                    let item = currentBinaryTree[index];
                    let bracket = createBracket(item, wrapper, sampleOptions);

                    // Find the maximum round index
                    let maxRoundIndex = item.matches.reduce((max, match) => Math.max(max, match.roundIndex), 0);

                    // Set the base round index to the maximum round index
                    bracket.setBaseRoundIndex(maxRoundIndex);
                });

                // Create a new parent element
                parentElement = document.createElement('div');
                parentElement.className = 'tab-pane fade';
                parentElement.id = `pills-${bracketTournamentNameNoSpaces}`;
                parentElement.setAttribute('role', 'tabpanel');
                parentElement.setAttribute('aria-labelledby', `pills-elseScenario-${bracketTournamentNameNoSpaces}-tab`);

                // Append the wrappers to the parent element
                wrappers.forEach(wrapper => parentElement.appendChild(wrapper));

                parentElements.push(parentElement);
            }

            let winnerImg = '';
            if (data.winner_id && teamNames[data.winner_id] && i === data.tournaments.length - 1) {
                for (let j = 0; j < tournament.matches.length; j++) {
                    let match = tournament.matches[j];
                    match.opponents.forEach(opponent => {
                        if (opponent.opponent.id === data.winner_id) {
                            winnerImg = opponent.opponent.image_url;
                        }
                    });
                }
                winnerShow = /*html*/`
        ${teamNames[data.winner_id].name} <img src="${winnerImg}" style="width: 1.4rem; height: 1.4rem; border-radius: 50%;">
        `;
            } else if (i === data.tournaments.length - 1 && (tournament.winner_id && teamNames[tournament.winner_id])) {
                for (let j = 0; j < tournament.matches.length; j++) {
                    let match = tournament.matches[j];
                    match.opponents.forEach(opponent => {
                        if (opponent.opponent.id === tournament.winner_id) {
                            winnerImg = opponent.opponent.image_url;
                        }
                    });
                }
                winnerShow = /*html*/`
        ${teamNames[tournament.winner_id].name} <img src="${winnerImg}" style="width: 1.4rem; height: 1.4rem; border-radius: 50%;">
        `;
            } else {
                winnerShow = '';
            }

            seriesTeams = { ...seriesTeams, ...teamNames };

            if (tournament.expected_roster) {
                for (let roster of tournament.expected_roster) {
                    if (seriesTeams[roster.team.id]) {
                        if (!seriesTeams[roster.team.id].rosters) {
                            seriesTeams[roster.team.id].rosters = [];
                        }
                        seriesTeams[roster.team.id].rosters.push(roster);
                    }
                }
            }
        }
        seriesTeamsCount = Object.keys(seriesTeams).length;
        let finalWinner = '';
        if (winnerShow !== '') {
            finalWinner = /*html*/`
            <li class="list-group-item text-resize-tournament-info-left"><span class="winner-labelP">Winner:</span> <span class="text-resize-tournament-info-right text-white"> ${winnerShow}</span></li>
            `;
        }
        data.totalPrizePool = new Intl.NumberFormat('de-DE').format(data.totalPrizePool);
        data.prizepoolMessage = data.noPrizePoolMsg + (data.totalPrizePool !== "0" ? data.totalPrizePool : '') + ' ' + data.currency;
        let seriesPrizepool = data.prizepoolMessage;
        if (data.actualprizepool && data.totalPrizePool === '0') {
            seriesPrizepool = data.actualprizepool;
        }
        const seriesTier = data.tier.toUpperCase();
        const startTime = data.begin_at ? data.begin_at : data.scheduled_at;
        const endTime = data.end_at ? data.end_at : 'NoDateMsg';
        const dateRaw = new Date(data.begin_at);
        const startTimeDate = new Date(startTime);
        const endTimeDate = endTime !== 'NoDateMsg' ? new Date(endTime) : null;
        const formatedStartTime = startTimeDate.toLocaleString(PostTournamentsComponent.userLanguage, { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long' });
        const formatedEndTime = endTimeDate ? endTimeDate.toLocaleString(PostTournamentsComponent.userLanguage, { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long' }) : 'NoDateMsg';

        let teamsDesktop = '';
        let teamsMobile = '';

        Object.entries(seriesTeams).forEach(([teamId, team]) => {
            if (data.videogame.id !== 30 && data.videogame.id !== 29) {
                let playersListD = '';
                let playersListM = '';
                if (team.rosters && team.rosters[0].players) {
                    for (let i = 0; i < Math.min(team.rosters[0].players.length, 5); i++) {
                        let player = team.rosters[0].players[i];
                        playersListD += `<li>`;
                        playersListM += `<li class="list-group-item">`;

                        if (player.nationality) {
                            playersListD += `<span class="flag-icon flag-icon-${player.nationality.toLowerCase()}"></span>`;
                            playersListM += `<span class="flag-icon flag-icon-${player.nationality.toLowerCase()}"></span>`;
                        }
                        playersListD += ` ${player.name}</li>`;
                        playersListM += ` ${player.name}</li>`;
                    }
                }

                teamsDesktop += /*html*/`
                            <div class="col-4 col-md-4 col-lg-3 col-xxl-2 p-1">
                            <div class="team-name py-1 color-dark rounded-top">${team.name}</div>
                            <div class="team-card rounded-bottom color-lightdark border-0" style="background-image: url(${team.rosters[0].team.image_url});">
                                <div class="team-info rounded-bottom">
                                    <ul>
                                        ${playersListD}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        `;

                teamsMobile += /*html*/`
                                    <div class="col-12">
                        <div class="team-name">${team.name}</div>
                        </div>
                        <div class="col-6">
                        <div class="team-card" style="background-image: url(${team.rosters[0].team.image_url});"></div>
                        </div>
                        <div class="col-6 d-flex align-items-center justify-content-start align-content-center">
                        <ul class="list-group m-0 p-0 text-white team-info-mobile">
                            ${playersListM}
                        </ul>
                        </div>
                        `;
            }

        });
        const seriesname = /*html*/
            `<div class="row g-0 color-dark mb-3 rounded">

                            <div class="img-circle-container-tournament-logo d-flex justify-content-center align-items-center ">
                           <img src="" onerror="this.onerror=null; this.src='';" alt="" class="img-fluid mx-auto d-block unzoom" style="filter: drop-shadow(0 0 1.3rem black);">
                           </div>
                           
                       
                       
                            
                                <div class="col-12 d-flex justify-content-center">
                                    <span class="text-resize-tournament-name text-center text-white mt-2">${data.league.name} ${data.full_name}</span>
                                </div>
                           
                           
      
                                <div class="col-6 d-flex flex-wrap flex-column align-content-around">
                                         <ul class="list-group ">
                                          <li class="list-group-item text-resize-tournament-info-left prizepool-labelP">${seriesPrizepool}</li>
                                          <li class="list-group-item text-resize-tournament-info-left"><span class="start-labelP"></span>  <span class="text-resize-tournament-info-right text-white">${formatedStartTime}</span></li>
                                          <li class="list-group-item text-resize-tournament-info-left"><span class="tier-labelP"></span> <span class="text-resize-tournament-info-right text-white">${seriesTier}</span></li>
                                          </ul>
                                </div>
                                 <div class="col-6 d-flex flex-wrap flex-column align-content-around">
                                      <ul class="list-group ">
                                          <li class="list-group-item text-resize-tournament-info-left teams-compete-labelP"></li>
                                          <li class="list-group-item text-resize-tournament-info-left"><span class="end-labelP"></span> <span class="text-resize-tournament-info-right text-white check-nodate-labelP">${formatedEndTime}</span></li>
                                          ${finalWinner}
                                          </ul>
                                </div>
                           
              
                       
                        
                    </div>
                    <!-- DESKTOP TEAMS PARTICIAPNTS START-->
    <div class="container my-5">
    <div class="row g-4">
        <!-- Team 1 -->
       
        ${teamsDesktop}
    </div>
</div>
<!-- MOBILE TEAMS PARTICIAPNTS START-->
<div class="container my-5 d-none">
  <div class="row">
    
     
         ${teamsMobile}
      </div>
    </div>
  
    </div>


        `
        // console.debug(seriesTeamsCount);
        // console.debug(data.tournaments[0].matches[0].opponents[0].type);
        // console.debug(data.totalPrizePool)
        document.querySelector('#theSeriesContainer').innerHTML = seriesname;



        // Create HTML strings for the pills and the content
        let pillsHTML = '';
        let contentHTML = '';
        let pills2HTML = {};
        let tournamentNames = Object.keys(ladders);
        let matchIdsArrayForListener = [];
        let matchnamesArrayForListener = [];
        let liveWeekButtons = new Set();

        const groupOrder = ['group a', 'group b', 'group c', 'group d'];

        function sortTournamentNames(tournamentNames) {
            return tournamentNames.sort((a, b) => {
                const indexA = groupOrder.indexOf(a.toLowerCase());
                const indexB = groupOrder.indexOf(b.toLowerCase());
                const orderA = indexA === -1 ? groupOrder.length : indexA;
                const orderB = indexB === -1 ? groupOrder.length : indexB;
                return orderA - orderB;
            });
        }
        tournamentNames = sortTournamentNames(tournamentNames);

        for (let i = 0; i < tournamentNames.length; i++) {
            let tournamentName = tournamentNames[i];
            let tournamenNameNoSpaces = tournamentName.replace(/\s/g, '');
            let activeClass = i === 0 ? 'active' : ''; // Make the first pill active
            pills2HTML[i] = [];

            // Add a pill for this tournament
            pillsHTML += `<li class="nav-item text-resize-nav-pills" role="presentation">
                    <button class="nav-link ${activeClass}" id="pills-${tournamentName}-tab" data-bs-toggle="pill" data-bs-target="#pills-${tournamenNameNoSpaces}" type="button" role="tab" aria-controls="pills-${tournamentName}" aria-selected="true">${tournamentName}</button>
                  </li>`;

            // Add content for this tournament
            let hasTiebreakers = ladders[tournamentName].some(team => team.tiebrakersW !== 0 || team.tiebrakersL !== 0);

            contentHTML += `
                <div class="tab-pane fade show ${activeClass}" id="pills-${tournamenNameNoSpaces}" role="tabpanel" aria-labelledby="pills-${tournamentName}-tab">
               <div class="table-responsive">
                    <table class="table table-striped color-dark rounded">
                    <thead>
                        <tr>
                        <th scope="col">#</th>
                        <th scope="col"></th>
                        <th scope="col" class="winsClass"></th>
                        <th scope="col" class="tiesClass"></th>
                        <th scope="col" class="lossesClass"></th>
                        <th scope="col" class="gWinsClass"></th>
                        <th scope="col" class="gLossesClass"></th>
                        ${hasTiebreakers ? '<th scope="col" class="tieBreakerClass">Tiebreakers</th>' : ''}
                        </tr>
                    </thead>
                    <tbody>`;

            for (let j = 0; j < ladders[tournamentName].length; j++) {
                let team = ladders[tournamentName][j];
                let tbHtml = '';
                if (team.tiebrakersW !== 0 || team.tiebrakersL !== 0) {
                    tbHtml = `${team.tiebrakersW} - ${team.tiebrakersL}`;
                }
                contentHTML += `
                <tr>
                <th scope="row">${j + 1}</th>
                <td> <div class="d-flex align-items-center">   
                    <div class=" img-circle-container-team-logo-table d-flex justify-content-center flex-column">
                    <img src=${team.teamlogo} class=" img-fluid mx-auto d-block">
                    </div>             
                    <span class="text-resize-team-name-table ms-3">${team.team}</span>
                    </div>
                   
                </td>
                <td><span class="text-resize-table-info" style="color:green;">${team.wins}</span></td>
                <td><span class="text-resize-table-info">${team.draws}</span></td>
                <td><span class="text-resize-table-info" style="color: red;">${team.losses}</span></td>
                <td><span class="text-resize-table-info">${team.gamesW}</span></td>
                <td><span class="text-resize-table-info">${team.gamesL}</span></td>
                ${hasTiebreakers ? `<td><span class="text-resize-table-info">${tbHtml}</td>` : ''}
                </tr>
                `;
            }

            contentHTML += `
        </tbody>
        </table>
    </div>
    <div style="text-align: center;" id="matchesOfGroup${i}"><span class="eachLaddersMatches">Matches</span>
    `;
            let addedMatches = new Set();
            let matchesArray = [];
            for (let j = 0; j < ladders[tournamentName].length; j++) {
                let team = ladders[tournamentName][j];
                for (let matchId of team.matches) {
                    if (!addedMatches.has(matchId)) {
                        let data = window.savedMatchesFromTournaments[matchId];
                        matchesArray.push({ matchId: matchId, data: data, scheduled_at: data.scheduled_at });
                        addedMatches.add(matchId);
                    }
                }
            }
            if (matchesArray.length !== 0) {
                matchesArray.sort((a, b) => new Date(a.scheduled_at) - new Date(b.scheduled_at));
                let weeklyMatches = [];
                console.debug(matchesArray);
                let weekStart = new Date(matchesArray[0].scheduled_at);
                weekStart.setHours(0, 0, 0, 0);
                let currentWeekMatches = [];


                for (let match of matchesArray) {
                    let matchDate = new Date(match.scheduled_at);
                    let weekEnd = new Date(weekStart);
                    weekEnd.setDate(weekEnd.getDate() + 7);

                    // Check if the current date is within the week being processed
                    let now = new Date();
                    let minusOnenow = new Date(now);
                    minusOnenow.setDate(now.getDate() + 1); // add a day due to the need to show the live week if its starting tomorrow
                    if (minusOnenow >= weekStart && now < weekEnd) {
                        liveWeekButtons.add(`${i}pills-day${weeklyMatches.length}-tab`);
                    }

                    if (matchDate < weekEnd) {
                        currentWeekMatches.push(match);
                    } else {
                        weeklyMatches.push(currentWeekMatches);
                        currentWeekMatches = [match];
                        weekStart = new Date(matchDate);
                        weekStart.setHours(0, 0, 0, 0);
                    }
                }

                weeklyMatches.push(currentWeekMatches);
                console.debug(weeklyMatches);

                for (let weekIndex = 0; weekIndex < weeklyMatches.length; weekIndex++) {
                    let activeClass = weekIndex === 0 ? 'show active' : '';
                    pills2HTML[i].push(`<li class="nav-item text-resize-nav-pills" role="presentation">
                                    <button class="nav-link ${activeClass} weekButton" id="${i}pills-day${weekIndex}-tab" data-bs-toggle="pill" data-bs-target="#${i}pills-day${weekIndex}" type="button" role="tab" aria-controls="${i}pills-day${weekIndex}" aria-selected="false">Week ${weekIndex + 1}</button>
                                </li>`)
                }
                contentHTML += `              <ul class="nav nav-pills mb-3" id="daily-pills-tab" role="tablist">
                      ${pills2HTML[i].join('')}
                    </ul> 
                    <div class="tab-content" id="daily-pills-tabContent"> `;
                // Iterate over weeklyMatches instead of matchesArray
                for (let weekIndex = 0; weekIndex < weeklyMatches.length; weekIndex++) {
                    let matchesForWeek = weeklyMatches[weekIndex];

                    let activeClass = weekIndex === 0 ? 'show active' : '';

                    // Create a container for the matches of each day
                    contentHTML += `                                  
                    <div class="tab-pane fade ${activeClass}" id="${i}pills-day${weekIndex}" role="tabpanel" aria-labelledby="${i}pills-day${weekIndex}-tab">
                    `;

                    // Iterate over the matches for the current day
                    for (let match of matchesForWeek) {
                        let matchId = match.matchId;
                        let data = window.savedMatches && window.savedMatches[matchId] ? window.savedMatches[matchId] : match.data;
                        const matchTourId = `matchfrompostTour-${data.id}`;
                        matchIdsArrayForListener.push(matchTourId);
                        matchnamesArrayForListener.push(data.name ? `${data.name.replace(/\s/g, "_").replace(/match/gi, "")}` : 'unknown');
                        const team1name = data.opponents[0].opponent.name;
                        const team1id = data.opponents[0].opponent.id;
                        const team1img = data.opponents[0].opponent.image_url;

                        const team2name = data.opponents[1].opponent.name;
                        const team2id = data.opponents[1].opponent.id;
                        const team2img = data.opponents[1].opponent.image_url;

                        const matchTypeRaw = data.match_type;
                        const numberOfGames = data.number_of_games;
                        let matchTypeFinal = ``;
                        if (matchTypeRaw === 'best_of' && (numberOfGames !== 2 && numberOfGames !== 1)) {
                            matchTypeFinal = `best_of_${numberOfGames}`;
                        } else if (matchTypeRaw === 'best_of' && numberOfGames === 1) {
                            matchTypeFinal = 'single_game';
                        } else if (matchTypeRaw === 'best_of' && numberOfGames === 2) {
                            matchTypeFinal = 'bestof_2';
                        } else if (matchTypeRaw === 'all_games_played') {
                            matchTypeFinal = 'all_games_played';
                        } else if (matchTypeRaw === 'first_to') {
                            matchTypeFinal = 'first_to_X';
                        } else {
                            matchTypeFinal = 'general_drop_to';
                        }
                        const matchType = matchTypeRaw.charAt(0).toUpperCase() + matchTypeRaw.slice(1).replace('_', ' ');
                        const matchTypefull = `${matchType} ${data.number_of_games.toString()}`;

                        const score = `${data.results[0].score.toString()} - ${data.results[1].score.toString()}`;
                        const winnerId = data.winner && data.winner.id ? data.winner.id : data.winner_id;
                        const team1Element = document.getElementById(`tourMatch-${matchId}-team-${team1id}`);
                        const team2Element = document.getElementById(`tourMatch-${matchId}-team-${team2id}`);
                        // If winnerId exists and it matches team1id or team2id, add a CSS class to the corresponding team
                        if (winnerId) {
                            if (winnerId === team1id && team1Element) {
                                team1Element.classList.add('winner-left');
                            } else if (winnerId === team2id && team2Element) {
                                team2Element.classList.add('winner-right');
                            }
                        }

                        const dateRaw = new Date(data.begin_at ? data.begin_at : data.scheduled_at);
                        const date = `${dateRaw.toLocaleDateString(MatchesComponent.userLanguage, {
                            weekday: 'short', // "short" for abbreviated weekday name (e.g., Mon)
                            day: 'numeric',   // numeric day of the month
                            month: 'short'    // "short" for abbreviated month name (e.g., Feb)
                        })} ${dateRaw.toLocaleTimeString(MatchesComponent.userLanguage, {
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: false
                        })}`;
                        contentHTML += /*html*/
                            `
                <div id="${matchTourId}" class="row mt-3 rounded text-white color-lightdark p-0 clickable" style="position: relative;">
                <div class="col-12 mb-2 color-dark rounded-top p-2">
                <div class="text-white text-resize-date  d-flex align-items-center align-content-center justify-content-center">
                       <span class=""> <i class="me-1 bi bi-clock"></i> ${date}</span>
                        </div>
                    </div>
                
                    <div class="col-5 mt-2 d-flex justify-content-end align-items-center ${winnerId === team1id ? 'winner-left' : ''}" id="tourMatch-${matchId}-team-${team1id}">
                    <span class="text-resize-team-name mx-2 my-0 ">${team1name}</span>
                        <div class=" img-circle-container-team-logo d-flex justify-content-center flex-column m-2 ">
                        <img src="${team1img}" alt="" class="img-fluid mx-auto d-block" >
                        </div>
                    </div>
                    <div class="col-2 gay-bg d-flex flex-column align-items-center justify-content-around">
                    <p class="text-resize-score m-0">${score}</p>
                    </div>
                    <div class="col-5 d-flex justify-content-start align-items-center ${winnerId === team2id ? 'winner-right' : ''}" id="tourMatch-${matchId}-team-${team2id}">
                        <div class=" img-circle-container-team-logo d-flex justify-content-center flex-column m-2 ">
                        <img src="${team2img}" alt="" class="img-fluid mx-auto d-block" >
                        </div>
                    <span class="text-resize-team-name mx-2 my-0 ">${team2name}</span>
                    </div>
                   
                    <div class="col-12 text-center  p-0 m-0">
                    <span class="mb-1 badge bg-secondary text-resize-badge matchTypeFromPostTournaments">${matchTypeFinal}</span>   
                    </div>
                    
                 </div>
                 `;
                    }
                    contentHTML += `</div>`;
                }
            } else {
                contentHTML += `<div> Matches are yet to be decided, stay tuned</div>`;
            }
            contentHTML += `
            </div>
    </div>
    </div>
    `;
        }
        for (let i = 0; i < bracketTournamentNamesNoSpaces.length; i++) {
            pillsHTML += `<li class="nav-item text-resize-nav-pills" role="presentation">
        <button class="nav-link" id="pills-${bracketTournamentNamesNoSpaces[i]}-tab" data-bs-toggle="pill" data-bs-target="#pills-${bracketTournamentNamesNoSpaces[i]}" type="button" role="tab" aria-controls="pills-${bracketTournamentNamesNoSpaces[i]}" aria-selected="false">${bracketTournamentNames[i]}</button>
    </li>`;
        }
        // Wrap the HTML strings in the appropriate Bootstrap structure
        let finalHTML = `<div class="container">
                    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                      ${pillsHTML}
                    </ul>
                    <div class="tab-content" id="pills-tabContent">
                      ${contentHTML}
                    </div>
                 </div>`;

        // Insert the HTML string into the DOM
        console.debug(parentElements);
        document.querySelector('#ladderContainer').innerHTML = finalHTML;

        let container = document.querySelector('#pills-tabContent');
        parentElements.forEach(parentElement => container.appendChild(parentElement));

        // Initialize the pills
        let pillElements = document.querySelectorAll('#pills-tab .nav-link');
        for (let i = 0; i < pillElements.length; i++) {
            new bootstrap.Tab(pillElements[i]);
        }
        // Initialize the pills
        let pillElements2 = document.querySelectorAll('#daily-pills-tab .nav-link');
        for (let i = 0; i < pillElements2.length; i++) {
            new bootstrap.Tab(pillElements2[i]);
        }
        Array.from(liveWeekButtons).forEach(buttonId => {
            let element = document.getElementById(buttonId);
            if (element) {
                element.classList.add("styleTheLiveWeek");
                element.innerText = element.innerText + " : LIVE! ";
            }
        });
        PostTournamentsComponent.translations(data, seriesTeamsCount);
        PostTournamentsComponent.redirectToMatchFromTour(matchIdsArrayForListener, matchnamesArrayForListener);
        console.debug(ladders);
    }


    /**
    * Sort matches by `scheduled_at` in reverse order
    * @param {Array} matches Array of matches
    * @returns {Array} Array of matches
    */
    static sortInReverseChronologicalOrder(matches) {
        if (!matches) {
            console.error('Matches is undefined');
            return [];
        }
        return matches.sort((matchA, matchB) => {
            return DateTime.fromISO(matchB.scheduled_at).toMillis() - DateTime.fromISO(matchA.scheduled_at).toMillis()
        })
    }




    /**
     * Creates and return a binary tree where each node represents the winner or loser of a match
     * @param {Array} matches Array of matches
     * @returns {Object} A binary tree
     */
    static formatAsTree(matches) {
        // Sort matches for easier Finals access
        const orderedMatches = PostTournamentsComponent.sortInReverseChronologicalOrder(matches)
        console.debug(orderedMatches);
        // console.debug(orderedMatches);
        const findMatchById = (matchId) => orderedMatches.find(({ id }) => id === matchId)
        const countRounds = (match) => {
            if (!match) {
                return 0;
            }
            let rounds = 1; // count current match as a round
            if (match.previous_matches.length) {
                // count rounds in previous matches
                rounds += Math.max(
                    countRounds(findMatchById(match.previous_matches[0].match_id)),
                    match.previous_matches.length === 2 ? countRounds(findMatchById(match.previous_matches[1].match_id)) : 0
                );
            }
            return rounds;
        }
        const buildMatchTree = (match, previousType, round = countRounds(match), order = 0) => {
            if (!match || match.status === 'canceled' && match.winner_id === null) {
                return {}
            }
            // console.debug(window.savedMatches[match.id]);
            console.debug(match);
            let team1name = 'TBD';
            let team2name = 'TBD';
            let team1score = 0;
            let team2score = 0;
            let team1img = '';
            let team2img = '';
            let team1id = Math.floor(Math.random() * 9000000 + 1000000).toString();
            let team2id = Math.floor(Math.random() * 9000000 + 1000000).toString();
            if (window.savedMatches && window.savedMatches[match.id] && window.savedMatches[match.id].opponents[0] && window.savedMatches[match.id].opponents[0].opponent && window.savedMatches[match.id].opponents[0].opponent.name) {
                team1name = window.savedMatches[match.id].opponents[0].opponent.name;
            } else if (match.opponents[0] && match.opponents[0].opponent && match.opponents[0].opponent.name) {
                team1name = match.opponents[0].opponent.name;
            }

            if (window.savedMatches && window.savedMatches[match.id] && window.savedMatches[match.id].opponents[1] && window.savedMatches[match.id].opponents[1].opponent && window.savedMatches[match.id].opponents[1].opponent.name) {
                team2name = window.savedMatches[match.id].opponents[1].opponent.name;
            } else if (match.opponents[1] && match.opponents[1].opponent && match.opponents[1].opponent.name) {
                team2name = match.opponents[1].opponent.name;
            }

            if (window.savedMatches && window.savedMatches[match.id] && window.savedMatches[match.id].results[0] && window.savedMatches[match.id].results[0].score) {
                team1score = window.savedMatches[match.id].results[0].score;
            } else if (match.results[0] && match.results[0].score) {
                team1score = match.results[0].score;
            }
            if (window.savedMatches && window.savedMatches[match.id] && window.savedMatches[match.id].results[1] && window.savedMatches[match.id].results[1].score) {
                team2score = window.savedMatches[match.id].results[1].score;
            } else if (match.results[1] && match.results[1].score) {
                team2score = match.results[1].score;
            }

            if (window.savedMatches && window.savedMatches[match.id] && window.savedMatches[match.id].opponents[0] && window.savedMatches[match.id].opponents[0].opponent && window.savedMatches[match.id].opponents[0].opponent.id) {
                team1id = window.savedMatches[match.id].opponents[0].opponent.id;
            } else if (match.opponents[0] && match.opponents[0].opponent && match.opponents[0].opponent.id) {
                team1id = match.opponents[0].opponent.id;
            }
            if (window.savedMatches && window.savedMatches[match.id] && window.savedMatches[match.id].opponents[1] && window.savedMatches[match.id].opponents[1].opponent && window.savedMatches[match.id].opponents[1].opponent.id) {
                team2id = window.savedMatches[match.id].opponents[1].opponent.id;
            } else if (match.opponents[1] && match.opponents[1].opponent && match.opponents[1].opponent.id) {
                team2id = match.opponents[1].opponent.id;
            }
            if (window.savedMatches && window.savedMatches[match.id] && window.savedMatches[match.id].opponents[0] && window.savedMatches[match.id].opponents[0].opponent && window.savedMatches[match.id].opponents[0].opponent.image_url) {
                team1img = window.savedMatches[match.id].opponents[0].opponent.image_url;
            } else if (match.opponents[0] && match.opponents[0].opponent && match.opponents[0].opponent.image_url) {
                team1img = match.opponents[0].opponent.image_url;
            }
            if (window.savedMatches && window.savedMatches[match.id] && window.savedMatches[match.id].opponents[1] && window.savedMatches[match.id].opponents[1].opponent && window.savedMatches[match.id].opponents[1].opponent.image_url) {
                team2img = window.savedMatches[match.id].opponents[1].opponent.image_url;
            } else if (match.opponents[1] && match.opponents[1].opponent && match.opponents[1].opponent.image_url) {
                team2img = match.opponents[1].opponent.image_url;
            }

            const maxRoundIndex = round - 1;
            const treeNode = {
                id: match.id,
                name: match.name.split(':')[0].trim(),
                url_name: match.name,
                team1: team1name,
                team2: team2name,
                team1id: team1id,
                team2id: team2id,
                team1img: team1img,
                team2img: team2img,
                score1: team1score,
                score2: team2score,
                type: previousType,
                rounds: round,
                roundIndex: maxRoundIndex,
                order: order,
                matchwinner: match.winner_id,

            }
            // If the node represents a match loser, we should not continue building this branch
            // Because the match already appears in the winner bracket

            if (match.previous_matches.length) {
                treeNode.prevLeft = buildMatchTree(findMatchById(match.previous_matches[0].match_id), match.previous_matches[0].type, round - 1, order * 2)
            }
            if (match.previous_matches.length === 2) {
                treeNode.prevRight = buildMatchTree(findMatchById(match.previous_matches[1].match_id), match.previous_matches[1].type, round - 1, order * 2 + 1)
            }



            return treeNode
        }

        // Find all initial matches (matches that don't have any previous matches)
        const initialMatches = orderedMatches.filter(match => match.previous_matches.length === 0);

        // Find the root match for each initial match
        const rootMatchesSet = new Set();

        function findRoots(match) {
            let nextMatches = orderedMatches.filter(nextMatch => nextMatch.previous_matches.some(prevMatch => prevMatch.match_id === match.id));
            if (nextMatches.length === 0) {
                rootMatchesSet.add(match);
            } else {
                nextMatches.forEach(findRoots);
            }
        }

        initialMatches.forEach(findRoots);

        // Convert the Set to an array
        const rootMatches = Array.from(rootMatchesSet);
        // Build a separate tree for each root match
        const trees = rootMatches.map(rootMatch => buildMatchTree(rootMatch)).reverse();
        const sortedTrees = trees.sort((a, b) => a.id - b.id);
        console.debug(sortedTrees);
        const treesBracketry = sortedTrees.filter(tree => Object.keys(tree).length > 0).map(tree => PostTournamentsComponent.formatTreesAsBracketry(tree));
        console.debug(treesBracketry);
        // console.debug(sortedTrees);
        return treesBracketry;
    }

    static formatTreesAsBracketry(treeNode) {
        const sampleData2 = {
            rounds: [],
            matches: [],
            contestants: {}
        };
        const traverseTree = (node, parent) => {
            if (!node) return;

            if (node.matchwinner === null) {
                node.matchwinner = Math.floor(Math.random() * 9000000 + 1000000);
            }
            const match = {
                title: node.name,
                url_name: node.url_name,
                id: node.id,
                roundIndex: node.roundIndex,
                order: node.order,
                sides: [
                    {
                        contestantId: node.team1id.toString(),
                        scores: [{ mainScore: node.score1.toString(), isWinner: node.type === 'loser' ? node.matchwinner !== node.team1id : node.matchwinner === node.team1id }],
                        isWinner: node.type === 'loser' ? node.matchwinner === node.team2id : node.matchwinner === node.team1id
                    },
                    {
                        contestantId: node.team2id.toString(),
                        scores: [{ mainScore: node.score2.toString(), isWinner: node.type === 'loser' ? node.matchwinner !== node.team2id : node.matchwinner === node.team2id }],
                        isWinner: node.type === 'loser' ? node.matchwinner === node.team1id : node.matchwinner === node.team2id
                    }
                ]
            };


            sampleData2.matches.push(match);

            if (!sampleData2.contestants[node.team1id]) {
                sampleData2.contestants[node.team1id] = { players: [{ title: node.team1, teamimg: node.team1img, }] };
            }

            if (!sampleData2.contestants[node.team2id]) {
                sampleData2.contestants[node.team2id] = { players: [{ title: node.team2, teamimg: node.team2img, }] };
            }

            traverseTree(node.prevLeft, node);
            traverseTree(node.prevRight, node);
        };

        traverseTree(treeNode);

        for (let i = 0; i < treeNode.rounds; i++) {
            sampleData2.rounds.push({});
        }
        return sampleData2;
    }

    static redirectToMatchFromTour(mIDs, mNames) {
        mIDs.forEach(mID => {
            const element = document.getElementById(mID);
            const alteredmID = mID.replace('matchfrompostTour-', '');
            if (element) {
                element.addEventListener('click', function (evt) {
                    RouterAssistant.redirect('s_match', {
                        match: alteredmID,
                        name: mNames[mIDs.indexOf(mID)]
                    })
                });
            }
        });
    }

}



PostTournamentsComponent.template = /*html*/ `
<div id="theSeriesContainer"></div>
    <div id="ladderContainer"></div>
    <div id="postForTournamentBrackets"></div>

`;

PostTournamentsComponent.reg('posttournaments-component');
