import { BaseComponent } from "@symbiotejs/symbiote";

const player = require('./assets/dendi.jpg')
const dota2logo = require('../game-navbar/assets/images/dota2logo 150x150.png')
const css = `
    <style>
    .timeline-with-icons {
  border-left: 1px solid hsl(0, 0%, 90%);
  position: relative;
  list-style: none;
   
}

.timeline-with-icons .timeline-item {
  position: relative;
}

.timeline-with-icons .timeline-item:after {
  position: absolute;
  display: block;
  top: 0;
}

.timeline-with-icons .timeline-icon {
  position: absolute;
  left: -48px;
  background-color: hsl(217, 88.2%, 90%);
  color: hsl(217, 88.8%, 35.1%);
  border-radius: 50%;
  height: 31px;
  width: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
}
 upcoming-matches .text-resize{
 font-size: 0.6rem;
 }
 upcoming-matches .text-resize-bigger{
 font-size: 0.8rem;
 }
.img-resize{
width: 100px;
height: 100px;
object-fit: cover;
}
@media (min-width: 1200px) {
    .responsiveHotText {
        font-size: 1.1rem;
         
    }
    .score-resize {
        font-size: 1.5rem;
}
    .responsiveHotImg {
      max-width: 4rem !important;
}
 upcoming-matches .card-text, upcoming-matches i{
 font-size: 0.7rem;
 margin-bottom:1rem;
 }
 upcoming-matches .card{
 background-color: transparent;
 border: none;
 }
.team-hot-resize{
font-size: 1.5rem;
margin:0;
}
}
@media (max-width:  776px) {
    .responsiveHot {
        font-size: 1.1rem; 
}
      .score-resize {
        margin-top: 1rem;
        font-size: 0.8rem;
} 
 .responsiveHotImg {
      max-width: 25% !important;
}
.team-hot-resize{
font-size: 1rem;
margin:0;
}
}      
    
    </style>
`

export class upcomingMatches extends BaseComponent {

  initCallback() {
    document.head.insertAdjacentHTML("beforeend", css)
    window.addEventListener('resize', upcomingMatches.performOrientationChangeActions);
    upcomingMatches.performOrientationChangeActions();
    upcomingMatches.resizeTimelinetoTwitchHeight();

  }

  static performOrientationChangeActions() {
    if (document.querySelectorAll('upcoming-matches').length !== 0) { // make sure the event listener only runs when the component exists in the page
      if (window.innerHeight > window.innerWidth) { // mobile

      } else { // desktop

      }
      upcomingMatches.resizeTimelinetoTwitchHeight();
      console.debug("fuck you");
    }

  }


  static resizeTimelinetoTwitchHeight() {
    const twitchIframe = document.getElementById('twitch-home');
    if (twitchIframe) {
      // Get the current width of the Twitch iframe
      const width = twitchIframe.offsetWidth;
      // Compute the height based on the 16:9 aspect ratio
      const height = width * (9 / 16); // For a 16:9 aspect ratio, height is width * (9/16)
      // Now, apply this height to the timeline
      const timelineHome = document.getElementById('timeline-home');
      if (timelineHome) {
        timelineHome.style.height = `${height}px`; // Set the computed height
        timelineHome.style.overflow = 'scroll'; // Ensure overflow is set to scroll
      } else {
        console.debug('Timeline element not found');
      }
    } else {
      console.debug('Twitch iframe element not found');
    }
  }
}




upcomingMatches.template = `
 
<div class="container p-0 text-white my-5 ">

  <div class="row mt-xs-1 mt-sm-1 mt-md-1 mt-lg-2 mt-xl-2 mt-xxl-2 mt-2 mt-5 rounded-top color-dark " >
    <div class="col-12 p-0 d-flex justify-content-between">
      <div class="text-start d-flex py-1 rounded-start " style="background-color:#702525">
        <img src="${dota2logo}" alt="tournament logo" class="img-fluid object-fit-scale ms-2" style="max-width: 1.5rem"> 
        <p class="m-0 mx-2 ">tournament name</p>
      </div>
      <div class="text-end">
        
        <span class="badge text-resize mx-2" style="background: rgb(219, 16, 27);">LIVE</span>
      </div>
    </div>
    <div class="row my-4">
    <div class="col-5 d-flex justify-content-end align-items-center ">
      <p class="team-hot-resize mx-2 my-0 ">Team 1</p>
      <img src="${dota2logo}" alt="" class="img-fluid object-fit-scale responsiveHotImg" style="max-width: 4rem">
    </div>
    <div class="col-2 align-self-center text-center">
      <span class="score-resize m-0">0 - 0</span>
    </div>
    <div class="col-5 d-flex justify-content-start align-items-center ">
      <img src="${dota2logo}" alt="" class="img-fluid object-fit-scale responsiveHotImg" style="max-width: 4rem">
      <p class="team-hot-resize mx-2 my-0  ">Team 2</p>
    </div>
</div>
  </div>
  
  <div  class="row color-dark rounded-bottom">
    
    <!-- Column for Twitch Embed Player -->
    <div  class="col-sm-12 col-md-12 col-lg-12 col-xl-7 col-xxl-7 d-flex  justify-content-center my-2 ">
      <!-- Assuming you have a Twitch embed link, replace 'your_twitch_embed_link' with the actual link -->
     <div  class="ratio ratio-16x9 ">
        <iframe id="twitch-home"
    src="https://player.twitch.tv/?channel=LITofficial&&parent=${window.location.hostname}"
  width="100%" 
  height="100%" 

  

allowfullscreen="true">
</iframe>
      </div>
    </div>
    
    
    
    <div class="col-5 my-2 ">
    
    <div class="py-1 ">
  <ul id="timeline-home" class="timeline-with-icons m-0 scrollbar">
    <li class="timeline-item mb-5">
      <span class="timeline-icon">
        <i class="fas fa-rocket text-primary fa-sm fa-fw"></i>
      </span>

      <h5 class="fw-bold">Our company starts its operations</h5>
      <p class="text-muted mb-2 fw-bold">11 March 2020</p>
      <p class="text-muted">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit
        necessitatibus adipisci, ad alias, voluptate pariatur officia
        repellendus repellat inventore fugit perferendis totam dolor
        voluptas et corrupti distinctio maxime corporis optio?
      </p>
    </li>

    <li class="timeline-item mb-5">

      <span class="timeline-icon">
        <i class="fas fa-hand-holding-usd text-primary fa-sm fa-fw"></i>
      </span>
      <h5 class="fw-bold">First customer</h5>
      <p class="text-muted mb-2 fw-bold">19 March 2020</p>
      <p class="text-muted">
        Quisque ornare dui nibh, sagittis egestas nisi luctus nec. Sed
        aliquet laoreet sapien, eget pulvinar lectus maximus vel.
        Phasellus suscipit porta mattis.
      </p>
    </li>

    <li class="timeline-item mb-5">

      <span class="timeline-icon">
        <i class="fas fa-users text-primary fa-sm fa-fw"></i>
      </span>
      <h5 class="fw-bold">Our team exceeds 10 people</h5>
      <p class="text-muted mb-2 fw-bold">24 June 2020</p>
      <p class="text-muted">
        Orci varius natoque penatibus et magnis dis parturient montes,
        nascetur ridiculus mus. Nulla ullamcorper arcu lacus, maximus
        facilisis erat pellentesque nec. Duis et dui maximus dui aliquam
        convallis. Quisque consectetur purus erat, et ullamcorper sapien
        tincidunt vitae.
      </p>
    </li>

    <li class="timeline-item mb-5">

      <span class="timeline-icon">
        <i class="fas fa-money-bill-wave text-primary fa-sm fa-fw"></i>
      </span>
      <h5 class="fw-bold">Earned the first million $!</h5>
      <p class="text-muted mb-2 fw-bold">15 October 2020</p>
      <p class="text-muted">
        Nulla ac tellus convallis, pulvinar nulla ac, fermentum diam. Sed
        et urna sit amet massa dapibus tristique non finibus ligula. Nam
        pharetra libero nibh, id feugiat tortor rhoncus vitae. Ut suscipit
        vulputate mattis.
      </p>
    </li>
  </ul>
</div>
<!-- Section: Timeline -->
</div>
    </div>
  
  
  
  
  
  
  
<!--
  <div class="row">
  <div class="col-sm-12 col-xs-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6">
  <div class="row mt-xs-1 mt-sm-1 mt-md-1 mt-lg-2 mt-xl-2 mt-xxl-2 mt-2  mx-xs-1 mx-sm-1 mx-md-1 mx-lg-1 mx-xl-1 mx-xxl-1 mx-1 rounded border color-dark " >    
  
  <div class="col-12 p-0 d-flex justify-content-between">
      <div class="text-start d-flex p-1 rounded-start " style="background-color:#0f2b46">
        <img src="${dota2logo}" alt="tournament logo" class="img-fluid object-fit-scale" style="max-width: 1.5rem"> <span class="m-0 ms-2 text-resize-bigger">tournament name</span>
      </div>
      <div class="text-end">
        <i class="bi bi-clock text-resize"><span class="text-resize ms-1 me-1">15:00</span></i>
        
      </div>
    </div>
  
   
      <div class="col-5 d-flex justify-content-end align-items-center ">
      <p class="fs-6 m-0">Team 1</p>
      <img src="${dota2logo}" alt="" class="img-fluid object-fit-scale w-25" >
    </div>
    
    <div class="col-2 justify-content-around d-flex align-items-center ">
      <p class="score-resize">0 - 0</p>
    </div>
    
    <div class="col-5 d-flex justify-content-start align-items-center ">
      <img src="${dota2logo}" alt="" class="img-fluid object-fit-scale w-25">
      <p class="fs-6 m-0">Team 2</p>
    </div>
      <div class="col-12 text-center">
      <span class="badge bg-secondary text-resize">BO3</span>
    </div>
     </div>
     
     
     
     
     
     
     
     
     
     <div class="row mt-xs-1 mt-sm-1 mt-md-1 mt-lg-2 mt-xl-2 mt-xxl-2 mt-2  mx-xs-1 mx-sm-1 mx-md-1 mx-lg-1 mx-xl-1 mx-xxl-1 mx-1  rounded border  color-dark ">    
  
  <div class="col-12 p-0 d-flex justify-content-between">
      <div class="text-start d-flex p-1 rounded-start " style="background-color:#0f2b46">
        <img src="${dota2logo}" alt="tournament logo" class="img-fluid object-fit-scale" style="max-width: 1.5rem"> <span class="m-0 ms-2 text-resize-bigger">tournament name</span>
      </div>
      <div class="text-end">
        <i class="bi bi-clock text-resize"><span class="text-resize ms-1 me-1">15:00</span></i>
        
      </div>
    </div>
  
   
      <div class="col-5 d-flex justify-content-end align-items-center ">
      <p class="fs-6 m-0">Team 1</p>
      <img src="${dota2logo}" alt="" class="img-fluid object-fit-scale w-25" >
    </div>
    
    <div class="col-2 justify-content-around d-flex align-items-center ">
      <p class="score-resize">0 - 0</p>
    </div>
    
    <div class="col-5 d-flex justify-content-start align-items-center ">
      <img src="${dota2logo}" alt="" class="img-fluid object-fit-scale w-25">
      <p class="fs-6 m-0">Team 2</p>
    </div>
      <div class="col-12 text-center">
      <span class="badge bg-secondary text-resize">BO3</span>
    </div>
     </div>
     
     
     
     
     
     
     
     
     
     <div class="row mt-xs-1 mt-sm-1 mt-md-1 mt-lg-2 mt-xl-2 mt-xxl-2 mt-2  mx-xs-1 mx-sm-1 mx-md-1 mx-lg-1 mx-xl-1 mx-xxl-1 mx-1  rounded border  color-dark ">   
  
  <div class="col-12 p-0 d-flex justify-content-between">
      <div class="text-start d-flex p-1 rounded-start " style="background-color:#0f2b46">
        <img src="${dota2logo}" alt="tournament logo" class="img-fluid object-fit-scale" style="max-width: 1.5rem"> <span class="m-0 ms-2 text-resize-bigger">tournament name</span>
      </div>
      <div class="text-end">
        <i class="bi bi-clock text-resize"><span class="text-resize ms-1 me-1">15:00</span></i>
        
      </div>
    </div>
  
   
      <div class="col-5 d-flex justify-content-end align-items-center ">
      <p class="fs-6 m-0">Team 1</p>
      <img src="${dota2logo}" alt="" class="img-fluid w-25" >
    </div>
    
    <div class="col-2 justify-content-around d-flex align-items-center ">
      <p class="score-resize">0 - 0</p>
    </div>
    
    <div class="col-5 d-flex justify-content-start align-items-center ">
      <img src="${dota2logo}" alt="" class="img-fluid object-fit-scale w-25">
      <p class="fs-6 m-0">Team 2</p>
    </div>
      <div class="col-12 text-center">
      <span class="badge bg-secondary text-resize">BO3</span>
    </div>
     </div>
    </div>
    
    
    
    
    
    
    
    
    
    
  <div class="col-sm-12 col-xs-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6 ">
      <div class="row mt-xs-1 mt-sm-1 mt-md-1 mt-lg-2 mt-xl-2 mt-xxl-2 mt-2  mx-xs-1 mx-sm-1 mx-md-1 mx-lg-1 mx-xl-1 mx-xxl-1 mx-1  rounded border  color-dark ">   
  
  <div class="col-12 p-0 d-flex justify-content-between">
      <div class="text-start d-flex p-1 rounded-start " style="background-color:#0f2b46">
        <img src="${dota2logo}" alt="tournament logo" class="img-fluid object-fit-scale" style="max-width: 1.5rem"> <span class="m-0 ms-2 text-resize-bigger">tournament name</span>
      </div>
      <div class="text-end">
        <i class="bi bi-clock text-resize"><span class="text-resize ms-1 me-1">15:00</span></i>
        
      </div>
    </div>
  
   
      <div class="col-5 d-flex justify-content-end align-items-center ">
      <p class="fs-6 m-0">Team 1</p>
      <img src="${dota2logo}" alt="" class="img-fluid object-fit-scale w-25" >
    </div>
    
    <div class="col-2 justify-content-around d-flex align-items-center ">
      <p class="score-resize">0 - 0</p>
    </div>
    
    <div class="col-5 d-flex justify-content-start align-items-center ">
      <img src="${dota2logo}" alt="" class="img-fluid object-fit-scale w-25">
      <p class="fs-6 m-0">Team 2</p>
    </div>
      <div class="col-12 text-center">
      <span class="badge bg-secondary text-resize">BO3</span>
    </div>
     </div>
     
     
     
     
     
     
     
     
     
     <div class="row mt-xs-1 mt-sm-1 mt-md-1 mt-lg-2 mt-xl-2 mt-xxl-2 mt-2  mx-xs-1 mx-sm-1 mx-md-1 mx-lg-1 mx-xl-1 mx-xxl-1 mx-1  rounded border  color-dark ">   
  
  <div class="col-12 p-0 d-flex justify-content-between">
      <div class="text-start d-flex p-1 rounded-start " style="background-color:#0f2b46">
        <img src="${dota2logo}" alt="tournament logo" class="img-fluid object-fit-scale" style="max-width: 1.5rem"> <span class="m-0 ms-2 text-resize-bigger">tournament name</span>
      </div>
      <div class="text-end">
        <i class="bi bi-clock text-resize"><span class="text-resize ms-1 me-1">15:00</span></i>
        
      </div>
    </div>
  
   
      <div class="col-5 d-flex justify-content-end align-items-center ">
      <p class="fs-6 m-0">Team 1</p>
      <img src="${dota2logo}" alt="" class="img-fluid object-fit-scale w-25" >
    </div>
    
    <div class="col-2 justify-content-around d-flex align-items-center ">
      <p class="score-resize">0 - 0</p>
    </div>
    
    <div class="col-5 d-flex justify-content-start align-items-center ">
      <img src="${dota2logo}" alt="" class="img-fluid object-fit-scale w-25">
      <p class="fs-6 m-0">Team 2</p>
    </div>
      <div class="col-12 text-center">
      <span class="badge bg-secondary text-resize">BO3</span>
    </div>
     </div>
     
     
     
     
     
     
     
     
     
     <div class="row mt-xs-1 mt-sm-1 mt-md-1 mt-lg-2 mt-xl-2 mt-xxl-2 mt-2  mx-xs-1 mx-sm-1 mx-md-1 mx-lg-1 mx-xl-1 mx-xxl-1 mx-1  rounded border  color-dark ">   
  
  <div class="col-12 p-0 d-flex justify-content-between">
      <div class="text-start d-flex p-1 rounded-start " style="background-color:#0f2b46">
        <img src="${dota2logo}" alt="tournament logo" class="img-fluid object-fit-scale" style="max-width: 1.5rem"> <span class="m-0 ms-2 text-resize-bigger">tournament name</span>
      </div>
      <div class="text-end">
        <i class="bi bi-clock text-resize"><span class="text-resize ms-1 me-1">15:00</span></i>
        
      </div>
    </div>
  
   
      <div class="col-5 d-flex justify-content-end align-items-center ">
      <p class="fs-6 m-0">Team 1</p>
      <img src="${dota2logo}" alt="" class="img-fluid object-fit-scale w-25" >
    </div>
    
    <div class="col-2 justify-content-around d-flex align-items-center ">
      <p class="score-resize">0 - 0</p>
    </div>
    
    <div class="col-5 d-flex justify-content-start align-items-center ">
      <img src="${dota2logo}" alt="" class="img-fluid object-fit-scale w-25">
      <p class="fs-6 m-0">Team 2</p>
    </div>
      <div class="col-12 text-center">
      <span class="badge bg-secondary text-resize">BO3</span>
    </div>
     </div>
    </div>
    </div>
    </div>
     -->
     
      
      
      
      <!--

     <div class="row">
    
   
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6 d-flex  justify-content-center mt-2 ">
   
     <div class="ratio ratio-16x9 ">
        <iframe
    src="https://player.twitch.tv/?channel=LITofficial&&parent=${window.location.hostname}"
  width="100%" 
  height="100%" 

  

allowfullscreen="true">
</iframe>
      </div>
    </div>
    
    
    
  
    <div class=" mt-2 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6 d-flex justify-content-between  justify-content-sm-between  justify-content-md-between  justify-content-lg-between  justify-content-xl-between  justify-content-xxl-between flex-wrap  color-dark">
   
    <div class="row g-2 d-inline-flex flex-column d-sm-inline-flex flex-sm-column d-md-inline-flex flex-md-column  d-lg-inline-flex flex-lg-column  d-xl-inline-flex flex-xl-row  d-xxl-inline-flex flex-xxl-row  mt-2 teams1">
   
    
    <div class="col-auto  col-sm-auto col-md-auto   col-lg-auto col-xl-auto col-xxl-autologo h-auto  " style="width: 6rem;" >
    <img src="${dota2logo}" class="img-fluid"  alt="...">    
   
    </div>
              <div class="col-auto  col-sm-auto col-md-auto   col-lg-auto col-xl-auto col-xxl-auto player 1 ">
              
              <div class="card" >
              <div class="ratio ratio-1x1">
                <img src="${player}" class="card-img-top" alt="...">
                </div>
                <div class="card-body d-inline-flex justify-content-center p-0 mt-2  ">
                <i class="bi bi-globe-americas"></i>
                  <p class="card-text ms-2">player name</p>
                </div>
              </div>
              </div>
              
                <div class="col-auto col-sm-auto  col-md-auto  col-lg-auto col-xl-auto col-xxl-autoplayer 1">
              <div class="card" >
               <div class="ratio ratio-1x1">
                <img src="${player}" class="card-img-top" alt="...">
                </div>
                <div class="card-body d-inline-flex justify-content-center p-0 mt-2  ">
                <i class="bi bi-globe-americas"></i>
                  <p class="card-text ms-2">player name</p>
                </div>
              </div>
              </div>
              
                <div class="col-auto  col-sm-auto col-md-auto   col-lg-auto col-xl-auto col-xxl-autoplayer 1">
              <div class="card" >
                <div class="ratio ratio-1x1">
                <img src="${player}" class="card-img-top " alt="...">
                </div>
                <div class="card-body d-inline-flex justify-content-center p-0 mt-2  ">
                <i class="bi bi-globe-americas"></i>
                  <p class="card-text ms-2">player name</p>
                </div>
              </div>
              </div>
              
                <div class="col-auto  col-sm-auto col-md-auto   col-lg-auto col-xl-auto col-xxl-autoplayer 1">
              <div class="card" >
                <div class="ratio ratio-1x1">
                <img src="${player}" class="card-img-top " alt="...">
                </div>
                <div class="card-body d-inline-flex justify-content-center p-0 mt-2  ">
                <i class="bi bi-globe-americas"></i>
                  <p class="card-text ms-2">player name</p>
                </div>
              </div>
              </div>
              
                <div class="col-auto  col-sm-auto col-md-auto   col-lg-auto col-xl-auto col-xxl-autoplayer 1">
              <div class="card" >
                <div class="ratio ratio-1x1">
                <img src="${player}" class="card-img-top " alt="...">
                </div>
                <div class="card-body d-inline-flex justify-content-center p-0 mt-2  ">
                <i class="bi bi-globe-americas"></i>
                  <p class="card-text ms-2 ms-2">player name</p>
                </div>
              </div>
              </div>
</div>
     
    <div class="row g-2 d-inline-flex flex-column d-sm-inline-flex flex-sm-column d-md-inline-flex flex-md-column  d-lg-inline-flex flex-lg-column  d-xl-inline-flex flex-xl-row  d-xxl-inline-flex flex-xxl-row teams2 mt-2">
    
    
    <div class="col-auto  col-sm-auto col-md-auto   col-lg-auto col-xl-auto col-xxl-autologo h-auto  " style="width: 6rem;" >
    <img src="${dota2logo}" class="img-fluid"  alt="...">    
    
    </div>
              <div class="col-auto  col-sm-auto col-md-auto   col-lg-auto col-xl-auto col-xxl-autoplayer 1 ">
              
              <div class="card" >
              <div class="ratio ratio-1x1">
                <img src="${player}" class="card-img-top" alt="...">
                </div>
                <div class="card-body d-inline-flex justify-content-center p-0 mt-2  ">
                <i class="bi bi-globe-americas"></i>
                  <p class="card-text ms-2">player name</p>
                </div>
              </div>
              </div>
              
                <div class="col-auto  col-sm-auto col-md-auto   col-lg-auto col-xl-auto col-xxl-autoplayer 1">
              <div class="card" >
               <div class="ratio ratio-1x1">
                <img src="${player}" class="card-img-top" alt="...">
                </div>
                <div class="card-body d-inline-flex justify-content-center p-0 mt-2  ">
                <i class="bi bi-globe-americas"></i>
                  <p class="card-text ms-2">player name</p>
                </div>
              </div>
              </div>
              
                <div class="col-auto  col-sm-auto col-md-auto   col-lg-auto col-xl-auto col-xxl-autoplayer 1">
              <div class="card" >
                <div class="ratio ratio-1x1">
                <img src="${player}" class="card-img-top " alt="...">
                </div>
                <div class="card-body d-inline-flex justify-content-center p-0 mt-2  ">
                <i class="bi bi-globe-americas"></i>
                  <p class="card-text ms-2">player name</p>
                </div>
              </div>
              </div>
              
                <div class="col-auto  col-sm-auto col-md-auto   col-lg-auto col-xl-auto col-xxl-autoplayer 1">
              <div class="card" >
                <div class="ratio ratio-1x1">
                <img src="${player}" class="card-img-top " alt="...">
                </div>
                <div class="card-body d-inline-flex justify-content-center p-0 mt-2  ">
                <i class="bi bi-globe-americas"></i>
                  <p class="card-text ms-2">player name</p>
                </div>
              </div>
              </div>
              
                <div class="col-auto  col-sm-auto col-md-auto   col-lg-auto col-xl-auto col-xxl-autoplayer 1">
              <div class="card" >
                <div class="ratio ratio-1x1">
                <img src="${player}" class="card-img-top " alt="...">
                </div>
                <div class="card-body d-inline-flex justify-content-center p-0 mt-2  ">
                <i class="bi bi-globe-americas"></i>
                  <p class="card-text ms-2 ms-2">player name</p>
                </div>
              </div>
              </div>
              </div>
</div>
</div>
-->
</div>
    

`;

upcomingMatches.reg('upcoming-matches'); // components should always be 2 words seperated with a dash
