import { BaseComponent } from "@symbiotejs/symbiote";
import { I18n } from "../../utilities/i18n/i18n";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";
import { NewsCarousel } from "../news-carousel/news-carousel";
import { GridOrchestrator } from "../grid-orchestrator/grid-orchestrator";
import { AuthorsComponent } from "../author-component/author-component";
import showdown from 'showdown';


const css = `
<style>

#follow-us {
    background-color: #1E252E; /* Dark background to match your theme */
    padding-top: 3rem;
    padding-bottom: 3rem;
    margin-bottom: 3rem;
    position: relative;
    overflow: hidden;
}

#follow-us h2 {
    color: #ff3b3b; /* Secondary color red */
    font-size: 2.5rem;
    font-weight: bold;
    text-transform: uppercase;
    animation: bounceIn 1s ease-in-out;
    letter-spacing: 2px;
}

#follow-us h5 {
    font-size: 1.25rem;
    color: #eaeaea;
    margin-bottom: 2rem;
}

#follow-us .btn {
    border-radius: 50%;
    width: 3.5rem;
    height: 3.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    border: 2px solid #ff3b3b; /* Red outline for buttons */
    transition: all 0.4s ease;
    color: #eaeaea;
    background: none;
}

#follow-us .btn i {
    line-height: 1;
}

#follow-us .btn:hover {
    color: #ffffff;
    background-color: #ff3b3b; /* Red background on hover */
    transform: scale(1.2); /* Slight zoom effect */
    box-shadow: 0px 0px 15px 3px rgba(255, 59, 59, 0.6); /* Red glowing effect */
}

/* Optional hover effect for icons */
#follow-us .btn i {
    transition: transform 0.4s ease;
}

#follow-us .btn:hover i {
    transform: rotate(360deg); /* Icon rotation on hover */
}

/* Keyframes for bounce-in effect */
@keyframes bounceIn {
    0% {
        transform: scale(0.5);
        opacity: 0;
    }
    80% {
        transform: scale(1.2);
        opacity: 1;
    }
    100% {
        transform: scale(1);
    }
}

/* Responsive grid system for media */
 .post-news-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}
#articleHtmlPlaceholder hr {
    border: none; /* Remove default styling */
}
/* Headings styling */
#articleHtmlPlaceholder h1,#articleHtmlPlaceholder h2, #articleHtmlPlaceholder h3, #articleHtmlPlaceholder h4, #articleHtmlPlaceholder h5, #articleHtmlPlaceholder h6 {
    
    color: #ffffff;
    margin-bottom: 1rem;
}

#articleHtmlPlaceholder h1 {
    font-size: 2.5rem;
    line-height: 1.2;
}

#articleHtmlPlaceholder h2 {
    font-size: 2rem;
    line-height: 1.3;
}

#articleHtmlPlaceholder h3 {
    font-size: 1.75rem;
}

#articleHtmlPlaceholder h4 {
    font-size: 1.5rem;
}

/* Paragraphs styling */
#articleHtmlPlaceholder p {
    font-size: 1.1rem;
    color: #dadada;
    margin-bottom: 1.5rem;
}

/* Links */
#articleHtmlPlaceholder a {
    color: #007BFF;
    text-decoration: none;
    transition: color 0.3s ease;
}

#articleHtmlPlaceholder a:hover {
    color: #0056b3;
}

/* Images */
#articleHtmlPlaceholder img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 1.5rem;
}

/* Videos and Iframes */
#articleHtmlPlaceholder iframe,#articleHtmlPlaceholder  video {
    width: 100%;
    max-width: 100%;
    height: auto;
    margin-bottom: 1.5rem;
    border-radius: 10px;
    border: none;
}

/* Lists */
#articleHtmlPlaceholder ul,#articleHtmlPlaceholder ol {
    margin-left: 2rem;
    margin-bottom: 1.5rem;
}

#articleHtmlPlaceholder li {
    margin-bottom: 0.5rem;
}

/* Blockquotes */
#articleHtmlPlaceholder blockquote {
    border-left: 4px solid #007BFF;
    padding: 1rem;
    background-color: #f1f1f1;
    margin: 1.5rem 0;
    font-style: italic;
    color: #555;
}

/* Buttons */
#articleHtmlPlaceholder button,#articleHtmlPlaceholder  .btn {
    display: inline-block;
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 5px;
    background-color: #007BFF;
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

#articleHtmlPlaceholder button:hover,#articleHtmlPlaceholder  .btn:hover {
    background-color: #0056b3;
}

/* Tables */
#articleHtmlPlaceholder table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 1.5rem;
}

#articleHtmlPlaceholder th,#articleHtmlPlaceholder  td {
    padding: 12px;
    border: 1px solid #ddd;
    text-align: left;
}

news-post .text-resize-icons{
    top: 3px;
    left: 3px;
}
news-post .news-post-img {
width: 100%;
height: auto;
object-fit: cover;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}   
news-post .author-info {
padding: 20px;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}    
news-post .social-icons a {
color: #e0e0e0;
}
@media only screen and (max-width: 600px) {
 #follow-us h2 {
        font-size: 1.2rem;
    }
    #follow-us h5 {
        font-size: 1rem;
    }
    #follow-us .btn {
        width: 2rem;
        height: 2rem;
        font-size: 0.7rem;
    }
    #articleHtmlPlaceholder h1 {
        font-size: 1.75rem;
    }
    #articleHtmlPlaceholder h2 {
        font-size: 1.5rem;
    }
    #articleHtmlPlaceholder p {
        font-size: 0.9rem;
    }
news-post .img-circle-container-author{
    width: 40px;
    height: 40px;
}   
news-post .img-circle-container-author img{
     max-width: 40px;
     max-height: 40px;
}   
news-post .text-resize-name{
font-size: 0.6rem;
font-weight: 700;
color: #ffffff;
}
news-post .text-resize-date{
font-size: 0.4rem;
font-weight: 500;
}
news-post .text-resize-icons{
font-size: 0.5rem;
    top: 3px;
    left: 3px;
}
}
@media only screen and (min-width: 600px) {
  #follow-us h2 {
        font-size: 1.4rem;
    }
    #follow-us h5 {
        font-size: 1.1rem;
    }
    #follow-us .btn {
        width: 2.5rem;
        height: 2.5rem;
        font-size: 1rem;
    }
#articleHtmlPlaceholder h1 {
        font-size: 2rem;
    }
    #articleHtmlPlaceholder h2 {
        font-size: 1.75rem;
    }
    #articleHtmlPlaceholder p {
        font-size: 1rem;
    }
news-post .img-circle-container-author{
    width: 50px;
    height: 50px;
}   
news-post .img-circle-container-author img{
     max-width: 50px;
     max-height: 50px;
}   
news-post .text-resize-name{
font-size: 0.65rem;
font-weight: 700;
color: #ffffff;
}
news-post .text-resize-date{
font-size: 0.45rem;
font-weight: 500;
}
news-post .text-resize-icons{
font-size: 0.9rem;
}
}
@media only screen and (min-width: 768px) {
#follow-us h2 {
        font-size: 1.6rem;
    }
    #follow-us h5 {
        font-size: 1.2rem;
    }
    #follow-us .btn {
        width: 3rem;
        height: 3rem;
        font-size: 1.25rem;
    }
 #articleHtmlPlaceholder h1 {
        font-size: 2.25rem;
    }
    #articleHtmlPlaceholder h2 {
        font-size: 2rem;
    }
    #articleHtmlPlaceholder p {
        font-size: 1.1rem;
    }
news-post .img-circle-container-author{
    width: 50px;
    height: 50px;
}   
news-post .img-circle-container-author img{
     max-width: 50px;
     max-height: 50px;
}   
news-post .text-resize-name{
font-size: 0.8rem;
font-weight: 700;
color: #ffffff;
}
news-post .text-resize-date{
font-size: 0.6rem;
font-weight: 500;
}
news-post .text-resize-icons{
font-size: 0.9rem;
}
}
@media only screen and (min-width: 992px) {
#follow-us h2 {
        font-size: 1.7rem;
    }
    #follow-us h5 {
        font-size: 1.25rem;
    }
    #follow-us .btn {
        width: 3.5rem;
        height: 3.5rem;
        font-size: 1.5rem;
    }
 #articleHtmlPlaceholder h1 {
        font-size: 2.5rem;
    }
    #articleHtmlPlaceholder h2 {
        font-size: 2.25rem;
    }
    #articleHtmlPlaceholder p {
        font-size: 1.15rem;
    }
news-post .img-circle-container-author{
    width: 80px;
    height: 80px;
}   
news-post .img-circle-container-author img{
     max-width: 80px;
     max-height: 80px;
}   
news-post .text-resize-name{
font-size: 0.8rem;
font-weight: 700;
color: #ffffff;
}
news-post .text-resize-date{
font-size: 0.6rem;
font-weight: 500;
}
news-post .text-resize-icons{
font-size: 0.9rem;
}
}
@media only screen and (min-width: 1200px) {
#follow-us h2 {
        font-size: 2.5rem;
    }
    #follow-us h5 {
        font-size: 1.4rem;
    }
    #follow-us .btn {
        width: 3.8rem;
        height: 3.8rem;
        font-size: 1.6rem;
    }
#articleHtmlPlaceholder h1 {
        font-size: 3rem;
    }
    #articleHtmlPlaceholder h2 {
        font-size: 2.5rem;
    }
    #articleHtmlPlaceholder p {
        font-size: 1.25rem;
    }
news-post .img-circle-container-author{
    width: 4rem;
    height: 4rem;
}   
news-post .img-circle-container-author img{
     max-width: 100%;
     max-height: 100%;
}   
news-post .text-resize-name{
font-size: 0.9rem;
font-weight: 800;
color: #ffffff;
}
news-post .text-resize-date{
font-size: 0.9rem;
font-weight: 500;
}
news-post .text-resize-icons{
font-size: 1.1rem;
}
}

@media only screen and (min-width: 1920px) {
#follow-us h2 {
        font-size: 3rem;
    }
    #follow-us h5 {
        font-size: 1.5rem;
    }
    #follow-us .btn {
        width: 4rem;
        height: 4rem;
        font-size: 1.75rem;
    }
 #articleHtmlPlaceholder h1 {
        font-size: 3.5rem;
    }
    #articleHtmlPlaceholder h2 {
        font-size: 3rem;
    }
    #articleHtmlPlaceholder p {
        font-size: 1.35rem;
    }

news-post .img-circle-container-author{
    width: 200px;
    height: 200px;
}   
news-post .img-circle-container-author img{
     max-width: 100%;
     max-height: 100%;
}   
news-post .text-resize-name{
font-size: 1.4rem;
font-weight: 800;
color: #ffffff;
}
news-post .text-resize-date{
font-size: 0.9rem;
font-weight: 500;
}
news-post .text-resize-icons{
font-size: 1.4rem;
}
}
}

</style>

`

export class NewsPost extends BaseComponent {

    initCallback() {
        GridOrchestrator.deactivateGameFilters()
        document.head.insertAdjacentHTML("beforeend", css)
        const articleName = RouterAssistant.getCurrentVariableValue('article');
        NewsCarousel.getNews(function () {
            AuthorsComponent.getAuthors('fromNewsPost', () => {
                let newsPostId = null;
                const findNewsPostId = new Promise((resolve, reject) => {
                    let newsPostId = null;
                    Object.entries(window.savedNews).forEach(([key, news]) => {
                        if (news.public_url_greeklish === articleName) {
                            newsPostId = key;
                        }
                    });
                    if (newsPostId) {
                        resolve(newsPostId);
                    } else {
                        console.debug('No matching entry found.');
                        reject('No matching entry found.');
                    }
                });

                findNewsPostId.then((resolvedNewsPostId) => {
                    newsPostId = resolvedNewsPostId;

                    let tempAuthor = '';
                    let tempAuthorData = {};
                    Object.entries(window.savedAuthors).forEach(([authorName, authorData]) => {
                        if (authorName.toLowerCase() === window.savedNews[newsPostId].linkedAuthor.toLowerCase()) {
                            tempAuthor = authorName;
                            tempAuthorData = authorData;
                        }
                    })

                    const converter = new showdown.Converter();
                    const convertedMarkdownContent = window.savedNews[newsPostId].content[I18n.getUsersPreferredLanguage()];
                    const htmlContent = converter.makeHtml(convertedMarkdownContent);
                    const writtenAtTimestamp = window.savedNews[newsPostId].writtenAt;
                    const writtenAtDate = new Date(writtenAtTimestamp.seconds * 1000 + writtenAtTimestamp.nanoseconds / 1000000);
                    const formattedDate = writtenAtDate.toLocaleString(I18n.getUsersPreferredLanguage(), {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit'
                    });

                    document.getElementById('articleHtmlPlaceholder').insertAdjacentHTML('beforeend', htmlContent)
                    document.getElementById('newsArticleCoverPhoto').src = window.savedNews[newsPostId].article_photo
                    // if (!window.savedNews[newsPostId].is_page) {
                    document.querySelector('.author-info h5').textContent = tempAuthorData.fullName + ' "' + tempAuthorData.name + '" ';
                    document.querySelector('.author-info p').textContent = ` ${I18n.translateString("newsTranslations.writtenAt")} ${formattedDate}`;
                    document.querySelector('.author-photo').src = tempAuthorData.photo;
                    document.querySelector('#follow-us h2').textContent = ` ${I18n.translateString("newsTranslations.footerArticle")} `;
                    document.querySelector('#follow-us h5').textContent = ` ${I18n.translateString("newsTranslations.footerArticleFollow")} `;
                    document.querySelector('.social-icons').insertAdjacentHTML('beforeend',
                        `
                ${!tempAuthorData.socials.instagram || tempAuthorData.socials.instagram === "N/A" ? '' : `<a href="${tempAuthorData.socials.instagram}" target="_blank" rel="noopener noreferrer"><i class="bi bi-instagram"></i></a>`}
                ${!tempAuthorData.socials.discord || tempAuthorData.socials.discord === "N/A" ? '' : `<a href="${tempAuthorData.socials.discord}" target="_blank" rel="noopener noreferrer"><i class="bi bi-discord"></i></a>`}
                ${!tempAuthorData.socials.twitter || tempAuthorData.socials.twitter === "N/A" ? '' : `<a href="${tempAuthorData.socials.twitter}" target="_blank" rel="noopener noreferrer"><i class="bi bi-twitter"></i></a>`}
                ${!tempAuthorData.socials.facebook || tempAuthorData.socials.facebook === "N/A" ? '' : `<a href="${tempAuthorData.socials.facebook}" target="_blank" rel="noopener noreferrer"><i class="bi bi-facebook"></i></a>`}
                `
                    );
                    // }

                }).catch((error) => {
                    console.debug(error);
                });
            });
        });
    }
}

NewsPost.template = /*html*/`
    <div class="main-content col-lg-8 col-md-8 col-sm-8 col-xs-12 g-0 mt-0 mt-sm-0 mt-md-0 mt-lg-0 mt-xl-5 mt-xxl-5">
        <div class="home-matches home-components row mx-2">
            
            <div class="container post-news-container mt-5">
        <div class="row color-dark p-1 rounded">
          <div class="col-6 p-0 color-dark rounded-start h-100 position-relative ">
        <div class="ratio ratio-16x9">
                <div class=" author-info p-0 text-center d-flex flex-column justify-content-center align-content-center align-items-center color-lightdark">
                 <div class="img-circle-container-author d-flex justify-content-center border border-2 border-danger bg-dark bg-gradient rounded-circle overflow-hidden">
                    <img src="" alt="Author Photo" class="author-photo img-fluid rounded-circle mx-auto d-block">
                   </div>
                    <h5 class="mt-2 text-resize-name"></h5>
                    <p class="text-resize-date"></p>
                    <div class="social-icons text-resize-icons position-absolute"></div>
                </div>
                </div>
            </div>
           <div class="col-6 p-0 h-100 rounded-end">
                <img id="newsArticleCoverPhoto" src="" alt="Article Image" class="news-post-img ">
           </div>
          
        </div>
            <div id="articleHtmlPlaceholder" class="mt-5"></div>
        </div>
        <!-- Footer Section for the End of the Article -->
<section id="follow-us" class="text-center py-5 rounded">
    <div class="container">
        <h2 class="mb-4"></h2>
        <h5 class="mb-4"></h5>
        <!-- Social Media Icons (using Bootstrap Icons) -->
        <div class="d-flex justify-content-center align-items-center">
            <a href="https://www.facebook.com/profile.php?id=61563471180577" target="_blank" class="btn btn-outline-light btn-lg social-btn me-2">
                <i class="bi bi-facebook"></i>
            </a>
            <a href="https://x.com/myesportsgr" target="_blank" class="btn btn-outline-light btn-lg social-btn me-2">
                <i class="bi bi-twitter-x"></i>
            </a>
            <a href="https://www.instagram.com/myesportsgr/" target="_blank" class="btn btn-outline-light btn-lg social-btn me-2">
                <i class="bi bi-instagram"></i>
            </a>
           
            <a href="https://www.youtube.com/@myesportsgr" target="_blank" class="btn btn-outline-light btn-lg social-btn me-2">
                <i class="bi bi-youtube"></i>
            </a>
            <a href="https://www.tiktok.com/@myesports.gr" target="_blank" class="btn btn-outline-light btn-lg social-btn me-2">
                <i class="bi bi-tiktok"></i>
            </a>
             <a href="https://discord.gg/3sU4u59qze" target="_blank" class="btn btn-outline-light btn-lg social-btn ">
                <i class="bi bi-discord"></i>
            </a>
        </div>
      </div>
</section>
    </div>
    </div>
    <div class="sidebar col-lg-4 col-md-4 col-sm-4 col-xs-12 mt-5 ">
       
        <placeholder-matcheswidget></placeholder-matcheswidget>
        <div class="sidebar-matches home-components row mx-2 ">
            <matches-component data-mode="homePage"></matches-component>
        </div>

         <div class="sidebar-predictions home-components row mx-2 ">
            <predictions-homecomponent></predictions-homecomponent>
        </div>
       
    </div>
`;

NewsPost.reg('news-post');
