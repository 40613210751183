import { BaseComponent } from "@symbiotejs/symbiote";
import { FirebaseAssistant } from "../../utilities/firebase-assistant/firebase-assistant";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";
import { BookmakershomeComponent } from "../bookmakershome-component/bookmakershome-component";


import showdown from 'showdown';

const css = `
    <style>
 postbookmakers-component   .scrollable-section {
  padding-top: 70px; /* Adjust this value based on your navbar's height */
  margin-top: -70px; /* Same as padding-top but negative */
}

        
 /* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
     postbookmakers-component .text-resize-payment-name {
         font-weight: 700;
         font-size: 0.4rem;
         color: white;
    }
     postbookmakers-component .text-resize-title{
         font-weight: 800;
         font-size: 0.8rem;
         color: white;
    }
     postbookmakers-component .bookmaker-content p{
         font-weight: 400;
         font-size: 0.6rem;
    }
     postbookmakers-component .bookmaker-content h1,postbookmakers-component .bookmaker-content h2,postbookmakers-component .bookmaker-content h3{
         font-weight: 600;
         font-size: 0.7rem;
    }
     postbookmakers-component .text-resize-top {
         font-weight: 400;
         font-size: 0.3rem;
    }
     postbookmakers-component .text-resize-bot {
         font-weight: 700;
         font-size: 0.4rem;
         color: white;
    }
     postbookmakers-component #myTab .nav-link {
         font-weight: 500;
         font-size: 0.5rem;
         color: white;
    }
     postbookmakers-component #navbar-bookmaker .nav-link {
         font-weight: 500;
         font-size: 0.5rem;
         color: white;
    }
     postbookmakers-component .text-resize-esports {
         font-weight: 700;
         font-size: 0.5rem;
         color: white;
    }
     postbookmakers-component .text-resize {
         font-weight: 500;
         font-size: 0.5rem;
         color: white;
    }
     postbookmakers-component .text-rating-resize {
         background-color: #f5c518;
         color: #333333;
         padding: 0.25rem 0.5rem;
         border-radius: 0.25rem;
         font-size: 0.5rem;
         font-weight: 800;
    }
     postbookmakers-component .text-resize-bookmaker-name {
         display: none;
    }
     postbookmakers-component .text-resize-name-rating {
         font-weight: 500;
         font-size: 0.5rem;
         color: white;
    }

 postbookmakers-component .text-resize-progress-bar {
     font-weight: 500;
     font-size: 0.5rem;
     color: white;
}
 postbookmakers-component .scrollable-section {
     padding-top: 10px;
    /* Adjust this value based on your navbar's height */
     margin-top: -10px;
    /* Same as padding-top but negative */
}

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
      postbookmakers-component .text-resize-payment-name {
         font-weight: 700;
         font-size: 0.4rem;
         color: white;
    }
     postbookmakers-component .text-resize-title{
         font-weight: 800;
         font-size: 0.8rem;
         color: white;
    }
     postbookmakers-component .bookmaker-content p{
         font-weight: 400;
         font-size: 0.6rem;
    }
     postbookmakers-component .bookmaker-content h1,postbookmakers-component .bookmaker-content h2,postbookmakers-component .bookmaker-content h3{
         font-weight: 600;
         font-size: 0.7rem;
    }
     postbookmakers-component .text-resize-top {
         font-weight: 400;
         font-size: 0.3rem;
    }
     postbookmakers-component .text-resize-bot {
         font-weight: 700;
         font-size: 0.4rem;
         color: white;
    }
     postbookmakers-component #myTab .nav-link {
         font-weight: 500;
         font-size: 0.5rem;
         color: white;
    }
     postbookmakers-component #navbar-bookmaker .nav-link {
         font-weight: 500;
         font-size: 0.5rem;
         color: white;
    }
     postbookmakers-component .text-resize-esports {
         font-weight: 700;
         font-size: 0.5rem;
         color: white;
    }
     postbookmakers-component .text-resize {
         font-weight: 500;
         font-size: 0.5rem;
         color: white;
    }
     postbookmakers-component .text-rating-resize {
         background-color: #f5c518;
         color: #333333;
         padding: 0.25rem 0.5rem;
         border-radius: 0.25rem;
         font-size: 0.5rem;
         font-weight: 800;
    }
     postbookmakers-component .text-resize-bookmaker-name {
         display: none;
    }
     postbookmakers-component .text-resize-name-rating {
         font-weight: 500;
         font-size: 0.5rem;
         color: white;
    }

 postbookmakers-component .text-resize-progress-bar {
     font-weight: 500;
     font-size: 0.5rem;
     color: white;
}
 postbookmakers-component .scrollable-section {
     padding-top: 10px;
    /* Adjust this value based on your navbar's height */
     margin-top: -10px;
    /* Same as padding-top but negative */
}
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
         postbookmakers-component .text-resize-payment-name {
         font-weight: 700;
         font-size: 0.45rem;
         color: white;
    }
     postbookmakers-component .text-resize-title{
         font-weight: 800;
         font-size: 0.85rem;
         color: white;
    }
     postbookmakers-component .bookmaker-content p{
         font-weight: 400;
         font-size: 0.65rem;
    }
     postbookmakers-component .bookmaker-content h1,postbookmakers-component .bookmaker-content h2,postbookmakers-component .bookmaker-content h3{
         font-weight: 600;
         font-size: 0.75rem;
    }
     postbookmakers-component .text-resize-top {
         font-weight: 400;
         font-size: 0.35rem;
    }
     postbookmakers-component .text-resize-bot {
         font-weight: 700;
         font-size: 0.45rem;
         color: white;
    }
     postbookmakers-component #myTab .nav-link {
         font-weight: 500;
         font-size: 0.5rem;
         color: white;
    }
     postbookmakers-component #navbar-bookmaker .nav-link {
         font-weight: 500;
         font-size: 0.55rem;
         color: white;
    }
     postbookmakers-component .text-resize-esports {
         font-weight: 700;
         font-size: 0.55rem;
         color: white;
    }
     postbookmakers-component .text-resize {
         font-weight: 500;
         font-size: 0.55rem;
         color: white;
    }
     postbookmakers-component .text-rating-resize {
         background-color: #f5c518;
         color: #333333;
         padding: 0.25rem 0.5rem;
         border-radius: 0.25rem;
         font-size: 0.55rem;
         font-weight: 800;
    }
     postbookmakers-component .text-resize-bookmaker-name {
         display: none;
    }
     postbookmakers-component .text-resize-name-rating {
         font-weight: 500;
         font-size: 0.55rem;
         color: white;
    }

 postbookmakers-component .text-resize-progress-bar {
     font-weight: 500;
     font-size: 0.55rem;
     color: white;
}
 postbookmakers-component .scrollable-section {
     padding-top: 70px;
    /* Adjust this value based on your navbar's height */
     margin-top: -70px;
    /* Same as padding-top but negative */
}
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
        postbookmakers-component .text-resize-payment-name {
         font-weight: 700;
         font-size: 0.5rem;
         color: white;
    }
     postbookmakers-component .text-resize-title{
         font-weight: 800;
         font-size: 0.9rem;
         color: white;
    }
     postbookmakers-component .bookmaker-content p{
         font-weight: 400;
         font-size: 0.7rem;
    }
     postbookmakers-component .bookmaker-content h1,postbookmakers-component .bookmaker-content h2,postbookmakers-component .bookmaker-content h3{
         font-weight: 600;
         font-size: 0.8rem;
    }
     postbookmakers-component .text-resize-top {
         font-weight: 400;
         font-size: 0.4rem;
    }
     postbookmakers-component .text-resize-bot {
         font-weight: 700;
         font-size: 0.5rem;
         color: white;
    }
     postbookmakers-component #myTab .nav-link {
         font-weight: 500;
         font-size: 0.55rem;
         color: white;
    }
     postbookmakers-component #navbar-bookmaker .nav-link {
         font-weight: 500;
         font-size: 0.6rem;
         color: white;
    }
     postbookmakers-component .text-resize-esports {
         font-weight: 700;
         font-size: 0.6rem;
         color: white;
    }
     postbookmakers-component .text-resize {
         font-weight: 500;
         font-size: 0.6rem;
         color: white;
    }
     postbookmakers-component .text-rating-resize {
         background-color: #f5c518;
         color: #333333;
         padding: 0.25rem 0.5rem;
         border-radius: 0.25rem;
         font-size: 0.6rem;
         font-weight: 800;
    }
     postbookmakers-component .text-resize-bookmaker-name {
         display: none;
    }
     postbookmakers-component .text-resize-name-rating {
         font-weight: 500;
         font-size: 0.6rem;
         color: white;
    }

 postbookmakers-component .text-resize-progress-bar {
     font-weight: 500;
     font-size: 0.6rem;
     color: white;
}
 postbookmakers-component .scrollable-section {
     padding-top: 70px;
    /* Adjust this value based on your navbar's height */
     margin-top: -70px;
    /* Same as padding-top but negative */
}
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
          postbookmakers-component .text-resize-payment-name {
         font-weight: 700;
         font-size: 0.7rem;
         color: white;
    }
     postbookmakers-component .text-resize-title{
         font-weight: 800;
         font-size: 1rem;
         color: white;
    }
     postbookmakers-component .bookmaker-content p{
         font-weight: 400;
         font-size: 0.9rem;
    }
     postbookmakers-component .bookmaker-content h1,postbookmakers-component .bookmaker-content h2,postbookmakers-component .bookmaker-content h3{
         font-weight: 600;
         font-size: 1rem;
    }
     postbookmakers-component .text-resize-top {
         font-weight: 400;
         font-size: 0.6rem;
    }
     postbookmakers-component .text-resize-bot {
         font-weight: 700;
         font-size: 0.7rem;
         color: white;
    }
     postbookmakers-component #myTab .nav-link {
         font-weight: 500;
         font-size: 0.7rem;
         color: white;
    }
     postbookmakers-component #navbar-bookmaker .nav-link {
         font-weight: 500;
         font-size: 0.7rem;
         color: white;
    }
     postbookmakers-component .text-resize-esports {
         font-weight: 700;
         font-size: 0.7rem;
         color: white;
    }
     postbookmakers-component .text-resize {
         font-weight: 500;
         font-size: 0.7rem;
         color: white;
    }
     postbookmakers-component .text-rating-resize {
         background-color: #f5c518;
         color: #333333;
         padding: 0.25rem 0.5rem;
         border-radius: 0.25rem;
         font-size: 0.7rem;
         font-weight: 800;
    }
     postbookmakers-component .text-resize-bookmaker-name {
         display: none;
    }
     postbookmakers-component .text-resize-name-rating {
         font-weight: 500;
         font-size: 0.7rem;
         color: white;
    }

 postbookmakers-component .text-resize-progress-bar {
     font-weight: 500;
     font-size: 0.7rem;
     color: white;
}
 postbookmakers-component .scrollable-section {
     padding-top: 70px;
    /* Adjust this value based on your navbar's height */
     margin-top: -70px;
    /* Same as padding-top but negative */
}
}       
    </style>
`

export class PostBookmakersComponent extends BaseComponent {

  initCallback() {
    document.head.insertAdjacentHTML("beforeend", css)
    RouterAssistant.checkForSingleContainer('postbookmakers-component', PostBookmakersComponent.initiator)


  }


  static initiator() {
    BookmakershomeComponent.getBookmakers('fromBookmakersComponent', () => {
      if (window.location.href.includes('s_bookmaker')) {
        console.debug('I am running the post bookmakers component callback!!!')
        const bookmakerid = RouterAssistant.getCurrentVariableValue('bookmaker')
        let data = null;
        if (window.savedBookMakers !== undefined) {
          data = window.savedBookMakers[bookmakerid];
        }

        console.debug('DATAAAAAAAAAAA', data)

        const bookmakerName = data.name;
        const converter = new showdown.Converter();
        const bookmakerAbout = data.bookmaker_about;
        const bookmakerAboutContent = converter.makeHtml(bookmakerAbout);
        const bookmakerImage = data.bookmaker_logo;
        const bookmakerColor = data.bookmaker_color;
        const bookmakerRatingApodoseis = data.bookmaker_rating.apodoseis;
        const bookmakerRatingPlithwra = data.bookmaker_rating.plithwra_esports;
        const bookmakerRatingLiveSupport = data.bookmaker_rating.live_support;
        const bookmakerRatingPlhrwmes = data.bookmaker_rating.plhrwmes;
        const bookmakerRatingLiveSteaming = data.bookmaker_rating.live_streaming;
        const bookmakerWithdrawals = data.bookmaker_withdrawals;
        const bookmakerDeposits = data.bookmakers_deposit;
        const bookmakersEsports = data.bookmakers_esports;
        const ratingsSum = bookmakerRatingApodoseis + bookmakerRatingPlithwra + bookmakerRatingLiveSupport + bookmakerRatingPlhrwmes + bookmakerRatingLiveSteaming;
        const overallRating = ratingsSum / 5 / 10;

        function populateWithdrawals(withdrawals) {
          const container = document.getElementById('withdrawals-container');
          container.innerHTML = ''; // Clear existing content


          // Sort the entries alphabetically by the payment method name
          const sortedEntries = Object.entries(withdrawals).sort((a, b) => {
            const nameA = a[0].toUpperCase(); // ignore upper and lowercase
            const nameB = b[0].toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0; // names must be equal
          });

          sortedEntries.forEach(([method, values]) => {
            // Check if the values array is not empty
            if (values.length === 0 || values.every((item) => !item)) {
              return; // Skip to the next iteration if values are empty
            }
            const row = document.createElement('div');
            row.className = "row color-dark d-flex align-items-center justify-content-start rounded mt-2";

            // Payment method name
            const nameCol = document.createElement('div');
            nameCol.className = "col-4 resize-name";
            const nameSpan = document.createElement('span');
            nameSpan.textContent = method.replace(/_/g, ' ').toUpperCase(); // Replace underscores with spaces and make uppercase
            nameSpan.classList.add('text-resize-payment-name'); // Add class here
            nameCol.appendChild(nameSpan);
            row.appendChild(nameCol);

            // Other columns (Time, Charge, Minimum, Maximum per day)
            values.forEach((value, index) => {
              const col = document.createElement('div');
              col.className = "col-2 d-flex flex-column";

              const labelSpan = document.createElement('span');
              labelSpan.textContent = ['Χρόνος', 'Χρέωση', 'Ελάχιστη', 'Max/Ημέρα'][index];
              labelSpan.classList.add('text-resize-top'); // Add class here

              col.appendChild(labelSpan);

              const valueSpan = document.createElement('span');
              valueSpan.textContent = value;
              valueSpan.classList.add('text-resize-bot'); // Add class here
              col.appendChild(valueSpan);

              row.appendChild(col);
            });

            container.appendChild(row);
          });
        }

        function populateDeposits(deposits) {
          const container = document.getElementById('deposits-container');
          container.innerHTML = ''; // Clear existing content


          // Sort the entries alphabetically by the payment method name
          const sortedEntries = Object.entries(deposits).sort((a, b) => {
            const nameA = a[0].toUpperCase(); // ignore upper and lowercase
            const nameB = b[0].toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0; // names must be equal
          });


          sortedEntries.forEach(([method, values]) => {
            // Check if the values array is not empty
            if (values.length === 0 || values.every((item) => !item)) {
              return; // Skip to the next iteration if values are empty
            }
            const row = document.createElement('div');
            row.className = "row color-dark d-flex align-items-center justify-content-start rounded mt-2";

            // Payment method name
            const nameCol = document.createElement('div');
            nameCol.className = "col-4 resize-name";
            const nameSpan = document.createElement('span');
            nameSpan.textContent = method.replace(/_/g, ' ').toUpperCase(); // Replace underscores with spaces and make uppercase
            nameSpan.classList.add('text-resize-payment-name'); // Add class here
            nameCol.appendChild(nameSpan);
            row.appendChild(nameCol);

            // Other columns (Time, Charge, Minimum, Maximum per day)
            values.forEach((value, index) => {

              if (values.length === 0 || values.every((item) => !item)) {
                return; // Skip to the next iteration if values are empty
              }

              const col = document.createElement('div');
              col.className = "col-2 d-flex flex-column ";

              const labelSpan = document.createElement('span');
              labelSpan.textContent = ['Χρόνος', 'Χρέωση', 'Ελάχιστη', 'Max/Ημέρα'][index];
              labelSpan.classList.add('text-resize-top'); // Add class here
              col.appendChild(labelSpan);

              const valueSpan = document.createElement('span');
              valueSpan.textContent = value;
              valueSpan.classList.add('text-resize-bot'); // Add class here
              col.appendChild(valueSpan);

              row.appendChild(col);
            });

            container.appendChild(row);
          });
        }

        function populateEsports(esportsArray) {
          const container = document.getElementById('esports-bookmakers-list');
          // Remove existing esports entries
          container.innerHTML = '';


          esportsArray.forEach((esport) => {
            if (esport) { // Make sure the esport item is not null
              const esportDiv = document.createElement('div');
              esportDiv.className = "col-auto color-dark m-2 rounded ";
              const esportSpan = document.createElement('span');
              esportSpan.className = "text-resize-esports";
              esportSpan.textContent = esport; // Assuming the text content is what you want to display
              esportDiv.appendChild(esportSpan);
              container.appendChild(esportDiv);
            }
          });
        }

        const bkmkrHtml =  /*html*/`
<div class="container">
  <div class="row top rounded-top" style="background-color: ${bookmakerColor}">
    <div class="col-12 text-center">
      <img src="${bookmakerImage}" alt="" class="img-fluid mx-auto d-block">
    </div>
    <div class="col-12 text-center">
      <span class="text-white text-resize-bookmaker-name">${bookmakerName}</span>
    </div>
    <div class="col-12 text-center my-2">
      <span class="text-rating-resize">
        <span class="">${overallRating}</span>
        <i class="bi bi-star-fill"></i>
      </span>
    </div>
    <div class="col-12 text-center my-2">
      <button type="button" class="btn btn-danger live-animation text-resize">ΕΓΓΡΑΦΗ</button>
    </div>
    <div class="col-12 p-1 justify-content-center align-content-center text-center align-items-center eeep-text">ΕΕΕΠ | 21+ | ΠΑΙΞΕ ΥΠΕΥΘΥΝΑ </div>
  </div>
  <!-- menu start -->
  <div class="row color-dark">
    <nav id="navbar-bookmaker" class="navbar navbar-expand color-dark">
      
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link" href="#rating">Βαθμολογία</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#analysis">Ανάλυση</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#payments">Καταθέσεις/Αναλύψεις</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#bookmakers_esports-scroll">Esports</a>
          </li>
        </ul>
      
    </nav>
  </div>
  <div data-bs-spy="scroll" data-bs-target="#navbar-bookmaker" data-bs-offset="60" tabindex="0">
    <!-- Rating start -->
    <div class="row d-flex justify-content-center  mt-1 mt-lg-2">
      <div class="col-12 scrollable-section d-flex flex-column" id="rating">
        <span class="text-resize-title">Βαθμολογία</span>
        <span class="text-center mb-2 text-resize-name-rating">Αποδόσεις</span>
        <div class="progress mb-2" style="height: 20px;">
          <div class="progress-bar text-resize-progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style="width: ${bookmakerRatingApodoseis}%; background-color: ${bookmakerColor};" aria-valuenow="${bookmakerRatingApodoseis}" aria-valuemin="0" aria-valuemax="100">${bookmakerRatingApodoseis}%</div>
        </div>
        <span class="text-center mb-2 text-resize-name-rating">Πληθώρα Esports</span>
        <div class="progress mb-2" style="height: 20px;">
          <div class="progress-bar text-resize-progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style="width: ${bookmakerRatingPlithwra}%; background-color: ${bookmakerColor};" aria-valuenow="${bookmakerRatingPlithwra}" aria-valuemin="0" aria-valuemax="100">${bookmakerRatingPlithwra}%</div>
        </div>
        <span class="text-center mb-2 text-resize-name-rating">Live Support</span>
        <div class="progress mb-2" style="height: 20px;">
          <div class="progress-bar text-resize-progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style="width: ${bookmakerRatingLiveSupport}%; background-color: ${bookmakerColor};" aria-valuenow="${bookmakerRatingLiveSupport}" aria-valuemin="0" aria-valuemax="100"> ${bookmakerRatingLiveSupport}%</div>
        </div>
        <span class="text-center mb-2 text-resize-name-rating">Live Streaming</span>
        <div class="progress mb-2" style="height: 20px;">
          <div class="progress-bar text-resize-progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style="width: ${bookmakerRatingLiveSteaming}%; background-color: ${bookmakerColor};" aria-valuenow="${bookmakerRatingLiveSteaming}" aria-valuemin="0" aria-valuemax="100">${bookmakerRatingLiveSteaming}%</div>
        </div>
        <span class="text-center mb-2 text-resize-name-rating">Πληρωμές</span>
        <div class="progress mb-2" style="height: 20px;">
          <div class="progress-bar text-resize-progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style="width: ${bookmakerRatingPlhrwmes}%; background-color: ${bookmakerColor};" aria-valuenow="${bookmakerRatingPlhrwmes}" aria-valuemin="0" aria-valuemax="100"> ${bookmakerRatingPlhrwmes}%</div>
        </div>
      </div>
    </div>
    <div class="col-12 mt-1 mt-lg-2 scrollable-section d-flex flex-column" id="analysis">
      <span class="text-resize-title ">Ανάλυση</span>
      <div class="bookmaker-content">${bookmakerAboutContent}</div>
    </div>
    <div class="col-12 mt-1 mt-lg-2 scrollable-section d-flex flex-column" id="payments">
      <span class="text-resize-title">Καταθέσεις/Αναλήψεις</span>
      <!-- Tabs navigation -->
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button class="nav-link active" id="deposits-tab" data-bs-toggle="tab" data-bs-target="#deposits" type="button" role="tab" aria-controls="deposits" aria-selected="true">Deposits</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="withdrawals-tab" data-bs-toggle="tab" data-bs-target="#withdrawals" type="button" role="tab" aria-controls="withdrawals" aria-selected="false">Withdrawals</button>
        </li>
      </ul>
      <!-- Tabs content -->
      <div class="tab-content " id="myTabContent">
        <!-- Deposits tab pane -->
        <div class="tab-pane fade show active " id="deposits" role="tabpanel" aria-labelledby="deposits-tab">
          <!-- Add your deposit content here -->
          <div id="deposits-container"></div>
        </div>
        <!-- Withdrawals tab pane -->
        <div class="tab-pane fade" id="withdrawals" role="tabpanel" aria-labelledby="withdrawals-tab">
          <!-- Add your withdrawal content here -->
          <div id="withdrawals-container"></div>
        </div>
      </div>
    </div>
    <div class="row mt-1 mt-lg-2 scrollable-section" id="bookmakers_esports-scroll">
      <span class="text-resize-title">Esports</span>
      <div id="esports-bookmakers-list" class="row  d-flex flex-row"></div>
    </div>
  </div>
</div>
</div>
        
        <!-- ETC ETC -->
        `

        document.querySelector('#s_bookmaker').insertAdjacentHTML('beforeend', bkmkrHtml)
        populateWithdrawals(bookmakerWithdrawals);
        populateDeposits(bookmakerDeposits);
        populateEsports(bookmakersEsports);

      }
    })
  }


}

PostBookmakersComponent.template = /*html*/`
<div id="s_bookmaker"></div>
`;

PostBookmakersComponent.reg('postbookmakers-component'); // components should always be 2 words seperated with a dash
