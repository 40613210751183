import {BaseComponent} from "@symbiotejs/symbiote";
import {I18n} from "../../utilities/i18n/i18n";
import {RouterAssistant} from "../../utilities/router-assistant/router-assistant";

const css = `
    <style>
      
    </style>
`

export class GamerShowcase extends BaseComponent {

    initCallback() {
        document.head.insertAdjacentHTML("beforeend", css)
        GamerShowcase.translations()

        document.getElementById('gamerShowCaseModal').addEventListener('hidden.bs.modal', () => {
            console.debug('running after modal is hidden')
            RouterAssistant.removeCurrentPageVariable('gamerShowcaseId')
        })
        window.gamerShowCaseModal = new window.bootstrap.Modal(document.getElementById('gamerShowCaseModal'))
        if ('gamerShowcaseId' in window.currentPageVariables){
            GamerShowcase.showGamer(window.currentPageVariables['gamerShowcaseId'])
        }
    }

    static translations() {
        // document.getElementById('showcaseGamerActivationButton').innerText = I18n.translateString('gamerShowcase.showGamer')
    }

    static showGamer(url) {
        // console.debug(url)
        // document.getElementById('modalHeader').innerText = `${I18n.translateString('gamerShowcase.modalHeader')} '${url.split('/')[1]}'`
        // document.getElementsByName('playerShowcase')[0].src = 'https://liquipedia.net/' + url;
        window.gamerShowCaseModal.show()
        // RouterAssistant.addOrUpdateCurrentPageVariable('gamerShowcaseId', gamerId)
    }
}

GamerShowcase.template = `
<div class="modal fade" id="gamerShowCaseModal" style="z-index: 1059" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-modal="true" role="dialog">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content" style="height: 100%">
                <div  class="modal-header"  >
                    <h1 id="modalHeader" class="modal-title fs-5"></h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div id="gamerShowCaseModalBody" class="modal-body">
                              <iframe is="x-frame-bypass" name="playerShowcase" style="width: 100%;height: 100%"></iframe>

                </div>
                <div class="modal-footer">
                </div>
            </div>
        </div>
    </div>


`;

GamerShowcase.reg('gamer-showcase');
