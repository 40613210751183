"use strict";
/*---------------------------------------------------------------------------|
 * Contains the rest of the types which doesn't belong to the other files.
 *--------------------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.Status = void 0;
/**
 * The possible status for a match.
 */
var Status;
(function (Status) {
    /** The two matches leading to this one are not completed yet. */
    Status[Status["Locked"] = 0] = "Locked";
    /** One participant is ready and waiting for the other one. */
    Status[Status["Waiting"] = 1] = "Waiting";
    /** Both participants are ready to start. */
    Status[Status["Ready"] = 2] = "Ready";
    /** The match is running. */
    Status[Status["Running"] = 3] = "Running";
    /** The match is completed. */
    Status[Status["Completed"] = 4] = "Completed";
    /** At least one participant completed his following match. */
    Status[Status["Archived"] = 5] = "Archived";
})(Status = exports.Status || (exports.Status = {}));
