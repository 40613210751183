import { BaseComponent } from "@symbiotejs/symbiote";
import { I18n } from "../../utilities/i18n/i18n";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";
import { DiscordTemplate } from "../discord-template/discord-template";
import { NewsCarousel } from "../news-carousel/news-carousel";
import { PredictionsHomecomponent } from "../predictions-homecomponent/predictions-homecomponent";
import { AnalysesHomecomponent } from "../analyses-homecomponent/analyses-homecomponent";
import { GridOrchestrator } from "../grid-orchestrator/grid-orchestrator";
import { AuthorsComponent } from "../author-component/author-component";
import { upcomingMatches } from "../upcomingmatches-container/upcoming-matches";
import { BookmakershomeComponent } from "../bookmakershome-component/bookmakershome-component";
import { WidgetMatches } from "../widget-matches/widget-matches";
import { WidgetBookmakers } from "../widget-bookmakers/widget-bookmakers";
import { MatchesComponent } from "../matches-component/matches-component";
import { HotmatchComponent } from "../hotmatch-component/hotmatch-component";
import { HottournamentsComponent } from "../hottournaments-component/hottournaments-component";
import { PlaceholderMatchesWidget } from "../placeholder-matcheswidget/placeholder-matcheswidget";

import { MiscAssistant } from "../../utilities/misc-assistant/misc-assistant";


const css = `
    <style>
   
        
        .home-components{
        position: relative;
        background-color:transparent ;
        border-radius: 8px;
        
        }
        
         @media screen and (orientation:portrait) {
            
        }
         @media screen and (orientation:landscape) {
            
        }
    </style>
`;

export class HomeContainer extends BaseComponent {

    initCallback() {

        document.head.insertAdjacentHTML("beforeend", css);
        window.addEventListener('resize', function () {
            // if no resize event is fired within 250ms of the lastly fired one, call the performOrientationChangeActions function :)
            MiscAssistant.debounce(HomeContainer.performOrientationChangeActions, 250)
        }, true)

        RouterAssistant.checkForSingleContainer('home-container', HomeContainer.initiator)
    }

    static initiator() {
        HomeContainer.performOrientationChangeActions();
        GridOrchestrator.activateGameFilters()
    }


    static loadDesktopLayout() {
        if (document.querySelectorAll('hotmatch-component').length !== 0) {
            const container = document.getElementById('homeContainerInnerContainer')
            container.removeAttribute('class')
            container.classList.add("main-content","col-lg-8","col-md-8","col-sm-8","col-xs-12","g-0","mt-0","mt-sm-0","mt-md-0","mt-lg-0","mt-xl-2","mt-xxl-2")
            document.querySelector('.sidebar-matches').remove();
            document.querySelector('.sidebar-predictions').remove();
            document.querySelector('.sidebar-discord').remove();
            document.querySelector('.sidebar-bonus').remove();
            container.insertAdjacentHTML("afterend", `
  
                <div class="sidebar col-lg-4 col-md-4 col-sm-4 col-xs-12 mt-0 ">
              
                    <placeholder-matcheswidget></placeholder-matcheswidget>
                    <div class="sidebar-matches home-components row m-2 ">
                        <div class="title">
                        </div>
                        <matches-component data-mode="homePage"></matches-component>
                    </div>
                    <div class="sidebar-predictions home-components row m-2 ">
<!--                        <predictions-homecomponent></predictions-homecomponent>-->
                        <analyses-homecomponent></analyses-homecomponent>
                    </div>
                    <div class="sidebar-discord home-components row m-2 " >
                        <div class="home-discord-template">
                            <discord-template></discord-template>
                        </div>
                    </div>
                    <div class="sidebar-bonus home-components row m-2 ">
                        <div class="home-widget-bookmakers">
                            <widget-bookmakers></widget-bookmakers>
                        </div>
                    </div>
                </div>
            `)
        } else{
            document.querySelector('home-container').insertAdjacentHTML('afterbegin', /*html*/`
            <div id="homeContainerInnerContainer" class="main-content col-lg-8 col-md-8 col-sm-8 col-xs-12 g-0 mt-0 mt-sm-0 mt-md-0 mt-lg-0 mt-xl-2 mt-xxl-2 ">
                <div class="home-matches home-components row mx-2">
                    <div class="home-upcoming-matches">
                        <hotmatch-component></hotmatch-component>
                    </div>
                </div>
                <div class="home-latest-news home-components row m-1 mt-0 mt-lg-5">
                    <news-carousel></news-carousel>
                </div>
                <div class="home-tournaments home-components row m-2">
                    <div class="home-carousel-slider">
                        <hottournaments-component></hottournaments-component>
                    </div>
                </div>
            </div>
            <div class="sidebar col-lg-4 col-md-4 col-sm-4 col-xs-12 mt-0 ">
                <placeholder-matcheswidget></placeholder-matcheswidget>
                <div class="sidebar-matches home-components row m-2 ">
                    <div class="title">
                    </div>
                    <matches-component data-mode="homePage"></matches-component>
                </div>
                <div class="sidebar-predictions home-components row m-2 ">
<!--                    <predictions-homecomponent></predictions-homecomponent>-->
                    <analyses-homecomponent></analyses-homecomponent>
                </div>
                <div class="sidebar-discord home-components row m-2 " >
                    <div class="home-discord-template">
                        <discord-template></discord-template>
                    </div>
                </div>
                <div class="sidebar-bonus home-components row m-2 ">
                    <div class="home-widget-bookmakers">
                        <widget-bookmakers></widget-bookmakers>
                    </div>
                </div>
            </div>  
        `)
        }
    }

    static loadMobileLayout() {
        if (document.querySelectorAll('hotmatch-component').length !== 0) {
            const container = document.getElementById('homeContainerInnerContainer')
            container.removeAttribute('class')
            container.classList.add("main-content", "col-12")
            document.querySelector('.sidebar').remove();
            document.querySelector('.home-matches').insertAdjacentHTML('afterend', `
                <div class="sidebar-matches home-components row mx-0 mt-1">
                    <div class="title">
                    </div>
                    <matches-component data-mode="homePage"></matches-component>
                </div> 
            `)
            document.querySelector('.home-tournaments').insertAdjacentHTML('afterend', `
                <div class="sidebar-predictions home-components row m-2 ">
<!--                    <predictions-homecomponent></predictions-homecomponent>-->
                    <analyses-homecomponent></analyses-homecomponent>
                </div>
                <div class="sidebar-discord home-components row m-2 " >
                    <div class="home-discord-template">
                        <discord-template></discord-template>
                    </div>
                </div>
                <div class="sidebar-bonus home-components row m-2 ">
                    <div class="home-widget-bookmakers">
                        <widget-bookmakers></widget-bookmakers>
                    </div>
                </div>
            `)
        } else {
            document.querySelector('home-container').insertAdjacentHTML('afterbegin', /*html*/`
            <div  id="homeContainerInnerContainer" class="main-content col-12 ">
                <div class="home-matches home-components row mx-2">
                    <div class="home-upcoming-matches">
                        <hotmatch-component></hotmatch-component>
                    </div>
                </div>
                 <div class="sidebar-matches home-components row mx-0 mt-1">
                    <div class="title">
                    </div>
                    <matches-component data-mode="homePage"></matches-component>
                </div>
                <div class="home-latest-news home-components row m-1 mt-0 mt-lg-5">
                    <news-carousel></news-carousel>
                </div>
                <div class="home-tournaments home-components row m-2">
                    <div class="home-carousel-slider">
                        <hottournaments-component></hottournaments-component>
                    </div>
                </div>
                <div class="sidebar-predictions home-components row m-2 ">
<!--                    <predictions-homecomponent></predictions-homecomponent>-->
                    <analyses-homecomponent></analyses-homecomponent>
                </div>
                <div class="sidebar-discord home-components row m-2 " >
                    <div class="home-discord-template">
                        <discord-template></discord-template>
                    </div>
                </div>
                <div class="sidebar-bonus home-components row m-2 ">
                    <div class="home-widget-bookmakers">
                        <widget-bookmakers></widget-bookmakers>
                    </div>
                </div>
            </div>
        `)
        }
    }

    static wipeCurrentLayout() {
        document.querySelector('home-container').innerHTML = ''
    }

    static performOrientationChangeActions() {
        if (window.location.href.includes('home')) {
            const currentState = (window.innerHeight > window.innerWidth) ? 'mobile' : 'desktop';
            const thereIsContentInside = document.querySelectorAll('hotmatch-component').length !== 0;
            if (!thereIsContentInside && window.homePagePreviousOrientationState !== undefined) {
                window.homePagePreviousOrientationState = undefined
                HomeContainer.performOrientationChangeActions()
            } else {
                if (currentState === 'mobile' && (window.homePagePreviousOrientationState === undefined || window.homePagePreviousOrientationState === 'desktop')) {
                    console.debug('changing home page state layout')
                    HomeContainer.loadMobileLayout()
                    window.homePagePreviousOrientationState = currentState
                } else if (currentState === 'desktop' && (window.homePagePreviousOrientationState === undefined || window.homePagePreviousOrientationState === 'mobile')) {
                    console.debug('changing home page state layout')
                    HomeContainer.loadDesktopLayout()
                    window.homePagePreviousOrientationState = currentState
                }
            }
        }
    }
}

HomeContainer.template = /*html*/ ``;

HomeContainer.reg('home-container');
